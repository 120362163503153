import React, { useEffect, useState } from 'react';
import { Grid, FormControl } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from '@material-ui/core/InputLabel';

import { optionSelectProps, selectProps } from '../../../_helpers/props/form_props';

export function useSelect(){
  const SelectFilter = (props:selectProps) => {
    const [ options, setOptions ] = useState<React.ReactNode>();

    useEffect( () => {
      var auxOptions:optionSelectProps[] = props.options ? props.options : [];
      setOptions( auxOptions.map( ( item:any, index:number ) => {
        return <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
      }) );
    }, [props.options] );

    const handleSelectChangeVal = (event: React.ChangeEvent<{ value: unknown }>) => {
      props.handleData( event.target.value as string );
    };
 
    return (
      <React.Fragment>
        <Grid item>
          <FormControl variant="outlined">
            { props.label &&
                <InputLabel id="summary-select">{props.label}</InputLabel>
            }
            <Select
              value={ props.value ? props.value : "" }
              onChange={ handleSelectChangeVal }
              disabled={ props.disabled ? props.disabled : false }
              autoWidth
              style={{minWidth: 200}}
            >
              <MenuItem value=""><em>None</em></MenuItem>
              { options }
            </Select>
          </FormControl>
        </Grid>
      </React.Fragment>
    )
  }

  return { SelectFilter }
}

export default useSelect;
