import React from 'react';
import useFetch from '../../_services/fetch';
import useErrorManager from '../common/ErrorManager';
import { errorSectionType } from '../../_helpers/enums/all';
import LinearProgress from '@material-ui/core/LinearProgress';


import ReservationsForm from './forms/ReservationsForms';



const ReservationsNew = () => {
  const { response, loading, error } = useFetch({ method: 'GET', path: '/get/reservations/new'});
  const { alertBlock } = useErrorManager({ id: 'reservations_form', section: errorSectionType.Custom });
  
  return (
    loading ? <LinearProgress color="primary" /> :
      <div className="content_all">
        <div className="content">
          { alertBlock }
          <ReservationsForm response={response} error={error}/>
      </div>
    </div>
  )
}

export default ReservationsNew;