import React, { useEffect } from "react";
import { ItemInCartProps, KitChangeItem, changeProductProps } from "../../../_helpers/props/cash_register_props";
import { useCartDataContext } from "../cart_provider";


const BillItem = (props:ItemInCartProps) => {

  return (
    <>
      { props.adults !== 0 &&
        <ItemRow data={props} size='Adulto' />
      }
      { props.junior !== 0 &&
        <ItemRow data={props} size='Junior' />
      }
    </>
  )

};

export default BillItem;

interface itemProps {
  data: ItemInCartProps,
  size: string,

}

const ItemRow = (props:itemProps) => {
  const { getProductName } = useCartDataContext();

  function getName( product_id:string|number|null ){
    if( product_id ){
      return getProductName({ size: 'adults', variation_id: Number(product_id) }).replace('Adulto', 'Ad.').replace('Junior', 'Jr.');
    }
  }

  return (
    <>
      <div className="detalle_producto_unidad">
        <div className="detalle_producto_cantidad text-center">
        { props.size === 'Adulto' ?
            props.data.adults
          :
            props.data.junior
        }
        </div>
        <div className="detalle_producto_nombre  text-left">
          { props.data.product_name } - { props.size }
        </div>
        <div className="detalle_producto_total text-right">
          ${new Intl.NumberFormat("es-CL").format( Math.abs(props.data.sizes.filter(variation => variation.name === props.size )[0].price) )}
        </div>
        <div className="suelta"></div>
      </div>
      <div className="detalle_producto_unidad text-left" style={{paddingLeft: 40,}}>
      

        { (props.data.changes.adults.length !== 0 || props.data.changes.junior.length !== 0) ?
            

            props.size === 'Adulto' ? 
              props.data.changes.adults.map((item:KitChangeItem, index:number) => {
                return item.data.filter( change => change.product_new !== null ).map((change:changeProductProps, indexChange: number) => {
                  return (
                    <div className="detalle_producto_cambio" key={indexChange}>
                      Cambio { getName(change.product_original) } por { getName(change.product_new) }
                    </div>
                  )
                });
              })
            :
            props.data.changes.junior.map((item:KitChangeItem, index:number) => {
              return item.data.filter( change => change.product_new !== null ).map((change:changeProductProps, indexChange: number) => {
                return (
                  <div className="detalle_producto_cambio" key={indexChange}>
                    Cambio { getName(change.product_original) } por { getName(change.product_new) }
                  </div>
                )
              });
            })
          :
            null
        }
      </div>
    </>
  )
}