import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import BaseButton from '../../common/BaseButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { errorSectionType, errorType } from '../../../_helpers/enums/all';
import useErrorManager from '../../common/ErrorManager';
import { baseApiURL } from '../../../_services/fetch';
import { post } from '../../../_services/api';

interface confirmationBoxProps {
  url               : string,
  active            : boolean,
  type              : string,
  request           : string,
  closeAction     (): void,
  resolvedAction  (): void,
}

const ConfirmationBox = ( props:confirmationBoxProps ) => {
  const { alertBlock, showAlert, resetAlerts } = useErrorManager({ id: 'guarantee_modal', section: errorSectionType.Custom });
  const [ activeModal, setActiveModal ]         = useState<boolean>(true);
  const [ loadingSend, setLoadingSend ]         = useState<boolean>( false );

  const handleCancel = () => {
    setActiveModal( false );
    resetAlerts();
    props.closeAction();
  }

  const handleSend = () => {
    setLoadingSend( true );
    let guaranteeApproval_response = post( baseApiURL+props.url, {} );

    guaranteeApproval_response.then((response:any) => { 
      setLoadingSend( false );
      props.resolvedAction();
    })
    .catch( error => {
      setLoadingSend( false );
      showAlert({
        message: 'Hubo un problema al solicitar '+(props.type === 'approve' ? 'la aprobación' : 'el rechazo')+'. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });
  }
  

  return (
    <Dialog open={activeModal} className="guarantee-box">
      <DialogTitle id="alert-dialog-title">
        { props.type === 'approve' ?
            '¿Deseas aprobar esta solicitud?'
          :
            '¿Deseas rechazar esta solicitud?'
        }
      </DialogTitle>
      <DialogContent className="guarantee-box-content">
        { alertBlock }
        <DialogContentText id="alert-dialog-description">
          <strong>Solicitud:</strong>
          <br /><em>{props.request}</em>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <BaseButton onClick={() => { handleCancel() }} disabled={loadingSend}>
          Cancelar
        </BaseButton>
        <BaseButton onClick={() => { handleSend() }} disabled={loadingSend} >
          { loadingSend ?
              <CircularProgress />
            :
              props.type === 'approve' ?
                'Aprobar solicitud'
              :
                'Rechazar solicitud'
          }
        </BaseButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationBox;