import React, { useState, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import { useCartDataContext } from '../cart_provider';
import { StepBlock, MoneyTypes } from '../../../_helpers/enums/cash_register';
import { NumberFormatPesos, NumberFormatDolar, NumberFormatReal } from '../../common/InputNumberFormat';
import { CreditItem, DebitItem } from './CardItem';
import BaseButton from '../../common/BaseButton';
import PreSaleInput from './PreSaleInput';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import PreSaleItem from './PreSaleItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { errorSectionType, errorType, creditRequestStatusType } from '../../../_helpers/enums/all';
import useErrorManager from '../../common/ErrorManager';
import { baseApiURL } from '../../../_services/fetch';
import { get, post } from '../../../_services/api';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import loadingGif from '../../../assets/all/loading.gif';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { SalePaymentProps } from '../../../_helpers/props/cash_register_props';


interface CountryType {
  name: string;
}

const CartPayment = () => {
  const { alertBlock, showAlert, resetAlerts } = useErrorManager({ id: 'cart_payments', section: errorSectionType.Custom });



  const { cart,
          cartOrder,
          cartPayment,
          setMoneyValues, setDubraCredit, addCard, addPreSale } = useCartDataContext();
  const [ totalPayment, setTotalPayment ]       = useState<number>(0);
  const [ totalVuelto, setTotalVuelto ]         = useState<number>( 0 );
  const [ creditPayment, setCreditPayment ]     = useState<React.ReactNode>();
  const [ debitPayment, setDebitPayment ]       = useState<React.ReactNode>();
  const [ preSalePayment, setPreSalePayment ]   = useState<React.ReactNode>();
  const [ presaleDetail, setPresaleDetail ]     = useState<React.ReactNode>();
  const [ activePayment, setActivePayment ]     = useState<number>( 1 );
  const [ cashDiscount, setCashDiscount ]       = useState<boolean>( false );

  const [ noPayOptions, setNoPayOptions ]       = useState<React.ReactNode[]>( [] );
  const [ noPaySelected, setNoPaySelected ]     = useState<string>( 'none' );
  const [ loadingSend, setLoadingSend ]         = useState<boolean>( false );

  const [ requestUUID, SetRequestUUID ]         = useState<string>( 'new' );
  const [ activePending, setActivePending ]     = useState<boolean>( false );
  const [ requestStatus, SetRequestStatus ]     = useState<number>( 0 );

  


  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActivePayment(newValue);
    resetAlerts();
    setNoPayOptions([]);

    if( newValue === 3 ){
      let creditOptions_response = get( baseApiURL+'/get/credit-options' );

      creditOptions_response.then((response:any) => { 
        if( response.data ){
          let auxCreditsOptions:React.ReactNode[] = [];
          let auxDataLength = response.data.length;

          for( let i=0; i<auxDataLength; i++ ){
            auxCreditsOptions.push(
              <MenuItem key={i+1} value={response.data[i].uuid}>
                <em>{response.data[i].name}</em>
              </MenuItem>
            );
          }

          setNoPayOptions( auxCreditsOptions );
        }
      })
      .catch( error => {
        setNoPayOptions( [<em>Error en la carga de las opciones</em>] );
        showAlert({
          message: 'Hubo un problema al cargar las opciones de crédito disponibles. ['+error+']',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      });

    }

  };

  useEffect( () => {
    // Limpiamos valores de modal
    setPresaleDetail( null );

    if( cartPayment.payment.pre_sale.length !== 0 ){
      let preSalePaymentAux = cartPayment.payment.pre_sale.map((item, index:number) => {
        return (
          <PreSaleInput key={index} index={index} />
        )
      });
      setPreSalePayment( preSalePaymentAux );

      let auxItemActive = false;
      const auxPresaleDetail:React.ReactNode = cartPayment.payment.pre_sale.map((item:any, index:number) => {

        if( item.info && item.info !== null ){
          auxItemActive = true;
          const auxInfoUsed = JSON.parse( item.info.used );

          var auxProductDetail:any = [];
          if( item.info.products ){
            Object.keys(item.info.products).forEach( (key:any) => {

              
              // Debo repetir por toda la cantidad
              for( let i = 0; i < item.info.products[ key ].quantity; i++ ){

                const auxProductData = {
                  data: item.info.products[ key ],
                  indexValue: auxProductDetail.length,
                  indexPresale: key,
                  indexPresalePosition: index,
                }
                if( i < auxInfoUsed[ key ] ){
                  auxProductDetail.push( <PreSaleItem key={auxProductData.indexValue} {...auxProductData} used={true} /> );
                } else {
                  auxProductDetail.unshift( <PreSaleItem key={auxProductData.indexValue} {...auxProductData} used={false} /> );
                }
              }

            })
          }

          return (
            <li key={index}>Pax: {item.info.pax && item.info.pax } <small>{item.info.agency && '(' + item.info.agency + ')' } </small>
              <ul>
                { auxProductDetail }
              </ul>
            </li>
          );
        }
      });

      if( auxItemActive ){
        setPresaleDetail( auxPresaleDetail );
      }
      
    } else {      
      setPreSalePayment( <BaseButton className="btn-dubra" onClick={ () => { addPreSale && addPreSale(); } } disabled={ cashDiscount }>Agregar código de preventa</BaseButton> );
    }
    
    if( cartPayment.payment.credito.length !== 0 ){
      let creditPaymentAux = cartPayment.payment.credito.map((item, index:number) => {
        return (
          <CreditItem key={index} 
            step={ StepBlock.Pago }
            index={index} />
        )
      });
      setCreditPayment( creditPaymentAux );
    } else {
      setCreditPayment( <div><BaseButton className="btn-dubra" onClick={ () => { addCard && addCard({ type: MoneyTypes.Credit ,step: StepBlock.Pago }); } } disabled={ cashDiscount }>Agregar pago con crédito</BaseButton></div> );
    }

    if( cartPayment.payment.debito.length !== 0 ){
      let debitPaymentAux = cartPayment.payment.debito.map((item, index:number) => {
        return (
          <DebitItem key={index} 
            step={ StepBlock.Pago }
            index={index} />
        )
      });

      setDebitPayment( debitPaymentAux );
    } else {
      setDebitPayment( <div><BaseButton className="btn-dubra" onClick={ () => { addCard && addCard({ type: MoneyTypes.Debit ,step: StepBlock.Pago }); } } disabled={ cashDiscount }>Agregar pago con débito</BaseButton></div> );
    }

    if( cartPayment.payment.pesos || cartPayment.payment.real || cartPayment.payment.dolar || cartPayment.payment.credito || cartPayment.payment.debito ){
      
      let totalPesos = 0;
      if( cartPayment.payment.pesos ){
        totalPesos = Number( cartPayment.payment.pesos );
      }
      
      let totalDolar = 0;
      if( cartPayment.tc.dolar && cartPayment.payment.dolar ){
        totalDolar = cartPayment.tc.dolar * cartPayment.payment.dolar;
      }

      let totalReal = 0;
      if( cartPayment.tc.real && cartPayment.payment.real ){
        totalReal = cartPayment.tc.real * cartPayment.payment.real;
      }

      let totalCredito = 0;
      if( cartPayment.payment.credito ){
        const auxCreditoLength = cartPayment.payment.credito.length;

        if( auxCreditoLength !== 0 ){
          for( let i = 0; i < auxCreditoLength; i++ ){
            if( cartPayment.payment.credito[i] ){
                totalCredito += Number( cartPayment.payment.credito[i].amount );
            }
          }
        }
      }

      let totalDebito = 0;
      if( cartPayment.payment.debito ){
        const auxDebitoLength = cartPayment.payment.debito.length;

        if( auxDebitoLength !== 0 ){
          for( let i = 0; i < auxDebitoLength; i++ ){
            if( cartPayment.payment.debito[i] ){
              totalDebito += Number( cartPayment.payment.debito[i].amount );
            }
          }
        }
      }

      let totalPresale = 0;
      if( cartPayment.payment.pre_sale ){
        const auxPresaleLength = cartPayment.payment.pre_sale.length;

        if( auxPresaleLength !== 0 ){
          for( let i = 0; i < auxPresaleLength; i++ ){
            if( cartPayment.payment.pre_sale[i] ){
              totalPresale += Number( cartPayment.payment.pre_sale[i].amount );
            }
          }
        }
      }

      let totalDiscount = 0;
      if( cartPayment.payment.discount.amount && cartPayment.payment.discount.amount !== 0 ){
        totalDiscount = cartPayment.payment.discount.amount;
      }
      //console.log('****', totalPresale, cartPayment.payment);
      

      let auxTotalCalculated = cartPayment.payment.total.subtotal - totalPesos - totalDolar - totalReal - totalCredito - totalDebito - totalPresale - totalDiscount;
      if( auxTotalCalculated < 0 ){
        //alert( 'pasamos' );
        setTotalVuelto( auxTotalCalculated * -1 );
      } else {
        setTotalVuelto( 0 );
      }

      // Verificamos si trae un estado activo para credito dubra
      /*
      if( requestStatus === creditRequestStatusType.Approved ){
        setTotalVuelto( 0 );
        setTotalPayment( 0 );
      } else  {
        //setVuelto && setVuelto( totalVuelto );
        setTotalPayment( auxTotalCalculated );
      }
      */
      setTotalPayment( auxTotalCalculated );

    } else {
      setTotalPayment( cartPayment.garantia.total );
    }
    
  }, [cartPayment, addPreSale, addCard] );

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const requestCredit = () => {
    setActivePending( false );
    setLoadingSend( true );
        
    let creditOptions_response = post( baseApiURL+'/post/new-request/'+requestUUID+'/credit', {
      cash_register: cartOrder.cash_register,
      name: cartOrder.name,
      agency: cartOrder.agencia,
      guide: cartOrder.guia,
      reason: noPaySelected,
    } );


    creditOptions_response.then((response:any) => { 
      if( response.data ){
        setLoadingSend( false );

        if( response.data.new ){
          SetRequestUUID( response.data.new );
          setActivePending( true );
          SetRequestStatus( creditRequestStatusType.Pending );
        } else if( response.data.status_id ) {

          if( response.data.status_id === creditRequestStatusType.Pending ){
            SetRequestStatus( response.data.status_id );
            setActivePending( true );



          } else if( response.data.status_id === creditRequestStatusType.Approved || response.data.status_id === creditRequestStatusType.Rejected ){

            SetRequestStatus( response.data.status_id );

            if( response.data.status_id === creditRequestStatusType.Approved ){
              setValueDiscount( 'dubra-100' );
              setCashDiscount( true );
              setDubraCredit && setDubraCredit({ request_auth: requestUUID, selected_reason: noPaySelected })
            }



          } else {
            showAlert({
              message: 'Hubo un problema al reconocer el estado de la solicitud.',
              type_values: errorType.Error,
              section: errorSectionType.Custom,
            });
          }
          
        } else {
          showAlert({
            message: 'Hubo un problema al cargar la información de la solicitud.',
            type_values: errorType.Error,
            section: errorSectionType.Custom,
          });
        }

      }
    })
    .catch( error => {
      setLoadingSend( false );
      showAlert({
        message: 'Hubo un problema al cargar al solicitar el crédito. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });

  }

  useEffect( () => {
    if( activePending ){
      const i_id = setInterval(() => {
        if( requestUUID !== 'new' ){
          requestCredit();
        }
      },5000);
      return () => {
        clearInterval(i_id);
      }
    }
  },[activePending, requestUUID]);

  const handleChangeEfectivo = (event: React.ChangeEvent<HTMLInputElement>) => {
    //console.log( event );
    //setState({ ...state, [event.target.name]: event.target.checked });
    setCashDiscount( !cashDiscount );
  };





  const [valueDiscount, setValueDiscount] = React.useState('cash-5');

  const handleChangeDiscountTotal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueDiscount((event.target as HTMLInputElement).value);
  };

  useEffect(() => {

    if( cashDiscount && setMoneyValues ){

      var auxDiscountPercent = 0;
      if( valueDiscount === 'cash-5' ){
        auxDiscountPercent = 0.05;
      } else if( valueDiscount === 'cash-10' ){
        auxDiscountPercent = 0.1;
      } else if( valueDiscount === 'dubra-100' ){
        auxDiscountPercent = 1;
      } 

      setMoneyValues({type: MoneyTypes.Discount, step: StepBlock.Pago,  value: {
        amount: auxDiscountPercent,
        name: valueDiscount,
      }})

    } else {
      setMoneyValues && setMoneyValues({type: MoneyTypes.Discount, step: StepBlock.Pago,  value: 0})
    }
    
  }, [cashDiscount, valueDiscount]);






  return (
    <React.Fragment>
      <h3>Tipo de pago</h3>
      <p>Ingresar el tipo de pago del cliente</p>

      <Tabs
        value={activePayment}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
          scrollButtons="auto"
      >
        <Tab label="Pre-compra" />
        <Tab label="Efectivo" />
        <Tab label="Tarjeta" />
        <Tab label="Dubra" />
      </Tabs>

      { activePayment === 0 ?
          <div className="payment-item-block">
            <h3>Ingresar detalle Preventa</h3>
            { preSalePayment }
            { cartPayment.payment.pre_sale.length !== 0 &&
              <React.Fragment>

                { presaleDetail !== null &&
                    <BaseButton onClick={handleClickOpen}>Revisar detalle</BaseButton>
                }
                
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">Detalle preventa</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Listado con detalle de las preventa ingresadas
                    </DialogContentText>
                    <div className="presale-dialog-content">
                      { presaleDetail !== null &&
                          presaleDetail
                      }
                    </div>
                  </DialogContent>
                  <DialogActions>
                    - - - 
                  </DialogActions>
                </Dialog>
              </React.Fragment>
            }
          </div>
        :
          activePayment === 1 ? 
            <div className="payment-item-block">
              <h3 style={{marginBottom: 0}}>Ingresar detalle pago</h3>
              <p style={{marginTop: 0}}>Coloca el monto pagado en según tipo de moneda</p>
              <br />


              <div className='' style={{background: '#ffc855', padding: 20, marginBottom: 20,}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cashDiscount}
                      onChange={handleChangeEfectivo}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Activar descuento efectivo sobre arriendo"
                  //style={{paddingBottom: 20}}
                />
                { cashDiscount ?
                    <FormControl component="fieldset" style={{display: 'block'}}>
                      <FormLabel component="legend" style={{paddingTop: 10, paddingBottom: 10, fontWeight: 'bold'}}>Monto del descuento</FormLabel>
                      <RadioGroup aria-label="discountTotal" name="discountTotal" value={valueDiscount} onChange={handleChangeDiscountTotal} style={{display: 'flex', flexDirection: 'initial'}}>
                        <FormControlLabel value="cash-5" control={<Radio />} label="5% de descuento" style={{width: '49%'}} />
                        <FormControlLabel value="cash-10" control={<Radio />} label="10% de descuento" style={{width: '49%'}} />
                      </RadioGroup>
                    </FormControl>
                  : null
                }
              </div>

              <FormControl variant="outlined" fullWidth className="form-item form-item-payment">
                <TextField label="Monto en pesos" value={ cartPayment.payment.pesos } onChange={ (aux_val) => {
                    setMoneyValues && setMoneyValues({type: MoneyTypes.Pesos, step: StepBlock.Pago,  value: aux_val.target.value})
                  }} InputProps={{ inputComponent: NumberFormatPesos, }} variant="outlined" />
                </FormControl>
              <FormControl variant="outlined" fullWidth className="form-item form-item-payment">
                <TextField label="Monto en reales" value={ cartPayment.payment.real } onChange={ (aux_val) => {
                    setMoneyValues && setMoneyValues({type: MoneyTypes.Real, step: StepBlock.Pago,  value: aux_val.target.value})
                  }} InputProps={{ inputComponent: NumberFormatReal, }} variant="outlined" />
                </FormControl>
              <FormControl variant="outlined" fullWidth className="form-item form-item-payment">
                <TextField label="Monto en dólares" value={ cartPayment.payment.dolar } onChange={ (aux_val) => {
                    setMoneyValues && setMoneyValues({type: MoneyTypes.Dolar, step: StepBlock.Pago,  value: aux_val.target.value})
                  }} InputProps={{ inputComponent: NumberFormatDolar, }} variant="outlined" />
              </FormControl>

            </div>
          :
            activePayment === 2 ? 
              <div className="payment-item-block">
                <h3>Ingresar pago crédito</h3>
                { creditPayment }
                <hr />
                <h3>Ingresar pago débito</h3>
                { debitPayment }

              </div>
            :
              activePayment === 3 ? 
              <div className="payment-item-block">
                <h3 style={{marginBottom: 0}}>Seleccione un motivo</h3>
                <p style={{marginTop: 0}}>Debes escoger la razón para solicitar crédito dubra</p>
                { alertBlock }
                
                { noPayOptions.length === 0 ?
                    <CircularProgress />
                  :
                  <React.Fragment>
                    <FormControl variant="outlined" fullWidth className="form-item form-item-payment">
                      <Select
                        labelId="request_nopayment"
                        value={noPaySelected}
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => { if( typeof event.target.value !== 'string' ){ setNoPaySelected('none'); } else { setNoPaySelected( event.target.value ); }  }}
                        disabled={ (requestUUID !== 'new' || cashDiscount) ? true : loadingSend }
                      >
                        <MenuItem value="none">
                          <em>Selecciona una opción</em>
                        </MenuItem>
                        { noPayOptions }
                      </Select>
                    </FormControl>
                    { requestUUID === 'new' ?
                        <BaseButton className="btn-dubra" onClick={requestCredit} disabled={ noPaySelected === 'none' ? true : loadingSend} loading={loadingSend}>Solicitar autorización</BaseButton>
                      :
                        (
                          requestStatus === creditRequestStatusType.Pending ?
                            <div style={{textAlign: 'center', paddingBottom: 20,}}>
                              <img src={loadingGif} width={60} alt="Waiting for approval" style={{margin:'0 auto',}} />
                              <br />
                              <Typography variant="h5" align="center" color="textSecondary" gutterBottom>
                                Estamos esperando la aprobación
                                <br />del supervisor
                              </Typography>
                            </div>
                          :
                            (
                              requestStatus === creditRequestStatusType.Approved ?
                                'aprobado'
                              :
                                'rechazado ):'
                            )
                        )
                    }
                    <Divider />
                  </React.Fragment>
                }
              </div>
              :
              '-'
      }

      { totalPayment > 0 ?
          `Pendiente: $${new Intl.NumberFormat("es-CL").format( totalPayment )}`
        :
          `Vuelto: $${new Intl.NumberFormat("es-CL").format( totalVuelto )} ${ (cartPayment.payment.dubra.selected_reason !== '' || cartPayment.payment.dubra.request_auth !== '') ? '- Crédito dubra' : '' }`
      }
      

    </React.Fragment>
  )
}

export default CartPayment;