import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { InputNumberProps } from '../../_helpers/props';
import FormControl from '@material-ui/core/FormControl';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const InputNumber = ( props:InputNumberProps ) => {
  return (
    <Grid container className="input-number" alignItems="flex-end">
      <Grid item className="input-number-less" onClick={props.onLess}>
        <RemoveIcon fontSize="large" color="primary" />
      </Grid>
      <Grid item className="input-number-input">
        <FormControl fullWidth className="form-item">
          <TextField label={props.label && props.label} value={props.value} size="small" />
        </FormControl>
      </Grid>
      <Grid item className="input-number-more" onClick={props.onPlus}>
        <AddIcon fontSize="large" color="primary" />
      </Grid>
    </Grid>
  )
}

export default InputNumber;