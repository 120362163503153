import React, { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import TableContent from '../common/table/TableContent';

import { IColumn } from '../common/table/table-types';
import { errorSectionType, errorType } from '../../_helpers/enums/all';
import useErrorManager from '../common/ErrorManager';
import EditIcon from '@material-ui/icons/Edit';

const Users = (props: RouteComponentProps) => {
  let history = useHistory();
  document.title = 'Usuarios';

  //const { response, loading, error } = useFetch({ method: 'GET', path: '/get/presales'});
  const { alertBlock, showAlert } = useErrorManager({ id: 'users_list', section: errorSectionType.Custom });
  const [ loadingValidation ] = useState<boolean>( false ); //const [ loadingValidation, setLoadingValidation ] = useState<boolean>( false );

  const handleClick = ( props:any ) => {
    history.push( props.url );
  }

  const columns:Array<IColumn> = [
    { id: 'created',        visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Fecha',                 sortable: true },
    { id: 'name',           visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Nombre',                sortable: true },
    { id: 'agency_name',    visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Agencia',               sortable: true },
    { id: 'mail',           visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Mail',                  sortable: false },
    { id: 'access',         visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Acceso',                sortable: false },
    { id: 'status',         visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Estado',                sortable: false },
    { id: 'edit',           visible: true,    numeric: false,   button: true,    disablePadding: true,  label: 'Modificar',             sortable: false    ,   button_name: <EditIcon />,           button_color: 'secondary',     button_action: handleClick,  },
    //{ id: 'status',         visible: true,    numeric: false,   button: true,    disablePadding: true,  label: 'Deshabilitar',          sortable: false    ,   button_name: <Button>e</Button>,           button_color: 'secondary',     button_action: handleClick,  },
  ];

  useEffect( () => {

    if( history.location.search.includes('save') ){
      let auxUrlParams = new URLSearchParams( history.location.search );
      
      showAlert({
        message: 'La cuenta del usuario '+auxUrlParams.get('save')+' se ha modificado correctamente.',
        type_values: errorType.Success,
        section: errorSectionType.Custom,
        id: 'users_list',
      });
    } else if( history.location.search.includes('deleted') ){
      let auxUrlParams = new URLSearchParams( history.location.search );
      
      showAlert({
        message: 'La cuenta del usuario '+auxUrlParams.get('deleted')+' se ha eliminado correctamente.',
        type_values: errorType.Warning,
        section: errorSectionType.Custom,
        id: 'users_list',
      });
    } else if( history.location.search.includes('new') ){
      let auxUrlParams = new URLSearchParams( history.location.search );
      
      showAlert({
        message: 'La cuenta del usuario '+auxUrlParams.get('new')+' se ha creado correctamente correctamente.',
        type_values: errorType.Success,
        section: errorSectionType.Custom,
        id: 'users_list',
      });
    }

  }, [] );

  return (
    loadingValidation ? <LinearProgress color="primary" /> :
    <div className="content_all">
      <div className="content">
        { alertBlock }

        <Paper className="content">
          <div className="paper_content_container">
            <TableContent
              id="users_table"
              title="Listado de usuarios"
              columnsValues={columns}
              //urlData="/get/users/all/list"
              urlData="/admin/users/all"
              emptyData="No se encontraron usuarios para mostrar"
              apiType
            />
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default Users;