import React, { useEffect, useState } from 'react';
import BlockContent from '../../common/BlockContent';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import BaseButton from '../../common/BaseButton';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useHistory } from 'react-router-dom';
import { baseApiURL } from '../../../_services/fetch';
import { errorSectionType, errorType, roleType } from '../../../_helpers/enums/all';
import useErrorManager from '../../common/ErrorManager';
import Grid from '@material-ui/core/Grid';
import {actionProps} from '../../../_helpers/props/all';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { NumberFormatPesos } from '../../common/InputNumberFormat';
import { get, post } from '../../../_services/api';
import { useAuthDataContext } from '../../../_auth/auth_provider'

const ExchangeBlock = () => {
  let history = useHistory();
  const { userPermission } = useAuthDataContext();

  const { alertBlock, showAlert } = useErrorManager({ id: 'exchange_block', section: errorSectionType.Custom });
  const alertModal = useErrorManager({ id: 'exchange_dialog', section: errorSectionType.Custom });

  const [ loading, setLoading ] = useState<boolean>( true );
  
  const [ dataLoaded, setDataLoaded ] = useState<boolean>( false );
  const [ node, setNode ]             = useState<string>( '' );
  const [ dolar, setDolar ]           = useState<number>(0);
  const [ real, setReal ]             = useState<number>(0);
  const [ date, setDate ]             = useState<string>( '-' );
  const [ actions, setActions ]       = useState<actionProps[]>([]);

  const [ activeDialog, setActiveDialog ] = useState<boolean>( false );
  const [ savingData, setSavingData ] = useState<boolean>( false );
  const [ tempDolar, setTempDolar ] = useState<number>(0);
  const [ tempReal, setTempReal ] = useState<number>(0);

  const getData = () => {
    setLoading( true );
    const responseData = get(baseApiURL+'/get/dashboard/exchange');

    responseData.then((response:any) => {
      setLoading( false );
      setDataLoaded( true );

      if( response.data ){

        if( response.data !== null ){
          setDolar( response.data.dolar );
          setReal( response.data.real );
          setDate( response.data.created );
          setNode( response.data.uuid );
        }
        
      }
      
    }).catch(error => {
      setLoading( false );
      showAlert({
        message: 'Hubo un error al cargar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
        id: 'dashboard',
      });
    });

  }

  useEffect( () => {
    getData();
  }, []);

  const handleCancel = () => {
    setActiveDialog( false );
    setTempDolar( 0 );
    setTempReal( 0 );

    alertModal.resetAlerts();
  }

  const handleSave = () => {
    setSavingData( true );
    alertModal.resetAlerts();

    const responseData = post(baseApiURL+'/post/admin/exchange', {
      uuid: node,
      dolar: tempDolar,
      real: tempReal,
    });

    responseData.then((response:any) => {
      if( response.data && response.data === 'ok' ){
        setSavingData( false );
        getData();
        setActiveDialog( false );
      } else {
        setSavingData( false );
        alertModal.showAlert({
          message: 'Hubo un error al guardar la información. [Error: Problema en la respuesta]',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      }
    }).catch(error => {
      setSavingData( false );
      alertModal.showAlert({
        message: 'Hubo un error al guardar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });

  }

  return (
    <BlockContent title="Tipo de cambio" loading={loading} actions={ actions }>
      { alertBlock }
      { dataLoaded &&
        <React.Fragment>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AttachMoneyIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Dólar" />
              <ListItemSecondaryAction>
                <strong>${new Intl.NumberFormat("es-CL").format(dolar)}</strong>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AttachMoneyIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Real" />
              <ListItemSecondaryAction>
                <strong>${new Intl.NumberFormat("es-CL").format( real )}</strong>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <br />
          { userPermission([roleType.Admin, roleType.SuperAdmin]) && 
              <div className="btn-container-dashboard">
                <BaseButton 
                    className="btn btn-simple btn-dubra"
                    onClick={() => {
                      setActiveDialog( true );
                      setTempDolar( dolar );
                      setTempReal( real );
                  }}>Modificar valores</BaseButton>
              </div>
          }
          <Typography variant="caption" align="center"  display="block">
            <em>Última actualización:
            <br />{ date }
            </em>
          </Typography>
          <Dialog
            open={activeDialog}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              Tipo de cambio
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Ingresa el valor actual de las monedas Dólar y Real:
                <br />
              </DialogContentText>
              { alertModal.alertBlock }
              <Grid container spacing={4}>
                <Grid item xs={12} md={5}>
                  <FormControl fullWidth variant="outlined" className="form-item form-item-payment">
                    <TextField label="Valor dólar" value={tempDolar} onChange={ (aux_val) => { setTempDolar( parseInt(aux_val.target.value) ); }} InputProps={{ inputComponent: NumberFormatPesos, }} variant="outlined" disabled={savingData} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={5}>
                  <FormControl fullWidth variant="outlined" className="form-item form-item-payment">
                    <TextField label="Valor real" value={tempReal} onChange={ (aux_val) => { setTempReal( parseInt(aux_val.target.value) ); }} InputProps={{ inputComponent: NumberFormatPesos, }} variant="outlined" disabled={savingData} />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <div className="btn-container-dashboard">
                <BaseButton className="btn btn-outlined btn-dubra-cancel" onClick={handleCancel} disabled={savingData}>
                  Cancelar
                </BaseButton>
                <BaseButton className="btn btn-simple btn-dubra" onClick={handleSave} loading={savingData}>
                  Guardar
                </BaseButton>
              </div>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      }
    </BlockContent>
  );
}

export default ExchangeBlock;