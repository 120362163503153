import React, {useEffect, useState} from 'react';
import { useTabsDataContext } from './tabs_provider';

import { useHistory } from 'react-router-dom';
import { roleType } from '../../../_helpers/enums/all';


import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useAuthDataContext } from '../../../_auth/auth_provider';


const HeaderTabs = () => {
  let history = useHistory();
  const { tabsMenu, activeValue, setDEFActiveValue } = useTabsDataContext();
  const { user, activeCashRegister } = useAuthDataContext();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    //console.log( 'holaaaaaaaaa', newValue, activeCashRegister );
    var auxCountPemission = 0;

    setDEFActiveValue( newValue );

    for(var i=0; i<tabsMenu.length; i++){
      if( tabsMenu[i].permission.some(r => user.usertype.indexOf(r) >= 0 ) ){
        
        if(newValue === auxCountPemission){
          history.push( tabsMenu[i].link.replace('{activeCashier}', activeCashRegister) );
        }

        auxCountPemission++;
      }
      
    }
  };
  
  return (
    <div className="headerTabs" data-testid="headerTabs">
      { tabsMenu.length !== 0 ?
          <Tabs value={activeValue} onChange={handleChange} indicatorColor="primary" className="">
            {
              // Filter: revisamos si tiene el permiso correspondiente
              tabsMenu.filter((item:any) => { return item.permission.some(r => user.usertype.indexOf(r) >= 0 ) }).map((item:any, index:number) => {
                return <Tab key={index} label={item.name} />
              })
            }
          </Tabs>
        :
          null
      }
    </div>
  )
}

export default HeaderTabs;