import React from 'react'
import { useAuthDataContext } from '../../_auth/auth_provider';
import { useTabsDataContext } from './tabs/tabs_provider';
import { ContainerProps, menuLinkProps, menuListProps } from './props'
import { Link, RouteProps } from 'react-router-dom'
import { History } from 'history';

import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TodayIcon from '@material-ui/icons/Today';
import Hidden from '@material-ui/core/Hidden';
import LinearProgress from '@material-ui/core/LinearProgress';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import StoreIcon from '@material-ui/icons/Store';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import ContactsIcon from '@material-ui/icons/Contacts';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import CartProvider from '../pos/cart_provider';
import Header from './Header'

import { roleType } from '../../_helpers/enums/all';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';


export const drawerWidth = 60;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
  }),
);

const MenuListItem = (props:menuListProps) => {
  const { userPermission } = useAuthDataContext();
  const { setActivePath } = useTabsDataContext();

  const handleLink = ( item:menuLinkProps ) => {
    //console.log( 'itemssss', item );
    setActivePath( item.link );
  }

  const listItems = props.menu.map( (propsItem:menuLinkProps, index:number) => {
    if( userPermission( propsItem.permission ) ){
      return (
        <Link to={propsItem.link} key={index} onClick={() => {handleLink(propsItem);}}>
          <ListItem button key={propsItem.name}>
            <ListItemIcon>
              {propsItem.icon}  
            </ListItemIcon>
          </ListItem>
        </Link>
      )
    } else {
      return null
    }
  });

  return (
    <List className={ props.className ? props.className : '' }>{listItems}</List>
  )
}

interface AppProps {
  history: History;
}

const MainContainer = (props:ContainerProps & RouteProps & AppProps) => {
  const { isAuth, loading, tabsActive } = useAuthDataContext();


  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
	const menuLinks:menuLinkProps[] = [
		{
			name        : 'Inicio',
			icon        : <DashboardIcon />,
      link        : '/',
      permission  : [ roleType.Authenticated ],
		}, {
			name        : 'Presale',
			icon        : <CardGiftcardIcon />,
      link        : '/presale',
      permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.Agency, roleType.Reseller ],
    }, {
			name        : 'Reservas',
			icon        : <TodayIcon />,
      link        : '/reservations',
      permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.CashSupervisor, roleType.Agency, roleType.Executive, roleType.StoreManager ],
    }, {
			name        : 'Cash Register',
			icon        : <StoreIcon />,
      link        : '/cash-register',
      permission  : [ roleType.Admin, roleType.Cashier ],
    }, {
			name        : 'Skiroom',
			icon        : <AcUnitIcon />,
      link        : '/skiroom',
      permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.Skiroom, roleType.SAC ],
    }, {
			name        : 'Solicitudes',
			icon        : <AssignmentTurnedInIcon  />,
      link        : '/requests',
      permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.CashSupervisor, roleType.StoreManager ],
    }, {
			name        : 'Atenciones',
			icon        : <PlaylistAddCheckIcon  />,
      link        : '/attentions',
      permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.CashSupervisor ],
    }, {
			name        : 'Returns',
			icon        : <SwapHorizIcon />,
      link        : '/returns',
      permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.CashSupervisor, roleType.StoreManager, roleType.Return ],
    }, {
			name        : 'Returns',
			icon        : <SwapHorizIcon />,
      link        : '/returns/attentions/group',
      permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.Skiroom ],
    }, {
			name        : 'Usuarios',
			icon        : <ContactsIcon  />,
      link        : '/users',
      permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.Agency, roleType.StoreManager ],
    }, {
			name        : 'Recaudación',
			icon        : <MonetizationOnIcon  />,
      link        : '/attentions/summary',
      permission  : [ roleType.Admin, roleType.SuperAdmin ],
    },

  ]
  
  const menuLinksAccount:menuLinkProps[] = [
		{
			name        : 'e',
			icon        : <AccountCircleIcon />,
      link        : '/',
      permission  : [ roleType.Admin, roleType.SuperAdmin ],
		}, {
			name        : 'i',
			icon        : <ExitToAppIcon />,
      link        : '/logout',
      permission  : [ roleType.Authenticated ],
		}
	]

  const drawer = (
    <React.Fragment>
      <div className={classes.toolbar} />
      <Divider />
      <MenuListItem menu={menuLinks} />
      <Divider />
      <MenuListItem className="user_menu" menu={menuLinksAccount} />
    </React.Fragment>
  );

  return (
    loading ?
      <LinearProgress color="primary" />
    :
      isAuth ?
        <div className={"all_container"+( tabsActive ? ' active-tabs' : '' )}>

          <Header />
          
          <nav className={classes.drawer} aria-label="mailbox folders">
            <Hidden smUp implementation="css">
              <Drawer
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>

          <main className="container_all logged_user">
            <CartProvider>  
              {props.children}
            </CartProvider>
          </main>
        </div>
      :
        <GoogleReCaptchaProvider reCaptchaKey="6LeWFQEVAAAAAJVWKXFlFVbA2VtIaBFiCjjwYoXG">
          <main className="container_all">
            {props.children}
          </main>
        </GoogleReCaptchaProvider>
	);
}
  
export default MainContainer;