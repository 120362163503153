import React, {useState, useEffect} from 'react';


import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {setAccessToken} from '../../_auth/auth';
import { postU } from '../../_services/api'
import { baseApiURL } from '../../_services/fetch';

import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useAuthDataContext } from '../../_auth/auth_provider';
import useErrorManager from '../common/ErrorManager';
import { errorSectionType, errorType } from '../../_helpers/enums/all';

import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



const Login = (props: RouteComponentProps) => {
  const { isAuth, setLostedSession }                = useAuthDataContext();
  let history                                       = useHistory();
  const { alertBlock, showAlert, resetAlerts }      = useErrorManager({ id: 'session_info', section: errorSectionType.General });

  const classes = useStyles();
  const [ username, setUsername ]                   = useState<string>('');
  const [ password, setPassword ]                   = useState<string>('');
  const [ isButtonDisabled, setIsButtonDisabled ]   = useState(true);
  const [ helperText, setHelperText ]               = useState('');
  const [ errorLocal, setError ]                    = useState(false);

  const [ loading, setLoading ]                     = useState<boolean>( false );

  useEffect( () => {

    if( history.location.search.includes('newpass') ){
      let auxUrlParams = new URLSearchParams( history.location.search );
      resetAlerts();
      showAlert({
        message: 'Tu contraseña de ha cambiado correctamente, ahora puedes iniciar sesión.',
        type_values: errorType.Success,
        section: errorSectionType.Custom,
      });
    }

  }, [] );
  
  useEffect( () => {
    if( isAuth ){
      history.push( '/' );
    }
  }, [isAuth] );

  //const {response, error} = useFetch({method: 'GET', path: '/user/login?_format=json'});

  useEffect(() => {
    if (username.trim() && password.trim()) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [username, password]);


  const handleLogin = (event?:any) => {
    if( event ){
      event.preventDefault();
    }
    setIsButtonDisabled(true);
    setLoading( true );

    
    // Revisamos si los datos de acceso son correctos
    let login_response = postU( baseApiURL+'/user/login?_format=json', {
      "name": username,
      "pass": password,
    });

    login_response.then((response:any) => { 
      setError(false);
      setHelperText('Login Successfully');

      setLostedSession( true );

      // Guardamos el token
      setAccessToken( response.access_token );

      window.location.replace('/');
      setIsButtonDisabled(false);
    })
    .catch( error => {
      //console.log(error.response)
      setLoading( false );
      setError(true);
      if( error.response === undefined ){
        setHelperText( 'No se pudo obtener la información de acceso.' );
      } else {
        setHelperText( error.response.data.message );
      }
      setIsButtonDisabled(false);
    });
    

  };

  const handleKeyPress = (e:any) => {
    if (e.keyCode === 13 || e.which === 13) {
      isButtonDisabled || handleLogin();
    }
  };


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper} id="login_container">
        <Avatar className="login_avatar">
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        { alertBlock }
        <form className={classes.form} noValidate autoComplete="off">
          <TextField
            error={errorLocal}
            helperText={helperText}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            onChange={(e)=>setUsername(e.target.value)}
            onKeyPress={(e)=>handleKeyPress(e)}
            autoFocus
          />
          <TextField
            error={errorLocal}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e)=>setPassword(e.target.value)}
            onKeyPress={(e)=>handleKeyPress(e)}
          />
          
          <br />
          <br />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isButtonDisabled}
            color="primary"
            onClick={(e)=>handleLogin( e )}
          >
            { loading ?
                <CircularProgress />
              :
                'Ingresar'
            }
          </Button>

          <div className="login-extra-link">
            <Link href="/login/password" variant="body2">
              ¿Olvidaste tu contraseña?
            </Link>
          </div>


        </form>
      </div>
    </Container>
  );
}

export default Login;