export enum Order {
  asc= 'asc',
  desc= 'desc'
};

export interface IPagination {
  page: number,
  pageSize: number,
  total: number,
  orderOrientation: Order,
  orderField: string,
  pages: number,
}

interface columnButtonInfo {
  action?: (props:any) => void,
  status?: any,
  true?: any,
  false?: any,
}

export interface IColumn {
  id: string;
  visible: boolean,
  label: string;
  numeric: boolean;
  disablePadding: boolean;
  sortable?: boolean;
  multiple?: boolean;
  uKey?: boolean;
  force?: boolean;
  button: boolean;
  button_name?: string|React.ReactNode;
  button_action?: (props:any) => void,
  button_color?: string,
  button_extra?: columnButtonInfo,
  extra?: boolean;
  extraData?: (props:any) => any;
}

export interface headerValues {
  id    : string,
  value : string,
}

export interface ITableHeader {
  columns: IColumn[],  
  dataColumns: headerValues[],
  order: Order,
  orderBy: string,
  numSelected: number,
  rowCount: number,
  onSelectAllClick(event: React.ChangeEvent<HTMLInputElement>): void,
  onRequestSort: (event: React.MouseEvent<unknown>, property: string, order:Order) => void,
  delete: string,
}

export interface TableFooterProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => void;
}

export interface HeadRowProps {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}
export interface CustomTableProps2 {
  loading: boolean;
  totalCount: number;
  onChangePageTable: (page: any, pageSize?: any, order?: any, orderBy?:any, filters?: any) => Promise<void>;
}
export interface skeleton {
  columns : number;
  rows:     number;
}






export interface presaleDataValues {
  nid: number,
  created: string,
  seller_name: string,
  pax_name: string,
  pax_file: string,
  products_name: string,
  products_id: string,
  code: string,
  status_name: string,
}