import React, { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import TableContent from '../common/table/TableContent';

import { IColumn } from '../common/table/table-types';
import { errorSectionType, errorType } from '../../_helpers/enums/all';
import useErrorManager from '../common/ErrorManager';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import ConfirmationBox from './modal/ConfirmationBox';
import RequestFilters from './RequestFilters';
import { addToQuery, createQuery, getQueryParams } from '../../_services/request';
import { DateTime } from 'luxon';


const Requests = (props: RouteComponentProps) => {
  let history = useHistory();
  const { alertBlock, showAlert } = useErrorManager({ id: 'request_list', section: errorSectionType.General });
  const [ loadingValidation ] = useState<boolean>( false ); //const [ loadingValidation, setLoadingValidation ] = useState<boolean>( false );
  document.title = 'Solicitudes';

  const [ confirmationActive, setConfirmationActive ] = useState<boolean>( false );
  const [ confirmationBox, setConfirmationBox ] = useState<React.ReactNode[]>([]);
  const [ listingTable, setListingTable ] = useState<React.ReactNode>();

  const closeModal = () => {
    setConfirmationBox( [] );
    
    // Change url
    let auxParams = getQueryParams();

    // Agrega active
    auxParams.push({
      param: 'changed',
      value: Math.trunc( Math.random() * 100000 ).toString(),
    });
    

    //history.push({ search: createQuery( auxParams ) });
    history.push({ search: createQuery( addToQuery(auxParams) ) });
  }

  const handleApprove = ( props:any ) => {
    // Cargamos los datos
    let auxConfirmationBox:React.ReactNode = <ConfirmationBox key={0} active={confirmationActive} url={props.url} closeAction={closeModal} type="approve" request={props.all.detail} resolvedAction={approvedRequest} />;
    setConfirmationBox( [auxConfirmationBox] );
  }

  const handleReject = ( props:any ) => {
    // Cargamos los datos
    let auxConfirmationBox:React.ReactNode = <ConfirmationBox key={0} active={confirmationActive} url={props.url} closeAction={closeModal} type="reject" request={props.all.detail} resolvedAction={rejectedRequest} />;
    setConfirmationBox( [auxConfirmationBox] );
  }

  const approvedRequest = () => {
    closeModal();

    showAlert({
      message: 'La solicitud se aprobó correctamente.',
      type_values: errorType.Success,
      section: errorSectionType.Custom,
    });

    setConfirmationActive( false );
    setConfirmationBox( [] );

    
  }

  const rejectedRequest = () => {
    closeModal();

    showAlert({
      message: 'La solicitud se rechazó correctamente.',
      type_values: errorType.Error,
      section: errorSectionType.Custom,
    });

    setConfirmationActive( false );
    setConfirmationBox( [] );
  }

  const columns:Array<IColumn> = [
    { id: 'created',        visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Fecha de solicitud',    sortable: true },
    { id: 'author_name',    visible: true,    numeric: true,    button: false,   disablePadding: true,  label: 'Nombre de usuario',     sortable: true },
    { id: 'detail',         visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Detalle',               sortable: false },
    { id: 'status_name',    visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Estado',                sortable: false },

    { id: 'approve',        visible: true,    numeric: false,   button: true,    disablePadding: true,  label: 'Aprobar',               sortable: false    ,   button_name: <CheckIcon />,           button_color: 'secondary',     button_action: handleApprove,  },
    { id: 'reject',         visible: true,    numeric: false,   button: true,    disablePadding: true,  label: 'Rechazar',              sortable: false    ,   button_name: <CloseIcon />,           button_color: 'secondary',     button_action: handleReject,  },
  ];

  

  return (
    loadingValidation ? <LinearProgress color="primary" /> :
    <div className="content_all">
      <div className="content">
        { alertBlock }

        <Paper className="content">
          <div className="paper_content_container">

          <TableContent
            id="returns_requests_table"
            title="Listado de solicitudes"
            columnsValues={columns}
            //urlData="/get/requests"
            urlData="/admin/requests/all"
            emptyData="No se encontraron solicitudes para mostrar"
            filters={<RequestFilters />}
            today
            apiType
          />
          </div>
        </Paper>

        { confirmationBox }

      </div>
    </div>
  );
}

export default Requests;