import React, { useState, useEffect, FormEvent } from 'react';
import { BaseButtonProps } from './props';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';


const BaseButton = ( props: BaseButtonProps ) => {
  const [classNames, setClassNames] = useState<string>('');

  useEffect(() => {
    if( props.className ){
      setClassNames( props.className );
    }
  }, [props.className]);

  const handleClick = (event: FormEvent) => {
    if( props.onClick !== undefined ){
      props.onClick( event );
    }
  }

  return (
    <button onClick={handleClick} disabled={props.disabled || props.loading} className={ (props.className ? props.className : '') +' '+ (props.disabled ? 'disabled' : '')+' '+ (props.loading ? 'loading' : '' ) } >
      { props.loading !== undefined && props.loading ? <CircularProgress size={28} color="inherit" /> : props.children }
    </button>
  )
}

export default BaseButton;