import React, { useState } from 'react';
import { Grid, FormControl } from '@material-ui/core';

import LuxonFnsUtils from "@date-io/luxon";
import { DateTime } from 'luxon';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { urlParamsProps } from '../../../_services/request';

function useDateRange(){
  const minDate = DateTime.fromObject({ year: 2020, month: 1, day: 1});
  const [ selectedDateStart, setSelectedDateStart ]       = useState<DateTime | null>( null );
  const [ selectedDateEnd, setSelectedDateEnd ]       = useState<DateTime | null>( null );

  const handleDateStartChange = (date: DateTime | null) => {
    setSelectedDateStart(date);
    //props.handleData();
  };

  const handleDateEndChange = (date: DateTime | null) => {
    setSelectedDateEnd(date);
    //props.handleData();
  };

  interface dateRangeProps {
    dateLimit ?: boolean,
  }

  const DateRangeFilter = (props:dateRangeProps) => {
    
    return (
      <React.Fragment>
        <Grid item>
          <FormControl fullWidth className="form-item">
            <MuiPickersUtilsProvider utils={LuxonFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                format="dd/LL/yyyy"
                minDate={ minDate }
                maxDate={ (!props.dateLimit && props.dateLimit !== undefined ) ? undefined : DateTime.local() }
                margin="normal"
                label="Fecha de inicio"
                value={selectedDateStart}
                onChange={handleDateStartChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                inputVariant="outlined"
                required={true}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth className="form-item">
            <MuiPickersUtilsProvider utils={LuxonFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                format="dd/LL/yyyy"
                minDate={ selectedDateStart ? selectedDateStart : minDate }
                maxDate={ (!props.dateLimit && props.dateLimit !== undefined ) ? undefined : DateTime.local() }
                margin="normal"
                label="Fecha de fin"
                value={selectedDateEnd}
                onChange={handleDateEndChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                inputVariant="outlined"
                required={true}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
      </React.Fragment>
    )
  }

  function handleCleanDateRange(){
    setSelectedDateStart( null );
    setSelectedDateEnd( null );
  }

  function handleSearchDateRange(){
    let auxParams:urlParamsProps[] = [];

    // Según los valores que tienen los filtros
    if( selectedDateStart ){
      auxParams.push({
        param: 'from',
        value: selectedDateStart.toFormat('dd-LL-yyyy'),
      });
    }

    if( selectedDateEnd ){
      auxParams.push({
        param: 'end',
        value: selectedDateEnd.toFormat('dd-LL-yyyy'),
      });
    }

    return auxParams;
  }


  return {minDate, selectedDateStart, selectedDateEnd, DateRangeFilter, handleCleanDateRange, handleSearchDateRange}
}

export function useDateRangeVal(){
  const minDate = DateTime.fromObject({ year: 2021, month: 1, day: 1});
  

  interface dateRangePropsVal {
    dateLimit ?: boolean,
    handleData: (type:string, date:DateTime|null) => void,
    from: DateTime|null,
    end: DateTime|null,
  }

  const DateRangeFilter = (props:dateRangePropsVal) => {
    const handleDateStartChangeVal = (date: DateTime | null) => {
      props.handleData('from', date);
    };
  
    const handleDateEndChangeVal = (date: DateTime | null) => {
      props.handleData('end', date);
    };

    return (
      <React.Fragment>
        <Grid item>
          <FormControl fullWidth className="form-item">
            <MuiPickersUtilsProvider utils={LuxonFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                format="dd/LL/yyyy"
                minDate={ minDate }
                maxDate={ (!props.dateLimit && props.dateLimit !== undefined ) ? undefined : DateTime.local() }
                margin="normal"
                label="Fecha de inicio"
                value={props.from}
                onChange={handleDateStartChangeVal}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                inputVariant="outlined"
                required={true}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth className="form-item">
            <MuiPickersUtilsProvider utils={LuxonFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                format="dd/LL/yyyy"
                minDate={ props.from ? props.from : minDate }
                maxDate={ (!props.dateLimit && props.dateLimit !== undefined ) ? undefined : DateTime.local() }
                margin="normal"
                label="Fecha de fin"
                value={props.end}
                onChange={handleDateEndChangeVal}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                inputVariant="outlined"
                required={true}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
      </React.Fragment>
    )
  }

  /*
  function handleCleanDateRange(){
    setSelectedDateStart( null );
    setSelectedDateEnd( null );
  }

  function handleSearchDateRange(){
    let auxParams:urlParamsProps[] = [];

    // Según los valores que tienen los filtros
    if( selectedDateStart ){
      auxParams.push({
        param: 'from',
        value: selectedDateStart.toFormat('dd-LL-yyyy'),
      });
    }

    if( selectedDateEnd ){
      auxParams.push({
        param: 'end',
        value: selectedDateEnd.toFormat('dd-LL-yyyy'),
      });
    }

    return auxParams;
  }
  */


  //return {minDate, selectedDateStart, selectedDateEnd, DateRangeFilter, handleCleanDateRange, handleSearchDateRange}
  return { DateRangeFilter }
}

export default useDateRange;
