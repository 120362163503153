import React, {useState, useEffect} from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { selectDateProps, selectProps, selectAllProps } from '../../../_helpers/props/all';

export const AgencySelect = ( props:selectDateProps ) => {
  const [ auxYears ] = useState<any[]>( [
    {value: 'all', name: 'Todos'},
    {value: 2020, name: 2020}
  ]);
  const [ yearOptions, setYearOptions ] = useState<React.ReactNode>();

  useEffect( () => {
    setYearOptions( auxYears.map( ( item:any, index:number ) => {
      return <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
    }) );
  }, [auxYears] );
  
  return (
    <FormControl variant="outlined">
      <InputLabel id="summary-select">Agencia</InputLabel>
      <Select
        labelId="summary-select"
        value={ props.value ? props.value : '' }
        onChange={ props.onChange }
        disabled={ props.disabled }
        displayEmpty
      >
        { yearOptions }
        
      </Select>
    </FormControl>  
  );
}

export const FilterSelect = ( props:selectAllProps ) => {
  const [ allOptions, setAllOptions ] = useState<React.ReactNode>();

  useEffect( () => {
    setAllOptions( props.options.map( ( item:selectProps, index:number ) => {
      return (
        <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
      )
    }) );
  }, [props.options] );
  
  return (
    <FormControl variant="outlined">
      <InputLabel id="summary-select">{props.title}</InputLabel>
      <Select
        labelId="summary-select"
        value={ props.value ? props.value : '' }
        onChange={ props.onChange }
        disabled={ props.disabled }
        displayEmpty
      >
        { allOptions }
        
      </Select>
    </FormControl>  
  );
}