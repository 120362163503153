import React, {useState, useEffect} from 'react';
import { baseApiURL } from '../../_services/fetch';
import { useHistory } from 'react-router-dom';
import useErrorManager from '../common/ErrorManager';
import { errorSectionType, errorType } from '../../_helpers/enums/all';
import { autocompleteProps } from '../../_helpers/props/all';
import LinearProgress from '@material-ui/core/LinearProgress';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import BaseButton from '../common/BaseButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CancelIcon from '@material-ui/icons/Cancel';

import { post, get } from '../../_services/api';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

interface roleOptionsProps {
  uuid: string,
  value: string,
}

const SkiroomEdit = () => {
  let history = useHistory();

  const { alertBlock, showAlert, resetAlerts } = useErrorManager({ id: 'user_edit', section: errorSectionType.Custom });
  const [ loading, setLoading ] = useState<boolean>( true );
  const [ errorLoading, setErrorLoading ] = useState<boolean>( false );
  const [ sendData, setSendData ] = useState<boolean>( false );
  
  const [ reservation, setReservation ]   = useState<string>( '' );
  const [ activeUser, setActiveUser ]   = useState<string>( '' );
  const [ ageOptions, setAgeOptions ] = useState<autocompleteProps[]>([]);
  const [ equipmentOptions, setEquipmentOptions ] = useState<autocompleteProps[]>([]);
  const [ heightOptions, setHeightOptions ] = useState<autocompleteProps[]>([]);
  const [ footOptions, setFootOptions ] = useState<autocompleteProps[]>([]);
  const [ weightOptions, setWeightOptions ] = useState<autocompleteProps[]>([]);
  
  const [ name, setName ] = useState<string>( '' );
  const [ validName, setValidName ] = useState<string>( '' );
  const [ age, setAge ] = useState<string>( '' );
  const [ validAge, setValidAge ] = useState<string>( '' );
  const [ equipment, setEquipment ] = useState<string>( '' );
  const [ validEquipment, setValidEquipment ] = useState<string>( '' );
  const [ height, setHeight ] = useState<string>( '' );
  const [ validHeight, setValidHeight ] = useState<string>( '' );
  const [ foot, setFoot ] = useState<string>( '' );
  const [ validFoot, setValidFoot ] = useState<string>( '' );
  const [ weight, setWeight ] = useState<string>( '' );
  const [ validWeight, setValidWeight ] = useState<string>( '' );


  const getData = () =>{
    setLoading( true );
    let responseData;

    if( activeUser !== 'new' ){
      responseData = get(baseApiURL+'/get/skiroom/'+activeUser+'/data');
    } else {
      responseData = get(baseApiURL+'/get/skiroom/new/data');
    }
    

    responseData.then((response:any) => {
      if( response.data ){
        //console.log( response.data );
        setLoading( false );

        if( response.data.age ){
          setAgeOptions( response.data.age );
        }

        if( response.data.equipment ){
          setEquipmentOptions( response.data.equipment );
        }

        if( response.data.height ){
          setHeightOptions( response.data.height );
        }

        if( response.data.foot ){
          setFootOptions( response.data.foot );
        }

        if( response.data.weight ){
          setWeightOptions( response.data.weight );
        }
        
      }

    }).catch(error => {
      setLoading( false );
      setErrorLoading( true );
      showAlert({
        message: 'Hubo un error al cargar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });
  } 

  useEffect(() => {
    if( activeUser !== '' ){
      getData();
    }
  }, [activeUser]);

  useEffect(() => {
    if( history.location.pathname ){
      setName( '' );

      let auxData = history.location.pathname.split( '/' );
      setReservation( auxData[2] );

      if( history.location.pathname.includes('/new') ){
        setActiveUser( 'new' );
      } else {
        setActiveUser( auxData[4] );
      }

    }
  }, [history.location.pathname]);

  const handleSend = () => {
    // Validamos envío
    let auxSend = true;
    
    if( name === '' ){
      auxSend = false;
      setValidName( 'Este campo es obligatorio' );
      showAlert({
        message: 'Debes ingresar un nombre',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }

    if( age === '' ){
      auxSend = false;
      setValidAge( 'Este campo es obligatorio' );
      showAlert({
        message: 'Debes ingresar la edad del turista',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }

    if( equipment === '' ){
      auxSend = false;
      setValidEquipment( 'Este campo es obligatorio' );
      showAlert({
        message: 'Debes seleccionar el equipo del turista',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }

    if( height === '' ){
      auxSend = false;
      setValidHeight( 'Este campo es obligatorio' );
      showAlert({
        message: 'Debes ingresar la altura del turista',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }

    if( weight === '' ){
      auxSend = false;
      setValidWeight( 'Este campo es obligatorio' );
      showAlert({
        message: 'Debes ingresar el peso del turista',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }

    if( auxSend ){
      setSendData( true );
      resetAlerts();

      const responseData = post(baseApiURL+'/post/skiroom/newpax', {
        reservation: reservation,
        name: name,
        age: age,
        equipment: equipment,
        height: height,
        weight: weight,
        foot: foot,
      });

      responseData.then((response:any) => {
        if( response.data ){
          setSendData( false );
          //getData();
          
          /*
          if( activeUser === 'new' ){
            history.push('/users?new='+name);
          } else {
            history.push('/users?save='+name);
          }
          */
          history.push( '/skiroom/'+reservation+'?new='+name );

          showAlert({
            message: 'La información del usuario se ha modificado correctamente.',
            type_values: errorType.Success,
            section: errorSectionType.Custom,
          });
        }
      }).catch(error => {
        setSendData( false );
        showAlert({
          message: 'Hubo un error al cargar la información. ['+error+']',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      });
      
    }
    
  }

  const handleEquipment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidEquipment( '' );
    setEquipment( (event.target as HTMLInputElement).value );
  }
  
  return (
    loading ? <LinearProgress color="primary" /> :
      <div className="content_all">
        <div className="content">
          { alertBlock }
          { errorLoading ||
              <div className="cash-register-box">
                <Paper className="fullwidth">
                  <Grid container className="cash-register-container form-container-common">
                    <Grid item xs={12} sm={6} md={8} className="cash-register-content">
                      <Grid container  spacing={2} justify="space-between">
                        <Grid item>
                          <h1 className="form-title" style={{margin: 0}}>
                            { activeUser === 'new' ?
                                'Agregar pasajero:'
                              :
                                'Modificar cuenta:'
                            }
                          </h1>
                        </Grid>
                      </Grid>
                      <div className="form-detail-big">
                        <FormControl fullWidth className="form-item">
                          <TextField
                              label="Nombre pasajero"
                              value={name}
                              helperText={ validName === '' ? '' : validName }
                              error={ validName !== '' ? true : false }
                              variant="outlined"
                              onChange={(event:any) => {
                                if( event.target.value !== '' ){
                                  setValidName( '' );
                                }
                                setName( event.target.value );
                              }}
                            />
                        </FormControl>

                        <Autocomplete
                          autoSelect={true}
                          options={ageOptions}
                          getOptionLabel={(option) => option.name}
                          filterOptions={createFilterOptions({
                            matchFrom: 'start',
                            stringify: option => option.name,
                          })}
                          renderInput={(params) => <TextField {...params} label="Edad" variant="outlined" required={true} helperText={ validAge === '' ? '' : validAge } error={ validAge !== '' ? true : false } />}
                          onChange={(event: any, newValue: autocompleteProps | null) => {
                            setValidAge('');
                            if( newValue ){
                              setAge( newValue.value );
                            } else {
                              setAge( '' );
                            }
                          }}
                        />

                        <FormControl fullWidth error={ validEquipment !== '' ? true : false } className="form-item" style={{ paddingTop: 25, }}>
                          <FormLabel component="legend">Equipo</FormLabel>
                          <RadioGroup value={equipment} onChange={handleEquipment} style={{flexDirection: 'row',}}>
                            { equipmentOptions.map((item:autocompleteProps, index: number) => {
                                return <FormControlLabel key={index} value={item.value} control={<Radio color="primary" />} label={item.name} />
                            }) }
                          </RadioGroup>
                        </FormControl>

                        <Autocomplete
                          options={heightOptions}
                          getOptionLabel={(option) => option.name}
                          filterOptions={createFilterOptions({
                            matchFrom: 'start',
                            stringify: option => option.name,
                          })}
                          renderInput={(params) => <TextField {...params} label="Altura" variant="outlined" required={true} helperText={validHeight === '' ? '' :validHeight } error={validHeight !== '' ? true : false } />}
                          onChange={(event: any, newValue: autocompleteProps | null) => {
                            setValidHeight('');
                            if( newValue ){
                              setHeight( newValue.value );
                            } else {
                              setHeight( '' );
                            }
                          }}
                        />
                        <br />

                        <Autocomplete
                          options={weightOptions}
                          getOptionLabel={(option) => option.name}
                          filterOptions={createFilterOptions({
                            matchFrom: 'start',
                            stringify: option => option.name,
                          })}
                          renderInput={(params) => <TextField {...params} label="Peso" variant="outlined" required={true} helperText={validWeight === '' ? '' :validWeight } error={validWeight !== '' ? true : false } />}
                          onChange={(event: any, newValue: autocompleteProps | null) => {
                            setValidWeight('');
                            if( newValue ){
                              setWeight( newValue.value );
                            } else {
                              setWeight( '' );
                            }
                          }}
                        />
                        <br />

                        <Autocomplete
                          options={footOptions}
                          getOptionLabel={(option) => option.name}
                          filterOptions={createFilterOptions({
                            matchFrom: 'start',
                            stringify: option => option.name,
                          })}
                          renderInput={(params) => <TextField {...params} label="Número de pie" variant="outlined" required={true} helperText={validFoot === '' ? '' :validFoot } error={validFoot !== '' ? true : false } />}
                          onChange={(event: any, newValue: autocompleteProps | null) => {
                            setValidFoot('');
                            if( newValue ){
                              setFoot( newValue.value );
                            } else {
                              setFoot( '' );
                            }
                          }}
                        />
                        <br />

                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className="cash-register-sidebar" >
                      <div className="cash-register-sidebar-info info-data">
                        
                        <div className="cash-register-sidebar-item">
                          <div className="cash-register-sidebar-item-detail">
                            
                            {/* <div className="sidebar-item-box ">
                              <div className="cash-register-sidebar-item-title">
                                PERMISOS
                              </div>
                            </div>
                            */}
                            <br />
                          </div>
                        </div>

                        
                      </div>
                      <div className="cash-register-sidebar-actions">
                        <BaseButton className="btn btn-sidebar-action btn-dubra" onClick={handleSend} disabled={ sendData } loading={sendData}>
                          Guardar pasajero <ChevronRightIcon />
                        </BaseButton>
                        <BaseButton className="btn btn-sidebar-action btn-dubra-cancel" onClick={() => { history.goBack() }} disabled={sendData}>
                          Cancelar <CancelIcon />
                        </BaseButton>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
          }

      </div>

    </div>
  )
}

export default SkiroomEdit;