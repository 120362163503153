import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import useFetch, { baseApiURL } from '../../_services/fetch';
import { get } from '../../_services/api';
import { YearSelect, MonthSelect, DaySelect } from './date/DateOptions';
import useErrorManager from '../common/ErrorManager';
import { errorSectionType, errorType } from '../../_helpers/enums/all';

interface dateSummaryFilters {
  year   ?: boolean,
  month  ?: boolean,
  day    ?: boolean,
}

interface summaryProps {
  title: string,
  subtitle?: string,
  urlData: string,
  date?: dateSummaryFilters,
}

interface headerProps {
  id    : string,
  name  : string,
}

const SummaryBlock = ( props:summaryProps ) => {
  const [ loadingData, setLoadingData ] = useState<boolean>( false );
  const { response, loading, error } = useFetch({ method: 'GET', path: props.urlData});
  const { alertBlock, showAlert } = useErrorManager({ id: 'summary_block', section: errorSectionType.Custom });
  const [ auxFirst, setAuxFirst ] = useState<boolean>( true );

  // Data
  const [ headers, setHeaders ] = useState<headerProps[]>( [] );
  const [ data, setData ] = useState<any[]>( [] );

  // Filters
  const [ date, setDate ] = useState<dateSummaryFilters>();

  useEffect( () => {
    if( response ){
      setHeaders( response.header );
      setData( response.data );
      
      let auxDateInfo:dateSummaryFilters = {};
      if( response.date.year ){
        auxDateInfo.year = response.date.year;
      }
      if( response.date.month ){
        auxDateInfo.month = response.date.month;
      }
      if( response.date.day ){
        auxDateInfo.day = response.date.day;
      }
      setDate( auxDateInfo );

    }
  }, [response] );

  useEffect( () => {
    if( error ){
      setAuxFirst( false );
      showAlert({
        message: 'Hubo un error al cargar los datos: '+error,
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }
  }, [error] );

  useEffect( () => {
    setLoadingData( loading );
  }, [loading] );


  useEffect( () => {
    if( date ){
      if( props.date?.year || props.date?.month || props.date?.day ){
        if( !auxFirst ){
          
          let queryValues:string[] = [];
          if( props.date?.year && date ){
            queryValues.push( 'year='+date.year );
          }
          if( props.date?.month && date ){
            queryValues.push( 'month='+date.month );
          }
          if( props.date?.day && date ){
            queryValues.push( 'day='+date.day );
          }
          setLoadingData( true );
          
          let summary_response = get( baseApiURL+props.urlData+'?'+queryValues.join('&') );
          summary_response.then((response:any) => { 
            setHeaders( response.header );
            setData( response.data );
            setLoadingData( false );
          }).catch( error => {
            setLoadingData( false );
            showAlert({
              message: 'Hubo un error al cargar los datos: '+error,
              type_values: errorType.Error,
              section: errorSectionType.Custom,
            });
          });          
  
  
        } else {
          setAuxFirst( false );
        }
      }
    }
  }, [ date ] );

  return (
    <Paper className="content">
      <div className="paper_content_container">
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" id="tableTitle" component="div">
              { props.title }
            </Typography>
          </Grid>
          { props.date?.year || props.date?.month || props.date?.day ?
              <Grid item xs={12} sm={6} className="summary-filters">
                <Grid container justify="flex-end" spacing={2}>
                  { props.date?.year && date ?
                      <Grid item xs={12} sm={3}>
                        <YearSelect value={date.year} onChange={(event: React.ChangeEvent<{ value: any }>) => { event.target.value && setDate({...date, year: event.target.value}); }} disabled={loading} />  
                      </Grid>
                    :
                      null
                  }
                  { props.date?.month && date ?
                      <Grid item xs={12} sm={3}>
                        <MonthSelect value={date.month} onChange={(event: React.ChangeEvent<{ value: any }>) => { event.target.value && setDate({...date, month: event.target.value}); }} disabled={loading} />
                      </Grid>
                    :
                      null
                  }
                  { props.date?.day && date ?
                      <Grid item xs={12} sm={3}>
                        <DaySelect value={date.day} onChange={(event: React.ChangeEvent<{ value: any }>) => { event.target.value && setDate({...date, day: event.target.value}); }} disabled={loading} />
                      </Grid>
                    :
                      null
                  }
                </Grid>
              </Grid>
            :
              null
          }
        </Grid>
        <br />
        <Divider />
        {
           loadingData ?
            <React.Fragment>
              <LinearProgress />
            </React.Fragment>
          :
            <React.Fragment>
              <br />
              {alertBlock}
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      { headers && headers.map(  ( item:headerProps, index:number ) => {
                        return <TableCell key={index} align="center"><strong>{item.name}</strong></TableCell>
                      }) }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { data && data.map(  ( item:any, index:number ) => {
                      let itemValues = headers && headers.map(  ( item_col:headerProps, index_col:number ) => {
                        if( item[item_col.id] ){
                          return <TableCell key={index_col} align="center">{item[item_col.id]}</TableCell>
                        } else {
                          return null
                        }
                      });
                      return <TableRow key={index}>{itemValues}</TableRow>;
                    }) }                    
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
        }
      </div>
    </Paper>
  )
}

export default SummaryBlock;