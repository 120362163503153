import React from "react";
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import {reset} from '../_auth/auth';
import PrivateRoute from './PrivateRoute';

import Login from '../components/login/Login';
import PassRecovery from '../components/login/PassRecovery';
import PassReset from '../components/login/PassReset';
import Dashboard from '../components/dashboard/Dashboard';
import { CashRegister, CashRegisterOpen, CashRegisterSale, CashRegisterClose} from '../components/pos';
import { Presale, PresaleNew } from '../components/presale';
import { Reservations, ReservationsAssign, ReservationsEdit, ReservationsNew } from '../components/reservations';
import { Returns } from '../components/returns';
import { Attentions, AttentionsNotes, AttentionsSummary } from '../components/attentions';
import { Requests } from '../components/requests';
import { Users, UserEdit } from '../components/users';
import { Skiroom, SkiroomList, SkiroomEdit } from '../components/skiroom';

import CashRegisterAdmin from '../components/admin/CashRegisters/CashRegisterAdmin';


import Notfound from '../components/common/notfound';
import CashRegisterNew from "../components/admin/CashRegisters/CashRegisterNew";

const LogoutBox = () => {
  reset();
  window.location.replace('/login')

  return <React.Fragment></React.Fragment>
}

const init = () => {
  return <div>A</div>
}

const Router = () => {

  return (
    <Switch>
      <Route path="/login/password" component={PassRecovery} />
      <Route path="/login" component={Login} />

      <Route path="/access/:uuid/token" component={PassReset} />

        

      <PrivateRoute exact path="/" component={Dashboard} />

      <PrivateRoute path="/users/new" component={UserEdit} />
      <PrivateRoute path="/users/:uuid/edit" component={UserEdit} />
      <PrivateRoute path="/users" component={Users} />

      <PrivateRoute path="/admin/cash-registers/:uuid/edit" component={init} />
      <PrivateRoute path="/admin/cash-registers/new" component={CashRegisterNew} />
      <PrivateRoute path="/admin/cash-registers" component={CashRegisterAdmin} />

      <PrivateRoute path="/cash-register/:uuid/close" component={CashRegisterClose} />
      <PrivateRoute path="/cash-register/attentions" component={AttentionsNotes} />
      <PrivateRoute path="/cash-register/sale" component={CashRegisterSale} />
      <PrivateRoute path="/cash-register/new" component={CashRegisterOpen} />
      <PrivateRoute path="/cash-register" component={CashRegister} />

      <PrivateRoute path="/returns/attentions/group" component={Attentions} />
      <PrivateRoute path="/returns/attentions" component={AttentionsNotes} />
      <PrivateRoute path="/returns" component={Returns} />
      <PrivateRoute path="/returns/:uid" component={Returns} />

      <PrivateRoute path="/skiroom/:uid/new" component={SkiroomEdit} />
      <PrivateRoute path="/skiroom/:uid" component={SkiroomList} />
      <PrivateRoute path="/skiroom" component={Skiroom} />

      <PrivateRoute path="/presale/new" component={PresaleNew} />
      <PrivateRoute path="/presale" component={Presale} />

      <PrivateRoute path="/reservations/new" component={ReservationsNew} />
      <PrivateRoute path="/reservations/edit/:nid" component={ReservationsEdit} />
      <PrivateRoute path="/reservations/assign/:nid" component={ReservationsAssign} />
      <PrivateRoute path="/reservations" component={Reservations} />

      <PrivateRoute path="/requests" component={Requests} />

      
      <PrivateRoute path="/attentions/summary" component={AttentionsSummary} />
      <PrivateRoute path="/attentions" component={Attentions} />


      <PrivateRoute path="/logout" component={LogoutBox} />
      <Route component={Notfound} />
    </Switch>
  )
};

export default Router;