import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Divider, Grid, MenuItem, Select, InputLabel, FormControl, LinearProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { DateTime } from 'luxon';
import { summaryProps, dateProps } from '../../_helpers/props/all';
import useFetch, { baseApiURL } from '../../_services/fetch';
import useErrorManager from '../common/ErrorManager';
import { errorSectionType, errorType } from '../../_helpers/enums/all';
import classes from '*.module.css';
import { FilterSelect } from '../common/filters/AgencyOptions';
import { selectProps } from '../../_helpers/props/all';
import { YearSelect, MonthSelect } from '../common/date/DateOptions';

import { get } from '../../_services/api';
import { useAuthDataContext } from '../../_auth/auth_provider';
import { roleType } from '../../_helpers/enums/all';

interface kitQProps {
  name: string,
  Adulto  ?: number,
  Junior  ?: number,
}

const PresaleSummary = () => {
  const { userPermission } = useAuthDataContext();
  const urlData:string = '/get/presales/summary';
  const { response, loading, error } = useFetch({ method: 'GET', path: urlData});

  const { alertBlock, showAlert } = useErrorManager({ id: 'presale_summary', section: errorSectionType.Custom });

  const [ auxFirst, setAuxFirst ] = useState<boolean>( true );
  const [ loadingData, setLoadingData ] = useState<boolean>( false );
  const [ year, setYear ] = useState<number|string>();
  const [ month, setMonth ] = useState<number|string>();
  const [ kits, setKits ] = useState<kitQProps[]>( [] );

  // Filtro de agencia
  const [ activeFilterName, setActiveFilterName ] = useState<string>( 'Todos' );
  const [ agencyOptions, setAgencyOptions ] = useState<selectProps[]>([{value: 'all', name: 'Todos'}]);
  const [ agency, setAgency ] = useState<string>('all');
  
  const [ sellerOptions, setSellerOptions ] = useState<selectProps[]>([{value: 'all', name: 'Selecciona la agencia'}]);
  const [ sellerDisabled, setSellerDisabled ] = useState<boolean>( false );
  const [ seller, setSeller ] = useState<string>('all');

  const setDate = ( props:dateProps ) => {
    setYear( props.year );
    setMonth( props.month );
  }

  useEffect( () => {
    if( response ){
      console.log( 'Valores:', response );

      // Si viene agencia, carga las agencias
      if( response.agencies ){
       setAgencyOptions( response.agencies );
      }

      if( response.seller ){
        if( response.seller.length !== 0 ){
          setSellerOptions( response.seller );
        }
      }

      // Declara los kit
      setKits( response.kits );

      // Declara la fecha activa
      setDate({
        year: parseInt( response.filters.year ),
        month: parseInt( response.filters.month ),
      });
    }
  }, [response] );

  useEffect( () => {
    if( error ){
      setAuxFirst( false );
      showAlert({
        message: 'Hubo un error al cargar los datos: '+error,
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }
  }, [error] );

  useEffect( () => {
    setLoadingData( loading );
  }, [loading] );

  useEffect( () => {    
    if( year !== undefined && month !== undefined ){
      if( !auxFirst ){
        setLoadingData( true );

        var summary_response_url = baseApiURL+urlData+'?year='+year+'&month='+month;


    
        // Acá debo revisar si trae agencia o no
        /*
        if( agency === 'all' ){
          summary_response = get( baseApiURL+urlData+'?year='+year+'&month='+month );
        } else {
          summary_response = get( baseApiURL+urlData+'?year='+year+'&month='+month+ );
        }
        */
        if( agency !== 'all' ){
          summary_response_url += '&agency='+agency;
        }

        if( seller !== 'all' ){
          summary_response_url += '&seller='+seller;
        }

       let summary_response = get( summary_response_url );
        
        summary_response.then((response:any) => { 
          if( response.seller ){
            if( response.seller.length !== 0 ){
              setSellerOptions( response.seller );
            } else {
              setSellerOptions([{value: 'all', name: 'Selecciona la agencia'}]);
            }
          }

          setKits( response.kits );
          setLoadingData( false );
        }).catch( error => {
          setLoadingData( false );
          showAlert({
            message: 'Hubo un error al cargar los datos: '+error,
            type_values: errorType.Error,
            section: errorSectionType.Custom,
          });
        });
        


      } else {
        setAuxFirst( false );
      }
    }

  }, [year, month, agency, seller] );

  useEffect(() => {
    if( agency === 'all' ){
      setSellerDisabled( true );
    } else {
      setSellerDisabled( false );
    }
    setSeller( 'all' );
  }, [agency]);

  return (
    <Paper className="content">
      <div className="paper_content_container">
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" id="tableTitle" component="div">
              Resumen de preventas - {activeFilterName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} className="summary-filters">
            <Grid container justify="flex-end" spacing={2}>
              { userPermission([roleType.Admin, roleType.SuperAdmin]) &&
                  <Grid item xs={12} sm={3}>
                    <FilterSelect title="Agencia" options={agencyOptions} value={agency} onChange={(event: React.ChangeEvent<{ value: any }>) => { event.target.value && setAgency( event.target.value ); }} disabled={loading} />
                  </Grid>
              }
              { userPermission([roleType.Admin, roleType.SuperAdmin, roleType.Agency]) &&
                  <Grid item xs={12} sm={3}>
                    <FilterSelect title="Ejecutivo" options={sellerOptions} value={seller} onChange={(event: React.ChangeEvent<{ value: any }>) => { event.target.value && setSeller( event.target.value ); }} disabled={(loading || sellerDisabled ) && !userPermission([roleType.Agency])  } />
                  </Grid>
              }
              <Grid item xs={12} sm={3}>
                <YearSelect value={year} onChange={(event: React.ChangeEvent<{ value: any }>) => { event.target.value && setYear( event.target.value ); }} disabled={loading} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <MonthSelect value={month} onChange={(event: React.ChangeEvent<{ value: any }>) => { event.target.value && setMonth( event.target.value ); }} disabled={loading} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Divider />
        {
           loadingData ?
            <React.Fragment>
              <LinearProgress />
            </React.Fragment>
          :
            <React.Fragment>
              <br />
              {alertBlock}
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Kits utilizados</strong></TableCell>
                      { kits && kits.map(  ( item:kitQProps, index:number ) => {
                        return <TableCell key={index} align="center"><strong>{item.name}</strong></TableCell>
                      }) }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    

                      <TableRow key="name">
                        <TableCell component="th" scope="row">
                          Adulto
                        </TableCell>
                        { kits && kits.map(  ( item:kitQProps, index:number ) => {
                          var total_item = 0;
                          if( item.Adulto ){
                            total_item = item.Adulto;
                          }
                          return <TableCell key={index} align="center">{total_item}</TableCell>
                        }) }
                      </TableRow>
                      <TableRow key="namee">
                        <TableCell component="th" scope="row">
                          Junior
                        </TableCell>
                        { kits && kits.map(  ( item:kitQProps, index:number ) => {
                          var total_item = 0;
                          if( item.Junior ){
                            total_item = item.Junior;
                          }
                          return <TableCell key={index} align="center">{total_item}</TableCell>
                        }) }
                      </TableRow>
                    
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
        }
      </div>
    </Paper>
  )
}

export default PresaleSummary;