import React, { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import TableContent from '../common/table/TableContent';

import { IColumn } from '../common/table/table-types';
import { errorSectionType } from '../../_helpers/enums/all';
import useErrorManager from '../common/ErrorManager';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import PrintIcon from '@material-ui/icons/Print';
import EditIcon from '@material-ui/icons/Edit';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import { baseApiURL } from '../../_services/fetch';
import ReprintBox from './modal/ReprintBox';
import GuaranteeBox from './modal/GuaranteeBox';

import AttentionsNotesFilters from './AttentionsNotesFilters';

const AttentionsNotes = (props: RouteComponentProps) => {
  let history = useHistory();

  //const { response, loading, error } = useFetch({ method: 'GET', path: '/get/presales'});
  const { alertBlock } = useErrorManager({ id: 'returns_attentions', section: errorSectionType.General });
  const [ loadingValidation ] = useState<boolean>( false ); //const [ loadingValidation, setLoadingValidation ] = useState<boolean>( false );
  const [ billUrl, setBillUrl  ] = useState<string>('');
  const [ billRender, setBillRender ] = useState<React.ReactNode[]>( [] );
  
  const [ guaranteeBox, setGuaranteeBox ] = useState<React.ReactNode[]>();
  const [ guaranteeActive, setGuaranteeActive ] = useState<boolean>( false );
  const [ guaranteeReprint, setGuaranteeReprint ] = useState<boolean>( false );

  const handleLink = ( props:any ) => {
    history.push( props.url );
  }

  const printAction = ( props:any ) => {
    setBillUrl( '' );
    setBillRender( [] );
    setBillUrl( baseApiURL+''+props );

    //console.log( 'reprint', props );
  }

  const handleReprint = ( props:any ) => {
    setGuaranteeReprint( true );

    // Cargamos los datos
    let auxGuaranteeBox:React.ReactNode = <ReprintBox key={0} active={guaranteeReprint} order={props.all.order_id} url={props.url}  printAction={printAction} closeAction={closeModal} />;
    setGuaranteeBox( [auxGuaranteeBox] );

  }

  const closeModal = () => {
    setGuaranteeBox( [] );
  }

  const handleGuarantee = ( props:any ) => {
    setGuaranteeActive( true );

    // Cargamos los datos
    let auxGuaranteeBox:React.ReactNode = <GuaranteeBox key={0} active={guaranteeActive} url={props.url} closeAction={closeModal} />;
    setGuaranteeBox( [auxGuaranteeBox] );
  }

  const columns:Array<IColumn> = [
    { id: 'created',        visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Fecha',                 sortable: true },
    { id: 'order_id',       visible: true,    numeric: true,    button: false,   disablePadding: true,  label: 'Nº nota',               sortable: true },
    { id: 'status_name',    visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Estado',                sortable: false },
    { id: 'cash_register',  visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Caja',                  sortable: false },
    { id: 'cashier',        visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Cajero',                sortable: false },
    { id: 'agency_name',    visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Agencia',               sortable: false },
    { id: 'touristGuide',   visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Guía',                  sortable: false },
    { id: 'pax_name',       visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Nombre pasajero',       sortable: false },
    { id: 'returns_data',   visible: true,    numeric: false,   button: true,    disablePadding: true,  label: 'Ver retorno',           sortable: false    ,   button_name: <SwapHorizIcon />,              button_color: 'secondary',     button_action: handleLink,     },
    { id: 'reprint',        visible: true,    numeric: false,   button: true,    disablePadding: true,  label: 'Reimprimir',            sortable: false    ,   button_name: <PrintIcon />,              button_color: 'secondary',     button_action: handleReprint,  },
    { id: 'edit',           visible: true,    numeric: false,   button: true,    disablePadding: true,  label: 'Modificar',             sortable: false    ,   button_name: <EditIcon />,              button_color: 'secondary',     button_action: handleLink,     },
    { id: 'guarantee',      visible: true,    numeric: false,   button: true,    disablePadding: true,  label: 'Garantía',              sortable: false    ,   button_name: <MoneyOffIcon />,              button_color: 'secondary',     button_action: handleGuarantee,     },
  ];

  const getPath = () => {
    if( history.location.pathname === '/cash-register/attentions' ){
      return '/get/attentions/notes';
    }
    //urlData = "/attentions/returns/all"
    return '/get/attentions/returns';
  }

  useEffect( () => {
    if( billUrl !== '' ){
      let auxFrame:React.ReactNode[] = [];
      
      auxFrame.push(
        <iframe
          id={"bill"+Math.random()}
          src={billUrl}
          key={auxFrame.length}
          style={{ width: 1, height: 10, border: 'none', overflow: 'hidden', }}
          title="Bill" />
      );

      setBillRender( auxFrame );
    }
  }, [billUrl] );

  return (
    loadingValidation ? <LinearProgress color="primary" /> :
    <div className="content_all">
      <div className="content">
        { alertBlock }

        <Paper className="content">
          <div className="paper_content_container">
            <TableContent
              id="returns_attentions_table"
              title="Listado de atenciones"
              columnsValues={columns}
              emptyData="No se encontraron atenciones para mostrar"
              filters={<AttentionsNotesFilters />}
              today

              urlData={history.location.pathname === '/cash-register/attentions' ? '/attentions/notes/all' : '/attentions/returns/all' }
              apiType
              
            />
          </div>
          { guaranteeBox }
        </Paper>
        { billRender }

      </div>
    </div>
  );
}

export default AttentionsNotes;