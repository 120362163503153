import React, { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import TableContent from '../common/table/TableContent';

import { IColumn } from '../common/table/table-types';
import { errorSectionType, errorType, roleType } from '../../_helpers/enums/all';
import useErrorManager from '../common/ErrorManager';

import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import IconButton from '@material-ui/core/IconButton/IconButton';
import ModalBox from '../common/ModalBox';
import BootAssign from './modal/BootAssign';
import DesistirAssign from './modal/DesistirAssign';
import Calibrated from './modal/Calibrated';

import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { DateTime } from 'luxon';
import { getParam, removeFromQuery, createQuery } from '../../_services/request';
import SkiroomReservationDetail from './SkiroomReservationDetail';
import { useAuthDataContext } from '../../_auth/auth_provider';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';


const SkiroomList = (props: RouteComponentProps) => {
  let history = useHistory();
  const { userPermission } = useAuthDataContext();
  const { alertBlock, showAlert } = useErrorManager({ id: 'skiroom_list', section: errorSectionType.Custom });
  const [ loadingValidation ] = useState<boolean>( false );

  const [ reservation, setReservation ]         = useState<string>( '' );
  const [ reservationUrl, setReservationUrl ]   = useState<string>( '' );
  const [ modalTitle, setModalTitle ]           = useState<string>( '' );

  const [ activeModal, setActiveModal ] = useState<React.ReactNode|null>();

  const handleClick = ( props:any ) => {
    //history.push( props.url );
    console.log( 'print', props );
  }

  const handleSend = () => {
    //alert('refresh');
    var date = new Date();
    history.push( history.location.pathname + history.location.search + '&' + date.getTime() );
    setActiveModal( null );
  }

  const handleDisable = ( props:any ) => {
    let setTitle = '¿El pasajero desea desistir del equipamiento?';
    
    if( DateTime.local().toFormat('yyyy-LL-dd') === props.reservation_date ){
      if( props.disabled !== '1' ){
        return (
          <IconButton aria-label="edit" onClick={() => { setModalTitle( setTitle ); setActiveModal( <DesistirAssign uuid={ props.uuid } enable={ props.disabled === '1' && true } sendAction={handleSend} closeAction={() => { setActiveModal( null ); }} /> ); }}>
            <BlockIcon />
          </IconButton>
        )
      } else {
        return '-';
      }
    } else {
      return '-';
    }
    
  }

  const handleCalibrated = ( props:any ) => {
    //console.log( props );
    let setTitle = '¿Estás seguro de haber calibrado el equipo?';

    // Verifico que tenga bota calibrada
    if( props.boot !== '' ){
      if( props.disabled !== '1' ){
        if( props.calibrated !== '1' ){

          // Verifico si tiene permisos para editar (Que no sea SAC)
          if( userPermission([roleType.SAC]) ){
            return <em>Pendiente Skiman</em>
          } else {
            return (
              <IconButton aria-label="edit" onClick={() => { setModalTitle( setTitle ); setActiveModal( <Calibrated uuid={ props.uuid } enable={ props.disabled === '1' && true } sendAction={handleSend} closeAction={() => { setActiveModal( null ); }} /> ); }}>
                <CheckCircleOutlineIcon />
              </IconButton>
            )
          }

        } else {
          return 'Ok';
        }
      } else {
        return 'd-';
      }
    } else {
      return <em>Falta bota</em>
    }



    if( DateTime.local().toFormat('yyyy-LL-dd') === props.reservation_date ){
      
    } else {
      return <em style={{color: '#ccc',}}>Fecha de reserva no activa</em>
    }
    
  }
  
  const handleValidateBoot = ( props:any ) => {
    let setTitle = 'Confirmación de bota';

    if( props.boot ){
      return (
        <React.Fragment>
          {props.boot} 
          { ( props.disabled === '1' || props.calibrated === '1' ) ?
                ''
              :
                (DateTime.local().toFormat('yyyy-LL-dd') === props.reservation_date) &&
                <IconButton aria-label="edit" onClick={() => { setModalTitle( setTitle ); setActiveModal( <BootAssign uuid={ props.uuid } sendAction={handleSend} closeAction={() => { setActiveModal( null ); }} /> ); }}>
                  <EditIcon />
                </IconButton>
          }
        </React.Fragment>
      );

    } else {

      if( props.disabled === '1' ){
        return '-';
      } else {
        if( DateTime.local().toFormat('yyyy-LL-dd') === props.reservation_date ){
          return (
            <React.Fragment>
              - <IconButton aria-label="edit" onClick={() => { setModalTitle( setTitle ); setActiveModal( <BootAssign uuid={ props.uuid } sendAction={handleSend} closeAction={() => { setActiveModal( null ); }} /> ); }}>
              <AddCircleOutlineIcon />
            </IconButton>
            </React.Fragment>
          ); 
        } else {
          return <em style={{color: '#ccc',}}>Fecha de reserva no activa</em>
        }
      }
    }
  }

  const columns:Array<IColumn> = [
    { id: 'pax_name',           visible: true,    numeric: false,     button: false,   disablePadding: true,      label: 'Nombre pax',          sortable: true     },
    { id: 'uuid',               visible: false,   numeric: false,     button: false,   disablePadding: true,      label: 'ID',                  sortable: false    ,   uKey: true},
    { id: 'age',                visible: true,    numeric: true,      button: false,   disablePadding: true,      label: 'Edad',                sortable: true     },
    { id: 'type',               visible: true,    numeric: false,     button: false,   disablePadding: true,      label: 'Equipo',              sortable: false     },
    { id: 'height',             visible: true,    numeric: true,      button: false,   disablePadding: true,      label: 'Altura',              sortable: false    },
    { id: 'weight',             visible: true,    numeric: true,      button: false,   disablePadding: true,      label: 'Peso',                sortable: false    },
    { id: 'foot',               visible: true,    numeric: true,      button: false,   disablePadding: true,      label: 'Pie',                 sortable: false    },
    { id: 'boot',               visible: true,    numeric: true,      button: false,   disablePadding: true,      label: 'Bota',                sortable: false    , extraData: handleValidateBoot},
    { id: 'calibrated',         visible: true,    numeric: false,     button: false,   disablePadding: true,      label: 'Calibrado',           sortable: false    , extraData: handleCalibrated},
    { id: 'print',              visible: true,    numeric: false,     button: true,    disablePadding: true,      label: 'Imprimir',            sortable: false    ,   button_name: <PrintIcon />,        button_color: 'primary',       button_action: handleClick,     },
    { id: 'disabled',           visible: (userPermission([roleType.Skiroom, roleType.Admin]) ? true : false),    numeric: false,     button: false,   disablePadding: true,      label: 'Desistir',            sortable: false    ,   extraData: handleDisable,     },
  ];

  useEffect( () => {
    let auxParams = getParam('new');
    if( auxParams ){
      
      showAlert({
        message: 'El pasajero '+auxParams+' se ha agregado a la reserva correctamente.',
        type_values: errorType.Success,
        section: errorSectionType.Custom,
        id: 'users_list',
      });
      
      let auxRemoveParams = removeFromQuery(['new']);
      history.push({ search: createQuery( auxRemoveParams ) });
    }
  }, [history, showAlert] );

  useEffect(() => {
    if( reservation !== '' ){
      setReservationUrl( "/get/skiroom/"+reservation+"/list" );
    }
  }, [reservation]);

  useEffect(() => {
    if( history.location.pathname ){
      let auxData = history.location.pathname.split( '/' );
      let auxData2 = auxData[2].split( '?' );
      setReservation( auxData2[0] );
    }
  }, [history.location.pathname]);

  return (
    loadingValidation ? <LinearProgress color="primary" /> :
    <div className="content_all">
      <div className="content">
        { alertBlock }

        <SkiroomReservationDetail reservationUUID={reservation} />
        <Paper className="content">
          <div className="paper_content_container">
            { reservationUrl !== '' &&
                <TableContent
                  id="skiroom_pax_table"
                  title="Listado de pasajeros"
                  columnsValues={columns}
                  urlData={reservationUrl}
                  emptyData="No se han agregado pasajeros en esta reserva"
                />
            }
          </div>
        </Paper>

      </div>

      <ModalBox title={modalTitle} active={ activeModal ? true : false } >
        { activeModal }
      </ModalBox>
    </div>
  );
}

export default SkiroomList;