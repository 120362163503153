import React, { useEffect, useState } from 'react';
import BlockContent from '../../common/BlockContent';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import BaseButton from '../../common/BaseButton';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useHistory } from 'react-router-dom';
import useFetch, { baseApiURL } from '../../../_services/fetch';
import { errorSectionType, errorType, roleType } from '../../../_helpers/enums/all';
import useErrorManager from '../../common/ErrorManager';
import Grid from '@material-ui/core/Grid';
import {actionProps} from '../../../_helpers/props/all';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { NumberFormatPesos } from '../../common/InputNumberFormat';
import { get, post } from '../../../_services/api';

import { useAuthDataContext } from '../../../_auth/auth_provider'

const FondoFijoBlock = () => {
  let history = useHistory();
  const { userPermission } = useAuthDataContext();

  const { alertBlock, showAlert } = useErrorManager({ id: 'fondofijo_block', section: errorSectionType.Custom });
  const alertModal = useErrorManager({ id: 'fondofijo_dialog', section: errorSectionType.Custom });
  
  const [ dataLoaded, setDataLoaded ] = useState<boolean>( false );
  const [ amount, setAmount ]         = useState<number>( 0 );
  const [ date, setDate ]             = useState<string>( '-' );
  const [ actions, setActions ]       = useState<actionProps[]>([]);

  const [ loading, setLoading ] = useState<boolean>( true );
  const [ activeDialog, setActiveDialog ] = useState<boolean>( false );
  const [ savingData, setSavingData ] = useState<boolean>( false );
  const [ tempAmount, setTempAmount ] = useState<number>( 0 );

  const getData = () => {
    setLoading( true );
    const responseData = get(baseApiURL+'/get/dashboard/fondo-fijo');

    responseData.then((response:any) => {
      if( response.data ){
        setLoading( false );
        setDataLoaded( true );

        setAmount( response.data.amount );
        setDate( response.data.created );
      }
    }).catch(error => {
      setLoading( false );
      showAlert({
        message: 'Hubo un error al cargar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });

  }

  useEffect( () => {
    getData();
  },[]  );

  const handleCancel = () => {
    setActiveDialog( false );
    alertModal.resetAlerts();
  }

  const handleSave = () => {
    setSavingData( true );
    alertModal.resetAlerts();

    const responseData = post(baseApiURL+'/post/admin/fondo-fijo', {
      amount: tempAmount,
    });

    responseData.then((response:any) => {
      if( response.data && response.data === 'ok' ){
        setSavingData( false );
        getData();
        setActiveDialog( false );
      } else {
        setSavingData( false );
        alertModal.showAlert({
          message: 'Hubo un error al guardar la información. [Error: Problema en la respuesta]',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      }
    }).catch(error => {
      setSavingData( false );
      alertModal.showAlert({
        message: 'Hubo un error al guardar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });

  }

  return (
    <BlockContent title="Fondo fijo cajas" loading={loading} actions={ actions }>
      { alertBlock }
      { dataLoaded &&
        <React.Fragment>
          <Typography variant="h4" gutterBottom align="center">
            ${new Intl.NumberFormat("es-CL").format( amount )}
          </Typography>
          <br />
          { userPermission([roleType.Admin, roleType.SuperAdmin]) && 
              <div className="btn-container-dashboard">
                <BaseButton className="btn btn-simple btn-dubra" onClick={() => { setTempAmount(amount); setActiveDialog( true ); }}>Modificar valores</BaseButton>
              </div>
          }
          <Typography variant="caption" align="center"  display="block">
            <em>Última actualización:
            <br />{ date }
            </em>
          </Typography>
        </React.Fragment>
      }
      <Dialog
        open={activeDialog}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Cambiar fondo fijo
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ingresa el valor del fondo de caja en pesos
            <br />
          </DialogContentText>
          { alertModal.alertBlock }
          <FormControl fullWidth variant="outlined" className="form-item form-item-payment">
            <TextField label="Fondo de caja inicial" value={tempAmount} onChange={ (aux_val) => { setTempAmount( parseInt(aux_val.target.value) ); }} InputProps={{ inputComponent: NumberFormatPesos, }} variant="outlined" disabled={savingData} />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <div className="btn-container-dashboard">
            <BaseButton className="btn btn-outlined btn-dubra-cancel" onClick={handleCancel} disabled={savingData}>
              Cancelar
            </BaseButton>
            <BaseButton className="btn btn-simple btn-dubra" onClick={handleSave} loading={savingData}>
              Guardar
            </BaseButton>
          </div>
        </DialogActions>
      </Dialog>
    </BlockContent>
  );
}

export default FondoFijoBlock;