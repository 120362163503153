import React, { useState, useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import BaseButton from '../../common/BaseButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { errorSectionType, errorType } from '../../../_helpers/enums/all';
import useErrorManager from '../../common/ErrorManager';
import { baseApiURL } from '../../../_services/fetch';
import { get, post } from '../../../_services/api';
import { autocompleteProps } from '../../../_helpers/props/all';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

interface BootAssignProps {
  uuid            : string,
  closeAction     : () => void,
  sendAction     : () => void,
}

const BootAssign = ( props:BootAssignProps ) => {
  const { alertBlock, showAlert, resetAlerts } = useErrorManager({ id: 'bootsassign_modal', section: errorSectionType.Custom });
  const [ orderTitle, setOrderTitle ]           = useState<string>('');

  const [ error, setError ]                     = useState<any>();
  const [ loading, setLoading ]                 = useState<boolean>( true );
  const [ loadingSend, setLoadingSend ]         = useState<boolean>( false );
  const [ guaranteeStatus, setGuaranteeStatus ] = useState<number>(0);
  const [ activePending, setActivePending ] = useState<boolean>( false );

  const [ footOptions, setFootOptions ] = useState<autocompleteProps[]>([]);
  const [ footTemp, setFootTemp ] = useState<string>('');
  const [ foot, setFoot ] = useState<autocompleteProps|null>( null );
  const [ validFoot, setValidFoot ] = useState<string>( '' );

  useEffect(() => {
    if( footOptions.length !== 0 && footTemp !== '' ){
      footOptions.filter(item => item.value === footTemp ).map((item:autocompleteProps) => {
        setFoot( item );
      });
    }
  }, [footOptions, footTemp]);

  const handleLoad = () => {
    setLoading( true );
    let guarantee_response = get( baseApiURL+'/get/skiroom/'+props.uuid+'/boot');

    guarantee_response.then((response:any) => { 
      setLoading( false );
      console.log( 'hola' );

      setFootOptions( response.data.boot );
      setFootTemp( response.data.active );

    })
    .catch( error => {
      setLoading( false );
      setError( error );
    });
  }

  useEffect( () => {
    if( error ){
      setOrderTitle( 'eo!' );
    }
  }, [error] );

  useEffect( () => {
    handleLoad();
  }, [] );

  const handleCancel = () => {
    resetAlerts();
    props.closeAction();
    console.log('**', foot);
  }

  const handleSend = () => {
    let auxSend = true;

    // Debo validar la selección
    if( foot === null ){
      auxSend = false;
      setValidFoot( 'Debes escoger el tamaño para la bota.' );
    }

    if( auxSend ){
      setLoadingSend( true );

      let guaranteeApproval_response = post( baseApiURL+'/post/skiroom/save-boot', {
        uuid: props.uuid,
        boot: foot ? foot.value : '',
      });

      guaranteeApproval_response.then((response:any) => { 
        setLoadingSend( false );
        //console.log( response );
        props.sendAction();
      })
      .catch( error => {
        setLoadingSend( false );
        //console.log( error );
        showAlert({
          message: 'Hubo un problema al solicitar el cambio de bota. ['+error+']',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      });
    }
  }

  return (
    <React.Fragment>
      <DialogContent className="guarantee-box-content">
        { alertBlock }
        <DialogContentText id="alert-dialog-description">
          Selecciona este valor una vez que hayas ajustado la bota del pasajero.
        </DialogContentText>
        <div className="guarantee-box-items">
          { !loading ?
              error ?
                <em>No logramos cargar la información para la bota, por favor intentalo más tarde o comunícate con los administradores.</em>
              : 
                <div style={{paddingTop: 15, paddingBottom: 25}}>
                  <Autocomplete
                    options={footOptions}
                    getOptionLabel={(option) => option.name}
                    filterOptions={createFilterOptions({
                      matchFrom: 'start',
                      stringify: option => option.name,
                    })}
                    renderInput={(params) => <TextField {...params} label="Número de bota" variant="outlined" required={true} helperText={validFoot === '' ? '' :validFoot } error={validFoot !== '' ? true : false } />}
                    onChange={(event: any, newValue: autocompleteProps | null) => {
                      setValidFoot('');
                      if( newValue ){
                        //setFoot( newValue.value );
                        setFoot( newValue );
                      } else {
                        setFoot( null );
                      }
                    }}
                    
                    value={ foot }
                  />
                </div>
            :
              <React.Fragment>
                <CircularProgress /><br /><br />
              </React.Fragment>
          }
        </div>
      </DialogContent>
      { !loading &&
          <DialogActions>
            <BaseButton onClick={() => { handleCancel() }} disabled={loadingSend}>
              { footOptions.length !== 0 ?
                  'Cancelar'
                :
                  'Cerrar'
              }
            </BaseButton>
            { footOptions.length !== 0 &&
                <BaseButton onClick={() => { handleSend() }} disabled={loadingSend} >
                  { loadingSend ?
                      <CircularProgress />
                    :
                      'Guardar bota'
                  }
                </BaseButton>
            }
          </DialogActions>
      }
    </React.Fragment>
  )
}

export default BootAssign;