import React, {useState, useEffect, FormEvent} from 'react';
import { post } from '../../_services/api';
import { LinearProgress, Button, TextField } from '@material-ui/core';
import SimpleBox from '../common/SimpleBox';
import { InitDataResponseCode, WSResponseCode } from '../common/enums';
import { cashRegisterStatusType, errorSectionType, errorType } from '../../_helpers/enums/all';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import HideContent, {ActiveContent, HiddenContent} from '../common/HiddenContent';
import FormContent, {FormItem, FormActions} from '../common/FormContent';
import Grid from '@material-ui/core/Grid';
import { ProductStockProps } from '../common/props';
import BaseButton from '../common/BaseButton';
import useFetch, { baseApiURL } from '../../_services/fetch';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import useErrorManager from '../common/ErrorManager';

const ProductStock = ( props:ProductStockProps ) => {
  
  const listProductsStock = props.products.map((item:any, index:number) => {
    if( item ){
      return (
        <Grid container className="form_table_item" key={index}>
          <Grid xs={9} sm={8} item className="form_table_content">
            {item.name}
          </Grid>
          <Grid xs={3} sm={4} item className="form_table_content form_col_center">
            <TextField value={item.total} size="small" disabled />
          </Grid>
        </Grid>
      )
    } else {
      return null
    }
  });

  return listProductsStock;

}

const CashRegisterOpen = (props: RouteComponentProps) => {
  let history = useHistory();
  const { response, loading, error, loadData } = useFetch({ method: 'GET', path: '/get/cash-registers/init-data'});
  const { alertBlock, showAlert, resetAlerts } = useErrorManager({ id: 'cashregister_open', section: errorSectionType.Custom });

  document.title = 'Caja';
  const [ loadingAction, setLoadingAction] = useState<boolean>(false);
  const [ assignated, setAssignated] = useState<boolean>(false);
  const [ activeHiddenContent, setActiveHiddenContent] = useState<boolean>( false );
  const [ dataProducts, setDataProducts] = useState<React.ReactNodeArray>([]);
  const [ baseAmount, setBaseAmount] = useState<number>( 0 );
  const [ idAtencion, setIdAtencion ] = useState<number>( 0 );
  const [ statusId, setStatusId] = useState<cashRegisterStatusType>();

  useEffect(() => {
    if( history.location.search.includes('closed') ){
      showAlert({
        message: 'La caja ha sido cerrada correctamente',
        type_values: errorType.Success,
        section: errorSectionType.Custom,
      });
    }
  }, []);

  useEffect( () => {
    if( response ){
      


      if( response.data.code === InitDataResponseCode.init1001 ){
        // No tiene caja asignada
      } else if( response.data.code === InitDataResponseCode.init2003 ){
        // Esta es su caja asignada, pero no está activa hoy
        setAssignated( true );

        // Guardamos los productos
        setDataProducts( response.data.stock );

        // Guardamos monto base
        if( response.data.base !== '-' ){
          setBaseAmount( response.data.base );
        }

        // Guardamos id de atencion que usaremos
        setIdAtencion( response.data.atencion );

        setStatusId( response.data.status_id );
      } else if( response.data.code === InitDataResponseCode.init2002 ){
        // Esta es su caja asignada y está activa para hoy, debe redirigir
        history.push(`/cash-register`);

      }
    }
  }, [response, history]);

  useEffect(() => {
    if( !activeHiddenContent ){
      window.scrollTo(0, 0);
    }
  }, [activeHiddenContent]);

  const sendHandler = (event: FormEvent) => {
    event.preventDefault();
    setLoadingAction( true );

    // Validaciones son por servidor
    // Revisamos si los datos de acceso son correctos
    let login_response = post( baseApiURL+'/api/dubra/v1.0/post/pos/init', {
      'id_atencion' : idAtencion,
      'base'        : baseAmount,
      'products'    : dataProducts,
    });

    login_response.then((response:any) => { 
      setLoadingAction( false );
      //console.log( response );
      // Revisamos la respuesta del server
      if( response.status === WSResponseCode.fail ){
        alert( 'error en laa respuesta' );
      } else {
        history.push(`/cash-register`);
      }
    })
    .catch( error => {
      setLoadingAction( false );
      alert( 'error en el llamado' );
    });

  }

  const cancelHandler = (event: FormEvent) => {
    event.preventDefault();
    setActiveHiddenContent( false );
  }

  return (
    loading ? <LinearProgress color="primary" /> :
    <div className="content_all">
      <div className="cash-register-box" style={{justifyContent: 'center',}}>
        { error != null ? 
            <SimpleBox>{error}</SimpleBox>
          :
            assignated ?

              <HideContent>
                <ActiveContent active={activeHiddenContent}>
                  { alertBlock }
                  <SimpleBox>
                    { statusId === cashRegisterStatusType.SupervisorClosed ?
                        <React.Fragment>
                          El supervisor aun no confirma el cierre de caja
                          <br /><Button variant="contained" size="large" color="primary" onClick={() => { resetAlerts(); loadData(); }}><AutorenewIcon /> Actualizar</Button>
                        </React.Fragment>
                      :
                        <React.Fragment>
                          Aún no has iniciado tu caja
                          <br /><Button variant="contained" size="large" color="primary" onClick={() => { setActiveHiddenContent( true ) }}>Abrir caja</Button>
                        </React.Fragment>
                    }
                  </SimpleBox>
                </ActiveContent>
                <HiddenContent active={activeHiddenContent}>
                  
                  <div className="cash-register-content">
                    <FormContent mini={true} title="Apertura de Caja" onSubmitAction={sendHandler} >

                      <FormItem title="Declarar el fondo fijo" help="Ingresa el monto en efectivo que recibiste para comenzar" require>
                        <TextField value={'$'+new Intl.NumberFormat('es-CL').format(baseAmount)} size="small" disabled />
                      </FormItem>

                      <FormItem title="Montaje de productos" help="Revisa las cantidades iniciales de productos disponibles para venta">
                        <div className="form_table">
                          <Grid container className="form_table_item">
                            <Grid xs={9} sm={8} item className="form_table_header">
                              Producto
                            </Grid>
                            <Grid xs={3} sm={4} item className="form_table_header form_col_center">
                              Cantidad
                            </Grid>
                          </Grid>
                          <ProductStock products={dataProducts} />
                        </div>
                      </FormItem>

                      <FormActions>
                        <BaseButton className="action_cancel" onClick={cancelHandler}>Cancelar</BaseButton>
                        <BaseButton className="action_go" loading={loadingAction}>Enviar</BaseButton>
                      </FormActions>

                    </FormContent>
                  </div>

                </HiddenContent>
              </HideContent>
              

            : 
              <SimpleBox>El administrador no te ha asignado ninguna caja</SimpleBox>
        }
      </div>
    </div>
  );
}

export default CashRegisterOpen;