import { createMuiTheme } from '@material-ui/core/styles';
  
  
  export const color = {
    neutral: {
      white:          '#ffffff',
      gray_10:        '#f7f7f7',
      gray_25:        '#DADADA',
      gray_50:        '#ADADAD',
      gray_75:        '#979797',
      gray_100:       '#666666',
      black:          '#000000',
    },
    primary: {
      green:          '#4ebf40',
    },
  };
  
  
  const store = createMuiTheme({
    typography: {
      fontFamily: 'Raleway, sans-serif',
    },
  
    overrides: {  
      MuiDrawer: {
        paper: {
          //backgroundColor: color.primary.blue_brilliant,
        },
      },
  
      MuiListItem: {
        root: {
  
        }
      },
  
      MuiListItemIcon: {
        root: {
          //color: color.neutral.white,
          //minWidth: 42,
        }
      },
  
       MuiListItemText: {
        root: {
          //color: color.neutral.white,
        }
      },
  
    }
  
  
  });
  
  export default store;