import axios from 'axios';
const queryString = require('query-string');

export const axiosNoIntercept = axios.create();

export const defaultHeaders = () => {
  let reqHeaders: { [k: string]: any } = {};
  reqHeaders['Content-Type'] = 'application/json';
  return reqHeaders;
}

export const getParamsFormat = (filter: Object) => {
  let params = "";
  Object.keys(filter).forEach((key) => {

    if (params !== "") {
      params = params + "&";
    }

    if (Array.isArray(filter[key as keyof Object])) {
      const arr: any = filter[key as keyof Object];

      arr.forEach((element: number, index: number) => {
        if (index === 0) {
          params = params + key + "=" + element;
        } else {
          params = params + "&" + key + "=" + element;
        }
      });
    } else {
      params = params + key + "=" + filter[key as keyof Object];
    }
  });
  return params;
};

export const getParamsFormatFromQs = (filter: Object) => {

  let qsParams = queryString.parse(window.location.search);
  filter = {...qsParams,...filter}

  let params = "";

  Object.keys(filter).forEach((key) => {

    if (filter[key as keyof Object] !== undefined && filter[key as keyof Object] !== null && filter[key as keyof Object].toString() !== "") {

      if (params !== "") {
        params = params + "&";
      }

      if (Array.isArray(filter[key as keyof Object])) {
        const arr: any = filter[key as keyof Object];

        arr.forEach((element: string, index: number) => {
          if (index === 0) {
            params = params + key + "=" + element;
          } else {
            params = params + "&" + key + "=" + element;
          }
        });
      } else {

        params = params + key + "=" + filter[key as keyof Object];

      }
    }
  });

  if (params.charAt(0) !== "?") {
    params = "?" + params;
  }
  return params;
};

export const getUrlFormat = (url: string, params: Object) => {
  if (params && Object.entries(params).length > 0) {
    return `${url}?${getParamsFormat(params)}`;
  }
  return url;
};

export const parseJSON = (response: any) => {
  return response.json();
}








export interface urlParamsProps {
  param: string,
  value: string,
}

export const getQueryParams = () => {
  let queryParams:urlParamsProps[] = [];
  let auxParams = window.location.search.substring(1).split('&');
  let paramsLength = auxParams.length;

  for( let i=0; i<paramsLength; i++ ){
    let auxValues = auxParams[i].split('=');
    if( auxValues[0] !== '' ){
      queryParams.push({
        param: auxValues[0],
        value: auxValues[1],
      });
    }
  }

  return queryParams;
}

export const getParam = ( param:string ) => {
  let params = getQueryParams();
  let value:null|string = null;
  
  let paramsLength = params.length;
  for(let i=0; i<paramsLength; i++ ){
    if( param === params[i].param ){
      // Encuentra el parametro
      value = params[i].value;
    }
  }

  return value;
}

export const createQuery = ( queryParams:urlParamsProps[] ) => {
  let auxQuery:string[] = [];
  let auxLength = queryParams.length;

  if( auxLength !== 0 ){
    for(let i=0; i<auxLength; i++){
      auxQuery.push( queryParams[i].param+'='+queryParams[i].value );
    }
  } else {
    return '';
  }

  return '?'+auxQuery.join('&');
}

export const addToQuery = ( queryParams:urlParamsProps[] ) => {
  let auxParams = getQueryParams();
  let auxLength = queryParams.length;

  for( let i=0; i<auxLength; i++ ){
    let saveValue:boolean = true;
    let auxLengthActive:number = auxParams.length;
    for(let a=0; a<auxLengthActive; a++){
      if( auxParams[a].param === queryParams[i].param ){
        saveValue = false;
        auxParams[a].value = queryParams[i].value;
        break;
      }
    }

    // Revisar si ya existe
    if( saveValue ){
      auxParams.push( queryParams[i] );
    }
  }

  return auxParams
}

export const removeFromQuery = ( queryParams:string[] ) => {
  let auxQuery:urlParamsProps[] = [];
  let activeParams:urlParamsProps[] = getQueryParams();
  let paramsLength:number = activeParams.length;

  for( let i=0; i<paramsLength; i++ ){
    if( !queryParams.includes(activeParams[i].param) ){
      auxQuery.push( activeParams[i] );
    }
  }

  return auxQuery;
}