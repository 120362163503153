export enum errorType {
  Success   = 1,
  Error     = 2,
  Warning   = 3,
}

export enum errorSectionType {
  All     = 1,
  General = 2,
  Custom  = 3,
}

export enum guaranteeStatusType {
  Pending         = 47,
  ApproveWaiting  = 52,
  Resolved        = 48,
}

export enum requestStatusType {
  Empty           = 0,
  Pending         = 29,
  Approved        = 31,
  Rejected        = 30,
}

export enum creditRequestStatusType {
  Pending         = 29,
  Approved        = 31,
  Rejected        = 30,
}

export enum roleType {
  Authenticated   = '2868a1de-78b2-42ae-917c-d6d7883aecba',
  Admin           = '3f1fa751-5551-470d-be31-1b15a3e1f5db',
  SuperAdmin      = '375bc5e7-70aa-410a-87a1-ed541edc3812',
  Cashier         = '592d9c5d-04c1-45cb-a93c-c08d07f05d34',
  CashSupervisor  = '00b9b641-ba91-44d7-a2de-45b53ec11d65',
  StoreManager    = '1553f8eb-923f-4f02-ad74-47b258ce0405',
  TouristGuide    = '79cc5566-087c-4d28-afcf-99993075806f',
  Agency          = '8f3abf69-bb47-4aa3-aeb1-2d971ccf788a',
  SAC             = '73899d71-2b33-46cd-9493-241c3fef450e',
  Skiroom         = '3bd23c55-5708-4f4f-a53e-4a63f244f558',
  Reseller        = '4631e57b-a516-424c-aada-cb7aac7b27db',
  Executive       = '6c2f4a5c-95cc-4763-84d0-5562ed528683',
  TrustAgency     = 'd3b9eafe-f7f2-40ca-af51-b79308db5262',
  Return          = '7c5de9c6-2359-406f-b750-ec7d04e22da1',
}

export enum cashRegisterStatusType {
  Open                = 15,
  Closed              = 16,
  Disabled            = 43,
  SupervisorClosed    = 49,
  ForcedClosed        = 50,
}

export enum passwordResetType {
  NotReviewed         = 1,
  Valid               = 2,
  Expired             = 3,
  Used                = 4,
}

export enum passwordOriginType {
  Anonymous         = 1,
  Set               = 2,
}

export enum weatherIconType {
  Bolt        = 'bolt',
  Cloud       = 'cloud',
  CloudRain   = 'cloud-rain',
  CloudSleet  = 'cloud-sleet',
  CloudSnow   = 'cloud-snow',
  CloudSun    = 'cloud-sun',
  Fog         = 'fog',
  Sun         = 'sun',
}