import React, { useEffect, useState } from 'react';
import { Grid, FormControl, TextField } from '@material-ui/core';

import { inputProps } from '../../../_helpers/props/form_props';

export function useInput(){

  const InputFilter = (props:inputProps) => {
    const [ customVal, setCustomValue ] = useState<string>( '' );

    useEffect(() => {
      if( customVal ){
        props.handleData( customVal );
      }
    }, [customVal]);

    useEffect(() => {
      console.log( 'reeender::Input', props.value );
      //setCustomValue( props.value ? props.value : "" );
    }, []);
 
    return (
      <React.Fragment>
        <Grid item>
          <TextField label={props.label} value={ props.value } onChange={ (event:any) => {props.handleData( event.target.value as string ); }} disabled={ props.disabled ? props.disabled : false } variant="outlined" />
        </Grid>
      </React.Fragment>
    )
  }

  return { InputFilter }
}

export default useInput;
