import React, { useState } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import TableContent from '../common/table/TableContent';

import { IColumn } from '../common/table/table-types';
import { errorSectionType } from '../../_helpers/enums/all';
import useErrorManager from '../common/ErrorManager';

import IconButton from '@material-ui/core/IconButton/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ProductListDate from './modal/ProductListDate';
import ModalBox from '../common/ModalBox';

import AttentionsFilters from './AttentionsFilters';

const AttentionsSummary = (props: RouteComponentProps) => {
  document.title = 'Recaudación';

  //const { response, loading, error } = useFetch({ method: 'GET', path: '/get/presales'});
  const { alertBlock } = useErrorManager({ id: 'attentions_summary', section: errorSectionType.General });
  const [ loadingValidation ] = useState<boolean>( false );

  const [ activeModal, setActiveModal ] = useState<React.ReactNode|null>();

  const handleProducts = (props:any) => {
    //console.log(props);

    return (
      <IconButton aria-label="edit" onClick={() => {console.log( props ); setActiveModal(<ProductListDate date={props.created} closeAction={() => { setActiveModal( null ); }} />)}}>
        <VisibilityIcon />
      </IconButton>
    );
  }

  const columns:Array<IColumn> = [
    { id: 'created',        visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Fecha',                 sortable: true },
    { id: 'pax_in_store',   visible: true,    numeric: true,    button: false,   disablePadding: true,  label: 'Pax tienda',            sortable: true },
    { id: 'adults',         visible: true,    numeric: true,    button: false,   disablePadding: true,  label: 'Cantidad adultos',      sortable: true },
    { id: 'junior',         visible: true,    numeric: true,    button: false,   disablePadding: true,  label: 'Cantidad junior',       sortable: true },
    { id: 'return_status',  visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Retorno',               sortable: false },
    { id: 'total_rental',   visible: true,    numeric: false,   button: false,   disablePadding: true,  label: '$ arriendo',            sortable: true },
    { id: 'total_sale',     visible: true,    numeric: false,   button: false,   disablePadding: true,  label: '$ ventas',              sortable: true },
    { id: 'total_pesos',    visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Pesos',                 sortable: true },
    { id: 'total_reales',   visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Reales',                sortable: true },
    { id: 'total_dolares',  visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Dólares',               sortable: true },
    { id: 'total_credito',  visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Crédito',               sortable: false },
    { id: 'total_debito',   visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Débito',                sortable: false },
    { id: 'products',       visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Productos',     sortable: false, extraData: handleProducts },
  ];

  return (
    loadingValidation ? <LinearProgress color="primary" /> :
    <div className="content_all">
      <div className="content">
        { alertBlock }

        <Paper className="content">
          <div className="paper_content_container">
            <TableContent
              id="attentions_summary_table"
              title="Recaudación"
              columnsValues={columns}
              urlData="/get/attentions/total"
              emptyData="No se encontraron atenciones para mostrar"
              filters={<AttentionsFilters />}
              today
            />
          </div>
        </Paper>
      </div>
      <ModalBox title="Detalle de productos" active={ activeModal ? true : false } >
        { activeModal }
      </ModalBox>
    </div>
  );
}

export default AttentionsSummary;