import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import BaseButton from '../../common/BaseButton';
import { useCartDataContext } from '../cart_provider';
import { preSaleInputProps } from '../../../_helpers/props/cash_register_props';
import { baseApiURL } from '../../../_services/fetch';
import { get } from '../../../_services/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { PreSaleResponseCode } from '../../../_helpers/enums/cash_register'
import FormControl from '@material-ui/core/FormControl';
import { Grid, IconButton } from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { color } from '../../../style/theme';

const PreSaleInput = (props:preSaleInputProps) => {
  const { cart,
          cartPayment,      
          addPreSale, removePreSale, setPreSaleValues } = useCartDataContext();
  const [ loading, setLoading ] = useState<boolean>( false );
  const [ error, setError ] = useState<string|null>( null );
  const [ code, setCode ] = useState<string>( '' );

  useEffect( () => {
    if( cartPayment.payment.pre_sale ){
      if( cartPayment.payment.pre_sale[ props.index ] ){
        setCode( cartPayment.payment.pre_sale[ props.index ].code );
      }
    }
  }, [ cartPayment ]);

  useEffect( () => {
    if( code !== undefined && code.length > 3 ){
      setLoading( true );
      setError( null) ;

      // Limpiamos el valor cuando se registra un cambio en el input
      setPreSaleValues && setPreSaleValues({ info: null, use: null, amount: 0, itemIndex: props.index });
      
      // Validamos que aun no existe el código
      var auxPresaleLength = cartPayment.payment.pre_sale.length;
      var auxCodeValid = true;
      for( let i = 0; i < auxPresaleLength; i++ ){
        if( cartPayment.payment.pre_sale[i].code ){
          if( cartPayment.payment.pre_sale[i].code === code && i !== props.index ){
            auxCodeValid = false;
            break;
          }
        }
      }
      
      if( auxCodeValid ){
        let code_response = get( baseApiURL+'/get/presale?code='+code );
        code_response.then((response:any) => { 
          setLoading( false );

          if( response.data.code === PreSaleResponseCode.Fail ){
            setError( 'Código no encontrado' );
          } else if( response.data.code === PreSaleResponseCode.Active ){
            setError( null );
            setPreSaleValues && setPreSaleValues({ info: response.data.values, itemIndex: props.index });
          } else {
            setError( 'Error desconocido' );
          }


        }).catch( (error:any) => {
          setLoading( false );
          setError( 'Hubo un error al contectar con la base de datos' );
        });
      } else {
        setLoading( false );
        setError( 'El código ingresado ya fue agregado' );
      }

    }
  }, [code]);

  return (
    <div className="preSaleInputItem">
      
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <FormControl variant="outlined" fullWidth className="form-item form-item-payment">
            <TextField
              label="Código"
              variant="outlined"
              value={ code }
              error= { error !== null }
              helperText={ error !== null && error }
              InputProps={{
                endAdornment: (
                  <React.Fragment>
                    {loading ?
                      <CircularProgress color="inherit" size={20} />
                    :
                      code.length > 3 ?
                        error !== null ?
                          <FontAwesomeIcon icon={faTimes} />
                        :
                          <FontAwesomeIcon icon={faCheck} />
                      :
                        ''
                    }
                  </React.Fragment>
                ),
              }}
              onChange={ (event) => { setPreSaleValues && setPreSaleValues({ code: event.target.value, itemIndex: props.index }); } }
            />
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="eliminar" size="medium" onClick={ () => { removePreSale && removePreSale(props.index);} } >
            <RemoveCircleIcon color="secondary" />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="agregar" size="medium" onClick={ () => { addPreSale && addPreSale(); } } >
            <AddCircleIcon style={{ color: color.primary.green }} />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  )
}

export default PreSaleInput;