import React, { useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import BaseButton from '../../common/BaseButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { errorSectionType, errorType } from '../../../_helpers/enums/all';
import useErrorManager from '../../common/ErrorManager';
import { baseApiURL } from '../../../_services/fetch';
import { post } from '../../../_services/api';

interface BootAssignProps {
  uuid            : string,
  enable         ?: boolean,
  closeAction     : () => void,
  sendAction      : () => void,
}

const DesistirAssign = ( props:BootAssignProps ) => {
  const { alertBlock, showAlert, resetAlerts } = useErrorManager({ id: 'bootsassign_modal', section: errorSectionType.Custom });
  const [ loadingSend, setLoadingSend ]         = useState<boolean>( false );

  const handleCancel = () => {
    resetAlerts();
    props.closeAction();
  }

  const handleSend = () => {
    setLoadingSend( true );

    let send_response = post( baseApiURL+'/post/skiroom/activity', {
      uuid: props.uuid,
    });

    send_response.then((response:any) => { 
      setLoadingSend( false );
      //console.log( response );
      props.sendAction();
    })
    .catch( error => {
      setLoadingSend( false );
      //console.log( error );
      showAlert({
        message: 'Hubo un problema al solicitar la aprobación. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });
  }

  return (
    <React.Fragment>
      <DialogContent className="guarantee-box-content">
        { alertBlock }
        <DialogContentText id="alert-dialog-description">
          { props.enable ?
              'Esto volverá a habilitar al pasajero con el equipo de nieve.'
            :
              'Debes confirmar que el pasajero decidió desistir de llevar el equipo para nieve.'
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <BaseButton onClick={() => { handleCancel() }} disabled={loadingSend}>
          Cancelar
        </BaseButton>
        <BaseButton onClick={() => { handleSend() }} disabled={loadingSend} >
          { loadingSend ?
              <CircularProgress />
            :
              'Desistir equipo'
          }
        </BaseButton>
      </DialogActions>
    </React.Fragment>
  )
}

export default DesistirAssign;