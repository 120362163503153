import React, { useState, useEffect }  from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel, CircularProgress, Grid } from '@material-ui/core';
import { ITableHeader, IColumn, Order, headerValues } from '../../common/table/table-types';

import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

import { post } from '../../../_services/api';
import { baseApiURL } from '../../../_services/fetch';
import useErrorManager from '../ErrorManager';
import { errorSectionType, errorType } from '../../../_helpers/enums/all';
import { useHistory } from 'react-router-dom';
import { getParam } from '../../../_services/request';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { filterProps, filtersData } from '../../../_helpers/props/form_props';
import TableFilterBlock from './TableFilterBlock';



const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      //flex: '1 1 100%',
      flex: '1 1',
    },
  }),
);

interface EnhancedTableToolbarProps {
  title         : string,
  numSelected   : number,
  deleteUrl     : string,
  selected      : string[],
  messages_id   : string,
  filters      ?: filterProps[],
  today        ?: boolean,
  todayLocked  ?: boolean,

  // Filter
  onFilterToday( action:boolean ): void,
  onFilter?: ( all:any ) => void,
}

export const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  let history = useHistory();


  // Filter structure
  const [ filters, setFilters ] = useState<filterProps[]>( [] );

  const { showAlert } = useErrorManager({ id: props.messages_id, section: errorSectionType.Custom });


  const classes = useToolbarStyles();
  const { numSelected } = props;
  const [ loading, setLoading ] = useState<boolean>( false );

  const [ activeFilter, setActiveFilter ] = useState<boolean>( false );
  const [ activeToday, setActiveToday ] = useState<boolean>( false );

  const handleFilter = () => {
    if( activeFilter ){
      setActiveFilter( false );
    } else {
      setActiveFilter( true );
    }
  }

  const handleDelete = () => {
    setLoading( true );
    let delete_response = post( baseApiURL+props.deleteUrl, [{itemsToRemove: props.selected}]);

    delete_response.then((response:any) => { 
      setLoading( false );
      let auxSuccess = false;
      
      if( response.data ){
        let auxDataLength = response.data.length;
        for( let i=0; i<auxDataLength; i++ ){
          console.log( response.data[i] );

          if( response.data[i].status === 'error' || response.data[i].status === 'pending' ){
            showAlert({
              message: 'Error al eliminar '+response.data[i].code+': '+response.data[i].detail,
              type_values: errorType.Error,
              section: errorSectionType.Custom,
            });


            
          } else if( response.data[i].status === 'success' ){
            auxSuccess = true;
            showAlert({
              message: response.data[i].detail,
              type_values: errorType.Success,
              section: errorSectionType.Custom,
            });
          }

        }
      }

      if( auxSuccess ){
        let auxSearch = history.location.search.split('&update');
        history.push({ search: auxSearch[0]+'&update='+new Date().getTime() });
      }

      
    })
    .catch( error => {
      setLoading( false );

      showAlert({
        message: 'No se pudo eliminar el elemento: '+error,
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });

    });

  }


  useEffect(() => {
    //if( !props.todayLocked ){
      if( activeToday ){
        // Agregar el filtro de hoy
        props.onFilterToday( true );
      } else {
        // Debemos quitar el filtro de fecha
        props.onFilterToday( false );
      }
    //}
  }, [activeToday]);

  useEffect(() => {

    if( props.todayLocked ){
      setActiveToday(true);
    } else {
      setActiveToday(false);
    }
  }, [props.todayLocked]);

  useEffect(() => {
    // Si recibe parámetro, se debe activar el switch
    if( getParam('active') === 'true' ){
       setActiveToday( true );
    }
  }, [history.location.search]);


  // Guardamos los filtros recibidos
  useEffect(() => {
    if( props.filters ){
      setFilters( props.filters );
    } else {
      setFilters( [] );
    }
  }, [props.filters]);

  return (
    <React.Fragment>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} { numSelected === 1 ? 'elemento seleccionado' : 'elementos seleccionados' }
          </Typography>
        ) : (
          <React.Fragment>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
              {props.title}
            </Typography> 
          </React.Fragment>
        )}
        {numSelected > 0 ? (
          !loading ?
            <Tooltip title="Delete">
              <IconButton aria-label="delete" onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          :
            <CircularProgress />
        ) : (
          <React.Fragment>

            { props.today &&
                <FormControlLabel
                  control={
                    <Switch
                      checked={ activeFilter ? false : activeToday}
                      onChange={(event) => { setActiveToday( event.target.checked ); }}
                      color="primary"
                      name="today"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      disabled={ activeFilter || props.todayLocked }
                    />
                  }
                  label="Ver solo hoy"
                />
            }


            { filters.length !== 0 && 
                <Tooltip title="Filter list">
                  <IconButton aria-label="filter list" onClick={handleFilter} disabled={ props.todayLocked || activeToday }>
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
            }
          </React.Fragment>
        )}
      </Toolbar>

      { activeFilter &&
          <TableFilterBlock filters={filters} />
      }
    </React.Fragment>
  );
};

  const TableHeader = (props: ITableHeader) => {
    const { onRequestSort, dataColumns } = props;
    const [ orderBy,setOrderBy ]= useState(props.orderBy);
    const [ order,setOrder ]= useState(props.order);

    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
      const isDesc = orderBy === property && order === Order.desc;
      const orderStr = isDesc ? Order.asc : Order.desc;
      setOrderBy(property);
      setOrder(orderStr);
      onRequestSort(event, property, orderStr);
    };

    
    const createColum = ( column:IColumn ) => {
      let auxColumn:IColumn = column;

      if( auxColumn.visible ){
        return(
          <TableCell
            key={auxColumn.id}
            align={'left'}
            sortDirection={orderBy === auxColumn.id ? order : false}
          >
            {auxColumn.sortable ? 
            <TableSortLabel
              active={orderBy === auxColumn.id}
              direction={order}
              onClick={createSortHandler(auxColumn.id)}
            >
              <strong>{auxColumn.label}</strong>
            </TableSortLabel> : <strong>{auxColumn.label}</strong> }
            
          </TableCell>
        )
      } else {
        return 
      }
    }

    let auxHeaders = dataColumns.map( (row:headerValues, index:number) => {

      // Buscamos la columna que corresponde
      let columnLength = props.columns.length;
      for( let i=0; i<columnLength; i++ ){
        if( props.columns[i].id === row.id ){
          //auxColumn = props.columns[i];
          return createColum( props.columns[i] );
        }
      }

      return null
      
    });
  
    return (
      <TableHead>
        <TableRow>
          { props.delete !== '' ?
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={props.numSelected > 0 && props.numSelected < props.rowCount}
                  checked={ props.rowCount > 0 && props.numSelected === props.rowCount}
                  onChange={props.onSelectAllClick}
                  inputProps={{ 'aria-label': 'select all desserts' }}
                />
              </TableCell>
            :
              null
          }
          { auxHeaders }
        </TableRow>
      </TableHead>
    );
  }

  export default TableHeader;