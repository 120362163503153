import { DateTime } from 'luxon';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { errorSectionType, errorType } from '../../../_helpers/enums/all';
import { post } from '../../../_services/api';
import { baseApiURL } from '../../../_services/fetch';
import { addToQuery, createQuery, getQueryParams } from '../../../_services/request';
import useErrorManager from '../../common/ErrorManager';
import ModalActions, { ModalAction } from '../../common/modal/ModalActions';

interface reservationCloseProps {
  uuid: string,
  activeModalFunc: Dispatch<SetStateAction<React.ReactNode|null>>,
}

const ReservationClose = ( props:reservationCloseProps ) => {
  let history = useHistory();
  const { alertBlock, showAlert, resetAlerts } = useErrorManager({ id: 'closeReservation_modal', section: errorSectionType.Custom });
  const [loading, setLoading] = useState<boolean>( false );

  const handleCloseReservation = () => {
    setLoading( true );

    let guaranteeApproval_response = post( baseApiURL+'/post/reservations/close', {
      uuid: props.uuid,
    });


    guaranteeApproval_response.then((response:any) => { 
      setLoading( false );

      if( response.status === 'ok' ){
        let auxParams = getQueryParams();

        // Agrega active
        auxParams.push({
          param: 'renew',
          value: DateTime.local().toFormat('x'),
        });

        history.push({ search: createQuery( addToQuery(auxParams) ) });
        props.activeModalFunc( null );

      } else {
        showAlert({
          message: 'Hubo un problema al solcitar el cierre de la reserva.',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      }
    })
    .catch( error => {
      setLoading( false );
      //console.log( error );
      showAlert({
        message: 'Hubo un problema al solcitar el cierre de la reserva. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });

  }

  return (
    <React.Fragment>
      <div className="modal_content modal_productbox">
        Al cerrar la atención, confirmas que el grupo atendido ya se ha retirado de Dubra.
        <br /><br />
        { alertBlock }
      </div>
      <ModalActions>
        <ModalAction onClick={() => { props.activeModalFunc( null ); }} disabled={loading} value="Cancelar" align="left" type="cancel" />
        <ModalAction onClick={handleCloseReservation} loading={loading} value="Cerrar atención" />
      </ModalActions>
    </React.Fragment>
  )
}

export default ReservationClose;