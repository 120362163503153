import React, {useState, useEffect} from 'react';


import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {setAccessToken} from '../../_auth/auth';
import { getU } from '../../_services/api'
import { baseApiURL } from '../../_services/fetch';

import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useAuthDataContext } from '../../_auth/auth_provider';
import useErrorManager from '../common/ErrorManager';
import { errorSectionType, errorType } from '../../_helpers/enums/all';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  //GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



const PassRecovery = (props: RouteComponentProps) => {
  const { isAuth, setLostedSession } = useAuthDataContext();
  let history = useHistory();
  const { alertBlock, showAlert, resetAlerts  } = useErrorManager({ id: 'session_pass_info', section: errorSectionType.General });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const classes = useStyles();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [helperText, setHelperText] = useState('');
  const [errorLocal, setError] = useState(false);
  
  const [ loading, setLoading ] = useState<boolean>( false );

  const [ validMail, setValidMail ] = useState<string>( '' );
  const [ username, setUsername ] = useState<string>( '' );

  

  useEffect( () => {
    if( isAuth ){
      history.push( '/' );
    }
  }, [isAuth] );

  const handleMail = ( email:string ) => {
    resetAlerts();
    setUsername( email );

    if( email === '' ){
      setValidMail( '' );
    } else {
      // don't remember from where i copied this code, but this works.
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if ( re.test(email) ) {
          // this is a valid email address
          // call setState({email: email}) to update the email
          // or update the data in redux store.
          setValidMail( '' );
      }
      else {
          // invalid email, maybe show an error to the user.
          setValidMail( 'Debes ingresar un correo válido.' );
      }
    }
  }


  const handlePassRecovery = (event?:any) => {
    if( event ){
      event.preventDefault();
    }
    setLoading( true );

    const token = executeRecaptcha && executeRecaptcha("pass_recovery");
    if( token ){
      token.then((response:any) => {
        //console.log('response', response);
        // Revisamos si los datos de acceso son correctos
        /*
        let login_response = postU( baseApiURL+'/post/user/pass-recovery', {
          "name": ,
        });
        */
        let login_response = getU( baseApiURL+'/get/user/'+username+'/pass-recovery');

        login_response.then((response:any) => { 
          setLoading( false );

          if( response.data ){

            if( response.data.status === 'ok' ){
              //console.log( response ); 
              setUsername( '' );

              showAlert({
                message: 'Hemos enviado un correo con un link para que puedas cambiar tu contraseña.',
                type_values: errorType.Success,
                section: errorSectionType.Custom,
              });
            } else {
              showAlert({
                message: 'Lo sentimos, no existen cuentas asociadas a este correo.',
                type_values: errorType.Error,
                section: errorSectionType.Custom,
              });
            }
          }

        })
        .catch( error => {
          setLoading( false );
          setValidMail( 'Usuario no registrado.' );
          
          showAlert({
            message: 'Hubo un error al cargar la información. ['+error+']',
            type_values: errorType.Error,
            section: errorSectionType.Custom,
          });
        });
      })
      .catch( error => {
        showAlert({
          message: 'Ups, hubo un problema al validar el recaptcha.',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      });
    }
    

  };

  const handleKeyPress = (e:any) => {
    if (e.keyCode === 13 || e.which === 13) {
      isButtonDisabled || handlePassRecovery();
    }
  };


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper} id="login_container">
        <Avatar className="login_avatar">
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Recuperar contraseña
        </Typography>
        { alertBlock }
        <form className={classes.form} noValidate autoComplete="off">
          <TextField
            helperText={ validMail === '' ? '' : validMail }
            error={ validMail !== '' ? true : false }
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            onChange={(event:any) => { handleMail( event.target.value );  } }
            onKeyPress={(e)=>handleKeyPress(e)}
            autoFocus
            value={username}
          />
          
          <br />
          <br />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={ loading || validMail !== '' || username === '' ? true : false }
            color="primary"
            onClick={(e)=>handlePassRecovery( e )}
          >
            { loading ?
                <CircularProgress />
              :
                'Recuperar contraseña'
            }
          </Button>

          <div className="login-extra-link">
            <Link href="/login" variant="body2">
              Iniciar sesión
            </Link>
          </div>


        </form>
      </div>
      
    </Container>
  );
}

export default PassRecovery;