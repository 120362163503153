import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import InputNumber from '../../../common/InputNumber';
import { typeProduct, productSize } from '../../../../_helpers/enums/cash_register';
import { productTotalCount } from '../../../../_helpers/props/cash_register_props';

interface detailProps {
  total     : number,
  size     ?: productSize,
  type      : typeProduct,
  onChange  : (props:productTotalCount) => void,
}

const ProductBoxSize = (props:detailProps) => {
  const [ auxTotal, setTotal ] = useState<number>( props.total );

  useEffect(() => {
    props.onChange({ size: props.size ? props.size : productSize.Adult, q: auxTotal });
  }, [auxTotal, props.size]);

  const handleLess = () => {
    if( auxTotal ){
      setTotal( (auxTotal-1) );
    }
  }

  const handlePlus = () => {
    setTotal( (auxTotal+1) );
  }

  return (
    <div className="modal_productbox_size">
      <Typography variant="h6">
        Cantidad { props.size && props.size }
      </Typography>
      <Typography variant="body1">
        Escoge la cantidad de productos a { props.type === typeProduct.Rental ? 'arrendar' : 'vender' }
      </Typography>
      <div className="modal_productbox_input">
        <InputNumber value={auxTotal} onLess={handleLess} onPlus={handlePlus} />
      </div>
    </div>
  )
}

export default ProductBoxSize;