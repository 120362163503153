import React, { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import TableContent from '../common/table/TableContent';

import { IColumn } from '../common/table/table-types';
import { errorSectionType, errorType } from '../../_helpers/enums/all';
import useErrorManager from '../common/ErrorManager';
import PresaleSummary from './PresaleSummary';

import PresaleFilters from './PresaleFilters';

const columns:Array<IColumn> = [
  { id: 'created',        visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Fecha',                 sortable: true },
  { id: 'seller_name',    visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Usuario',               sortable: true },
  { id: 'agency_name',    visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Agencia',               sortable: true },
  { id: 'pax_name',       visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Nombre pasajero',       sortable: true },
  { id: 'pax_file',       visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'File',                  sortable: false },
  { id: 'products_name',  visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Detalle productos',     sortable: false,   multiple: true },
  { id: 'code',           visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Código',                sortable: false,   uKey: true },
  { id: 'status_name',    visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Estado',                sortable: false },
];

const Presale = (props: RouteComponentProps) => {
  let history = useHistory();

  document.title = 'Pre-ventas';
  //const { response, loading, error } = useFetch({ method: 'GET', path: '/get/presales'});
  const { alertBlock, showAlert } = useErrorManager({ id: 'presale_page', section: errorSectionType.Custom });
  const [ loadingValidation ] = useState<boolean>( false );//const [ loadingValidation, setLoadingValidation ] = useState<boolean>( false );

  useEffect( () => {

    if( history.location.search.includes('newpresale') ){
      let auxUrlParams = new URLSearchParams( history.location.search );
      
      showAlert({
        message: 'La preventa '+auxUrlParams.get('newpresale')+' ha sido creada con éxito.',
        type_values: errorType.Success,
        section: errorSectionType.Custom,
        id: 'presale_page',
      });
    }

  }, [] );

  return (
    loadingValidation ? <LinearProgress color="primary" /> :
    <div className="content_all">
      <div className="content">
        { alertBlock }

        <PresaleSummary />

        <Paper className="content">
          <div className="paper_content_container">
            <TableContent
              id="presale_table"
              title="Listado de preventa"
              columnsValues={columns}
              //urlData="/get/presales"
              urlData="/admin/presales/all"
              emptyData="No se encontraron preventas para mostrar"
              filters={<PresaleFilters />}
              today
              apiType
            />
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default Presale;