import React, { useState, useEffect, FormEvent } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormContent, {FormItem, FormActions} from '../common/FormContent';
import TextField from '@material-ui/core/TextField';
import BaseButton from '../common/BaseButton';
import { errorSectionType, errorType } from '../../_helpers/enums/all';
import { useHistory } from 'react-router-dom';
import useErrorManager from '../common/ErrorManager';
import { baseApiURL } from '../../_services/fetch';
import { get, post } from '../../_services/api';
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import InputNumber from '../common/InputNumber';
import Alert from '@material-ui/lab/Alert/Alert';

interface returnProps {
  name        : string,
  product_id  : number,
  count       : number,
  returned    : number;
}

const Returns = () => {
  let history = useHistory();
  const { alertBlock, showAlert } = useErrorManager({ id: 'returns_form', section: errorSectionType.Custom });

  document.title = 'Control retorno';
  const [ loading, setLoading ]   = useState<boolean>( false );
  const [ loadingOrder, setLoadingOrder ]   = useState<boolean>( false);
  const [ loadingSave, setLoadingSave ]     = useState<boolean>( false );
  const [ orderId, setOrderId ]             = useState<string>( '' );
  const [ errorOrder, setErrorOrder ] = useState<string>('');
  const [ activeNote, setActiveNote ] = useState<string>( '' );
  const [ orderResponse, setOrderResponse ] = useState<any>( null );

  const [ returns, setReturns ] = useState<returnProps[]>( [] );
  const [ itemsToReturn, setItemsToReturn ] = useState<React.ReactNode[]>( [] );
  const [ completedOrder, setCompletedOrder ]     = useState<boolean>( false );

  interface checkFull {
    active      : boolean,
    product_id  : number,
  }

  interface changeValueProps {
    id  : number,
    new : number,
  }

  const getItem = ( product_id:number ) => {
    let returnsLength = returns.length;

    if( returnsLength !== 0 ){
      for( let i=0; i<returnsLength; i++ ){
        if( returns[i].product_id === product_id ){
          return i;
          break;
        }
      }
    }

    return 9999;
  }

  const changeValue = ( props:changeValueProps ) => {
    let returnLength = returns.length;
    let auxItem:returnProps[] = [];
    for( let i=0; i<returnLength; i++ ){
      let auxValue = returns[i];
      if( i === props.id ){
        auxValue.returned = props.new;
        console.log( 'cambio', props );
      }
      auxItem.push( auxValue );
    }
    setReturns( auxItem );
  }

  const handleCheck = ( props:checkFull ) => {
    let id_item:number = getItem( props.product_id );

    if( id_item !== 9999 ){
      let returnItem = returns[id_item];
      let newValue = 0;

      if( props.active ){
        newValue = returnItem.count;
      }

      changeValue({
        id: id_item,
        new: newValue,
      });
    }
  }
  
  const handlePlus = ( product_id:number ) => {
    let id_item:number = getItem( product_id );

    if( id_item !== 9999 ){
      let returnItem = returns[id_item];
      if( returnItem.returned < returnItem.count ){
        changeValue({
          id: id_item,
          new: returnItem.returned+1,
        });
      }
    }
  }

  const handleMinus = ( product_id:number ) => {
    let id_item:number = getItem( product_id );

    if( id_item !== 9999 ){
      let returnItem = returns[id_item];
      
      if( returnItem.returned ){
        changeValue({
          id: id_item,
          new: returnItem.returned-1,
        });
      }
    }
  }

  useEffect( () => {
    let returnsLength = returns.length;

    if( returnsLength !== 0 ){
      let auxItems:Array<React.ReactNode> = [];

      for( let i=0; i<returnsLength; i++ ){
        //console.log( returns[i].name, returns[i].count );
        auxItems.push(
          <Grid key={i} container className="form_table_item" alignItems="center" >
            <Grid xs={2} sm={2} item className="form_table_content form_col_center">
              <Checkbox
                checked={ returns[i].count == returns[i].returned }
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                onChange={ (event: React.ChangeEvent<HTMLInputElement>) => { handleCheck({ active: event.target.checked, product_id: returns[i].product_id }); } }
              />
            </Grid>
            <Grid xs={3} sm={4} item className="form_table_content">
              { returns[i].name }
            </Grid>
            <Grid xs={2} sm={2} item className="form_table_content form_col_center">
              { returns[i].count - returns[i].returned }
            </Grid>
            <Grid xs={3} sm={4} item className="form_table_content form_col_center">
              <InputNumber value={ returns[i].returned } onLess={() => {handleMinus( returns[i].product_id );}} onPlus={() => {handlePlus( returns[i].product_id );}} />
            </Grid>
          </Grid>
        );
      }

      setItemsToReturn( auxItems );
    } else {
      setItemsToReturn( [] );
    }
  }, [returns] );

  useEffect( () => {
    if( orderResponse ){
      orderResponse.then((response:any) => {

        // Vamos a revisar si trae datos
        if( response.data.uuid ){
          setErrorOrder( '' );
          setActiveNote( response.data.uuid );
          setLoadingOrder( false );

          history.push( '/returns/'+response.data.uuid );

          if( parseInt(response.data.completed) === 1 ){
            setCompletedOrder( true );
          }
          
          if( response.data.products ){
            setReturns( response.data.products );
          }


        } else {
          setReturns( [] );
          setErrorOrder( 'No se encuentra el código ingresado' );
        }
  
      }).catch( (error:any) => {
        showAlert({
          message: 'Hubo un error al buscar la información de la nota de venta. ['+error+']',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      });
    }
  }, [orderResponse] );


  

  useEffect(() => {
    if( orderId.length > 5 ){
      //orderResponse !== null && setOrderResponse( null );
      orderResponse && axios.CancelToken.source().cancel('Operation canceled by the user.');

      // La orden debe incluir "AWDUB"
      if( orderId.includes("AWDUB") || orderId.includes("awdub") ){
        //console.log( '***' );
        setErrorOrder( '' );
        setLoadingOrder( true );
        setReturns( [] );

        //  Buscamos la nota
        setOrderResponse( get( baseApiURL+'/get/return/'+orderId) );

      } else {
        setErrorOrder( 'El código ingresado no es válido' );
      }
      
    } else {
      setErrorOrder( '' );
      setLoadingOrder( false );
      
      // Verificamos si viene uuid en cabecera
      let auxPath:string[] = history.location.pathname.split('/');
      if( auxPath.length < 3 ){
        history.push( '/returns' ); 
      } else {
        setLoading( true );
        //setActiveNote( auxPath[2] );

        let return_response = get( baseApiURL+'/get/return/'+auxPath[2]);
        return_response.then((response:any) => {
          setLoading( false );
          if( response.code ){
            setOrderId( response.code );
          }
        }).catch( (error:any) => {
          setLoading( false );
          history.push( '/returns' ); 
          showAlert({
            message: 'Hubo un error al buscar el retorno de la nota de venta, por favor vuelve a intentarlo. ['+error+']',
            type_values: errorType.Error,
            section: errorSectionType.Custom,
          });
        });
        
        // Recuperamos el valor

      }
    }
  }, [orderId]);

  const handleCancel = (event: FormEvent) => {
    event.preventDefault();

    setErrorOrder( '' );
    setOrderId( '' );
    setActiveNote( '' );
    setReturns( [] );
    history.push( '/returns' );
  }

  const handleSend = (event: FormEvent) => {
    event.preventDefault();

    setLoadingSave( true );

    let saveReturn_response = post( baseApiURL+'/post/return/normal', {
      products  : returns,
      id        : activeNote,
    });

    saveReturn_response.then((response:any) => {
      setLoadingSave( false );

      
      history.push( '/returns' );
      setErrorOrder( '' );
      setActiveNote( '' );
      setReturns( [] );
      setOrderId( '' );

      let errorTypeValue = errorType.Warning;
      if( response.data.code === 1 ){
        errorTypeValue = errorType.Success;
      }

      showAlert({
        message: response.data.msg,
        type_values: errorTypeValue,
        section: errorSectionType.Custom,
      });



    }).catch( (error:any) => {
      setLoadingSave( false );
      showAlert({
        message: 'Hubo un error al registrar la devolución, por favor actualiza y vuelve a intentarlo. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });
  }

  return (
    loading ? <LinearProgress color="primary" /> :
    <div className="content_all">
      <div className="content">
        {alertBlock}

      <FormContent mini={true} title="Control retorno" onSubmitAction={(event) => { event.preventDefault() }} >

        <FormItem title="Ingresar código de nota de venta" help='Recuerda que el código aparece en la sección "Control retorno"' require>
          <FormControl>
            <Input
              id="input_code"
              type="text"
              value={ orderId }
              error={ errorOrder !== '' ? true : false }
              disabled={ activeNote === '' ? false : true }
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => { console.log( '*****::', event ); if( typeof event.target.value !== 'string' ){ setOrderId(''); } else { setOrderId( event.target.value ); } }}
              endAdornment={
                <InputAdornment position="end">
                  { loadingOrder ?
                      <CircularProgress color="primary" size={25} />
                    :
                      <CircularProgress color="primary" size={25} thickness={0} />
                  }
                </InputAdornment>
              }
              autoFocus
            />
            <FormHelperText id="input_code" error={ errorOrder !== '' ? true : false }>{errorOrder}</FormHelperText>
          </FormControl>
        </FormItem>

        <FormItem title={completedOrder ? "" : "Devolución" } help={completedOrder ? "" : "Identifica los artículos devueltos" } visible={ itemsToReturn.length === 0 ? false : true } expanded>
          {completedOrder ?
            <Alert severity="success">Todos los productos de esta nota de venta ya fueron devueltos.</Alert>
          :
            <div className="form_table">
              <Grid container className="form_table_item form_table_item_header">
                <Grid xs={2} sm={2} item className="form_table_header">
                  Completar
                </Grid>
                <Grid xs={3} sm={4} item className="form_table_header">
                  Producto
                </Grid>
                <Grid xs={2} sm={2} item className="form_table_header form_col_center">
                  Pendiente
                </Grid>
                <Grid xs={3} sm={4} item className="form_table_header form_col_center">
                  Devuelto
                </Grid>
              </Grid>
              { itemsToReturn }
            </div>
          }
        </FormItem>

        
        <FormActions>
          { activeNote &&
            <React.Fragment>
              <BaseButton className="action_cancel" onClick={handleCancel} disabled={loadingSave}>Cancelar</BaseButton>
              { !completedOrder &&
                  <BaseButton className="action_go" onClick={handleSend} loading={loadingOrder} disabled={loadingSave}>
                    { loadingSave ?
                        <CircularProgress />
                      :
                        'Guardar'
                    }
                  </BaseButton>
              }
            </React.Fragment>
          }
        </FormActions>

      </FormContent>
      </div>
    </div>
  )
}

export default Returns;