import React, {useState, useEffect, FunctionComponent} from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { useAuthDataContext } from '../_auth/auth_provider';
import { isAuthenticated } from '../_auth/auth';
import PropTypes from 'prop-types';

interface IPrivateRouteProps {
  component: FunctionComponent<any>,
}

const PrivateRoute = ( props: IPrivateRouteProps & RouteProps ) => {
  const [guard, setGuard] = useState<boolean|null>(true);

  const user = useAuthDataContext();
  const isAuth = isAuthenticated();

  useEffect(() => {
    if(user.isAuth !== null){
      setGuard( user.isAuth );
    }
  }, [user.isAuth, props.path]);

  useEffect(() => {
    if(isAuth === null) {
      setGuard( isAuth );
    } else {
      setGuard( user.isAuth );
    }
  }, [isAuth, user.isAuth])

  return (
    guard ? 
    
      <Route path={props.path} component={props.component} exact={props.exact} {...props} /> 
    :
    <Redirect to="/login" />
  )

};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired
};

export default PrivateRoute;