import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';

import BaseButton from '../common/BaseButton';
import { getQueryParams, addToQuery, createQuery, removeFromQuery, urlParamsProps } from '../../_services/request';
import { useHistory } from 'react-router-dom';

import useDateRange from  '../common/filters/DateRange';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

const PresaleFilters = () => {
  let history = useHistory();
  const { DateRangeFilter, handleCleanDateRange, handleSearchDateRange } = useDateRange();

  const handleSearch = () => {
    let auxParams:urlParamsProps[] = [];
    auxParams = handleSearchDateRange();

    // Custom params

    history.push({ search: createQuery( addToQuery(auxParams) ) });
  }

  const handleClean = () => {
    let auxParams = removeFromQuery(['from', 'end']);
    history.push({ search: createQuery( auxParams ) });

    handleCleanDateRange();
  }

  useEffect(() => {
    console.log('Debo verificar los filtros activos');
  }, []);

  return (
    <React.Fragment>
      <DateRangeFilter />
      <Grid item>
        <div className="btn-container-dashboard">
          <BaseButton className="btn btn-simple btn-dubra" onClick={handleSearch}>Buscar</BaseButton>
          <BaseButton className="btn btn-outlined btn-dubra-cancel" onClick={handleClean}><RotateLeftIcon />Limpiar</BaseButton>
        </div>
      </Grid>
    </React.Fragment>
  )
}

export default PresaleFilters