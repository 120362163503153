import React, {useState, useEffect} from 'react';


import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {setAccessToken} from '../../_auth/auth';
import { getU, postU } from '../../_services/api'
import { baseApiURL } from '../../_services/fetch';

import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useAuthDataContext } from '../../_auth/auth_provider';
import useErrorManager from '../common/ErrorManager';
import { errorSectionType, errorType, passwordResetType, passwordOriginType } from '../../_helpers/enums/all';
import CircularProgress from '@material-ui/core/CircularProgress';

import zxcvbn from 'zxcvbn';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import {
  //GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



const PassReset = (props: RouteComponentProps) => {
  const { isAuth, setLostedSession } = useAuthDataContext();
  let history = useHistory();
  const { alertBlock, showAlert, resetAlerts  } = useErrorManager({ id: 'session_pass_info', section: errorSectionType.General });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const classes = useStyles();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [helperText, setHelperText] = useState('');
  const [errorLocal, setError] = useState(false);
  
  const [ loading, setLoading ] = useState<boolean>( false );

  const [ validMail, setValidMail ] = useState<string>( '' );
  const [ username, setUsername ]   = useState<string>( '' );

  const [ newPassword, setNewPassword ]               = useState<string>( '' );
  const [ confirmNewPassword, setConfirmNewPassword ] = useState<string>( '' );

  const [ match, setMatch ]                 = useState<boolean>( false );
  const [ securityScore, setSecurityScore ] = useState<number>(0);
  const [ securityLevel, setSecurityLevel ] = useState<string|React.ReactNode>('-');

  const [ requestUUID, setRequestUUID ] = useState<string>( '' );
  const [ status, setStatus ]           = useState<passwordResetType>( 1 );
  const [ type, setType ]               = useState<passwordOriginType>( 1 );
  const [ loadingBase, setLoadingBase ] = useState<boolean>( true );
  const [ errorURL, setErrorURL ]       = useState<boolean>( false );

  useEffect( () => {
    if( requestUUID !== '' ){
      let login_response = getU( baseApiURL+'/get/user/'+requestUUID+'/pass-recovery');

      login_response.then((response:any) => { 
        setLoadingBase( false );
        if( response.status ){
          setStatus( response.status );
          setType( response.type );
        }
      })
      .catch( error => {
        setLoadingBase( false );
        setErrorURL( true );
        //setValidMail( 'Usuario no registrado.' );
        showAlert({
          message: 'Hubo un error al cargar la información. ['+error+']',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      });
    }
  }, [requestUUID] );

  useEffect( () => {
    let auxURL = history.location.pathname.split('/');
    setRequestUUID( auxURL[2] );
  }, [history.location.pathname] );

  useEffect( () => {
    if( isAuth ){
      history.push( '/' );
    }
  }, [isAuth] );

  useEffect(() => {
    // Verificar que son iguales
    if( newPassword !== '' && newPassword === confirmNewPassword ){
      //console.log( 'coinciden', newPassword, confirmNewPassword );
      setMatch( true );
    } else {
      //console.log( 'no coinciden', newPassword, confirmNewPassword );
      setMatch( false );
    }
  }, [newPassword, confirmNewPassword]);

  useEffect(() => {
    if( securityScore === 0 ){
      setSecurityLevel( '-' );
    } else if( securityScore === 1 ){
      setSecurityLevel( <React.Fragment><CloseIcon color="error"/> Muy inseguro</React.Fragment> );
    } else if( securityScore === 2 ){
      setSecurityLevel( <React.Fragment><CloseIcon color="error"/> Poco seguro</React.Fragment> );
    } else if( securityScore === 3 ){
      setSecurityLevel( <React.Fragment><CheckIcon color="primary"/> Bueno</React.Fragment> );
    } else if( securityScore === 4 ){
      setSecurityLevel( <React.Fragment><CheckIcon color="primary"/> Seguro</React.Fragment> );
    } else if( securityScore === 5 ){
      setSecurityLevel( <React.Fragment><CheckIcon color="primary"/> Muy seguro</React.Fragment> );
    }
  }, [securityScore]);
  
  const handlePass = ( pass:string ) => {
    resetAlerts();
    setNewPassword( pass );
    
    setSecurityScore( zxcvbn( pass ).score );
  }
  
  const handleConfirmPass = ( pass:string ) => {
    resetAlerts();
    setConfirmNewPassword( pass );
  }


  const handlePassRecovery = (event?:any) => {
    if( event ){
      event.preventDefault();
    }
    setLoading( true );

    const token = executeRecaptcha && executeRecaptcha("pass_recovery");
    if( token ){
      token.then((response:any) => {
        console.log('response', response);

        // Revisamos si los datos de acceso son correctos
        let login_response = postU( baseApiURL+'/user/data/uuid/set/password', {
          uuid: requestUUID,
          pass: newPassword,
        });

        login_response.then((response:any) => { 

          if( response.data ){

            if( response.data.status === 'ok' ){

              if( type === passwordOriginType.Anonymous ){
                history.push('/login?newpass=1');
              } else {
                // Debemos iniciar sesión
                //console.log( response );

                // Iniciamos la sesión
                let login_response = postU( baseApiURL+'/user/login?_format=json', {
                  "name": response.data.mail,
                  "pass": newPassword,
                });

                login_response.then((response:any) => {
                  // Guardamos el token
                  setAccessToken( response.access_token );

                  window.location.replace('/');
                })
                .catch( error => {
                  showAlert({
                    message: 'No se pudo obtener la información de acceso. ['+error+']',
                    type_values: errorType.Error,
                    section: errorSectionType.Custom,
                  });
                });

              }

            } else {
              setLoading( false );
              showAlert({
                message: 'Lo sentimos, no existen cuentas asociadas a este correo.',
                type_values: errorType.Error,
                section: errorSectionType.Custom,
              });
            }
          }

        })
        .catch( error => {
          setLoading( false );
          setValidMail( 'Usuario no registrado.' );
          
          showAlert({
            message: 'Hubo un error al cargar la información. ['+error+']',
            type_values: errorType.Error,
            section: errorSectionType.Custom,
          });
        });
      })
      .catch( error => {
        showAlert({
          message: 'Ups, hubo un problema al validar el recaptcha.',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      });
    }
    

  };

  const handleKeyPress = (e:any) => {
    if (e.keyCode === 13 || e.which === 13) {
      isButtonDisabled || handlePassRecovery();
    }
  };


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper} id="login_container">

        { loadingBase ?
            <CircularProgress />
          :
            errorURL || status !== passwordResetType.Valid ?
              <ReportProblemIcon color="error" fontSize="large" />
            :
              <Avatar className="login_avatar">
                <LockOutlinedIcon />
              </Avatar>
        }
        <Typography component="h1" variant="h5">
          { errorURL || status !== passwordResetType.Valid && !loadingBase ?
              'URL no válida'
            :
              'Cambio de contraseña'
          }
        </Typography>
        <br />


        
        <Typography variant="body2">
          { loadingBase ?
              <React.Fragment>Estamos revisando la validez del link.</React.Fragment>
            :
              errorURL || status !== passwordResetType.Valid ?
                <React.Fragment>La URL ingresada no es válida, si necesitas recuperar tu contraseña <Link href="/login/password" variant="body2">haz click aquí</Link>.</React.Fragment>
              :
                <React.Fragment>A continuación debes ingresar tu nueva contraseña en ambos campos. Para que sea una contraseña válida debe alcanzar al menos el nivel <em>bueno</em>.</React.Fragment>
          }
        </Typography>
        
        { alertBlock &&
            <React.Fragment>
              <br />
              {alertBlock}
            </React.Fragment>
        }
        { loadingBase ||
            errorURL || 
              status === passwordResetType.Valid &&
                <form className={classes.form} noValidate autoComplete="off">
                  <br />
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="newpassword">Nueva contraseña</InputLabel>
                    <OutlinedInput
                      autoFocus
                      id="newpassword"
                      type="password" //type={values.showPassword ? 'text' : 'password'}
                      required
                      value={newPassword}
                      onChange={(event:any) => { handlePass( event.target.value );  } }
                      onKeyPress={(e)=>handleKeyPress(e)}
                      endAdornment={
                        <InputAdornment position="end">
                          { securityLevel }
                        </InputAdornment>
                      }
                      labelWidth={140}
                    />
                  </FormControl>

                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Confirmar nueva contraseña"
                    type="password"
                    onChange={(event:any) => { handleConfirmPass( event.target.value );  } }
                    onKeyPress={(e)=>handleKeyPress(e)}
                    value={confirmNewPassword}
                    helperText={ confirmNewPassword !== '' && !match ? 'Ambas contraseñas NO coinciden' : '' }
                    error={ confirmNewPassword !== '' && !match ? true : false }
                  />
                  
                  <br />
                  <br />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={ match && securityScore >= 3 ? false : true }
                    color="primary"
                    onClick={(e)=>handlePassRecovery( e )}
                  >
                    { loading ?
                        <CircularProgress />
                      :
                        'Cambiar contraseña'
                    }
                  </Button>

                  <div className="login-extra-link">
                    <Link href="/login" variant="body2">
                      <Typography variant="button" color="error">
                        Cancelar
                      </Typography>
                    </Link>
                  </div>


                </form>
        }
      </div>
        
    </Container>
  );
}

export default PassReset;