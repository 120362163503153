import React, { useEffect, useState } from "react";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import BaseButton from "../BaseButton";
import TableFilters from "./table-filters";
import { filterProps, filtersData } from "../../../_helpers/props/form_props";
import { addToQuery, createQuery, getQueryParams, removeFromQuery, urlParamsProps } from "../../../_services/request";
import { useHistory } from "react-router";

interface tableFilterProps {
  filters: filterProps[],
}

const TableFilterBlock = ( props:tableFilterProps ) => {
  let history = useHistory();
  const [ dataFilter, setDataFilter ] = useState<filtersData[]>([]);


  const getParamValue = (id:string) => {
    //console.log('seeeearch', id);
    var dataActive:filtersData[] = dataFilter;
    let dataCount:number = dataActive.length;
    for(let i=0; i<dataCount; i++){
      if( dataFilter[i].id === id ){
        //console.log( id, dataFilter[i].value );
        return dataFilter[i].value;
        break;
      }
    }

    return null;
  }

  const setParamValue = (id: string, value: any) => {
    var dataActive:filtersData[] = [];

    // Armo completo el arreglo de filtros
    let dataCount:number = dataFilter.length;
    for(let i=0; i<dataCount; i++){
      var auxDataFilter:filtersData = dataFilter[i];
      
      // Si detecta el "id" realiza el cambio antes de guardar
      if( dataFilter[i].id === id ){
        auxDataFilter.value = value;
      }

      // Debo agregar
      dataActive.push( auxDataFilter );
    }

    if( getParamValue( id ) === null ){
      // Debo agregar
      dataActive.push({ id: id, value: value, });
    }
    
    //console.log( 'g', dataActive );
    setDataFilter( dataActive );
  }

  const removeParamValue = ( id:string[] ) => {
    //console.log( 'quiero quitar: ', id, dataFilter );
    var dataActive:filtersData[] = [];

    // Armo completo el arreglo de filtros
    let dataCount:number = dataFilter.length;
    for(let i=0; i<dataCount; i++){
      
      // Si detecta que el "id" no exista, no lo guarda
      if( !id.includes( dataFilter[i].id ) ){
        // Debo agregar
        var auxDataFilter:filtersData = dataFilter[i];
        dataActive.push( auxDataFilter );
      }

    }

    //console.log('Así guardamos:', dataActive);
    setDataFilter( dataActive );
  }

  const handleSearch = () => {
    //var auxParams:urlParamsProps[] = getQueryParams();
    var auxValue:urlParamsProps[] = [];

    // Buscamos los valores ya almacenados por query string
    let dataFiltersAllLength:number = dataFilter.length;
    for(var i=0; i<dataFiltersAllLength; i++){

      // Verificamos si viene datefrom o dateend
      if( dataFilter[i].id === 'datefrom' || dataFilter[i].id === 'dateend' ){
        // Como lo detectamos, debemos quitarle el "date" porque no pasa por query string
        auxValue.push( {
          param: dataFilter[i].id.replace( 'date', '' ),
          value: dataFilter[i].value.toFormat('dd-LL-yyyy'),
        } );
      } else {
        auxValue.push( {
          param: dataFilter[i].id,
          value: dataFilter[i].value,
        } );
      }

    }

    // Custom params
    history.push({ search: createQuery( addToQuery(auxValue) ) });
  }

  const handleClean = () => {
    var auxFilters:string[] = [];
    var auxFiltersRemove:string[] = [];

    // Recorrer los filtros básicos para quitarlos
    let filtersAllLength:number = props.filters.length;
    for(var i=0; i<filtersAllLength; i++){
      //console.log('filtro', props.filters[i]);
      if( props.filters[i].id === 'date' ){
        auxFilters.push('from');
        auxFilters.push('end');

        // **** Cambiar el valor almacenado en el campo
        auxFiltersRemove.push('datefrom');
        auxFiltersRemove.push('dateend');
        //removeParamValue(['datefrom', 'dateend']);
      } else {
        auxFilters.push( props.filters[i].id );
        auxFiltersRemove.push( props.filters[i].id );
        //removeParamValue([props.filters[i].id]);
      }
      
    }

    removeParamValue( auxFiltersRemove );

    let auxParams = removeFromQuery( auxFilters );
    history.push({ search: createQuery( auxParams ) });
  }

  return (
    <Toolbar>
      
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          Filtrar por:
        </Grid>
        { 
          props.filters.map((item:any, index: number) => {
            return <TableFilters itemData={item} key={index} getParamValue={getParamValue} setParamValue={setParamValue} />;
          })
      }
        <Grid item>
          <div className="btn-container-dashboard">
            <BaseButton className="btn btn-simple btn-dubra" onClick={handleSearch}>Buscar</BaseButton>
            <BaseButton className="btn btn-outlined btn-dubra-cancel" onClick={handleClean}><RotateLeftIcon />Limpiar</BaseButton>
          </div>
        </Grid>
      </Grid>
    </Toolbar>
  )
}

export default TableFilterBlock;