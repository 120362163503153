import axios, { AxiosRequestConfig } from "axios";
import * as auth from "../_auth/auth"; // Our custom auth utility file

export const ws_base_url = 'http://store-rest';

const getAccessTokenFromRefreshToken = (token: string) => {
  // Needs to be implemented on the backend
  // Mocking this for brevity
  return Promise.resolve("new-access-token");
};

export const ensureAuth = async () => {
  try {
    // If access token is expired attempt to get another via the refresh token
    if (!auth.isAuthenticated()) {
      const refreshToken = auth.getRefreshToken();
      if (!refreshToken) throw new Error("Missing refresh token");
      const accessToken = await getAccessTokenFromRefreshToken(refreshToken);
      auth.setAccessToken(accessToken);
    }
    return auth.getAccessToken()!;
  } catch (e) {
    // If unable to get a new id token then purge everything for a fresh login
    // Perform any actions necessary to reflect an unauthenticated state
    auth.reset();
    //history.pushState('login');
    //throw new Error("Unauthorized to complete request");
  }
};

type RequestMethod = AxiosRequestConfig["method"];


const performAuthenticatedRequest = async <T>(
  method: RequestMethod,
  url: string,
  data?: object,
  headers?: object
): Promise<T> => {
  let cancel;
  const accessToken = await ensureAuth();
  const response = await axios({
    url,
    data,
    method,
    headers: {
      //Authorization: accessToken
      Authorization: `Bearer `+accessToken
    },
    cancelToken: new axios.CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    }),
  });
  return response.data;
};

const performUnuthenticatedRequest = async <T>(
  method: RequestMethod,
  url: string,
  data?: object,
  headers?: object
): Promise<T> => {
  const response = await axios({
    url,
    data,
    method,
    headers: headers,
  });
  return response.data;  
};



export const get = <T>(endpoint: string): Promise<T> => {
  //console.log('GET', endpoint);
  return performAuthenticatedRequest("get", endpoint);
};

export const getU = <T>(endpoint: string): Promise<T> => {
  return performUnuthenticatedRequest("get", endpoint);
};

export const post = <T>(endpoint: string, data?: object, headers?: object): Promise<T> => {
  return performAuthenticatedRequest("post", endpoint, data, headers);
};

export const postU = <T>(endpoint: string, data?: object, headers?: object): Promise<T> => {
  return performUnuthenticatedRequest("post", endpoint, data, headers);
};

export const put = <T>(endpoint: string, data?: object): Promise<T> => {
  return performAuthenticatedRequest("put", endpoint, data);
};

export const del = <T>(endpoint: string, data?: object): Promise<T> => {
  return performAuthenticatedRequest("delete", endpoint, data);
};