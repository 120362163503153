import React, {useState, useEffect, useRef, useCallback} from 'react';
import { LinearProgress, Grid, CircularProgress, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core';

import swal from 'sweetalert';
import { ActiveCashRegisterResponseCode } from '../common/enums';
import { StepBlock } from '../../_helpers/enums/cash_register'
import SimpleBox from '../common/SimpleBox';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import useFetch, { baseApiURL } from '../../_services/fetch';
import BaseButton from '../common/BaseButton';
import CashStep from './CashStep';
import { SaleStepProps } from '../../_helpers/props';


import { faMitten, faShoppingBag, faTasks, faHandHoldingUsd, faTimesCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import CartContainer from './cart/CartContainer';

import { useCartDataContext } from './cart_provider';
import InputNumber from '../common/InputNumber';
import { get, post } from '../../_services/api';
import CartGuarantee from './cart/CartGuarantee';
import CartPayment from './cart/CartPayment';
import ModalBox from '../common/ModalBox';
import SweetAlert, { SweetAlertAddLi } from '../common/SweetAlert';

import ReactToPrint from 'react-to-print';
import {FunctionalBillPrintable} from './ComponentToPrint';
import { DateTime } from "luxon";
import { returnListProps } from '../../_helpers/props/cash_register_props';

const CashRegisterSale = (props: RouteComponentProps) => {
  const { loadingCart, setLoadingCart,
          cart, setCart, 

          cartOrder, setCartOrder,
          cartPayment, setCartPayment,

          cartProducts, arriendoProducts, 
          getProductArriendo, ventaProducts, 
          getProductVenta, 
          addAdult, minusAdult, addJunior, minusJunior,
          modalTitle, modalActive, modalContent,
          resetCart,
  } = useCartDataContext();

  let history = useHistory();
  const { response, loading, error } = useFetch({ method: 'GET', path: '/get/cash-registers/active'});
  document.title = 'Caja';

  const [ agenciesData, setAgenciesData ] = useState<Array<any>>( [] );
  const [ agencies, setAgencies ] = useState<Array<any>>( [] );
  const [ tourismGuides, setTourismGuides ] = useState<Array<any>>( [] );
  const [ loadingGuides, setLoadingGuides] = useState<boolean>( false );
  const [ cartDataLoaded, setCartDataLoaded ] = useState<any>();
  const [ cashRegisterValue, setCashRegisterValue ] = useState<string>('');
  const [ storeValue, setStoreValue ] = useState<string>('-');
  const [ orderId, setOrderId ] = useState<number>(0);
  const [ orderDate, setOrderDate ] = useState<DateTime>();
  const [ orderQR, setOrderQR ] = useState<string>();
  const [ orderReturn, setOrderReturn ] = useState<returnListProps[]>([]);
  

  const [ nameError, setNameError ] = useState<string>( '' );
  const [ paxError, setPaxError ] = useState<string>( '' );
  
  const [ loadingSale, setLoadingSale ] = useState<boolean>( false );
  
  const sidebarContainer = useRef<HTMLHeadingElement>(null);
  const [ activeGarantia, setActiveGarantia ] = useState<boolean>( true );
  const [ stepActive, setStepActive ] = useState<SaleStepProps>({
    arriendo  : 'active',
    venta     : '',
    garantia  : '',
    pago      : '',
    cancel    : 'hidden',
    checkout  : 'hidden',
  });



  const handleStep = (step:string) => {
    if( step === StepBlock.Arriendo ){
      setStepActive( { arriendo: 'active', venta: '', garantia: '', pago: '', cancel: 'hidden', checkout: 'hidden', } );
    } else if( step === StepBlock.Venta ){
      setStepActive( { arriendo: 'visited', venta: 'active', garantia: '', pago: '', cancel: 'hidden', checkout: 'hidden', } );
    } else if( step === StepBlock.Garantia || step === StepBlock.Pago ){

      let auxActive = true;






      var auxContent:any = SweetAlert( 'ul' );

      if( cartOrder.name === '' ){
        auxContent = SweetAlertAddLi({
          container: auxContent,
          content: 'Debes agregar un nombre antes de continuar'}
        );
        setNameError( 'Falta completar el nombre' );
        auxActive = false;
      }

      if( ( cartOrder.q_adultos + cartOrder.q_ninos ) === 0 ){
        auxContent = SweetAlertAddLi({
          container: auxContent,
          content: 'Debes seleccionar la cantidad de pasajeros'}
        );
        setPaxError( 'Falta seleccionar la cantidad de pasajeros adultos y junior' );
        auxActive = false;
      }

      if( !auxActive ){
        swal({
          title: "Antes de continuar",
          icon: "warning",
          dangerMode: true,
          content: {
            element: auxContent,
          },
        });
      }








      if( auxActive ){
        if( step === StepBlock.Garantia ){
          setStepActive( { arriendo: 'visited', venta: 'visited', garantia: 'active', pago: '', cancel: 'hidden', checkout: 'hidden', } );
        } else {
          let auxActivePago = true;
          if( cartPayment.payment.total.total === 0 ){
            auxActivePago = false;
            swal({
              title: "Antes de continuar",
              text: 'Para avanzar debes agregar al menos un producto a la canasta de compras.',
              icon: "warning",
              dangerMode: true,
            });
          }
          if( cartPayment.garantia.state === 'normal' ){

            if( cartPayment.garantia.pesos || cartPayment.garantia.real || cartPayment.garantia.dolar || cartPayment.garantia.credito ){
      
              let totalPesos = 0;
              if( cartPayment.garantia.pesos ){
                totalPesos = Number( cartPayment.garantia.pesos );
              }
              
              let totalDolar = 0;
              if( cartPayment.tc.dolar && cartPayment.garantia.dolar ){
                totalDolar = cartPayment.tc.dolar * cartPayment.garantia.dolar;
              }
        
              let totalReal = 0;
              if( cartPayment.tc.real && cartPayment.garantia.real ){
                totalReal = cartPayment.tc.real * cartPayment.garantia.real;
              }
        
              let totalCredito = 0;
              if( cartPayment.garantia.credito ){
                const auxCreditoLength = cartPayment.garantia.credito.length;
        
                if( auxCreditoLength !== 0 ){
                  for( let i = 0; i < auxCreditoLength; i++ ){
                    if( cartPayment.garantia.credito[i] ){
                        totalCredito += Number( cartPayment.garantia.credito[i].amount );
                    }
                  }
                }
              }
        
              let auxTotalCalculated = cartPayment.garantia.total - totalPesos - totalDolar - totalReal - totalCredito;
              if( auxTotalCalculated > 0 ){
                auxActivePago = false;
                //alert( 'Faltan $'+new Intl.NumberFormat("es-CL").format( Math.abs(auxTotalCalculated) )+' para completar la garantía.' );
                swal({
                  title: "Antes de continuar",
                  text: 'Faltan $'+new Intl.NumberFormat("es-CL").format( Math.abs(auxTotalCalculated) )+' para completar la garantía.',
                  icon: "warning",
                  dangerMode: true,
                });
              }
        
            } else {
              auxActivePago = false;
              swal({
                title: "Antes de continuar",
                text: 'Para avanzar debes resolver primero la garantía de esta venta.',
                icon: "warning",
                dangerMode: true,
              });
            }

          }

          if( auxActivePago ){
            setStepActive( { arriendo: 'hidden', venta: 'hidden', garantia: 'hidden', pago: 'active', cancel: '', checkout: '', } );
          }
          
        }
      }


    }
  }  

  useEffect( () => {
    if( response ){

      if( response.data.code === ActiveCashRegisterResponseCode.active1001 || response.data.code === ActiveCashRegisterResponseCode.active1002 ){
        // No tiene caja registrada, debo redirigir a apertura de caja
        history.push( '/cash-register/new' );
      } else if( response.data.code === ActiveCashRegisterResponseCode.active2002 || response.data.code === ActiveCashRegisterResponseCode.active2003 ){
        //console.log( 'Todo en orden con la caja' );

        //console.log('jijiji', response.data );

        // Seteamos valor caja
        setCashRegisterValue( response.data.info['cash-register'].title );
        setStoreValue( response.data.info['cash-register'].store );

        if( history.location.pathname.includes('/cash-register/sale/') ){
          console.log( 'Vamos a cargar información sobre esta venta' );
          setLoadingCart( true );
          getProductVenta && getProductVenta();

          let auxUrlCode = history.location.pathname.split('/');

          let venta_response = get( baseApiURL+'/get/order/'+auxUrlCode[auxUrlCode.length-1]);
          venta_response.then((response_sale:any) => { 

            if( response_sale.data ){

              //let cartItemsData = JSON.parse( response_sale.data.data );
              setCartDataLoaded( response_sale.data );


              

            }
          }).catch( error_sale => {
            alert( 'Error 404: Nota de venta no encontrada' );
          });

        } else {
          // Reset de información
          resetCart();
          
          // Almaceno el valor id de la caja en atención
          // OLD
          //setCart( cart => ({...cart, cash_register: response.data.info['cash-register'].nid, tc: response.data.info['exchange-values'] }) )

          setCartOrder( cartOrder => ({...cartOrder, cash_register: response.data.info['cash-register'].nid }) );
          setCartPayment( cartPayment => ({...cartPayment, tc: response.data.info['exchange-values'] }) );

        }

        // Cargamos los productos de arriendo
        if( getProductArriendo ) {
          getProductArriendo();
        }

        
      } else {
        alert( 'Error: Código de estado de atención de caja no encontrado' );
      }

    }
    
  }, [response]);

  useEffect( () => {
    if( arriendoProducts && arriendoProducts.status === 'loaded' ){
      //console.log( 'ya cargó los datos de arriendo', cartDataLoaded );
      if( cartDataLoaded && cartDataLoaded.order_id ){
        console.log('ANTIGUO');

        let cartItemsData = JSON.parse( cartDataLoaded.data );

        // OLD
        setCart( cart => ({...cart,
          cart_items      : cartItemsData.cart_items,
        }) );
        
        setCartOrder( cartOrder => ({...cartOrder,
          order_id        : cartDataLoaded.order_id,
          cash_register   : response?.data.info['cash-register'].nid ?? '',
          name            : cartDataLoaded.pax_name,
          q_adultos       : parseInt( cartDataLoaded.field_pax_qadultos ),
          q_ninos         : parseInt( cartDataLoaded.field_pax_qninos ),
          agencia         : cartDataLoaded.agency,
          guia            : cartDataLoaded.touristGuide,
        }) );
        setCartPayment( cartPayment => ({...cartPayment, 
          garantia        : JSON.parse( cartDataLoaded.guarantee_detail ),
          payment         : cartItemsData.payment,
          tc              : response?.data.info['exchange-values'] ?? 0
        }) );

        setLoadingCart( false );

      }
    }
  }, [arriendoProducts] );

  useEffect( () => {
    if( cartOrder.cash_register ){
      // Cargamos la información de las agencias
      let venta_response = get( baseApiURL+'/get/agencies?cashregister='+cartOrder.cash_register);
      venta_response.then((response:any) => { 
        setAgenciesData( response );
        setAgencies( response.map( (item: any, index:number) => {
          return (
            <MenuItem key={index} value={item.uuid}>{item.field_nombre}</MenuItem>
          );
        } ) );
        
      }).catch( error => {
        console.log('revisar caso error', error.response);
      });
    }
  }, [cartOrder.cash_register] );

  useEffect( () => {
    if( cartOrder.agencia !== 0 ){


      setLoadingGuides( true );
      // Cargamos la información de las agencias
      let guides_response = get( baseApiURL+'/get/tourism-guides?cashregister='+cartOrder.cash_register+'&agency='+cartOrder.agencia);

      guides_response.then((response:any) => {
        setTourismGuides( response.map( (item: any, index:number) => {
          return (
            <MenuItem key={index} value={item.uuid}>{item.field_nombre}</MenuItem>
          );
        } ) );
        setLoadingGuides( false );
      }).catch( error => {
        console.log('revisar caso error', error.response);
        setLoadingGuides( false );
      });
      
      // Revisamos la garantia, buscamos el dato de la agencia activa
      const auxAgenciesLength = agenciesData.length;
      for( let i = 0; i < auxAgenciesLength; i++ ){
        if( agenciesData[i].uuid === cartOrder.agencia ){
          //console.log( agenciesData[i] , cartOrder.agencia );

          // Cambiamos info de la garantía
          if( agenciesData[i].field_usuario_confianza === 1){
            setActiveGarantia( false );
          } else {
            setActiveGarantia( true );
          }
          break;
        }
      }
      
    } else {
      setTourismGuides( [] );
      setActiveGarantia( true );
    }
  }, [cartOrder.agencia] );

  useEffect( () => {
    let garantia = cartPayment.garantia;

    if( activeGarantia ){
      garantia.state = 'normal';
    } else {
      garantia.state = 'confianza';
    }
    
    // OLD
    setCart( cart => ({...cart, garantia: garantia}) );

    setCartPayment( cartPayment => ({...cartPayment, 
      garantia        : garantia
    }) );

  }, [activeGarantia] );

  const sendsaleData = () => {
    if( !loadingSale ){
      let auxActiveSale = true;

      // Validamos la información del pago
      if( cartPayment.payment.pesos || cartPayment.payment.real || cartPayment.payment.dolar || cartPayment.payment.credito || cartPayment.payment.debito ){
        
        let totalPesos = 0;
        if( cartPayment.payment.pesos ){
          totalPesos = Number( cartPayment.payment.pesos );
        }
        
        let totalDolar = 0;
        if( cartPayment.tc.dolar && cartPayment.payment.dolar ){
          totalDolar = cartPayment.tc.dolar * cartPayment.payment.dolar;
        }

        let totalReal = 0;
        if( cartPayment.tc.real && cartPayment.payment.real ){
          totalReal = cartPayment.tc.real * cartPayment.payment.real;
        }

        let totalCredito = 0;
        if( cartPayment.payment.credito ){
          const auxCreditoLength = cartPayment.payment.credito.length;

          if( auxCreditoLength !== 0 ){
            for( let i = 0; i < auxCreditoLength; i++ ){
              if( cartPayment.payment.credito[i] ){
                  totalCredito += Number( cartPayment.payment.credito[i].amount );
              }
            }
          }
        }

        let totalDebito = 0;
        if( cartPayment.payment.debito ){
          const auxDebitoLength = cartPayment.payment.debito.length;

          if( auxDebitoLength !== 0 ){
            for( let i = 0; i < auxDebitoLength; i++ ){
              if( cartPayment.payment.debito[i] ){
                totalDebito += Number( cartPayment.payment.debito[i].amount );
              }
            }
          }
        }

        let totalPresale = 0;
        if( cartPayment.payment.pre_sale ){
          const auxPresaleLength = cartPayment.payment.pre_sale.length;

          if( auxPresaleLength !== 0 ){
            for( let i = 0; i < auxPresaleLength; i++ ){
              if( cartPayment.payment.pre_sale[i] ){
                totalPresale += Number( cartPayment.payment.pre_sale[i].amount );
              }
            }
          }
        }

        let auxTotalCalculated = cartPayment.payment.total.total - totalPesos - totalDolar - totalReal - totalCredito - totalDebito - totalPresale;


        // Revisamos si es que tiene crédito dubra
        if( cartPayment.payment.dubra.selected_reason === '' || cartPayment.payment.dubra.request_auth === '' ){
          if( auxTotalCalculated > 0 ){
            auxActiveSale = false;
            swal({
              title: "Antes de continuar",
              text: 'Faltan $'+new Intl.NumberFormat("es-CL").format( Math.abs(auxTotalCalculated) )+' para completar el pago.',
              icon: "warning",
              dangerMode: true,
            });
          }
        }
        

      } else {
        auxActiveSale = false;
        swal({
          text: 'Falta información sobr el pago.',
          icon: "warning",
          dangerMode: true,
        });
      }






      //console.log( 'DEBO revisar que todos los datos se manden bien' );
      //console.log( cart );
      //console.log( {...cartOrder,...cartPayment} );

      if( auxActiveSale ){
        setLoadingSale( true );
        
        // OLD
        let login_response = post( baseApiURL+'/post/cash-registers/new', {...cart,...cartOrder,...cartPayment});

        login_response.then((response:any) => { 

          setOrderId( Number(response.data.id) );
          setOrderDate( DateTime.fromMillis(response.data.date * 1000) );
          setOrderQR( response.data.qr );
          setOrderReturn( response.data.retorno );

          //console.log( 'ahora debo imprimir', response );
          //reactToPrintTrigger();
          if( newRef.current ){
            newRef.current.click();
          } else {
            console.log('noooou ref');
          }

          console.log( 'ahora debo redirigir' );


          //history.push( '/cash-register?check='+response.data );
          history.push( '/cash-register' );
          /*setLoadingSale( false );*/
          resetCart();
        })
        .catch( error => {
          setLoadingSale( false );

          swal({
            title: "No pudimos finalizar la venta",
            text: "Info: "+error.response.data,
            icon: "warning",
            dangerMode: true,
          });

        });
      }
    }
  }

  const componentRef = useRef<HTMLDivElement>(null);
  const newRef = useRef<HTMLButtonElement>(null);

  return (
    loading || loadingCart ? <LinearProgress color="primary" /> :
    <>
    <div className="content_all">
      <div className="cash-register-box">
        { error !== null ? 
            <SimpleBox>{error}</SimpleBox>
          :
            
              <Paper className="cash-register-sale">

                <Grid container className="cash-register-container">
                  <DndProvider backend={Backend}>
                  <Grid item xs={12} sm={6} md={8} className="cash-register-content">

                    <Grid container spacing={3} >
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth className="form-item">
                          <TextField label="Nombre" value={cartOrder.name} error={ cartOrder.name === '' ? nameError !== '' ? true : false : false } helperText={ nameError } onChange={(event:any) => {
                            setCartOrder( cartOrder => ({...cartOrder, name: event.target.value}) );
                          } } variant="outlined" />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputNumber label="Adultos" value={ cartOrder.q_adultos } onLess={minusAdult} onPlus={addAdult} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth className="form-item">
                          <InputLabel id="agency_name">
                            Agencia
                          </InputLabel>
                          <Select
                            labelId="agency_name"
                            value={cartOrder.agencia}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                              setCartOrder( cartOrder => ({...cartOrder, agencia: event.target.value, guia: 0}) )
                            }}
                          >
                            <MenuItem value={0}>
                              <em>Selecciona una agencia</em>
                            </MenuItem>
                            { agencies }
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputNumber label="Junior" value={ cartOrder.q_ninos } onLess={minusJunior} onPlus={addJunior} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth className="form-item">
                          <InputLabel id="tourist_guide_name">
                            Guía
                          </InputLabel>
                          <Select
                            labelId="tourist_guide_name"
                            value={cartOrder.guia}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                              setCartOrder( cartOrder => ({...cartOrder, guia: event.target.value}) )
                            }}
                          >
                            <MenuItem value={0}>
                              <em>{ loadingGuides ? 'Cargando información' : cartOrder.agencia !== 0 ? 'Selecciona un guía' : 'Debes seleccionar una agencia' }</em>
                            </MenuItem>
                            { !loadingGuides && tourismGuides }
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth className="form-item">
                          <TextField label="Q Pasajeros" value={cartOrder.q_adultos + cartOrder.q_ninos} error={ ( cartOrder.q_adultos + cartOrder.q_ninos ) === 0 ? paxError !== '' ? true : false : false } helperText={ ( cartOrder.q_adultos + cartOrder.q_ninos ) === 0 ? paxError !== '' ? paxError : '' : '' } variant="outlined" disabled />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <h1 className="form_title">Canasta de compras</h1>
                    <CartContainer empty={cart.cart_items.length !== 0 ? false : true}>
                      { cartProducts }
                    </CartContainer>
                    
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className="cash-register-sidebar" >
                    <div className="cash-register-sidebar-info" ref={sidebarContainer}>
                      
                      <CashStep title="Arriendo" icon={faMitten} state={stepActive.arriendo} loading={false} className="step_a" onClick={ () => { handleStep('arriendo') } } parent={sidebarContainer}>
                        { arriendoProducts && arriendoProducts.loading ?
                            <CircularProgress />
                          :
                            arriendoProducts && arriendoProducts.status !== 'error' ?
                              <Grid container>
                                { arriendoProducts.items }
                              </Grid>
                            :
                              <React.Fragment>
                                <p>Hubo un error al cargar la información, por favor vuelve a intentarlo</p>
                                <BaseButton className="btn-dubra" onClick={getProductArriendo}>Volver a intentar</BaseButton>
                              </React.Fragment>
                        }
                      </CashStep>

                      <CashStep title="Venta" icon={faShoppingBag} state={stepActive.venta} loading={false} className="step_b" onClick={ () => { handleStep('venta'); getProductVenta && getProductVenta() } } parent={sidebarContainer}>
                      { ventaProducts && ventaProducts.loading ?
                            <CircularProgress />
                          :
                            ventaProducts && ventaProducts.status !== 'error' ?
                              <Grid container>
                                { ventaProducts.items }
                              </Grid>
                            :
                              <React.Fragment>
                                <p>Hubo un error al cargar la información, por favor vuelve a intentarlo</p>
                                <BaseButton className="btn-dubra" onClick={getProductVenta}>Volver a intentar</BaseButton>
                              </React.Fragment>
                        }
                        
                      </CashStep>

                      <CashStep title={<React.Fragment>Garantía ${new Intl.NumberFormat("es-CL").format( cartPayment.garantia.total )} <small> ( Dólar: { cartPayment.tc.dolar ? Math.ceil(cartPayment.garantia.total / cartPayment.tc.dolar) : 0 } - Real: { cartPayment.tc.real ? Math.ceil(cartPayment.garantia.total / cartPayment.tc.real) : 0 } )</small></React.Fragment>} state={ activeGarantia ? stepActive.garantia : 'hidden' } loading={false} icon={faTasks} onClick={ () => { handleStep('garantia') } } className="step_c" parent={sidebarContainer}>
                        <CartGuarantee />
                      </CashStep>

                      <CashStep title={<React.Fragment><span>Pago <span style={{ textDecoration: (cartPayment.payment.discount.amount && cartPayment.payment.discount.amount !== 0) ? 'line-through' : '' }}> ${new Intl.NumberFormat("es-CL").format( cartPayment.payment.total.subtotal )}</span> {(cartPayment.payment.discount.amount && cartPayment.payment.discount.amount !== 0) ? `$${new Intl.NumberFormat("es-CL").format( cartPayment.payment.total.total )}` : ''}</span>  <small> ( Dólar: { cartPayment.tc.dolar ? Math.ceil(cartPayment.payment.total.total / cartPayment.tc.dolar) : 0 } - Real: { cartPayment.tc.real ? Math.ceil(cartPayment.payment.total.total / cartPayment.tc.real) : 0 } )</small></React.Fragment>} state={stepActive.pago} loading={false} icon={faHandHoldingUsd} className="step_d" onClick={ () => { handleStep('pago') } } parent={sidebarContainer}>
                        <CartPayment />
                      </CashStep>

                      <CashStep title="Finalizar venta" state={stepActive.cancel} loading={loadingSale} icon={faChevronRight} className="btn-dubra" onClick={sendsaleData} parent={sidebarContainer} />
                      <CashStep title="Cancelar" state={stepActive.checkout} loading={false} icon={faTimesCircle} className="cancel" onClick={ () => { handleStep('arriendo') } } parent={sidebarContainer} />

                    </div>
                  </Grid>
                  </DndProvider>
                </Grid>

              </Paper>
        }
      </div>
      <ModalBox title={modalTitle} active={modalActive} maxWidth="md" className="modal_product_box" >
        { modalContent }
      </ModalBox>
    </div>

      <ReactToPrint
        trigger={() => <Button variant="contained" color="primary" ref={newRef} style={{display: 'none'}}>Generar</Button>}
        content={() => componentRef.current}
      />
      <div style={{display: 'none',}}>
        <div ref={componentRef}>
          <FunctionalBillPrintable
            cart={ cart }
            order={ cartOrder }
            payment={ cartPayment }
            agencias={ agencies }
            guias={ tourismGuides }
            cashRegister={ cashRegisterValue }
            store={ storeValue }
            orderId={ orderId }
            date={ orderDate }
            qr={ orderQR }
            orderReturn={ orderReturn }
          />
        </div>
      </div>

    </>
  );
}

export default CashRegisterSale;