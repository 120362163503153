import React from 'react';
import Grid from '@material-ui/core/Grid';
import useErrorManager from '../common/ErrorManager';
import { errorSectionType, roleType } from '../../_helpers/enums/all';
import BlockContent from '../common/BlockContent';

import ExchangeBlock from './blocks/ExchangeBlock';
import FondoFijoBlock from './blocks/FondoFijoBlock';
import StockVentasBlock from './blocks/Sales/StockVentasBlock';
import StockRentalBlock from './blocks/StockRentalBlock';
import LastWeekSaleBlock from './blocks/LastWeekSaleBlock';
import CashRegisterBlock from './blocks/CashRegister/CashRegisterBlock';

import { useAuthDataContext } from '../../_auth/auth_provider';

const Dashboard = () => {
  const { userPermission } = useAuthDataContext();

  //document.title = 'Dashboard';
  const { alertBlock, showAlert } = useErrorManager({ id: 'dashboard', section: errorSectionType.Custom });
  
  return (
    <div className="content_all">
      <div className="content">
        { alertBlock }

        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>    
            { userPermission([roleType.Admin, roleType.SuperAdmin]) &&
                <LastWeekSaleBlock />
            }
            { userPermission([roleType.Admin, roleType.SuperAdmin, roleType.CashSupervisor, roleType.StoreManager, roleType.SAC, roleType.Skiroom]) &&
                <StockRentalBlock />   
            }
            { userPermission([roleType.Admin, roleType.SuperAdmin, roleType.CashSupervisor, roleType.StoreManager, roleType.SAC]) &&
                <StockVentasBlock />   
            }
          </Grid>
          <Grid item xs={12} md={4}>
            { userPermission([roleType.Admin, roleType.SuperAdmin, roleType.CashSupervisor, roleType.StoreManager]) &&
                <CashRegisterBlock />
            }
            { userPermission([roleType.Admin, roleType.SuperAdmin, roleType.CashSupervisor, roleType.StoreManager, roleType.SAC, roleType.Cashier]) &&
                <ExchangeBlock />
            }
            { userPermission([roleType.Admin, roleType.SuperAdmin, roleType.CashSupervisor, roleType.Cashier]) &&
                <FondoFijoBlock />
            }
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Dashboard;