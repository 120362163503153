import React, {useState, useEffect} from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { drawerWidth } from './MainContainer'

import { DateTime } from "luxon";

import { useAuthDataContext } from '../../_auth/auth_provider';
import { weatherIconType } from '../../_helpers/enums/all';
import { weatherProps } from '../../_helpers/props/all';
import { get } from '../../_services/api';
import { baseApiURL } from '../../_services/fetch';

import Bolt from '../../assets/weather/Bolt';
import Sun from '../../assets/weather/Sun';
import CloudRain from '../../assets/weather/CloudRain';
import CloudSleet from '../../assets/weather/CloudSleet';
import CloudSnow from '../../assets/weather/CloudSnow';
import CloudSun from '../../assets/weather/CloudSun';
import Cloud from '../../assets/weather/Cloud';
import Fog from '../../assets/weather/Fog';
import { CircularProgress } from '@material-ui/core';

import Popover from '@material-ui/core/Popover';

import HeaderTabs from './tabs/Tabs';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    toolbar: theme.mixins.toolbar,
  }),
);

const Header = () => {
  const { user } = useAuthDataContext();
  
  const classes = useStyles();
  const [currentTime, setCurrentTime] = useState( DateTime.local().toFormat("HH':'mm") );
  const currentDate = DateTime.local().reconfigure({ locale: 'es-CL' }).toFormat("cccc dd 'de' LLLL', 'yyyy");

  const [ activePending, setActivePending ]     = useState<boolean>( false );
  const [ weather, setWeather ] = useState<weatherProps[]>([]);

  const [ loadingLogo, setLoadingLogo ] = useState<boolean>(true);
  const [ logoPath, setLogoPath ] = useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event:any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  
  useEffect(() => {
    // Cargamos el logo
    let creditOptions_response = get( baseApiURL+'/get/all/header/logo' );

    creditOptions_response.then((response:any) => { 
      if( response.data ){
        if( response.data.logopath ){
          setLoadingLogo( false );
          setLogoPath( baseApiURL+response.data.logopath );
        }
      }
      requestWeather();
    })
    .catch( error => {
      setLoadingLogo( false );
      requestWeather();
    });
  }, []);
  

  useEffect(() => {
    setInterval(() => {
      setCurrentTime( DateTime.local().toFormat("HH':'mm") )
      //console.log('obtengo la hora');
    }, 25000);
  }, []);

  
  const requestWeather = () => {
    setActivePending( false );
        
    let creditOptions_response = get( baseApiURL+'/get/all/weather/all' );

    creditOptions_response.then((response:any) => { 
      if( response.data ){
        setWeather( response.data );
        setActivePending( true );
      } else {
        console.log('** error data not found');
      }
    })
    .catch( error => {
      console.log('error general');
    });
  }

  useEffect( () => {
    if( activePending ){
      const i_id = setInterval(() => {
        requestWeather();
      },1800000);
      return () => {
        clearInterval(i_id);
      }
    }
  },[activePending]);

  return (
    <AppBar id="header" position="fixed" className={classes.appBar+' header'}>
      <Toolbar>
        <div className={"header_logo header_content"}>
          { document.title } &nbsp;
          { loadingLogo ? 
                <CircularProgress size={30} />
              :
                (logoPath !== '' &&
                  <React.Fragment>
                    | <img src={logoPath} width={100} alt="Dubra logo" />
                  </React.Fragment>
                )
            }
        </div>
        <div className="header_date header_content">
          {currentDate}
        </div>
        <div className="header_info header_content weather_icon_container">
          
          <div className="weather_info_box">
            { weather.length !== 0 &&
                  <React.Fragment>
                    <div onClick={handleClick} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                      <img src={weather[0].icon} width={25} /> &nbsp; {weather[0].data} |&nbsp;
                    </div>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <div style={{padding: '10px 20px'}}>
                        <List component="nav" aria-label="secondary mailbox folders">
                          { weather.map((value:any, index:number) => {
                            if( index !== 0 ){
                              return (
                                <ListItem button key={index}>
                                  <img src={value.icon} width={25} /> &nbsp; {value.data}
                                </ListItem>
                              )
                            } else {
                              return null
                            }
                          })}
                        </List>
                      </div>
                    </Popover>
                  </React.Fragment>
            } { currentTime }
          </div>
          { user.username !== '' &&
              <div>
                <span>bienvenid@ <strong>{user.username}</strong></span>
              </div>
          }
        </div>
      </Toolbar>
      
      <HeaderTabs />
    </AppBar>
  );
}

export default Header;