import React, { useEffect, useState } from 'react';
import { useCartDataContext } from '../cart_provider';
import TextField from '@material-ui/core/TextField';
import { StepBlock, MoneyTypes } from '../../../_helpers/enums/cash_register';
import { NumberFormatPesos, NumberFormatDolar, NumberFormatReal } from '../../common/InputNumberFormat';
import BaseButton from '../../common/BaseButton';
import { CreditItem } from './CardItem';
import FormControl from '@material-ui/core/FormControl';

const CartGuarantee = () => {
  const { cartPayment, setCartPayment,
          setMoneyValues,
          addCard } = useCartDataContext();
  const [ totalGuarantee, setTotalGuarantee ] = useState<number>(0);
  const [ creditPayment, setCreditPayment ] = useState();

  useEffect( () => {

    if( cartPayment.garantia.credito.length !== 0 ){
      let creditPaymentAux = cartPayment.garantia.credito.map((item, index:number) => {
        return (
          <CreditItem key={index} 
            step={ StepBlock.Garantia }
            index={index} />
        )
      });

      setCreditPayment( creditPaymentAux );
    } else {
      setCreditPayment( <div><BaseButton className="btn-dubra" onClick={ () => { addCard && addCard({ type: MoneyTypes.Credit ,step: StepBlock.Garantia }); } }>Agregar pago con tarjeta</BaseButton></div> );
    }

    if( cartPayment.garantia.pesos || cartPayment.garantia.real || cartPayment.garantia.dolar || cartPayment.garantia.credito ){
      
      let totalPesos = 0;
      if( cartPayment.garantia.pesos ){
        totalPesos = Number( cartPayment.garantia.pesos );
      }
      
      let totalDolar = 0;
      if( cartPayment.tc.dolar && cartPayment.garantia.dolar ){
        totalDolar = cartPayment.tc.dolar * cartPayment.garantia.dolar;
      }

      let totalReal = 0;
      if( cartPayment.tc.real && cartPayment.garantia.real ){
        totalReal = cartPayment.tc.real * cartPayment.garantia.real;
      }

      let totalCredito = 0;
      if( cartPayment.garantia.credito ){
        const auxCreditoLength = cartPayment.garantia.credito.length;

        if( auxCreditoLength !== 0 ){
          for( let i = 0; i < auxCreditoLength; i++ ){
            if( cartPayment.garantia.credito[i] ){
                totalCredito += Number( cartPayment.garantia.credito[i].amount );
            }
          }
        }
      }

      let auxTotalCalculated = cartPayment.garantia.total - totalPesos - totalDolar - totalReal - totalCredito;
      setTotalGuarantee( auxTotalCalculated );

    } else {
      setTotalGuarantee( cartPayment.garantia.total );
    }
    
  }, [cartPayment] );
  

  return (
    <React.Fragment>
      <h3>Detalle en monedas</h3>
      <p>Ingresar el monto pagado con moneda</p>

      <FormControl variant="outlined" fullWidth className="form-item form-item-payment">
        <TextField label="Monto en pesos" value={ cartPayment.garantia.pesos } onChange={ (aux_val) => {
          setMoneyValues && setMoneyValues({type: MoneyTypes.Pesos, step: StepBlock.Garantia,  value: aux_val.target.value})
          }} InputProps={{ inputComponent: NumberFormatPesos, }} variant="outlined" />
      </FormControl>
      <FormControl variant="outlined" fullWidth className="form-item form-item-payment">
        <TextField label="Monto en reales" value={ cartPayment.garantia.real } onChange={ (aux_val) => {
            setMoneyValues && setMoneyValues({type: MoneyTypes.Real, step: StepBlock.Garantia,  value: aux_val.target.value})
          }} InputProps={{ inputComponent: NumberFormatReal, }} variant="outlined" />
      </FormControl>
      <FormControl variant="outlined" fullWidth className="form-item form-item-payment">
        <TextField label="Monto en dólares" value={ cartPayment.garantia.dolar } onChange={ (aux_val) => {
          setMoneyValues && setMoneyValues({type: MoneyTypes.Dolar, step: StepBlock.Garantia,  value: aux_val.target.value})
          }} InputProps={{ inputComponent: NumberFormatDolar, }} variant="outlined" />
      </FormControl>


      <hr />
      <h3>Solo crédito</h3>
      <p>Ingresar el monto asociado a tarjetas de créditos</p>
      { creditPayment }
      <hr />
      
      { totalGuarantee > 0 ? 'Pendiente: $'+new Intl.NumberFormat("es-CL").format( totalGuarantee ) : 'Total pagado: $'+ new Intl.NumberFormat("es-CL").format(totalGuarantee*-1 + cartPayment.garantia.total) }

    </React.Fragment>
  )
}

export default CartGuarantee;