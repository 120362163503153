import React, { createContext, useState, useContext } from "react";
import { ErrorProps, alertToBlockProps, ErrorStructureProps, removeAlertProps } from '../../_helpers/props/errors';
import { errorSectionType } from "../../_helpers/enums/all";

const initialErrorCenter:ErrorProps = {
  error: {
    all: [],
    general: [],
    custom: [],
  },
  addAlertToBlock: () => {},
  destroyAlert: () => {},
  destroyAll: () => {},
};

export const ErrorContext = createContext( initialErrorCenter );
export const useErrorDataContext = () => useContext(ErrorContext);

const ErrorProvider = (props:any) => {
  const [ error, setError ] = useState<ErrorStructureProps>( initialErrorCenter.error );
  //const [ alertBlock, setAlertBlock ] = useState<React.ReactNode>();
  //const [ alertBlock, setAlertBlock ] = useState( initialErrorCenter.alertBlock );

  const addAlertToBlock = ( props:alertToBlockProps ) => {
    let auxNewArea = true;
    //let auxNewAreaArray:sectionErrorProps = [];
    let auxNewAreaArray:any = [];
    
    if( props.section === errorSectionType.All ){
      
      let auxAllLength = error.all.length;

      for( let i=0; i<auxAllLength; i++ ){
        auxNewAreaArray.push( error.all[i] );
        if( error.all[i].area === props.area ){
          auxNewArea = false;
          auxNewAreaArray[i].data.push( props.alert );
        }
      }

    } else if( props.section === errorSectionType.Custom ){
      
      let auxCustomLength = error.custom.length;

      for( let i=0; i<auxCustomLength; i++ ){
        auxNewAreaArray.push( error.custom[i] );
        if( error.custom[i].area === props.area ){
          auxNewArea = false;
          auxNewAreaArray[i].data.push( props.alert );
        }
      }    
      
    } else if( props.section === errorSectionType.General ){
      
      let auxGeneralLength = error.general.length;

      for( let i=0; i<auxGeneralLength; i++ ){
        auxNewAreaArray.push( error.general[i] );
        if( error.general[i].area === props.area ){
          auxNewArea = false;
          auxNewAreaArray[i].data.push( props.alert );
        }
      }

    }

    if( auxNewArea ){
      auxNewAreaArray.push({
        area: props.area,
        data: [ props.alert ]
      });
    }


    // Ahora debo reccorrer donde se inserta
    let auxError = error;

    // Guardamos en el bloque que corresponde
    if( props.section === errorSectionType.All ){
      auxError.all = auxNewAreaArray;
    } else if( props.section === errorSectionType.Custom ){
      auxError.custom = auxNewAreaArray;
    } else if( props.section === errorSectionType.General ){
      auxError.general = auxNewAreaArray;
    }

    setError( error => ({ ...auxError }) );

  }

  const destroyAll = () => {
    setError( error => ({ ...initialErrorCenter.error }) );
  }

  const destroyAlert = ( props:removeAlertProps ) => {
    let auxNewAreaArray:any = [];
    
    if( props.section === errorSectionType.All ){
      
      let auxAreaLength = error.all.length;
      for( let i=0; i<auxAreaLength; i++ ){
        auxNewAreaArray.push( error.all[i] );
        if( error.all[i].area === props.area ){
          if( auxNewAreaArray[i].data ){
            let AreaArrayLength = auxNewAreaArray[i].data.length;
            for( let i_area=0; i_area<AreaArrayLength; i_area++ ){
              if( error.all[i].data[i_area].id === props.alert_id ){
                if( i_area === 0 ){
                  auxNewAreaArray[i].data.shift();
                } else {
                  auxNewAreaArray[i].data.splice( i_area, 1 );
                }
                break;
              }
            }
          }
          
        }
      }

    } else if( props.section === errorSectionType.Custom ){
      
      let auxAreaLength = error.custom.length;
      for( let i=0; i<auxAreaLength; i++ ){
        auxNewAreaArray.push( error.custom[i] );
        if( error.custom[i].area === props.area ){
          if( auxNewAreaArray[i].data ){
            let AreaArrayLength = auxNewAreaArray[i].data.length;
            for( let i_area=0; i_area<AreaArrayLength; i_area++ ){
              if( error.custom[i].data[i_area].id === props.alert_id ){
                if( i_area === 0 ){
                  auxNewAreaArray[i].data.shift();
                } else {
                  auxNewAreaArray[i].data.splice( i_area, 1 );
                }
                break;
              }
            }
          }
          
        }
      }
      
    } else if( props.section === errorSectionType.General ){
      
      let auxAreaLength = error.general.length;
      for( let i=0; i<auxAreaLength; i++ ){
        auxNewAreaArray.push( error.general[i] );
        if( error.general[i].area === props.area ){
          if( auxNewAreaArray[i].data ){
            let AreaArrayLength = auxNewAreaArray[i].data.length;
            for( let i_area=0; i_area<AreaArrayLength; i_area++ ){
              if( error.general[i].data[i_area].id === props.alert_id ){
                if( i_area === 0 ){
                  auxNewAreaArray[i].data.shift();
                } else {
                  auxNewAreaArray[i].data.splice( i_area, 1 );
                }
                break;
              }
            }
          }
          
        }
      }

    }

    // Ahora debo reccorrer donde se inserta
    let auxError = error;

    // Guardamos en el bloque que corresponde
    if( props.section === errorSectionType.All ){
      auxError.all = auxNewAreaArray;
    } else if( props.section === errorSectionType.Custom ){
      auxError.custom = auxNewAreaArray;
    } else if( props.section === errorSectionType.General ){
      auxError.general = auxNewAreaArray;
    }

    setError( error => ({ ...auxError }) );
  }
  
  //return <ErrorContext.Provider value={{ error, alertBlock, setAlertBlock }} {...props} />;
  return <ErrorContext.Provider value={{ error, addAlertToBlock, destroyAlert, destroyAll }} {...props} />;
};

export default ErrorProvider;