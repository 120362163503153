import React, { useState, useEffect } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import BaseButton from '../../common/BaseButton';
import { LinearProgress } from '@material-ui/core';
import { baseApiURL } from '../../../_services/fetch';
import { get } from '../../../_services/api';
import { errorSectionType, errorType } from '../../../_helpers/enums/all';

import { cashRegistersListProps, sendDataProps } from '../../../_helpers/props/all';

interface CashRegisterModalProps {
  savingData       ?: boolean,
  cashRegisterUUID  : string,
  extraData         : cashRegistersListProps[],
  cancel()          : void,
  send(props:sendDataProps) : void,
  error             : any,
}

interface autocompleteProps {
  name: string,
  value: string
}

const CashRegisterModal = (props:CashRegisterModalProps) => {
  const [ loading, setLoading ]   = useState<boolean>( true );

  const [ cashierOptions, setCashierOptions ] = useState<autocompleteProps[]>([]);
  const [ cashier, setCashier ]   = useState<autocompleteProps|null>( null );
  const [ sacOptions, setSacOptions ] = useState<autocompleteProps[]>([]);
  const [ sac, setSac ]   = useState<autocompleteProps[]>([]);

  useEffect(() => {
    if( cashierOptions.length !== 0 ){
      // Buscamos cuál es la caja que está abierta
      props.extraData.filter(item => item.cash_register === props.cashRegisterUUID).map((item:cashRegistersListProps) => {        
        cashierOptions.filter(item_b => item_b.name === item.cashier_name ).map((item_b:autocompleteProps) => {
          setCashier( item_b );
        });
      });
    }
  }, [cashierOptions]);

  useEffect(() => {
    if( sacOptions.length !== 0 ){
      // Buscamos cuál es la caja que está abierta
      let auxSacItems:autocompleteProps[] = [];
      props.extraData.filter(item => item.cash_register === props.cashRegisterUUID).map((item:cashRegistersListProps) => {        
        sacOptions.filter(item_b => item.sac.includes( item_b.name ) ).map((item_b:any) => {
          auxSacItems.push( item_b );
        });
      });
      setSac( auxSacItems ); 
    }
  }, [sacOptions]);

  const getData = () => {
    setLoading( true );
    const responseData = get(baseApiURL+'/get/cash-register/'+props.cashRegisterUUID);

    responseData.then((response:any) => {
      if( response.data ){
        setLoading( false );

        // Revisamos si vienen las opciones
        if( response.data.options ){
          if( response.data.options.cashier ){
            setCashierOptions( response.data.options.cashier );
          }

          if( response.data.options.sac ){
            setSacOptions( response.data.options.sac );
          }
        }
      }
    }).catch(error => {
      setLoading( false );
      props.error.showAlert({
        message: 'Hubo un error al cargar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });

  }
  
  useEffect(() => {
    if( props.cashRegisterUUID !== '' ){
      getData();
    }
  }, [props.cashRegisterUUID]);

  const getCashierDisabled = ( option:any ) => {
    let auxActive = false;
    
    console.log( option );
    console.log( props.extraData );
    props.extraData.filter(item => item.cashier_name === option.name).map((item:cashRegistersListProps) => {
      auxActive = true;
      return null;
    });

    return auxActive;
  }

  const getSacDisabled = ( option:any ) => {
    let auxValue = false;
    props.extraData.filter(item_b => item_b.sac.includes( option.name ) ).map((item_b:any) => {
      auxValue = true;
    });
    
    if( sac.length === 4 ){
      auxValue = true;
    }

    return auxValue;
  }

  if( props.cashRegisterUUID ){
    return (
      <Dialog
          open={props.cashRegisterUUID === '' ? false : true}
          onClose={props.cancel}
          //maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            Modificar atención
          </DialogTitle>
          { loading ?
              <LinearProgress />
            :
              <React.Fragment>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Selecciona la información para modificar la atención actual
                    <br />
                  </DialogContentText>
                  { props.error.alertBlock }

                  
                  <Autocomplete
                      options={cashierOptions}
                      getOptionLabel={(option) => option.name}
                      getOptionDisabled={(option) => getCashierDisabled(option) }
                      renderInput={(params) => <TextField {...params} label="Cajero" variant="outlined" required={true} />}
                      onChange={(event: any, newValue: autocompleteProps | null) => {
                        /*
                        console.log('oli', newValue);
                        if( newValue ){
                          
                        } else {
                          
                        }
                        */
                        setCashier( newValue );
                      }}
                      value={cashier}
                    />
                  <br /><br />
                  <Autocomplete
                      multiple
                      options={sacOptions}
                      getOptionLabel={(option) => option.name}
                      getOptionDisabled={(option) => getSacDisabled(option) }
                      renderInput={(params) => <TextField {...params} label="SAC" variant="outlined" helperText={ sac.length === 4 ? 'Ya cumpliste el máximo de SAC, debes quitar uno para agregar uno nuevo.' : 'Puedes agregar hasta 4 SAC por caja.' } />}
                      onChange={(event: any, newValues: any ) => {
                        let auxItemsSelected:autocompleteProps[] = [];

                        if( newValues ){
                          newValues.map((item:autocompleteProps, index:number) => {
                            auxItemsSelected.push( item );
                          });
                        }

                        setSac( auxItemsSelected );
                      }}
                      value={sac}
                    />
                  <br /><br />
                  
                </DialogContent>
                <DialogActions>
                  <div className="btn-container-dashboard">
                    <BaseButton className="btn btn-outlined btn-dubra-cancel" onClick={props.cancel} disabled={props.savingData}>
                      Cancelar
                    </BaseButton>
                    <BaseButton className="btn btn-simple btn-dubra" onClick={() => { props.send({
                      sac: sac,
                      cashier: cashier,
                    }); }} loading={props.savingData}>
                      Guardar
                    </BaseButton>
                  </div>
                </DialogActions>
              </React.Fragment>
          }
        </Dialog>
    )
  } else {
    return null
  }
}

export default CashRegisterModal;