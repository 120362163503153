import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import { modalBoxProps } from '../../_helpers/props/modal';

const ModalBox = ( props:modalBoxProps ) => {
  return (
    <Dialog open={props.active} className={'modal '+props.className} fullWidth={ props.maxWidth ? true : false } maxWidth={ props.maxWidth ? props.maxWidth : undefined } 	>
      <DialogTitle>{ props.title }</DialogTitle>
      <div className="modal_block">
        { props.children }
      </div>
    </Dialog>
  )
}

export default ModalBox;