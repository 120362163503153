import { Grid, Typography } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import ProductBoxSize from './ProductBoxSize';
import { ItemInCartProps, productDetailBoxProps, productTotalCount, sizeElementProps } from '../../../../_helpers/props/cash_register_props';
import { productSize } from '../../../../_helpers/enums/cash_register';

import ModalActions, { ModalAction } from '../../../common/modal/ModalActions';
import { baseApiURL } from '../../../../_services/fetch';
import { useCartDataContext } from '../../cart_provider';

const ProductBox = ( props:productDetailBoxProps ) => {
  const { addCartProduct, setModalActive } = useCartDataContext();

  // Calculamos los totales
  const [ totalAdult, setTotalAdult ] = useState<number>( 0 );
  const [ totalJunior, setTotalJunior ] = useState<number>( 0 );
  const [ total, setTotal ] = useState<number>( 0 );

  useEffect(() => {
    var auxTotal:number = 0;

    // Calculamos el monto total
    if( props.detail.detail.Adulto ){
      let auxPrice = parseFloat(props.detail.detail.Adulto.price)*1000;
      auxTotal += auxPrice*totalAdult;
    }

    if( props.detail.detail.Junior ){
      let auxPrice = parseFloat(props.detail.detail.Junior.price)*1000;
      auxTotal += auxPrice*totalJunior;
    }

    setTotal( auxTotal );

  }, [totalAdult, totalJunior]);

  const handleQ = ( propsQ:productTotalCount ) => {
    if( propsQ.size == productSize.Adult ){
      setTotalAdult( propsQ.q );
    } else if( propsQ.size == productSize.Junior ) {
      setTotalJunior( propsQ.q );
    } else {
      alert('ERROR');
    }
  }

  const handleAddProduct = () => {

    let auxSize:Array<sizeElementProps> = [];
    let sizeLength = Object.keys( props.detail.detail ).length;
    for( let i = 0; i < sizeLength; i++ ){
      auxSize.push({
        variation_id: props.detail.detail[ Object.keys( props.detail.detail )[i] ].variation_id,
        name: Object.keys( props.detail.detail )[i],
        price: 0,
      });
    }

    var itemToCart:ItemInCartProps = ({
      product_id: parseInt( props.detail.product_id.toString() ),
      product_name: props.detail.name,
      type: props.detail.type,
      image: props.detail.image,
      sizes: auxSize,
      changes: {adults: [], junior: []},
      adults: totalAdult,
      junior: totalJunior,
    });

    addCartProduct( itemToCart ); 




  
    setModalActive( false );
  }

  return (
    <React.Fragment>
      <div className="modal_content modal_productbox">
        <Grid container justify="space-between">
          <Grid item xs={12} sm={3} className="modal_productbox_left">
            <div className="modal_productbox_image">
              <img src={ baseApiURL+props.detail.image } className="modal_productbox_image" />
            </div>
            <div className="modal_productbox_name">
              { props.detail.name }
            </div>
          </Grid>
          <Grid item xs={12} sm={8} className="modal_productbox_right">
            { props.detail.detail.Adulto &&
                ( !props.detail.detail.Junior ?
                    <ProductBoxSize total={totalAdult} type={props.detail.type} onChange={handleQ} />
                  :
                    <ProductBoxSize total={totalAdult} size={productSize.Adult} type={props.detail.type} onChange={handleQ} />
                )
            }
            { props.detail.detail.Junior &&
                <ProductBoxSize total={totalJunior} size={productSize.Junior} type={props.detail.type} onChange={handleQ} />
            }
          </Grid>
        </Grid>
      </div>
      <div className="modal_productbox_total">
        <Typography variant="h6" display="inline">
          Total: ${new Intl.NumberFormat("es-CL").format( total )}
        </Typography>
        &nbsp; &nbsp;
        <Typography variant="body2" display="inline">
          x día
        </Typography>
      </div>
      <ModalActions>
        <ModalAction onClick={() => { setModalActive( false ); }} value="Cancelar" align="left" type="cancel" />
        <ModalAction onClick={handleAddProduct} value="Agregar" />
      </ModalActions>
    </React.Fragment>
  )
}

export default ProductBox;