import React, { useEffect, useState } from 'react';
/*import { Grid } from '@material-ui/core';

import BaseButton from '../common/BaseButton';
import { addToQuery, createQuery, removeFromQuery, urlParamsProps } from '../../_services/request';
import { useHistory } from 'react-router-dom';

import useDateRange from  '../common/filters/DateRange';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
*/
import { filterProps, optionSelectProps } from '../../../_helpers/props/form_props';
import {useDateRangeVal} from  '../../common/filters/DateRange';
import useSelect from  '../../common/filters/Select';
import useInput from  '../../common/filters/Input';
import { DateTime } from 'luxon';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

interface tableFilterProps {
  itemData: filterProps,
  options?: optionSelectProps[],
  //filters: [],
  //setFilters: () => {},
  getParamValue: (id:string) => void,
  setParamValue: (id: string, value: any) => void,
}

const TableFilters = (props:tableFilterProps) => {
  const [ value, setValue ] = useState<any>(null);
  const [ valueInput, setValueInput ] = useState<string>('');
  const [ valueB, setValueB ] = useState<any>(null);

  const { DateRangeFilter } = useDateRangeVal();
  const { SelectFilter } = useSelect();
  const { InputFilter } = useInput();

  const handleValueDateRange = ( type: string, date: DateTime|null ) => {
    //console.log('Type:', type, date, props);

    if( type === 'from' ){
      props.setParamValue( props.itemData.id+type, date );
      //setValue( props.getParamValue( props.itemData.id+'from') );
      setValue( date );
    } else if( type === 'end' ){
      props.setParamValue( props.itemData.id+type, date );
      //setValueB( props.getParamValue( props.itemData.id+'end') );
      setValueB( date );
    } else {
      console.log('error de type en DateRange');
    }
  }

  const handleValueSelect = ( value: string) => {
    props.setParamValue( props.itemData.id, value );
    setValue( value );
  }

  const handleInputChange = ( event: any ) => {
    /*props.setParamValue( props.itemData.id, value );
    setValue( value );
    */
    //console.log( value );
    setValueInput( event.target.value );
  }

  useEffect(() => {
    if( props.itemData.component === 'date_full' ){
      setValue( props.getParamValue('datefrom') );
      setValueB( props.getParamValue('dateend') );
    } else if( props.itemData.component === 'input' ){
      if( props.getParamValue( props.itemData.id ) === null ){
        setValueInput('');
      }
      //console.log('aa', props.getParamValue( props.itemData.id ));
    } else {
      setValue( props.getParamValue( props.itemData.id ) );
    }

  }, [props]);

  useEffect(() => {
    props.setParamValue( props.itemData.id, valueInput );
  }, [valueInput]);

  /*
  useEffect(() => {
    console.log( 'reeender::table-filter' );
    //setCustomValue( props.value ? props.value : "" );
  }, []);
  */

  if( props.itemData.component === 'date_full' ){
    return (
      <DateRangeFilter dateLimit={true} handleData={handleValueDateRange} from={value} end={valueB} />
    )     
  } else if( props.itemData.component === 'select' ){
    if( props.itemData.options ){
      return (
        <SelectFilter value={value} label={ props.itemData.label ? props.itemData.label : null } options={ props.itemData.options && props.itemData.options } handleData={handleValueSelect} />
      )
    } else {
      return null
    }

  } else if( props.itemData.component === 'input' ){
    return (
      /*<InputFilter value={ value ? value : "" } label={ props.itemData.label ? props.itemData.label : null } handleData={handleValueSelect} />*/
      /*<InputFilter value={ valueInput } label= handleData={handleInputChange} />*/
      <Grid item>
        <TextField label={ props.itemData.label ? props.itemData.label : null } value={ valueInput } onChange={handleInputChange} variant="outlined" />
      </Grid>
    )     
  } else {
    return <React.Fragment>Algo que desconozco aun: {props.itemData.component}</React.Fragment>
  }
}

export default TableFilters