import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import BaseButton from '../../common/BaseButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { errorSectionType, guaranteeStatusType, requestStatusType, errorType, roleType } from '../../../_helpers/enums/all';
import useErrorManager from '../../common/ErrorManager';
import { baseApiURL } from '../../../_services/fetch';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { get, post } from '../../../_services/api';
import Typography from '@material-ui/core/Typography';
import loadingGif from '../../../assets/all/loading.gif';
import { Divider } from '@material-ui/core';

import { useAuthDataContext } from '../../../_auth/auth_provider'

interface guaranteeBoxProps {
  url           : string,
  active        : boolean,
  closeAction (): void,
}

const GuaranteeBox = ( props:guaranteeBoxProps ) => {
  const { userPermission } = useAuthDataContext();
  const { alertBlock, showAlert, resetAlerts } = useErrorManager({ id: 'guarantee_modal', section: errorSectionType.Custom });

  const [ activeModal, setActiveModal ]         = useState<boolean>(true);

  const [ orderTitle, setOrderTitle ]           = useState<string>('');
  const [ orderUUID, setOrderUUID ]             = useState<string>('');

  const [ itemsGuarantee, setItemsGuarantee ]   = useState<React.ReactNode[]>( [] );

  const [ response, setResponse ]               = useState<any>();
  const [ error, setError ]                     = useState<any>();
  const [ loading, setLoading ]                 = useState<boolean>( true );
  const [ loadingSend, setLoadingSend ]         = useState<boolean>( false );

  const [ guaranteeStatus, setGuaranteeStatus ] = useState<number>(0);
  const [ requestStatus, setRequestStatus ]     = useState<number>(0);

  const [ activePending, setActivePending ]     = useState<boolean>( false );


  //const { response, loading, error } = useFetch({ method: 'GET', path: props.url});
  const handleLoad = () => {
    console.log('handleLoad');
    setLoading( true );
    let guarantee_response = get( baseApiURL+props.url);

    guarantee_response.then((response:any) => { 
      setLoading( false );
      setResponse( response );
    })
    .catch( error => {
      setLoading( false );
      setError( error );
    });
  }

  /*
  interface guaranteeResponseProps {
    guarantee : any[],
    order_id  : number,
    uuid      : string,
    request   : string,
    status_id : number,
  }
  */

  useEffect(() => {
    if( guaranteeStatus ){
      
      if( response.status_id === guaranteeStatusType.Resolved  ){
        setActivePending( false );
        showAlert({
          message     : 'La devolución de garantía ya fue aprobada.',
          section     : errorSectionType.Custom,
          type_values : errorType.Success,
        });
      } else if( response.status_id === guaranteeStatusType.ApproveWaiting  ){
        setActivePending( true );
      } else {
        // Es: Pending
        setActivePending( false );
      }


    }
  }, [guaranteeStatus, requestStatus]);

  useEffect(() => {
    if( requestStatus ){
      
      // Debo verificar si fue rechazado anteriormente
      if( requestStatus === requestStatusType.Rejected ){
        setActivePending( false );

        // Mostramos aviso de haber sido rechazado
        showAlert({
          message: 'Lo sentimos, la solicitud fue rechazada, puedes volver a solicitar la devolución. - 0003x',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      } else if( requestStatus === requestStatusType.Approved ){
        // Se definió mensaje a partir de la garantía
        setActivePending( false );

      } else if( requestStatus === requestStatusType.Pending ){
        // Está a la espera, no hay mensaje
        setActivePending( true );
      }
    }
  }, [requestStatus]);



  





  useEffect( () => {
    if( response ){
      // Colocamos nombre del box
      setOrderTitle( 'Nota '+response.order_id );
      // Seteamos la orden en cuestión
      setOrderUUID( response.uuid );

      if( response.guarantee ){

        let auxItemsGuarantee:React.ReactNode[] = [];
        // Guardamos el estado de la garantia
        setGuaranteeStatus( response.status_id );
        // Guardamos el estado de la solicitud
        setRequestStatus( response.request );

        // Revisamos el estado
        let itemsLength:number = response.guarantee.length;


        for( let i=0; i<itemsLength; i++ ){
          console.log( response.guarantee[i] );
          let auxAmount   = '$'+new Intl.NumberFormat("es-CL").format( response.guarantee[i].amount );
          if( response.guarantee[i].id === 'real' ){
            auxAmount     = 'R$'+new Intl.NumberFormat("es-CL").format( response.guarantee[i].amount );
          } else if( response.guarantee[i].id === 'dolar' ){
            auxAmount     = 'USD '+new Intl.NumberFormat("es-CL").format( response.guarantee[i].amount );
          }

          auxItemsGuarantee.push(
            <ListItem key={i+1} className="eo">
              <ListItemAvatar>
                <Avatar>
                  { response.guarantee[i].type === 'Efectivo' ?
                      <LocalAtmIcon />
                    :  
                      <CreditCardIcon />
                  }
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={response.guarantee[i].name}
                secondary={response.guarantee[i].type}
              />
              <ListItemSecondaryAction>
                {auxAmount}
              </ListItemSecondaryAction>
            </ListItem>
          );

          setItemsGuarantee( auxItemsGuarantee );
        }

      }

    }
  }, [response] );

  useEffect( () => {
    if( error ){
      setOrderTitle( '' );
      alert( 'fail' );
    }
  }, [error] );

  useEffect( () => {
    handleLoad();
  }, [] );

  const initRequest = () => {
    console.log('paseeeeee por initRequest');

    let guaranteeApproval_response = get( baseApiURL+'/get/guarantee/'+orderUUID);
    guaranteeApproval_response.then((response:any) => { 

      // Guardamos el estado de la garantia
      setGuaranteeStatus( response.status_id );
      // Guardamos el estado de la solicitud
      setRequestStatus( response.request );
      
    })
    .catch( error => {
      showAlert({
        message: 'Hubo un problema al revisar la aprobación. - 0001x - ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });
  }

  useEffect( () => {
    if( activePending ){
      const i_id = setInterval(() => {
        if( activeModal ){
          initRequest();
        }
      },5000);
      return () => {
        clearInterval(i_id);
      }
    }
  },[activePending ]);

  const handleCancel = () => {
    setActiveModal( false );
    setGuaranteeStatus( 0 );
    resetAlerts();
    props.closeAction();
  }

  const handleSend = () => {

    if( guaranteeStatus === guaranteeStatusType.Pending ){
      setLoadingSend( true );
      //setLoading( true );
      console.log( 'Creamos la aprobación' );
      resetAlerts();
      
      let guaranteeApproval_response = post( baseApiURL+'/post/guarantee/approval', {
        order_uuid: orderUUID,
      });

      guaranteeApproval_response.then((response:any) => { 
        setLoadingSend( false );
        handleLoad();
      })
      .catch( error => {
        setLoadingSend( false );
        //console.log( error );
        showAlert({
          message: 'Hubo un problema al solcitar la aprobación. ['+error+']',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      });
      
    } else if( guaranteeStatus === guaranteeStatusType.ApproveWaiting ){
      console.log('a_');
    } else {
      console.log('otro');
    }
  }
  

  return (
    <Dialog open={activeModal} className="guarantee-box">
      <DialogTitle id="alert-dialog-title">Devolución de garantía: { loading ? <em>Cargando información</em> : orderTitle === '' ? <em>No encontrado</em> : orderTitle }</DialogTitle>
      <DialogContent className="guarantee-box-content">
        { alertBlock }
        <DialogContentText id="alert-dialog-description">
          Recuerda que debes devolver los montos en la moneda que corresponde y revertir la operación de transbank utilizando los voucher.
        </DialogContentText>
        <div className="guarantee-box-items">
          { !loading ?
              orderTitle === '' ?
                <em>No logramos cargar la información de la garantía, por favor comunícate con los administradores.</em>
              : 
                <div>
                  
                  { guaranteeStatus == guaranteeStatusType.ApproveWaiting &&
                      <div key={0} className="guarantee-box-waiting">
                        <img src={loadingGif} width={60} alt="Waiting for approval" />
                        <br />
                        <Typography variant="h5" align="center" color="textSecondary" gutterBottom>
                          Estamos esperando la aprobación
                          <br />del supervisor
                        </Typography>
                        <Divider />
                      </div>
                  }
                  
                  { itemsGuarantee }
                </div>
            :
              <React.Fragment>
                <CircularProgress /><br /><br />
              </React.Fragment>
          }
        </div>
      </DialogContent>
      { !loading &&
          <DialogActions>
            <div className="btn-container-dashboard">
              <BaseButton className="btn btn-outlined btn-dubra-cancel" onClick={() => { handleCancel() }} disabled={loadingSend}>
                { guaranteeStatus === guaranteeStatusType.Pending ?
                    'Cancelar'
                  :
                    'Cerrar'
                }
              </BaseButton>
              { orderTitle !== '' && guaranteeStatus === guaranteeStatusType.Pending && userPermission([roleType.Admin, roleType.SuperAdmin, roleType.StoreManager]) &&
                  <BaseButton className="btn btn-simple btn-dubra" onClick={() => { handleSend() }} disabled={loadingSend} >
                    { loadingSend ?
                        <CircularProgress />
                      :
                        'Solicitar aprobación'
                    }
                  </BaseButton>
              }
            </div>
          </DialogActions>
      }
    </Dialog>
  )
}

export default GuaranteeBox;