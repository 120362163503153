import React from 'react';

const SweetAlert = ( type:string ) => {
  var auxContentBox;
  if( type === 'ul' ){
    auxContentBox = document.createElement('ul');
    auxContentBox.style.textAlign = 'left';
  } else {
    auxContentBox = document.createElement('div');
    auxContentBox.append( type );
  }

  return auxContentBox;
}

interface liAlertProps {
  container: any,
  content: string,
}

export const SweetAlertAddLi = (props:liAlertProps) => {
  var auxContentLi = document.createElement('li');
  auxContentLi.append( props.content );
  props.container.appendChild( auxContentLi );

  return props.container;
}

export default SweetAlert;