import React, { createContext, useState, useEffect, Dispatch, SetStateAction, useContext } from "react";
import {isAuthenticated} from './auth';
import { useHistory } from "react-router-dom";
import { get } from "../_services/api";
import { baseApiURL } from "../_services/fetch";
import { roleType, errorSectionType, errorType } from "../_helpers/enums/all";
import useErrorManager from "../components/common/ErrorManager";

interface userProfileProps {
  username: string,
  usertype: string[],
}

interface authDataProps {
  isAuth: boolean | null,
  loading: boolean,
  setLoading: Dispatch<SetStateAction<boolean>>,
  user: userProfileProps,
  userPermission(permission:roleType[]): boolean,
  tabsActive: boolean,
  setTabsActive: Dispatch<SetStateAction<boolean>>,
  setLostedSession: Dispatch<SetStateAction<boolean>>,
  activeCashRegister: string,
  setActiveCashRegister: Dispatch<SetStateAction<string>>,
}

const initialAuthData:authDataProps = {
  isAuth: false,
  loading: true,
  setLoading: () => { },
  user: {
    username  : '',
    usertype  : [],
  },
  userPermission: (permission:roleType[]) => false,
  tabsActive: false,
  setTabsActive: () => { },
  setLostedSession: () => { },
  activeCashRegister: '',
  setActiveCashRegister: () => { },
};

export const AuthContext = createContext( initialAuthData );
export const useAuthDataContext = () => useContext(AuthContext);

const AuthProvider = (props:any) => {
  let history = useHistory();
  const { showAlert, resetAlerts } = useErrorManager({ id: 'session_info', section: errorSectionType.General });

  const [ isAuth, setAuthData ]       = useState<boolean | null>(null);
  const [ loading, setLoading ]       = useState<boolean>(true);
  const [ user, setUserData ]         = useState<userProfileProps>( initialAuthData.user );
  const [ tabsActive, setTabsActive ] = useState<boolean>( initialAuthData.tabsActive );
  const [ lostedSession, setLostedSession ] = useState<boolean>( false );
  const [ activeCashRegister, setActiveCashRegister ] = useState<string>( initialAuthData.activeCashRegister );

  const userPermission = ( permission:roleType ) => {
    const permissionLength = permission.length;
    for( let i=0; i<permissionLength; i++ ){
      if( user.usertype.includes( permission[i] ) ){
        return true;
      }
    }
    return false;
  }

  const checkAuthenticated = () => {
    let auxSessionCheck = isAuthenticated();

    if( isAuth !== null && !lostedSession ){
      if( !auxSessionCheck ){
        setLostedSession( true );

        showAlert({
          message: 'Lo sentimos, tu sesión ha expirado. Debes volver a iniciar sesión',
          type_values: errorType.Error,
          section: errorSectionType.General,
        });

        console.log( 'perdimos la sesión!' );
      }
    }

    setAuthData( auxSessionCheck );

    if( !auxSessionCheck ){
      setLoading( false );
    }
  }

  useEffect( () => {
    resetAlerts();
    checkAuthenticated();
  }, [history.location.pathname] );
  
  useEffect( () => {
    if( isAuth ){

      // Revisamos si los datos de acceso son correctos
      let user_response = get( baseApiURL+'/get/user');

      user_response.then((response:any) => { 
        setUserData( response );
        setLoading( false );

      })
      .catch( error => {
        console.log( 'error de carga de info de usuario' );
        console.log(error.response);
        setLoading( false );
      });
      
    }
  }, [isAuth] );

  /* The first time the component is rendered, it tries to
   * fetch the auth data from a source, like a cookie or
   * the localStorage.
   */
  useEffect( () => {
    checkAuthenticated();
  }, [] );

  return <AuthContext.Provider value={{isAuth, loading, setLoading, user, userPermission, tabsActive, setTabsActive, setLostedSession, activeCashRegister, setActiveCashRegister}} {...props} />;
};

export default AuthProvider;