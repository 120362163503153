import React, {useState, useEffect, useRef} from 'react';


import { LinearProgress, Grid, Button } from '@material-ui/core';
import { ActiveCashRegisterResponseCode } from '../common/enums';
import SimpleBox from '../common/SimpleBox';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import useFetch, { baseApiURL } from '../../_services/fetch';
import BaseButton from '../common/BaseButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import LastSaleItem from './cart/LastSaleItem';
import { useAuthDataContext } from '../../_auth/auth_provider';
import useErrorManager from '../common/ErrorManager';
import { errorSectionType, errorType } from '../../_helpers/enums/all';
import { getParam, removeFromQuery, createQuery } from '../../_services/request';
import SacItem from './cart/SacItem';

const CashRegister = (props: RouteComponentProps) => {
  let history = useHistory();

  document.title = 'Caja';
  const { response, loading, error } = useFetch({ method: 'GET', path: '/get/cash-registers/active'});
  const { setActiveCashRegister } = useAuthDataContext();
  const { alertBlock, showAlert } = useErrorManager({ id: 'cash_register_active', section: errorSectionType.Custom });

  const [ activeCash, setActiveCash ] = useState<string>( '' );
  const [ urlBoleta, setUrlBoleta ] = useState<string>( '' );
  const [ lastSales, setLastSales ] = useState<any>( [ <div key={0} className="sidebar-item-box"><div className="sidebar-item-detail empty-value">Sin atenciones el día de hoy</div></div> ] );
  const [ sacActivos, setSacActivos ] = useState<any>( [ <div key={0} className="sidebar-item-box"><div className="sidebar-item-detail empty-value"><i>Sin información</i></div></div> ] );
  //const params = new URLSearchParams(props.location.search);

  useEffect( () => {
    if( response ){


      if( response.data.code === ActiveCashRegisterResponseCode.active1001 || response.data.code === ActiveCashRegisterResponseCode.active1002 ){
        // No tiene caja registrada, debo redirigir a apertura de caja
        history.push( '/cash-register/new' );
        setActiveCashRegister('');
      } else if( response.data.code === ActiveCashRegisterResponseCode.active2002 || response.data.code === ActiveCashRegisterResponseCode.active2003 ){
        //alert( 'debo cargar la tienda :: '+response.data.code );
        //console.log(response.data);

        setActiveCash( response.data.info['cash-register'].title );
        setActiveCashRegister( response.data.info.cashregister_uuuid );

        if( response.data.info.last_sale ){

          let auxSales:any = [];
          const salesLength = response.data.info.last_sale.length;
          for (let i = 0; i < salesLength; i++) {
            if( response.data.info.last_sale[i].data.payment ){
              auxSales.push( <LastSaleItem key={i} title={ response.data.info.last_sale[i].data.name } date={ response.data.info.last_sale[i].fecha } total={ response.data.info.last_sale[i].data.payment.total.total } /> );
            } else {
              auxSales.push( <LastSaleItem key={i} title="Sin información" date={ response.data.info.last_sale[i].fecha } total={0} /> );
            }
          }
          setLastSales( auxSales );
        }

        if( response.data.sac ){
          setSacActivos(
            response.data.sac.map((item:string, index:number) => {
              return <SacItem title={item} />
            })
          );
        }
        

        let auxParams = getParam('check');
        //console.log('****', auxParams);
        if( auxParams ){
          setUrlBoleta( baseApiURL+'/tienda/venta/'+auxParams+'/boleta' );
          showAlert({
            message: 'La nota de venta ha sido registrada exitosamente.',
            type_values: errorType.Success,
            section: errorSectionType.Custom,
          });
          let auxRemoveParams = removeFromQuery(['check']);
          history.push({ search: createQuery( auxRemoveParams ) });
        }

      } else {
        //setError( 'Error: Código de estado de atención de caja no encontrado' );
        setActiveCashRegister('');
        alert( 'Error: Código de estado de atención de caja no encontrado' );
      }

    }
  }, [response, history]);

  return (
    loading ? <LinearProgress color="primary" /> :

    <React.Fragment>
    <div className="content_all">
      <div className="cash-register-box">
        { error != null ? 
            <SimpleBox>{'error - ver consola'}</SimpleBox>
          :
            <Paper className="fullwidth">
              <Grid container className="cash-register-container">
                <Grid item xs={12} sm={6} md={8} className="cash-register-content">
                  <h1 className="form_title">Atención: { activeCash }</h1>
                  { alertBlock }

                  <div className="form_detail">
                    { urlBoleta !== '' &&
                        <iframe
                          id="receipt"
                          src={urlBoleta}
                          style={{ width: 1, height: 1, border: 'none' }}
                          title="Receipt" />
                    }
                    <img src={ baseApiURL+"/sites/default/files/2020-03/productos.jpg" } alt="Listado de Kit" style={{maxWidth: '100%',}}/>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="cash-register-sidebar" >
                  <div className="cash-register-sidebar-info info-data">
                    
                    <div className="cash-register-sidebar-item">
                      <div className="cash-register-sidebar-item-title">
                        Sac activos
                      </div>
                      <div className="cash-register-sidebar-item-detail">
                        { sacActivos }
                      </div>
                    </div>

                    <div className="cash-register-sidebar-item">
                      <div className="cash-register-sidebar-item-title">
                        
                        <Grid container>
                          <Grid item xs={8} sm={9}>
                            Últimas ventas
                          </Grid>
                          <Grid item xs={4} sm={3}>
                            <small>Monto</small>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="cash-register-sidebar-item-detail">
                        { lastSales }
                      </div>
                    </div>
                  </div>
                  <div className="cash-register-sidebar-actions">
                    <BaseButton className="btn-sidebar-action" onClick={ () => { history.push( 'cash-register/sale' ) } }>
                      Agregar venta <FontAwesomeIcon icon={faPlusCircle} />
                    </BaseButton>
                  </div>
                </Grid>
              </Grid>
            </Paper>
        }
      </div>
    </div>
    
    </React.Fragment>
  );
}

export default CashRegister;