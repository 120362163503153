import React, {useState, useEffect} from 'react';
import useFetch, { baseApiURL } from '../../_services/fetch';
import { useHistory } from 'react-router-dom';
import useErrorManager from '../common/ErrorManager';
import { errorSectionType, errorType, cashRegisterStatusType } from '../../_helpers/enums/all';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useAuthDataContext } from '../../_auth/auth_provider';
import { roleType } from '../../_helpers/enums/all';

import ReservationsForm from '../reservations/forms/ReservationsForms';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import BaseButton from '../common/BaseButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CancelIcon from '@material-ui/icons/Cancel';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { post, get } from '../../_services/api';

interface guaranteeCashProps {
  pesos: number,
  reales: number,
  dolares: number,
}

interface guaranteeDataProps {
  voucher: string,
  amount: number,
}

interface efectivoProps {
  pesos: number,
  reales: number,
  reales_original: number,
  dolares: number,
  dolares_original: number,
  total: number,
  guarantee: guaranteeCashProps,
}

interface transbankProps {
  debito: number,
  crebito: number,
  total: number,
}

interface extraProps {
  init: number,
  total: number,
  totalAll: number,
}

const CashRegisterClose = () => {
  let history = useHistory();
  const { userPermission } = useAuthDataContext();

  document.title = 'Caja';
  let auxData = history.location.pathname.split( '/' );
  const { alertBlock, showAlert } = useErrorManager({ id: 'cashregister_close', section: errorSectionType.Custom });
  const [ loading, setLoading ] = useState<boolean>( true );
  const [ activeButton, setActiveButton ] = useState<boolean>( false );
  const [ sendData, setSendData ] = useState<boolean>( false );
  const [ cashier, setCashier ] = useState<boolean>( false );
  const [ supervisor, setSupervisor ] = useState<boolean>( false );
  const [ empty, setEmpty ] = useState<boolean>( true );

  const [ cash, setCash ] = useState<efectivoProps>({
    pesos: 0,
    reales: 0,
    reales_original: 0,
    dolares: 0,
    dolares_original: 0,
    total: 0,
    guarantee: {
      pesos: 0,
      reales: 0,
      dolares: 0,
    },
  });

  const [ guarantee, setGuarantee ] = useState<guaranteeDataProps[]>([]);

  const [ transbank, setTransbank ] = useState<transbankProps>({
    debito: 0,
    crebito: 0,
    total: 0,
  });

  const [ extra, setExtra ] = useState<extraProps>({
    init: 0,
    total: 0,
    totalAll: 0,
  });

  const getData = () =>{
    setLoading( true );
    let auxData = history.location.pathname.split( '/' );

    const responseData = get(baseApiURL+'/get/cash-registers/'+auxData[2]+'/close');

    responseData.then((response:any) => {
      if( response.data ){
        //console.log( response.info.extra );
        setLoading( false );

        // Se muestra si no viene vacío de data o si es administrador
        if( response.info.status !== 'empty' || userPermission([roleType.SuperAdmin, roleType.Admin]) ){
          setEmpty( false );

          // Verificamos los permisos que tiene
          setCashier( response.info.extra.cashier );
          setSupervisor( response.info.extra.supervisor );

          if( response.info.extra.status === cashRegisterStatusType.Open ){
            // Activar botón de cierre
            setActiveButton( true );
          } else {
            // Activar botón de cierre
            setActiveButton( true );

            if( response.info.extra.status !== cashRegisterStatusType.SupervisorClosed ){
              showAlert({
                message: 'Esta atención ya está cerrada',
                type_values: errorType.Warning,
                section: errorSectionType.Custom,
              });

              // Activar botón de cierre
              setActiveButton( false );
            }

            // Verificamos si es supervisor, sino, debemos redirigir
            if( response.info.extra.cashier && !response.info.extra.supervisor  ){
              //alert('Debo redirigir, no es supervisor y ya esta cerrada la caja');
              history.push( '/cash-register/new?closed=1' );
            }
          }
          
          // Guarantee
          if( response.info.garantia.transbank && response.info.garantia.transbank.length !== 0 ){
            setGuarantee( response.info.garantia.transbank );
          }

          // Cash
          setCash({
            pesos: response.info.efectivo.pesos,
            reales: response.info.efectivo.reales,
            reales_original: response.info.efectivo.reales_original,
            dolares: response.info.efectivo.dolares,
            dolares_original: response.info.efectivo.dolares_original,
            total: response.info.efectivo.total,
            guarantee: {
              pesos: response.info.garantia.efectivo.pesos,
              reales: response.info.garantia.efectivo.reales,
              dolares: response.info.garantia.efectivo.dolares,
            },
          });

          // Transbank
          setTransbank({
            debito: response.info.transbank.debito,
            crebito: response.info.transbank.credito,
            total: response.info.transbank.total,
          });

          // Extra info
          setExtra({
            init: response.info.otros.fondo_inicial,
            total: response.info.otros.total,
            totalAll: response.info.total,
          });
        } else {
          showAlert({
            message: 'No se han realizado ventas hasta ahora, debes solicitar a tu supervisor el cierre de caja.',
            type_values: errorType.Error,
            section: errorSectionType.Custom,
          });
        }
        
      }
    }).catch(error => {
      setLoading( false );
      showAlert({
        message: 'Hubo un error al cargar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });
  } 

  useEffect(() => {
    if( history.location.pathname ){
      getData();
    }
  }, [history]);

  const handleSend = () => {
    setSendData( true );

    const responseData = post(baseApiURL+'/post/cash-register/close', {
      cash_register: auxData[2],
    });

    responseData.then((response:any) => {
      if( response.data ){
        setSendData( false );
        //console.log( response );
        getData();
      }
    }).catch(error => {
      setSendData( false );
      showAlert({
        message: 'Hubo un error al cargar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });


  }
  
  return (
    loading ? <LinearProgress color="primary" /> :
      <div className="content_all">
        <div className="content">
          { alertBlock }
          { !empty &&
            <div className="cash-register-box">
              <Paper className="fullwidth">
                <Grid container className="cash-register-container form-container-common">
                  <Grid item xs={12} sm={6} md={8} className="cash-register-content">
                    <h1 className="form-title" style={{margin: 0}}>
                      Cierre de caja: 
                    </h1>
                    <br />
                    <p>Detalle sobre los movimientos relacionados a la garantía</p>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <h2>
                          Garantía transbank
                        </h2>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  Voucher
                                </TableCell>
                                <TableCell align="right">
                                  Monto
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              { guarantee.length === 0 ?
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Sin información sobre voucher
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                  </TableRow>
                                :
                                  guarantee.map((item:guaranteeDataProps, index: number) => {
                                    return (
                                      <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                          { item.voucher }
                                        </TableCell>
                                        <TableCell align="right">${new Intl.NumberFormat("es-CL").format( item.amount )}</TableCell>
                                      </TableRow>
                                    )
                                  })
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <h2>
                          Garantía efectivo
                        </h2>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  Moneda
                                </TableCell>
                                <TableCell align="right">
                                  Total
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Pesos
                                </TableCell>
                                <TableCell align="right">CLP ${new Intl.NumberFormat("es-CL").format( cash.guarantee.pesos )}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Reales
                                </TableCell>
                                <TableCell align="right">R ${new Intl.NumberFormat("es-CL").format( cash.guarantee.reales )}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Dólares
                                </TableCell>
                                <TableCell align="right">USD ${new Intl.NumberFormat("es-CL").format( cash.guarantee.dolares )}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className="cash-register-sidebar" >
                    <div className="cash-register-sidebar-info info-data">
                      
                      <div className="cash-register-sidebar-item">
                        <div className="cash-register-sidebar-item-detail">
                          
                          <div className="sidebar-item-box ">
                            <div className="cash-register-sidebar-item-title">
                              <Grid container justify="space-between">
                                <Grid item>
                                  TRANSBANK
                                </Grid>
                                <Grid item>
                                  Total: ${new Intl.NumberFormat("es-CL").format( transbank.total )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className="sidebar-item-detail item-form">
                              <Grid container justify="space-between">
                                <Grid item>
                                  <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                      <FiberManualRecordIcon className="icon-green" fontSize="small" />
                                    </Grid>
                                    <Grid>
                                      Operaciones Débito
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item >
                                  ${new Intl.NumberFormat("es-CL").format( transbank.debito )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className="sidebar-item-detail item-form">
                              <Grid container justify="space-between">
                                <Grid item>
                                  <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                      <FiberManualRecordIcon className="icon-green" fontSize="small" />
                                    </Grid>
                                    <Grid>
                                      Operaciones Crédito
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item >
                                  ${new Intl.NumberFormat("es-CL").format( transbank.crebito )}
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                          <br />
                          <div className="sidebar-item-box ">
                            <div className="cash-register-sidebar-item-title">
                              <Grid container justify="space-between">
                                <Grid item>
                                  EFECTIVO
                                </Grid>
                                <Grid item>
                                  Total: ${new Intl.NumberFormat("es-CL").format( cash.total )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className="sidebar-item-detail item-form">
                              <Grid container justify="space-between">
                                <Grid item>
                                  <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                      <FiberManualRecordIcon className="icon-green" fontSize="small" />
                                    </Grid>
                                    <Grid>
                                      Pesos
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item >
                                  ${new Intl.NumberFormat("es-CL").format( cash.pesos )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className="sidebar-item-detail item-form">
                              <Grid container justify="space-between">
                                <Grid item>
                                  <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                      <FiberManualRecordIcon className="icon-green" fontSize="small" />
                                    </Grid>
                                    <Grid>
                                      Reales ( R${new Intl.NumberFormat("es-CL").format( cash.reales_original )} )
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item >
                                  ${new Intl.NumberFormat("es-CL").format( cash.reales )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className="sidebar-item-detail item-form">
                              <Grid container justify="space-between">
                                <Grid item>
                                  <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                      <FiberManualRecordIcon className="icon-green" fontSize="small" />
                                    </Grid>
                                    <Grid>
                                      Dólares ( USD {new Intl.NumberFormat("es-CL").format( cash.dolares_original )} )
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item >
                                  ${new Intl.NumberFormat("es-CL").format( cash.dolares )}
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                          <br />
                          <div className="sidebar-item-box ">
                            <div className="cash-register-sidebar-item-title">
                              <Grid container justify="space-between">
                                <Grid item>
                                  Otros
                                </Grid>
                                <Grid item>
                                  Total: ${new Intl.NumberFormat("es-CL").format( extra.init )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className="sidebar-item-detail item-form">
                              <Grid container justify="space-between">
                                <Grid item>
                                  <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                      <FiberManualRecordIcon className="icon-green" fontSize="small" />
                                    </Grid>
                                    <Grid>
                                      Fondo caja
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item >
                                  ${new Intl.NumberFormat("es-CL").format( extra.total )}
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                          <br />
                          <div className="sidebar-item-box ">
                            <div className="cash-register-sidebar-item-title">
                              <Grid container justify="space-between">
                                <Grid item>
                                  TOTAL CAJA
                                </Grid>
                                <Grid item>
                                  Total: ${new Intl.NumberFormat("es-CL").format( extra.totalAll )}
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                          
                        </div>
                      </div>

                      
                    </div>
                    <div className="cash-register-sidebar-actions">
                      <BaseButton className="btn btn-sidebar-action btn-dubra" onClick={handleSend} disabled={ !activeButton || sendData } loading={sendData}>
                        Cerrar caja <ChevronRightIcon />
                      </BaseButton>
                      <BaseButton className="btn btn-sidebar-action btn-dubra-cancel" onClick={() => { history.goBack() }} disabled={sendData}>
                        Cancelar <CancelIcon />
                      </BaseButton>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          }    
      </div>
    </div>
  )
}

export default CashRegisterClose;