import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import BaseButton from '../../common/BaseButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { errorSectionType, guaranteeStatusType, errorType, roleType, creditRequestStatusType, requestStatusType } from '../../../_helpers/enums/all';
import useErrorManager from '../../common/ErrorManager';
import { baseApiURL } from '../../../_services/fetch';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { get, post } from '../../../_services/api';
import Typography from '@material-ui/core/Typography';
import loadingGif from '../../../assets/all/loading.gif';
import { Divider } from '@material-ui/core';

import { useAuthDataContext } from '../../../_auth/auth_provider'

interface reprintBoxProps {
  order         : string,
  url           : string,
  active        : boolean,
  closeAction (): void,
  printAction (props:any): void,
}

const ReprintBox = ( props:reprintBoxProps ) => {
  const { userPermission } = useAuthDataContext();
  const { alertBlock, showAlert, resetAlerts } = useErrorManager({ id: 'guarantee_modal', section: errorSectionType.Custom });

  const [ activeModal, setActiveModal ]         = useState<boolean>(true);

  const [ orderTitle, setOrderTitle ]           = useState<string>('');
  const [ orderUUID, setOrderUUID ]             = useState<string>('');

  const [ itemsGuarantee, setItemsGuarantee ]   = useState<React.ReactNode[]>( [] );

  const [ response, setResponse ]               = useState<any>();
  const [ error, setError ]                     = useState<any>();
  const [ loading, setLoading ]                 = useState<boolean>( true );
  const [ loadingSend, setLoadingSend ]         = useState<boolean>( false );

  const [ requestStatus, setRequestStatus ]     = useState<number>(0);

  const [ activePending, setActivePending ] = useState<boolean>( false );


  useEffect(() => {
    if( requestStatus ){
      
      // Debo verificar si fue rechazado anteriormente
      if( requestStatus === requestStatusType.Rejected ){
        setActivePending( false );
        setOrderTitle( 'Rechazado' );

        // Mostramos aviso de haber sido rechazado
        showAlert({
          message: 'Lo sentimos, la solicitud fue rechazada, puedes volver a solicitar la reimpresión. - 0003x',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
        
      } else if( requestStatus === requestStatusType.Approved ){
        showAlert({
          message     : 'La reimpresión ya fue aprobada.',
          section     : errorSectionType.Custom,
          type_values : errorType.Success,
        });
        setOrderTitle( 'Aprobado' );
        setActivePending( false );

      } else if( requestStatus === requestStatusType.Pending ){
        setOrderTitle( 'Pendiente de aprobación' );
        
        // Está a la espera, no hay mensaje
        setActivePending( true );

      }





      /*
      let auxItemsGuarantee:React.ReactNode[] = [];
          

      if( response.status === creditRequestStatusType.Approved  ){
        
        setItemsGuarantee( [] );
        setActivePending( false );


      } else if( response.status === creditRequestStatusType.Pending  ){

        console.log('PENDIENTE');

        auxItemsGuarantee.push(
          <div key={0} className="guarantee-box-waiting">
            <img src={loadingGif} width={60} alt="Waiting for approval" />
            <br />
            <Typography variant="h5" align="center" color="textSecondary" gutterBottom>
              Estamos esperando la aprobación
              <br />del supervisor
            </Typography>
            <Divider />
          </div>
        );
        setActivePending( true );
        setItemsGuarantee( auxItemsGuarantee );
        

      } else {
        showAlert({
          message: 'Hubo un problema al revisar la aprobación. - 0002x',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      }

      */



    }
  }, [requestStatus]);


  //const { response, loading, error } = useFetch({ method: 'GET', path: props.url});
  const handleLoad = () => {
    //console.log('handleLoad');
    setLoading( true );

    let reprintApproval_response = get( baseApiURL+'/get/requests/reprintnew/'+props.order);

    reprintApproval_response.then((response:any) => { 
      setLoading( false );
      setRequestStatus( response.status );
      //initRequest();
    })
    .catch( error => {
      setLoading( false );
      setError( error );
    });
  }

  useEffect( () => {
    if( error ){
      setOrderTitle( '' );
      alert( 'fail' );
    }
  }, [error] );

  useEffect( () => {
    handleLoad();
  }, [] );

  const initRequest = () => {
    //setActivePending( false );
    let reprintApproval_response = get( baseApiURL+'/get/requests/reprint/'+props.order);
  
    console.log('initRequest');
    reprintApproval_response.then((response:any) => { 

      //console.log('respondió', response, response.status);
      //setLoading( false );
      setRequestStatus( response.status );
      
    })
    .catch( error => {
      showAlert({
        message: 'Hubo un problema al revisar la aprobación. - 0001x - ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });
  }

  useEffect( () => {
    if( activePending ){
      const i_id = setInterval(() => {
        if( activeModal ){
          initRequest();
        }
      },5000);
      return () => {
        clearInterval(i_id);
      }
    }
  },[activePending ]);

  const handleCancel = () => {
    setActiveModal( false );
    resetAlerts();
    props.closeAction();
  }

  
  const handleSend = () => {
    console.log('Imprimimos ticket');
    props.printAction( props.url );
  }
  
  const handleSolicitud = () => {
    handleLoad();
    resetAlerts();
  }

  return (
    <Dialog open={activeModal} className="guarantee-box">
      <DialogTitle id="alert-dialog-title">Reimprimir boleta: { loading ? <em>Cargando información</em> : orderTitle === '' ? <em>No encontrado</em> : orderTitle }</DialogTitle>
      <DialogContent className="guarantee-box-content">
        { alertBlock }
        <DialogContentText id="alert-dialog-description">
          Para reimprimir una boleta requieres la autorización del supervisor de caja.
        </DialogContentText>
        <div className="guarantee-box-items">
          { !loading ?
              <div>
                { activePending &&
                    <div key={0} className="guarantee-box-waiting">
                      <img src={loadingGif} width={60} alt="Waiting for approval" />
                      <br />
                      <Typography variant="h5" align="center" color="textSecondary" gutterBottom>
                        Estamos esperando la aprobación
                        <br />del supervisor
                      </Typography>
                      <Divider />
                    </div>
                }
              </div>
            :
              <React.Fragment>
                <CircularProgress /><br /><br />
              </React.Fragment>
          }
        </div>
      </DialogContent>
      { !loading &&
          <DialogActions>
            <div className="btn-container-dashboard">
              <BaseButton className="btn btn-outlined btn-dubra-cancel" onClick={() => { handleCancel() }} disabled={loadingSend}>
                Cerrar
              </BaseButton>
              { requestStatus === requestStatusType.Rejected  && userPermission([roleType.Admin, roleType.SuperAdmin, roleType.StoreManager, roleType.Cashier]) &&
                  <BaseButton className="btn btn-simple btn-dubra" onClick={() => { handleSolicitud() }} disabled={loadingSend} >
                    Solicitar reimpresión
                  </BaseButton>
              }
              { orderTitle !== '' && orderTitle === 'Aprobado'  && userPermission([roleType.Admin, roleType.SuperAdmin, roleType.StoreManager, roleType.Cashier]) &&
                  <BaseButton className="btn btn-simple btn-dubra" onClick={() => { handleSend() }} disabled={loadingSend} >
                    Reimprimir
                  </BaseButton>
              }
            </div>
          </DialogActions>
      }
    </Dialog>
  )
}

export default ReprintBox;