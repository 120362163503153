import React, { useState, useEffect } from 'react';
import {StepBoxProps} from '../../_helpers/props';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BaseButton from '../common/BaseButton';
import CircularProgress from '@material-ui/core/CircularProgress';

const CashStep = (props: StepBoxProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [isVisited, setIsVisited] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  function handleResize() {
    /*if( props && props.parent && props.parent !== null && props.parent.current ){
      
      console.log( props.parent.current?.clientHeight );
      console.log( props.parent.current?.offsetHeight );
      console.log( props.parent.current?.scrollHeight );
      
    }
    */
  }

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if( props.state === 'active' ){
      setIsVisible( true );
      setIsActive( true );
      setIsVisited( false );
    } else if( props.state === 'visited' ){
      setIsVisible( true );
      setIsActive( false );
      setIsVisited( true );
    } else if( props.state === 'hidden' ){
      setIsVisible( false );
      setIsActive( false );
      setIsVisited( false );
    } else {
      setIsVisible( true );
      setIsActive( false );
      setIsVisited( false );
    }
  }, [props.state]);
  

  return (
    isVisible ?
      <div className={'cash-register-sidebar-step '+( isActive ? 'active ' : '' )+( isVisited ? 'visited ' : '' )}>
        <BaseButton className={'btn-sidebar-action ' + ( props.className ? props.className+' ' : '' )} onClick={props.onClick}>
          {props.title} 
          { props.loading ?
              <CircularProgress />
            :
              <FontAwesomeIcon icon={props.icon} />
          }
        </BaseButton>
        { props.children ?
            <div className="cash-register-sidebar-step-content">
              {props.children}
            </div>
          :
          ''
        }
      </div>
    :
    <React.Fragment></React.Fragment>
  )
}

export default CashStep;