import React, { useState, useEffect } from 'react';
import { stockAssignProps, stockDataProps, stockProps, inputActionsProps } from '../../../../_helpers/props/stock';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputNumber from '../../../common/InputNumber';

import BaseButton from '../../../common/BaseButton';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ContactsOutlined } from '@material-ui/icons';

const StockAssign = (props:stockAssignProps) => {
  const [ header, setHeader ]           = useState<any[]>( [] );
  const [ rows, setRows ]               = useState<any[]>( [] );

  const [ stockAvailable, setStockAvailable ] = useState<stockDataProps[]>( [] );
  const [ stock, setStock ]     = useState<stockProps[]>([]);

  const handleAction = ( props:inputActionsProps ) => {
    let auxDataStockAll:stockProps[] = [];

    // Buscamos la caja que corresponde
    let auxStockLength:number = stock.length;
    for( let i=0; i<auxStockLength; i++ ){
      auxDataStockAll.push( stock[i] );

      if( stock[i].cash_register === props.cash_register ){
        let auxStockDataLength:number = stock[i].stock.length;
        for( let i_stock=0; i_stock<auxStockDataLength; i_stock++ ){
          if( stock[i].stock[i_stock].variation_id === props.variation_id ){

            // Debo recuperar el item del stock
            let itemAvailable:number = 0;
            let auxAvailableLength = stockAvailable.length;
            let newAvailableStock:stockDataProps[] = [];
            for(let i_available=0; i_available<auxAvailableLength; i_available++ ){
              newAvailableStock.push( stockAvailable[i_available] );
              if( stockAvailable[i_available].variation_id === props.variation_id ){
                // Este debo cambiarlo
                itemAvailable = i_available;
              }
            }
            
            if( props.action === '+' ){
              // Debe existir disponibilidad para agregar
              if( newAvailableStock[itemAvailable].stock !== 0 ){
                auxDataStockAll[i].stock[i_stock].stock += 1;
                newAvailableStock[itemAvailable].stock -= 1;
              }
            } else {
              if( auxDataStockAll[i].stock[i_stock].stock > 0 ){
                auxDataStockAll[i].stock[i_stock].stock -= 1;
                newAvailableStock[itemAvailable].stock += 1;
              }
            }
            setStockAvailable( newAvailableStock );

            break;
          }
        }
        //break;
      }
    }
    setStock( auxDataStockAll );

  }

  const handlePlus = (props:any) => {
    handleAction({
      action: '+',
      cash_register: props.cash_register,
      variation_id: props.variation_id,
    });
  }
  
  const handleMinus = (props:any) => {
    handleAction({
      action: '-',
      cash_register: props.cash_register,
      variation_id: props.variation_id,
    });
  }

  useEffect(() => {
    if( stock.length !== 0 ){
      var auxProducts:any[] = [];

      // Finalizamos agregando los totales
      let productsLength:number = stockAvailable.length;
      for(let i=0; i<productsLength; i++){

        // Debo encontrar el dato de producto
        let dataStock:any = [];
        let stockLength:number = stock.length;
        for(let i_stock=0; i_stock<stockLength; i_stock++){
          
          let auxStockProdLength:number = stock[i_stock].stock.length;
          for(let i_stock_prod=0; i_stock_prod<auxStockProdLength; i_stock_prod++){
            if( stock[i_stock].stock[i_stock_prod].variation_id == props.data.productos[i].variation_id ){
              dataStock.push({
                variation_id: stock[i_stock].stock[i_stock_prod].variation_id,
                stock: stock[i_stock].stock[i_stock_prod].stock,
                cash_register: stock[i_stock].cash_register,
              });
              break;
            }
          }
        }

        auxProducts.push(
          <TableRow key={ auxProducts.length }>
            <TableCell component="th" scope="row">
              { props.data.productos[i].name }
            </TableCell>
            <TableCell align="left">
              { stockAvailable[i].stock }
            </TableCell>
            { dataStock.map((item:any, index:number) => {
              return (
                <TableCell key={index} align="center">
                  <InputNumber value={item.stock} onLess={() => { handleMinus( item ); }} onPlus={() => { handlePlus( item ); }} />
                </TableCell>
              );
            }) }
          </TableRow>
        );

      }

      setRows( auxProducts );


    }
  }, [stock]);

  useEffect(() => {
    if( props.data ){
      
      // Row
      var auxProductValues:any[] = [];
      let productsLength = 0;
      if( props.data.productos ){
        productsLength = props.data.productos.length;
      }


      for(let i=0; i<productsLength; i++){
        auxProductValues.push( {
          variation_id: props.data.productos[i].variation_id,
          stock: [],
        } );
      }
      setStockAvailable( props.data.productos );

      // Headers
      var auxHeader:any[] = [
        <TableCell key={0}>Productos</TableCell>,
        <TableCell key={1} align="left">Stock bodega</TableCell>,
      ];
      let auxStockData:stockProps[] = [];
      let headerLength = 0;
      if( props.data.tiendas ){
        headerLength = props.data.tiendas.length;
      }


      for(let i=0; i<headerLength; i++){
        let auxStockItem:stockProps = {
          cash_register : props.data.tiendas[i].cashregister_id,
          stock         : [],
        };
        auxHeader.push(<TableCell key={i+2} align="center">{props.data.tiendas[i].name}</TableCell>);

        for(let i_stock=0; i_stock<productsLength; i_stock++){
          let totalStockItem = 0;
          let auxId = auxProductValues[i_stock].variation_id;



          //console.log( props.data?.tiendas[i] );

          if( props.data?.tiendas[i].stock !== null ){
            //console.log('hay');
            if( props.data.tiendas[i].stock[ auxId ] ){
              totalStockItem = parseInt(props.data.tiendas[i].stock[ auxId ]);
            }
          }

          auxProductValues[i_stock].stock.push( totalStockItem );

          // Agrego al stock
          auxStockItem.stock.push({
            variation_id  : auxProductValues[i_stock].variation_id,
            stock         : totalStockItem,
          });
        }


        auxStockData.push(auxStockItem);

      }
      setStock( auxStockData );
      setHeader( auxHeader );

    }
  }, [props.data]);

  if( props.data ){
    return (
      <Dialog
          open={props.active}
          onClose={props.handleCancel}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>
            Modificar Stock Ventas
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Utiliza este formulario para agregar nuevos productos al stock total de venta
              <br />
            </DialogContentText>
            { props.error.alertBlock }
  
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {header.map((row) => (
                    row
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  row
                ))}
              </TableBody>
            </Table>
            
          </DialogContent>
          <DialogActions>
            <div className="btn-container-dashboard">
              <BaseButton className="btn btn-outlined btn-dubra-cancel" onClick={props.handleCancel} disabled={props.savingData}>
                Cancelar
              </BaseButton>
              <BaseButton className="btn btn-simple btn-dubra" onClick={() => { props.handleSend( stockAvailable, stock ) }} loading={props.savingData}>
                Guardar
              </BaseButton>
            </div>
          </DialogActions>
        </Dialog>
    )
  } else {
    return null
  }
}

export default StockAssign;