import * as React from "react";
import { useCartDataContext } from '../cart_provider';
//import image from "../../test_image.png";
import { DateTime } from "luxon";
import { ItemInCartProps, SaleOrderProps, SalePaymentProps, SaleProps, cardProps, preSaleProps, returnListProps } from "../../../_helpers/props/cash_register_props";
import BillItem from "./BillItem";
import { useAuthDataContext } from "../../../_auth/auth_provider";


interface propsBill {
  cart: SaleProps,
  order: SaleOrderProps,
  payment: SalePaymentProps,
  agencias: any,
  guias: any,
  cashRegister: string,
  store: string,
  orderId: number,
  date: DateTime|undefined,
  qr: string|undefined,
  orderReturn: returnListProps[],
}

export const FunctionalBillPrintable = React.forwardRef((props:propsBill) => {
  const { user } = useAuthDataContext();
  //const [orderId, setOrderId] = React.useState<number>( 0 );
  const [vuelto, setVuelto] = React.useState<number>( 0 );

  React.useEffect(() => {

    const auxVuelto = props.payment.payment.total.total
                          - Number(props.payment.payment.pesos ?? 0) - Number(props.payment.payment.dolar ?? 0)*Number(props.payment.tc.dolar ?? 0)
                          - Number(props.payment.payment.real ?? 0)*Number(props.payment.tc.real ?? 0) 
                          - props.payment.payment.credito.map((item:cardProps) => { return Number(item.amount) }).reduce((partialSum, a) => partialSum + a, 0) 
                          - props.payment.payment.debito.map((item:cardProps) => { return Number(item.amount) }).reduce((partialSum, a) => partialSum + a, 0) 
                          - props.payment.payment.pre_sale.map((item:preSaleProps) => { return item.amount }).reduce((partialSum, a) => partialSum + a, 0);
    setVuelto( auxVuelto * -1 );
    
    //console.log( 'A*', props.cart );
    //console.log( 'B*', props.order );
    //console.log( 'C*', props.payment );
    //console.log( user );
    

    
  }, [props]);

  React.useEffect(() => {
    console.log( 'holaaa', props.date );
  }, [props.date]);

  return (
    <div className="relativeCSS">
        <div className="boleta_box">
          <img id="logo_dubra" src="https://api.dubrachile.com/modules/custom/ratslabs/ratstore/images/boleta/logo.png" alt="" />

          <div id="header_data_dubra">
            <h3 style={{paddingBottom: 10,}}>Comprobante: {props.orderId}</h3>
            <div className="custom_col fecha_hora_terminal">
              <div className="col-4 text-left">
                <span className="detail_top_boleta">Fecha</span>{ props.date ? props.date.setLocale('es-CL').toFormat('dd/LL/yyyy') : '-' }
              </div>
              <div className="col-4 text-center">
                <span className="detail_top_boleta">Hora</span>{ props.date ? props.date.setLocale('es-CL').toFormat('HH:mm') : '-' }
              </div>
              <div className="col-4 text-right">
                <span className="detail_top_boleta">Terminal</span>-
              </div>
              <div className="suelta"></div>
            </div>

            <div className="custom_col fecha_hora_terminal">
              <div className="col-4 text-left">
                <span className="detail_top_boleta">Tienda</span> { props.store }
              </div>
              <div className="col-4 text-center">
                <span className="detail_top_boleta">Caja</span>{ props.cashRegister }
              </div>
              <div className="col-4 text-right">
                <span className="detail_top_boleta">Cajero</span>{ user.username }
              </div>
              <div className="suelta"></div>
            </div>
          </div>

          <div className="item_detail_head">
            <div className="title_detail_head">
              Pasajero
            </div>
            <div className="info_detail_head" style={{paddingLeft: 10,}}>
              { props.order.name } <span className="subinfo_detail_head">({ props.order.q_adultos + props.order.q_ninos })</span>
            </div>
          </div>



          <div style={{paddingTop: 8,}}>
            <div className="{% if dias != 1 %} col_50 {% else %} col_50{% endif %}">
              <div className="item_detail_head">
                <div className="title_detail_head text-center">
                  Adulto
                </div>
                <div className="info_detail_head text-center">
                  { props.order.q_adultos }
                </div>
              </div>
            </div>
            <div className="{% if dias != 1 %} col_50{% else %} col_50b{% endif %}" style={{marginLeft: '5%',}}>
              <div className="item_detail_head">
                <div className="title_detail_head text-center">
                  Jr
                </div>
                <div className="info_detail_head text-center">
                  { props.order.q_ninos }
                </div>
              </div>
            </div>
            {/*#% if dias != 1 %}
              <div className="col_30" style={{marginLeft: '5%',}}>
                <div className="item_detail_head">
                  <div className="title_detail_head text-center">
                    Días
                  </div>
                  <div className="info_detail_head text-center">
                    2
                  </div>
                </div>
              </div>
            {% endif %#*/}
            <div className="suelta"></div>
          </div>

          <div className="item_detail_head separate_item" style={{paddingTop: 10,}}>
            <div className="title_detail_head">
              Guía: <span className="info_detail_head">{ props.guias.filter((item:any) => item.props.value === props.order.guia )[0]?.props.children ?? '-' }</span>
            </div>
          </div>

          <div className="item_detail_head separate_item">
            <div className="title_detail_head">
              Agencia: <span className="info_detail_head">{ props.agencias.filter((item:any) => item.props.value === props.order.agencia )[0]?.props.children ?? '-' }</span>
            </div>
          </div>


          <div id="bloque_detalles_compra">
            <h4>Detalle Pedido</h4>

            { props.payment.payment.total.arriendo !== 0 ?
                <div>
                  <h5 className="text-left">Arriendo</h5>
                  <div className="bloque_detalle_productos">
                    <div className="detalle_productos">
                      { props.cart.cart_items.filter(values => { if( values.type === 'Kit Productos' || values.type === 'Arriendo' ){ return true } else { return false } }).map((item:ItemInCartProps, index:number) => {
                        return <BillItem {...item} key={index} />
                      })}
                    </div>
                    <div className="detalle_productos_subtotal text-right">
                      <span>Total Arriendos:</span> <strong>${new Intl.NumberFormat("es-CL").format( Math.abs(props.payment.payment.total.arriendo ) )}</strong>
                    </div>
                  </div>
                </div>
              :
                null
            }
            
            { props.payment.payment.total.venta !== 0 ?
                <>
                  <h5 className="text-left">Venta</h5>
                  <div className="bloque_detalle_productos">
                    <div className="detalle_productos">
                      { props.cart.cart_items.filter(values => values.type === 'Venta' ).map((item:ItemInCartProps, index:number) => {
                        return <BillItem {...item} key={index} />
                      })}
                    </div>
                    <div className="detalle_productos_subtotal text-right">
                      <span>Total Ventas:</span> <strong>${new Intl.NumberFormat("es-CL").format( Math.abs(props.payment.payment.total.venta ) )}</strong>
                    </div>
                  </div>
                </>
              :
                null  
            }

          </div>


          <div id="bloque_detalle_pagos">
            <h4>Detalle Pagos</h4>
            
            

            

            <div id="detalle_pagos">

              <div className="bloque_detalle_productos">
                <h5 className="text-left">Total a pagar</h5>


                { props.payment.payment.total.arriendo !== 0 ?
                    <div className="item_detalle_pago">
                      <div className="nombre_detalle_pago text-left">
                        Arriendo
                      </div>
                      <div className="cantidad_detalle_pago"> &nbsp; </div>
                      <div className="monto_detalle_pago text-right">
                        ${new Intl.NumberFormat("es-CL").format( Math.abs(props.payment.payment.total.arriendo ) )}
                      </div>
                      <div className="suelta"></div>
                    </div>
                  :
                    null
                }
                { props.payment.payment.discount.amount && props.payment.payment.discount.amount !== 0 ?
                    <div className="item_detalle_pago">
                      <div className="nombre_detalle_pago text-left">
                        Dscto. arriendo
                      </div>
                      <div className="cantidad_detalle_pago">
                        <i>{ props.payment.payment.discount.name }%</i>
                      </div>
                      <div className="monto_detalle_pago text-right">
                        - ${new Intl.NumberFormat("es-CL").format( props.payment.payment.discount.amount )}
                      </div>
                      <div className="suelta"></div>
                    </div>
                  :
                    null
                }
                
                { props.payment.payment.total.venta !== 0 ?
                    <div className="item_detalle_pago">
                      <div className="nombre_detalle_pago text-left">
                      Venta
                      </div>
                      <div className="cantidad_detalle_pago"> &nbsp; </div>
                      <div className="monto_detalle_pago text-right">
                        ${new Intl.NumberFormat("es-CL").format( Math.abs(props.payment.payment.total.venta ) )}
                      </div>
                      <div className="suelta"></div>
                    </div>
                  :
                    null
                }

                { props.payment.payment.pre_sale.length !== 0 ?
                    props.payment.payment.pre_sale.map((item:preSaleProps, index:number) => {
                      return (
                        <div className="item_detalle_pago">
                          <div className="nombre_detalle_pago text-left">
                            { index === 0 ? "Dscto. Precompra" : <span>&nbsp;</span> }
                          </div>
                          <div className="cantidad_detalle_pago" style={{fontSize: 11, fontStyle: 'italic'}}>
                            { item.code }
                          </div>
                          <div className="monto_detalle_pago text-right">
                            - ${new Intl.NumberFormat("es-CL").format( Math.abs( item.amount ) )}
                          </div>
                          <div className="suelta"></div>
                        </div>
                      )
                    })
                  :
                    null
                }


                <div className="item_detalle_pago" style={{paddingTop: 6, borderTop: 'solid 1px #000',}}>
                  <div className="nombre_detalle_pago text-left">
                    <strong>Monto Total</strong>
                  </div>
                  <div className="cantidad_detalle_pago"> &nbsp; </div>
                  <div className="monto_detalle_pago text-right">
                    <strong>${new Intl.NumberFormat("es-CL").format( Math.abs(props.payment.payment.total.total - props.payment.payment.pre_sale.map((item:preSaleProps) => { return item.amount }).reduce((partialSum, a) => partialSum + a, 0) ) )}</strong>
                    
                  </div>
                  <div className="suelta"></div>
                </div>
              </div>
              


              <div id="titulo_pagos_detalle">
                <div className="col-4">
                  <h5 className="text-left">&nbsp;</h5>
                </div>
                <div className="col-6">
                  <h5 className="">&nbsp;</h5>
                </div>
              </div>	




              
            
              <div id="titulo_pagos_detalle" className="item_detalle_pago">
                <div className="nombre_detalle_pago text-left">
                  <strong>Tipo de pago</strong>
                </div>
                <div className="cantidad_detalle_pago text-center">
                  {/*% if pagos.credito_dubra != '' %}
                    Motivo
                  {% else %}
                    &nbsp;
                  {% endif %*/}
                </div>
                <div className="monto_detalle_pago text-right">
                  &nbsp;
                </div>
                <div className="suelta"></div>
              </div>
        

              { ( props.payment.payment.pesos && props.payment.payment.pesos !== 0 ) ?
                  <div className="item_detalle_pago">
                    <div className="nombre_detalle_pago text-left">
                      Pesos
                    </div>
                    <div className="cantidad_detalle_pago text-center">
                      &nbsp;
                    </div>
                    <div className="monto_detalle_pago text-right">
                      ${new Intl.NumberFormat("es-CL").format( Math.abs(props.payment.payment.pesos ?? 0 ) )}
                    </div>
                    <div className="suelta"></div>
                  </div>
                :
                  null
              }

              { ( props.payment.payment.dolar && props.payment.payment.dolar !== 0 ) ?
                  <div className="item_detalle_pago">
                    <div className="nombre_detalle_pago text-left">
                      Dólares
                    </div>
                    <div className="cantidad_detalle_pago text-center">
                      USD$ {new Intl.NumberFormat("en").format( Math.abs(props.payment.payment.dolar ?? 0 ) )}
                    </div>
                    <div className="monto_detalle_pago text-right">
                      ${new Intl.NumberFormat("es-CL").format( Math.abs( (props.payment.payment.dolar ?? 0) * (props.payment.tc.dolar ?? 0) ) )}
                    </div>
                    <div className="suelta"></div>
                  </div>
                :
                  null
              }

              { (props.payment.payment.real && props.payment.payment.real !== 0) ?
                  <div className="item_detalle_pago">
                    <div className="nombre_detalle_pago text-left">
                      Reales
                    </div>
                    <div className="cantidad_detalle_pago text-center">
                      R$ {new Intl.NumberFormat("es-CL").format( Math.abs(props.payment.payment.real ?? 0 ) )}
                    </div>
                    <div className="monto_detalle_pago text-right">
                      ${new Intl.NumberFormat("es-CL").format( Math.abs( (props.payment.payment.real ?? 0) * (props.payment.tc.real ?? 0) ) )}
                    </div>
                    <div className="suelta"></div>
                  </div>
                :
                  null
              }

              { props.payment.payment.credito.length !== 0 ?
                  props.payment.payment.credito.map((item:cardProps, index:number) => {
                    return (
                      <div className="item_detalle_pago" key={index}>
                        <div className="nombre_detalle_pago text-left">
                          { index === 0 ? "T. Crédito" : <span>&nbsp;</span> }
                        </div>
                        <div className="cantidad_detalle_pago text-center">
                          <span className="detalle_producto_cambio">
                          C.A: { item.voucher }
                          </span>
                        </div>
                        <div className="monto_detalle_pago text-right">
                          ${new Intl.NumberFormat("es-CL").format( Math.abs( item.amount ) )}
                        </div>
                        <div className="suelta"></div>
                      </div>
                    )
                  })
                :
                  null
              }

              { props.payment.payment.debito.length !== 0 ?
                  props.payment.payment.debito.map((item:cardProps, index:number) => {
                    return (
                      <div className="item_detalle_pago" key={index}>
                        <div className="nombre_detalle_pago text-left">
                          { index === 0 ? "T. Débito" : <span>&nbsp;</span> }
                        </div>
                        <div className="cantidad_detalle_pago text-center">
                          <span className="detalle_producto_cambio">
                          C.A: { item.voucher }
                          </span>
                        </div>
                        <div className="monto_detalle_pago text-right">
                          ${new Intl.NumberFormat("es-CL").format( Math.abs( item.amount ) )}
                        </div>
                        <div className="suelta"></div>
                      </div>
                    )
                  })
                :
                  null
              }



              { props.payment.payment.dubra.request_auth !== '' ?
                  <div className="item_detalle_pago">
                    <div className="nombre_detalle_pago text-left">
                      Crédito Dubra
                    </div>
                    <div className="cantidad_detalle_pago text-center">
                      <span className="detalle_producto_cambio">
                        -
                      </span>
                    </div>
                    <div className="monto_detalle_pago text-right">
                      Abono
                    </div>
                    <div className="suelta"></div>
                  </div>
                :
                  null
              }

              { /*

              {% if pagos.credito_dubra != '' %}
                <div className="item_detalle_pago">
                  <div className="nombre_detalle_pago text-left">
                    Crédito Dubra
                  </div>
                  <div className="cantidad_detalle_pago text-center">
                    <span className="detalle_credito_motivo">{{ pagos.credito_motivo }}</span>
                  </div>
                  <div className="monto_detalle_pago text-right">
                    {{ pagos.credito_dubra }}
                  </div>
                  <div className="suelta"></div>
                </div>
              {% endif %}
          */ }
              
              <div className="item_detalle_pago" style={{paddingTop: 6, borderTop: 'solid 1px #000',}}>
                <div className="nombre_detalle_pago text-left">
                  Vuelto
                </div>
                <div className="cantidad_detalle_pago text-center">
                  &nbsp;
                </div>
                <div className="monto_detalle_pago text-right">
                  ${new Intl.NumberFormat("es-CL").format( Math.abs( vuelto ) )}
                </div>
                <div className="suelta"></div>
              </div>

            </div>
          </div>

        </div>
        <div style={{clear: 'both', pageBreakAfter: 'always'}}></div>

        <div>

        <div id="boleta_bottom">
          <br />
          <br />
        </div>

        <div className="boleta_box">	


          <div id="header_data_dubra">
            <h3 style={{paddingBottom: 10}}><strong>Control Retorno</strong></h3>

            <div id="bloque_retorno">
              <div id="bloque_QR" style={{textAlign: 'center'}}>
                { props.qr ?
                    <img src={props.qr} width={100} alt="QR code" style={{maxWidth: '100%'}} />
                  :
                    null
                }
                <em>AWDUB{props.orderId}</em><br />
              </div>
              <div id="bloque_datos">
                <br />
                <div className="item_detail_head separate_item" style={{paddingBottom: 5}}>
                  <div className="title_detail_head">
                    Orden: <span className="info_detail_head">N° {props.orderId}</span>
                  </div>
                </div>
                <div className="item_detail_head separate_item" style={{paddingBottom: 5}}>
                  <div className="title_detail_head">
                    Fecha: <span className="info_detail_head">{ props.date ? props.date.setLocale('es-CL').toFormat('dd/LL/yyyy') : '-' }</span>
                  </div>
                </div>
                <div className="item_detail_head separate_item" style={{paddingBottom: 5}}>
                  <div className="title_detail_head">
                    Hora: <span className="info_detail_head">{ props.date ? props.date.setLocale('es-CL').toFormat('HH:mm') : '-' }</span>
                  </div>
                </div>
              </div>
              <div className="suelta"></div>
            </div>
            
            
            <div id="bloque_detalles_compra">
              <h4>Detalle Devolución</h4>

              <h5 className="text-left">Arriendo</h5>
              <div className="bloque_detalle_productos">
                <div className="detalle_productos">
                  { props.orderReturn.map((item: returnListProps, index: number) => {
                    return (
                      <div className="detalle_producto_unidad">
                        <div className="detalle_producto_cantidad text-center">
                          { item.total }
                        </div>
                        <div className="detalle_producto_nombre  text-left">
                        { item.title }
                        </div>
                        <div className="suelta"></div>
                      </div>
                    )
                  })}


                </div>
              </div>
            </div>




          </div>



        </div>

        <div style={{clear: 'both', pageBreakAfter: 'always'}}></div>


            <div className="boleta_box">
		


		<div id="header_data_dubra">
			<h3 style={{paddingBottom: 10}}><strong>Garantía</strong></h3>
			<div className="custom_col fecha_hora_terminal">
				<div className="col-4 text-left">
					<span className="detail_top_boleta">Fecha</span> { props.date ? props.date.setLocale('es-CL').toFormat('dd/LL/yyyy') : '-' }
				</div>
				<div className="col-4 text-center">
					<span className="detail_top_boleta">Hora</span> { props.date ? props.date.setLocale('es-CL').toFormat('HH:mm') : '-' }
				</div>
				<div className="col-4 text-right">
					<span className="detail_top_boleta">Comprobante</span>Nº { props.orderId }
				</div>
				<div className="suelta"></div>
			</div>

			<div className="custom_col fecha_hora_terminal" style={{borderBottom: 'solid 1px #000'}}>
        <div className="col-4 text-left">
					<span className="detail_top_boleta">Caja</span> { props.cashRegister }
				</div>
				<div className="col-4 text-center">
					<span className="detail_top_boleta">Terminal</span> -
				</div>
				<div className="suelta"></div>
			</div>

			<div className="bajada_detalle_garantia">
				<div className="item_detail_head separate_item" style={{paddingTop: 5,}}>
					<div className="title_detail_head">
						Pasajero: <span className="info_detail_head">{ props.order.name } <span className="subinfo_detail_head">({ props.order.q_adultos + props.order.q_ninos })</span></span>
					</div>
				</div>

				<div className="item_detail_head separate_item">
					<div className="title_detail_head">
						Personas: <span className="info_detail_head">{props.order.q_adultos} adultos y {props.order.q_ninos} niños</span>
					</div>
				</div>

				<div className="item_detail_head separate_item">
					<div className="title_detail_head">
            { props.payment.garantia.state === 'confianza' ?
                <div>
                  Monto: <span className="info_detail_head">(<span className="no_garantia">${new Intl.NumberFormat("es-CL").format( props.payment.garantia.total )}</span>) Agencia</span>
                </div>
              :
                <div>
                  Monto: <span className="info_detail_head">${new Intl.NumberFormat("es-CL").format( props.payment.garantia.total )}</span>
                </div>

            }
					</div>
				</div>
        <div className="item_detail_head separate_item">
          { props.payment.garantia.state !== 'confianza' ?
              <div>
                <hr />
                <div className="custom_col fecha_hora_terminal">
                  <div className="col-12 text-left">
                    <span className="detail_top_boleta">Detalle pagado</span>
                  </div>
                  <div className="suelta"></div>
                </div>

                <div className="detalle_pagos">
                  { ( props.payment.garantia.pesos ) ? 
                      <div className="item_detalle_pago">
                        <div className="nombre_detalle_pago text-left">
                          Pesos
                        </div>
                        <div className="cantidad_detalle_pago"> &nbsp; </div>
                        <div className="monto_detalle_pago text-right">
                          ${new Intl.NumberFormat("es-CL").format( props.payment.garantia.pesos ?? 0 )}
                        </div>
                        <div className="suelta"></div>
                      </div>
                    :
                      null  
                }

                  { ( props.payment.garantia.real ) ? 
                      <div className="item_detalle_pago">
                        <div className="nombre_detalle_pago text-left">
                        Reales
                        </div>
                        <div className="cantidad_detalle_pago"> &nbsp; </div>
                        <div className="monto_detalle_pago text-right">
                          R$ {new Intl.NumberFormat("es-CL").format( props.payment.garantia.real ?? 0 )}
                        </div>
                        <div className="suelta"></div>
                      </div>
                      :
                        null
                    }

                  { ( props.payment.garantia.dolar ) ? 
                      <div className="item_detalle_pago">
                        <div className="nombre_detalle_pago text-left">
                          Pesos
                        </div>
                        <div className="cantidad_detalle_pago"> &nbsp; </div>
                        <div className="monto_detalle_pago text-right">
                          ${new Intl.NumberFormat("es-CL").format( props.payment.garantia.dolar ?? 0 )}
                        </div>
                        <div className="suelta"></div>
                      </div>
                      :
                        null
                    }
                

                  { props.payment.garantia.credito.length !== 0 ?
                      props.payment.garantia.credito.map((item:cardProps, index:number) => {
                        return (
                          <div className="item_detalle_pago" key={index}>
                            <div className="nombre_detalle_pago text-left">
                              { index === 0 ? "T. Crédito" : <span>&nbsp;</span> }
                            </div>
                            <div className="cantidad_detalle_pago text-center">
                              <span className="detalle_producto_cambio">
                              C.A: { item.voucher }
                              </span>
                            </div>
                            <div className="monto_detalle_pago text-right">
                              ${new Intl.NumberFormat("es-CL").format( Math.abs( item.amount ) )}
                            </div>
                            <div className="suelta"></div>
                          </div>
                        )
                      })
                    :
                      null
                  }

                </div>


              </div>
            :
              null
          }
          
          
				</div>
			</div>

			<div className="bajada_detalle_garantia">
				<div className="item_detail_head separate_item" style={{paddingTop: 5}}>
					<div className="title_detail_head">
						Guía: <span className="info_detail_head">{ props.guias.filter((item:any) => item.props.value === props.order.guia )[0]?.props.children ?? '-' }</span>
					</div>
				</div>

				<div className="item_detail_head separate_item">
					<div className="title_detail_head">
						Agencia: <span className="info_detail_head">{ props.agencias.filter((item:any) => item.props.value === props.order.agencia )[0]?.props.children ?? '-' }</span>
					</div>
				</div>
			</div>


	</div>
	<div id="boleta_bottom">
		Todos los derechos reservados
		<br />
		<br />
		-
	</div>
</div>








        </div>

        
        


        
      </div>
  )
});
