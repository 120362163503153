import React, { useState, useEffect } from 'react';
import { baseApiURL, baseApiRest, apiVersion } from '../../../_services/fetch';

import { IColumn, ITableHeader, Order, IPagination, headerValues } from '../../common/table/table-types';
import { Table, TableBody, TableRow, TableCell, TablePagination, Checkbox, Typography } from '@material-ui/core';
import TableHeader, { EnhancedTableToolbar } from './table-header';
import { TableSkeleton } from '../../common/table/table-skeleton';
import { getParamsFormat, getParamsFormatFromQs, getQueryParams, getParam, createQuery, removeFromQuery, addToQuery } from '../../../_services/request';
import { useHistory } from 'react-router-dom';
import { get } from '../../../_services/api';

import useErrorManager from '../ErrorManager';
import { errorType, errorSectionType } from '../../../_helpers/enums/all';

import IconButton from '@material-ui/core/IconButton';
import { DateTime } from 'luxon';

import Parser from 'html-react-parser';
import { filterProps } from '../../../_helpers/props/form_props';

interface tableProps {
  title           : string,
  columnsValues   : IColumn[],
  urlData         : string,
  emptyData       : string,
  id              : string,
  filters        ?: React.ReactNode,
  today          ?: boolean,
  todayLocked    ?: boolean,
  apiType        ?: boolean,
}

interface tableResponseProps {
  response: any,
  endpoint: string,
  id: number,
}

const TableContent = ( props:tableProps ) => {
  let history = useHistory();
  const { alertBlock, showAlert }       = useErrorManager({ id: props.id, section: errorSectionType.Custom });
  
  const [ loading, setLoading ]         = useState(false);
  const [ total, setTotal ]             = useState<number>(0);
  const [ dataTable, setDataTable ]     = useState([]);
  const [ dataHeader, setDataHeader ]   = useState<headerValues[]>([]);
  const [ selected, setSelected ]       = useState<string[]>([]);
  const [ rowCount, setRowCount]         = useState<number>( 0 );
  const [ deleteUrl, setDeleteUrl ]     = useState<string>( '' );
  
  const [ activeId, setActiveId ]       = useState<number>( 0 );
  const [ tableResponse, setTableResponse ] = useState<tableResponseProps>();
  const [ activeAux, setActiveAux ]     = useState<string[]>( [] );

  const [ filters, setFilters ]         = useState<filterProps[]>( [] );

  const [ pagination, setPagination]     = useState<IPagination>({
    page: 0,
    pageSize: 25,
    total: 0,
    orderOrientation: Order.desc,
    orderField: props.columnsValues[0].id,
    pages: 0,
  });
  
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string, order: Order) => {
    setPagination({ ...pagination, orderOrientation: order, orderField: property });
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({ ...pagination, pageSize: +event.target.value });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({ ...pagination, page: newPage });
  }

  const handleFilterToday = ( action:boolean ) => {
    if( action ){
      let auxParams = getQueryParams();

      // Agrega active
      auxParams.push({
        param: 'active',
        value: 'true',
      });

      //      
      //if( getParam('from') === null ){
        auxParams.push({
          param: 'from',
          value: DateTime.local().toFormat('dd-LL-yyyy'),
        });
      //}

      //if( getParam('end') === null ){
        auxParams.push({
          param: 'end',
          value: DateTime.local().toFormat('dd-LL-yyyy'),
        });
      //}

      //history.push({ search: createQuery( auxParams ) });
      history.push({ search: createQuery( addToQuery(auxParams) ) });
    } else {
      // Debemos quitar active, from y end
      let auxParams = removeFromQuery(['active', 'from', 'end']);

      history.push({ search: createQuery( auxParams ) });
    }
    //setPagination({ ...pagination, orderOrientation: order, orderField: property });
    
    
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    /*
    if (event.target.checked) {
      const newSelecteds = dataTable.map((n:DataValues) => n.code);
      setSelected(newSelecteds);
      return;
    }
    */
    setSelected([]);
  };

  const handleLoadData = ( endpoint:string ) => {
    //console.log('llamé!', endpoint);
    
    /*
    if( tableResponse !== undefined ){
      tableResponse.cancel();
    }
    newActiveId
    setTableResponse( get( endpoint ) );
    */
    let auxTableResponse:tableResponseProps = {
      response: get( endpoint ),
      endpoint: endpoint,
      id: countRepeated(endpoint)+1,
    }

    var auxDataActive = activeAux;
    auxDataActive.push( endpoint );
    setActiveAux( auxDataActive );
    setTableResponse(auxTableResponse);
    /*
    tableResponse = get( baseApiURL+props.urlData+qs);

    // Reviso los filtros
    tableResponse.then((response:any) => { 
      setLoading( false );
      
      console.log('ya cargue con esta info', response);
      

      if( response.options.delete ){
        setDeleteUrl( response.options.delete );
      }

      // Quitamos esta condicion para que siempre renueve la info
      //if( response.data.length !== 0 ){
        setDataTable( response.data );
        setDataHeader( response.header );
        setRowCount( response.pager.info.total_rows );
        setTotal( parseInt(response.pager.info.total_items) );
      //}

    })
    .catch( (error:any) => {
      setLoading( false );
      showAlert({
        message: 'Hubo un problema al cargar la información de la tabla: '+error,
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });

    });
    */

  }

  const countRepeated = (search: string) => {
    var auxCount = 0;

    // Contar los resultados
    var auxTotal = activeAux.length;
    return auxTotal;
    // Esto sirve para modificar solo uno
    for( var i=0; i<auxTotal; i++ ){

      if( search === activeAux[i] ){
        auxCount++;
      }
    }

    return auxCount;
  }

  useEffect(() => {
    // Verificamos que no sea undefined
    if( tableResponse !== undefined ){

      // Capturamos la promesa
      tableResponse.response.then((response:any) => { 
        //console.log('Responde: ', tableResponse.id, tableResponse.endpoint);

        var auxCount = countRepeated( tableResponse.endpoint );
        if( auxCount === tableResponse.id  ){
          //console.log('Resultado', 'ID response:'+tableResponse.id, response);




          setLoading( false );
      
          //console.log('ya cargue con esta info', response);
          

          if( response.options.delete ){
            setDeleteUrl( response.options.delete );
          }

          // Agrega la información en la tabla
          setDataTable( response.data ); 
            
          if( props.apiType ){
            setDataHeader( response.headers );
            setRowCount( response.pagination.info.total_rows );
            setTotal( parseInt(response.pagination.info.total_items) );
            setFilters( response.filters );

            

          } else {
            setDataHeader( response.header );
            setRowCount( response.pager.info.total_rows );
            setTotal( parseInt(response.pager.info.total_items) );
          }

        }
      })
      .catch( (error:any) => {
        setLoading( false );
        showAlert({
          message: 'Hubo un problema al cargar la información de la tabla: '+error,
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
  
      });

    }
  }, [tableResponse]);


  const tableHeader: ITableHeader = {
    order: Order.desc,
    orderBy: props.columnsValues[0].id,
    columns: props.columnsValues,
    dataColumns: dataHeader,
    onRequestSort: handleRequestSort,
    numSelected: selected.length,
    rowCount: rowCount,
    onSelectAllClick: handleSelectAllClick,
    delete: deleteUrl ? deleteUrl : '',
  }

  /*
  const getFilters = () => {
    let defaultFilters = {};
    let qs = getParamsFormatFromQs(defaultFilters);
    return qs;
  }
  */

  useEffect(() => {
    let _pagination: any ={
        page: pagination.page + 1,
        pageSize: pagination.pageSize,
        orderField: pagination.orderField,
        orderOrientation: pagination.orderOrientation,
    };

    /*
    let paramsQs = queryString.parse(props.location.search);

    if (paramsQs.issuersRUT || paramsQs.folios || paramsQs.folios || paramsQs.orderNumber || paramsQs.receptionNumber || paramsQs.DTEStatus) {
        setShowFilters(true);
    }
    */

    let qs = getParamsFormatFromQs(_pagination);
    history.push({ search: qs });

  }, [pagination, pagination.page, pagination.pageSize, pagination.orderField, pagination.orderOrientation]);

  let qs;
  //let tableResponse:any;




  //var activeId = 0;
  const getActiveId = () => {
    return activeId;
  }
  const newActiveId = () => {
    let auxActiveId = activeId+1;
    setActiveId( auxActiveId );
    return auxActiveId;
  }





  useEffect(() => {
    //console.log('Location search:', window.location.search);

    if (window.location.search === "") {
        setPagination({ ...pagination, page: 0 })
        return;
    }

    setLoading(true);
    setSelected( [] );
    setDataTable( [] );
    setDataHeader( [] );




    qs = createQuery(getQueryParams());
    //console.log('voy a cargar info', qs );
    //console.log('voy a cargar info', qs, tableResponse);

    if( props.apiType ){
      handleLoadData( baseApiRest+`/api/`+apiVersion+`/get/table_full`+props.urlData + qs );
    } else {
      handleLoadData( baseApiURL+props.urlData+qs );
    }
    

  }, [window.location.search]);

  interface clickProps {
    event: React.MouseEvent<unknown>,
    name: string,
    enabled: boolean,
  }

  const handleClick = ( props:clickProps ) => {

    if( props.enabled ){
      const selectedIndex = selected.indexOf(props.name);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, props.name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
    }

  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  
  return (
    <React.Fragment>
      { alertBlock }
      <EnhancedTableToolbar title={props.title} messages_id={props.id} numSelected={ selected.length } deleteUrl={deleteUrl} selected={selected} onFilterToday={handleFilterToday} filters={filters} today={props.today} todayLocked={props.todayLocked}  />
      <Table stickyHeader>
        <TableHeader {...tableHeader} />
        {loading ?
            <TableSkeleton rows={5} columns={ deleteUrl && deleteUrl !== '' ? props.columnsValues.length + 1 : props.columnsValues.length } hasActions={false} /> :
            <TableBody>
              {dataTable.length > 0 ? dataTable.map((row: any, index: number) => {
                let tableCells:any            = [];
                let auxKey:string             = '';
                let auxActiveDelete:boolean   = false;

                const auxColumnsLength:number = dataHeader.length;
                for( let i=0; i<auxColumnsLength; i++ ){
                  
                  if( row.delete && row.delete === "true" ){
                    auxActiveDelete = true;
                  }
                  
                  let auxDetail = row[dataHeader[i].id];
                  let auxDisabledClass = '';
                  if( row.disabled === '1' || row.disabled === 1 ){
                    auxDisabledClass = ' disabled ';
                  }

                  //console.log('~~~~~~~~~~', row, dataHeader[i].id, typeof row[dataHeader[i].id]);

                  //if( auxDetail ){
                  if( typeof row[dataHeader[i].id] !== 'undefined' ){

                    // Buscamos la columna
                    let auxColLength = props.columnsValues.length;
                    for( let i_col=0; i_col<auxColLength; i_col++ ){
                      //console.log( 'clubs', props.columnsValues[i_col].id, '//', dataHeader[i].id );




                      if( props.columnsValues[i_col].id === dataHeader[i].id ){
                        

                        // Primero verificamos si la columna es visible o no
                        if( props.columnsValues[i_col].visible ){



                          
                          // Verificamos si es una columna especial
                          if( props.columnsValues[i_col].extraData ){
                            tableCells[ i ] = <TableCell key={i} className={auxDisabledClass}>{ props.columnsValues[i_col].extraData!( row ) }</TableCell>;
                          } else {
                            // Revisamos si es múltiple,
                            if( props.columnsValues[i_col].multiple ){
                              auxDetail = row.products_name.split( '**--*' ).map((item:any, index:number) => {
                                return <li key={index}>{item}</li>;
                              });
                            }
                            
                            if( props.columnsValues[i_col].uKey ){
                              auxKey = auxDetail;
                            }                        

                            if( props.columnsValues[i_col].button ){
                              if( row[props.columnsValues[i_col].id] !== undefined && row[props.columnsValues[i_col].id] !== '' && row[props.columnsValues[i_col].id] !== '-' ){
                                auxDetail = (
                                <IconButton aria-label="delete" onClick={() => {
                                  props.columnsValues[i_col].button_action && props.columnsValues[i_col].button_action!({ url: row[props.columnsValues[i_col].id], all: row })
                                }}>
                                  {props.columnsValues[i_col].button_name && props.columnsValues[i_col].button_name}
                                </IconButton>
                                );
                              } else {
                                auxDetail = <Typography align="center">-</Typography>;
                              }
                            }
                            
                            if( auxDetail === '' ){
                              auxDetail = <small><em>Sin información</em></small>;
                            }

                            if( props.columnsValues[i_col].visible ){

                              if (typeof auxDetail === 'string' || auxDetail instanceof String){
                                tableCells[ i ] = <TableCell key={i} className={auxDisabledClass}>{ Parser(String(auxDetail)) }</TableCell>;
                              } else {
                                tableCells[ i ] = <TableCell key={i} className={auxDisabledClass}>{ auxDetail }</TableCell>;
                              }

                            }
                          }





                        } else {
                          // No se hace nada, ya que no debe retornar
                        }
                        

                        break;
                      }
                    }

                  } else {
                    tableCells[ i ] = <TableCell key={i} className={auxDisabledClass}>-</TableCell>;
                  }
                }
                
                
                
                const isItemSelected = isSelected( auxKey );
                const labelId = `enhanced-table-checkbox-${ auxKey }`;

                let checkboxVal = null;
                if( auxActiveDelete ){
                  checkboxVal = <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />;
                }

                return (
                  <TableRow
                    key={ index }
                    onClick={ (event) => handleClick({ event:event, name: auxKey, enabled: auxActiveDelete }) }
                    aria-checked={ isItemSelected }
                    selected={ isItemSelected }
                  >
                    {deleteUrl && deleteUrl !== '' ?
                        <TableCell padding="checkbox">{ checkboxVal }</TableCell>
                      :
                        null
                    }
                    { tableCells }
                  </TableRow>
                )
              }) : <TableRow key="no-list"><TableCell colSpan={10} align="center">{ props.emptyData }</TableCell></TableRow>}
              
            </TableBody>
        }
      </Table>
      <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          labelRowsPerPage={'Registros por página'}
          rowsPerPage={pagination.pageSize}
          page={(pagination.page)}
          backIconButtonProps={{
              'aria-label': 'Página anterior',
          }}
          nextIconButtonProps={{
              'aria-label': 'Página siguiente',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
}

export default TableContent;