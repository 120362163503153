import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {actionProps} from '../../_helpers/props/all';

interface blockProps {
  title    ?: string,
  children  : React.ReactNode,
  loading  ?: boolean,
  actions  ?: actionProps[],
}

const BlockContent = ( props:blockProps ) => {
  const handleClick = () => {

  }

  return (
    <Paper className="content">
      <div className="paper_content_container">
        { props.title &&
          <React.Fragment>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                  <Typography variant="h6" id="tableTitle" component="div">
                    { props.title }
                  </Typography>
              </Grid>
              { props.actions && props.actions.length !== 0 &&
                  <Grid item>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Grid>
              }
            </Grid>
            <Divider />
          </React.Fragment>
        }
        { props.loading ?
            <LinearProgress />
          :
            <React.Fragment>
              <div  className="paper_content_container_data">
                { props.children }
              </div>
            </React.Fragment>
        }
      </div>
    </Paper>
  )
}

export default BlockContent;