import React from 'react';
import useFetch from '../../_services/fetch';
import { useHistory } from 'react-router-dom';
import useErrorManager from '../common/ErrorManager';
import { errorSectionType } from '../../_helpers/enums/all';
import LinearProgress from '@material-ui/core/LinearProgress';
import ReservationsAssignForm from './forms/ReservationsAssignForm';



const ReservationsAssign = () => {
  let history = useHistory();
  let url_path = history.location.pathname.split('/');
  let reservation_id = url_path[ url_path.length-1 ];
  const { response, loading, error } = useFetch({ method: 'GET', path: '/get/reservations/assign/'+reservation_id});
  const { alertBlock } = useErrorManager({ id: 'reservations_assign_form', section: errorSectionType.Custom });
  
  return (
    loading ? <LinearProgress color="primary" /> :
      <div className="content_all">
        <div className="content">
          { alertBlock }
          <ReservationsAssignForm response={response} error={error} />
      </div>
    </div>
  )
}

export default ReservationsAssign;