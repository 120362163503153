import React from 'react';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

interface lastSaleProp {
  date?   : string,
  title   : string,
  total?  : number,
}

const SacItem = (props:lastSaleProp) => {
  return (
    <div className="sidebar-item-box">
      <div className="sidebar-itemSac-detail">
        {props.title}
      </div>
    </div>
  )
}

export default SacItem;