import React from 'react';
import BaseButton from '../BaseButton';
import { modalActionsProps, modalActionButtonProps} from '../../../_helpers/props/modal';

import CancelIcon from '@material-ui/icons/Cancel';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const ModalActions = (props:modalActionsProps) => {
  return (
    <div className="modal_actions">
      { props.children }
    </div>
  )
}

export const ModalAction = (props:modalActionButtonProps) => {
  return (
    <BaseButton onClick={ props.onClick } className={ (props.align ? 'buttontext_'+props.align : 'buttontext_right') + ' ' + ( props.type ? props.type : 'normal' ) } loading={props.loading ? props.loading : false } disabled={ props.disabled ? props.disabled : false }  >
      { props.type == 'cancel' &&
          <CancelIcon style={{marginRight: 10}} />
      }
      { props.value }
      { !props.type &&
          <ChevronRightIcon style={{marginLeft: 10}} />
      }
    </BaseButton>
  )
}

export default ModalActions;