import React from 'react';
import { StepBlock, MoneyTypes } from '../../../_helpers/enums/cash_register';
import { TextField, Grid, IconButton } from '@material-ui/core';
import BaseButton from '../../common/BaseButton';
import { useCartDataContext } from '../cart_provider';
import { NumberFormatPesos } from '../../common/InputNumberFormat';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { color } from '../../../style/theme';

interface cardActionsProps {
  type: string,
}

interface cardBaseActionsProps {
  step: string,
  index: number,
}

const CardItem = (props: cardActionsProps & cardBaseActionsProps) => {
  const { cart,
          cartPayment, setCartPayment,
          addCard, removeCard,
          setVoucherValues,
          setMoneyValues } = useCartDataContext();
  
  var auxItemVoucher;
  var auxItemAmount;
  if( props.type === MoneyTypes.Credit ){
    if( props.step === StepBlock.Garantia ){
      if( cartPayment.garantia.credito[ props.index ] ){
        auxItemVoucher = cartPayment.garantia.credito[ props.index ].voucher;
        if( cartPayment.garantia.credito[ props.index ].amount !== 0 ){
          auxItemAmount = cartPayment.garantia.credito[ props.index ].amount;
        }
      }
    } if( props.step === StepBlock.Pago ){
      
      if( cartPayment.payment.credito[ props.index ] ){
        auxItemVoucher = cartPayment.payment.credito[ props.index ].voucher;
        if( cartPayment.payment.credito[ props.index ].amount !== 0 ){
          auxItemAmount = cartPayment.payment.credito[ props.index ].amount;
        }
      } else {
        console.log( 'no rezoconco el id de credito' );
      }
    } else {
      console.log( 'No se reconoce la etapa' );
    }
  } else if( props.type === MoneyTypes.Debit ){
    
    if( props.step === StepBlock.Pago ){
      
      if( cartPayment.payment.debito[ props.index ] ){
        auxItemVoucher = cartPayment.payment.debito[ props.index ].voucher;
        if( cartPayment.payment.debito[ props.index ].amount !== 0 ){
          auxItemAmount = cartPayment.payment.debito[ props.index ].amount;
        }
      } else {
        console.log( 'no rezoconco el id de debito' );
      }
    } else {
      console.log( 'No se reconoce la etapa' );
    }

  } else {
    console.log( 'No se reconoce el tipo de tarjeta' );
  }


  const handleVoucher = ( auxValue:string ) => {
    if( setVoucherValues ){
      setVoucherValues( {step: props.step, type: props.type, value: auxValue, index: props.index} );
    }
  }

  const handleAmount = ( auxValue:string ) => {
    if( setMoneyValues ){
      setMoneyValues( {step: props.step, type: props.type, value: auxValue, index: props.index} );
    }
  }

  return (
    <div className="cardInputItem">
      <Grid container>
        <Grid item xs={12} sm={5}>
          <TextField label="Monto (CLP)" value={ auxItemAmount } onChange={ (event) => {
            handleAmount( event.target.value );
          }} InputProps={{ inputComponent: NumberFormatPesos, }} variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField value={ auxItemVoucher } label="Cod. Autorización" variant="outlined" onChange={ (event:any) => { handleVoucher( event.target.value ); }} />
        </Grid>
        <Grid item xs={12} sm={1}>
          <IconButton aria-label="eliminar" size="medium" onClick={ () => { removeCard && removeCard({ type: props.type, step: props.step, itemIndex: props.index }); } } >
            <RemoveCircleIcon color="secondary" />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={1}>
          <IconButton aria-label="agregar" size="medium" onClick={ () => { addCard && addCard({ type: props.type, step: props.step }); } } >
            <AddCircleIcon style={{ color: color.primary.green }} />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  )

}

export const CreditItem = (props: cardBaseActionsProps) => {
  return <CardItem type={MoneyTypes.Credit} step={props.step} {...props} />
}

export const DebitItem = (props: cardBaseActionsProps) => {
  return <CardItem type={MoneyTypes.Debit} step={props.step} {...props} />
}