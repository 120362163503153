export enum InitDataResponseCode {
  init1001= 'init-1001',
  init2002= 'init-2002',
  init2003= 'init-2003',
}

export enum ActiveCashRegisterResponseCode {
  active1001= 'id-1001',    // No cash register data
  active1002= 'id-1002',    // No cash register today
  active2002= 'id-2002',    // Cash register active
  active2003= 'id-2003',    // Cash register active
}

export enum WSResponseCode {
  fail= 'rejected',
  success= 'ok',
}

export enum FormItemZone {
  Common  = 'common',
  Actions = 'actions',
}