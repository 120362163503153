import React, {useState, useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid, TextField, FormControl, Divider, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import useFetch, { baseApiURL } from '../../_services/fetch';
import BaseButton from '../common/BaseButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import useErrorManager from '../common/ErrorManager';
import { errorSectionType, errorType } from '../../_helpers/enums/all';
import LinearProgress from '@material-ui/core/LinearProgress';
import { kitInfoProps, setTotalKitsPresaleProps, infoKitPresaleProps } from '../../_helpers/props/all';
import PresaleItem from './PresaleItem';
import SyncIcon from '@material-ui/icons/Sync';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { post } from '../../_services/api';

const PresaleNew = () => {
  let history = useHistory();
  const { response, loading, error } = useFetch({ method: 'GET', path: '/get/presales/new'});
  const { alertBlock, showAlert } = useErrorManager({ id: 'presale_new', section: errorSectionType.Custom });
  
  
  const [ paxName, setPaxName ] = useState<string>( '' );
  const [ paxNameError, setPaxNameError] = useState<boolean>( false );
  const [ paxFile, setPaxFile ] = useState<string>( '' );
  const [ paxMail, setPaxMail ] = useState<string>( '' );
  const [ validMail, setValidMail ] = useState<string>( '' );

  const [ kitError, setKitError ] = useState<boolean>( false );
  const [ kits, setKits ] = useState<kitInfoProps[]>( [] );
  const [ kitForm, setKitForm ] = useState<React.ReactNode>( null );

  const [ disableButton, setDisableButton ] = useState<boolean>( false );

  const setTotalValue = ( props:setTotalKitsPresaleProps ) => {
    let infoKitsData:kitInfoProps[] = [];
    let variantExist = false;
    let auxInfoKitLength = kits.length;
    for( let i=0; i<auxInfoKitLength; i++ ){
      infoKitsData.push( kits[i] );
      
      if( kits[i].name === props.product_name ){
        console.log( 'lo encontré' );


        // Buscamos la variant
        let sizeLength = kits[i].sizes.length;
        for( let i_v= 0; i_v<sizeLength; i_v++){
          if( kits[i].sizes[i_v].variation_id === props.variant_id ){

            if( infoKitsData[i].sizes[i_v].name === 'Adulto' ){
              if( props.action === 'add' ){
                setKitError( false );
                infoKitsData[i].totalAdult = ( infoKitsData[i].totalAdult+1 );
              } else {
                if( infoKitsData[i].totalAdult ){
                  infoKitsData[i].totalAdult = ( infoKitsData[i].totalAdult-1 );
                }
              }
            } else if( infoKitsData[i].sizes[i_v].name === 'Junior' ){
              if( props.action === 'add' ){
                setKitError( false );
                infoKitsData[i].totalJunior = ( infoKitsData[i].totalJunior+1 );
              } else {
                if( infoKitsData[i].totalJunior ){
                  infoKitsData[i].totalJunior = ( infoKitsData[i].totalJunior-1 );
                }
              }
            }

            break;
          }
        }
         
      }
    }

    setKits( infoKitsData );

    /*if( !variantExist ){
      console.log( 'agrega el kit' );
      infoKitsData.push({
        variant_id: props.variant_id,
        q: 1,
      });
    }*/

  }
  // Revisar permiso
  // Encontrar los productos

  useEffect( () => {
    if( kits.length !== 0 ){
      let auxKits = kits.map( (item:kitInfoProps, index:number) => {
        let auxVariantAdult = 0;
        let auxVariantJunior = 0;

        for( let i=0; i<item.sizes.length; i++ ){
          // console.log( 'hola' );
          if( item.sizes[i].name === 'Adulto' ){
            auxVariantAdult = item.sizes[i].variation_id;
          } else if( item.sizes[i].name === 'Junior' ){
            auxVariantJunior = item.sizes[i].variation_id;
          }
        }

        return <PresaleItem key={index} {...item} setTotal={setTotalValue} variant_adult={auxVariantAdult} variant_junior={auxVariantJunior} />
      });

      setKitForm( auxKits );
    }
  }, [kits] );

  useEffect( () => {
    if( response ){
      let infoKit = response.data;
      setKits( infoKit );
    }
  }, [response] );

  useEffect( () => {
    if( error ){
      showAlert({
        message: 'Hubo un error al cargar la información, por favor recarga la página ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }
  }, [error] );

  const handleCreatePresale = () => {
    let auxValidForm = true;
    setDisableButton( true );

    if( paxName === '' ){
      auxValidForm = false;
      setPaxNameError( true );
      showAlert({
        message: 'Falta ingresar el nombre del pasajero',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }

    // Validamos si existen productos agregados
    let auxInfoKitLength = kits.length;
    let auxTotales = true;
    for( let i=0; i<auxInfoKitLength; i++ ){
      if( kits[i].totalAdult !== 0 || kits[i].totalJunior !== 0 ){
        auxTotales = false;
        break;
      }
    }
    if( auxTotales ){
      auxValidForm = false;
      showAlert({
        message: 'Debes agregar al menos un kit para crear la preventa',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
      setKitError( true );
    }

    // Validar email
    if( validMail !== '' ){
      auxValidForm = false;
      showAlert({
        message: 'Debes ingresar un correo válido',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }



    if( auxValidForm ){
      
      let createPresale_response = post( baseApiURL+'/post/presales/new', {name: paxName, file: paxFile, mail: paxMail, kits });

      createPresale_response.then((response:any) => {
        history.push( '/presale?newpresale='+response.data );
      }).catch( error => {
        setDisableButton( false );
        showAlert({
          message: 'Hubo un error al registrar el voucher, por favor actualiza y vuelve a intentarlo. ['+error+']',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      });

    } else {
      setDisableButton( false );
    }

  }

  const handleMail = ( email:string ) => {
    setPaxMail( email );

    if( email === '' ){
      setValidMail( '' );
    } else {
      // don't remember from where i copied this code, but this works.
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if ( re.test(email) ) {
          // this is a valid email address
          // call setState({email: email}) to update the email
          // or update the data in redux store.
          setValidMail( '' );
      }
      else {
          // invalid email, maybe show an error to the user.
          setValidMail( 'Debes ingresar un correo válido.' );
      }
    }
  }
  
  
  return (
    loading ? <LinearProgress color="primary" /> :
      <div className="content_all">
        <div className="content">
          { alertBlock }

          {
            !error ?
              <div className="cash-register-box">
                <Paper className="fullwidth">
                  <Grid container className="cash-register-container form-container-common">
                    <Grid item xs={12} sm={6} md={8} className="cash-register-content">
                      <h1 className="form-title">Crear nueva preventa</h1>
                      <div className="subtitle">
                        Agrega la información del pasajero que utilizará esta preventa, si agregas su correo le compartiremos la información del ticket con el código para utilizarlo en caja.
                      </div>

                      <div className="form-detail-big">
                        
                        <FormControl fullWidth className="form-item">
                          <TextField
                              label="Nombre pasajero"
                              value={ paxName }
                              helperText="Ingresa el nombre del pasajero que utilizará el voucher"
                              variant="outlined"
                              onChange={(event:any) => { setPaxName(event.target.value); setPaxNameError( false );  } }
                              required={ true }
                              error={ paxNameError }
                            />
                        </FormControl>

                        <FormControl fullWidth className="form-item">
                          <TextField
                              label="File / record"
                              value={ paxFile }
                              helperText="Ingresa el código identificador del pasajero"
                              variant="outlined"
                              onChange={(event:any) => { setPaxFile(event.target.value)  } }
                            />
                        </FormControl>

                        <FormControl fullWidth className="form-item">
                          <TextField
                              label="Email"
                              value={ paxMail }
                              helperText={ validMail === '' ? "Ingresa el correo del pasajero para que reciba el comprobante de pago" : validMail }
                              error={ validMail !== '' ? true : false }
                              variant="outlined"
                              onChange={(event:any) => { handleMail( event.target.value );  } }
                            />
                        </FormControl>

                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className="cash-register-sidebar" >
                      <div className="cash-register-sidebar-info info-data">
                        
                        <div className="cash-register-sidebar-item">
                          <div className="cash-register-sidebar-item-title">
                            Selección de productos
                          </div>
                          <div className="cash-register-sidebar-item-detail">
                            
                            <div className="sidebar-item-box ">
                              <div className={"sidebar-item-detail item-form "+ ( kitError ? 'sidebar-item-box-error' : '' )}>
                                Selecciona los productos que se incluyen en el esta preventa:
                                <br /><br />
                                <Divider />

                                <ul className="sidebar-item-detail-item">
                                  { kitForm !== null ?
                                      kitForm
                                    :
                                      <React.Fragment>
                                        <div className="empty-value">
                                          <em>No logramos cargar la información de los kit disponibles</em>
                                          <br />
                                          <br />
                                          <Button color="primary" variant="contained"><SyncIcon /> Volver a cargar</Button>
                                        </div>
                                      </React.Fragment>
                                  }
                                </ul>
                              </div>
                            </div>
                            
                          </div>
                        </div>

                        
                      </div>
                      <div className="cash-register-sidebar-actions">
                        <BaseButton className="btn btn-sidebar-action btn-dubra" onClick={handleCreatePresale} disabled={disableButton} loading={disableButton}>
                          Crear preventa <ChevronRightIcon />
                        </BaseButton>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            :
              null
          }
      </div>
    </div>
    


  )
}

export default PresaleNew;