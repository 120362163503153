import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history'


import Router from './_router/router';
import AuthProvider from './_auth/auth_provider';
import TabsProvider from './components/common/tabs/tabs_provider';
import ErrorProvider from './components/common/errorCenter';

import * as serviceWorker from './serviceWorker';

import { ThemeProvider } from '@material-ui/styles';
import store from './style/theme';
import './index.css';
import './style/main.scss'
import CssBaseline from '@material-ui/core/CssBaseline';

import MainContainer from './components/common/MainContainer'
require('./assets/all/dubra_list.png');


const history = createBrowserHistory();

const routing = (
    <React.Fragment>
			<BrowserRouter>
				<ErrorProvider>
					<AuthProvider>
						<TabsProvider>

							<ThemeProvider theme={store}>
								<MainContainer history={history}>
									<CssBaseline />
									<Router />
								</MainContainer>
							</ThemeProvider>

						</TabsProvider>
					</AuthProvider>
				</ErrorProvider>
			</BrowserRouter>
		</React.Fragment>
)
ReactDOM.render(routing, document.getElementById('root'))


//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
