import React, { useState, useEffect } from 'react';
import BaseButton from '../../common/BaseButton';
import { useCartDataContext } from '../cart_provider';
import { useObjectProps } from '../../../_helpers/props/cash_register_props';

interface PresaleItemProps {
  data: any,
  indexValue: number,
  indexPresale: number,
  indexPresalePosition: number,
  used: boolean,
}

const PreSaleItem = (props: PresaleItemProps) => {
  const { cart, cartPayment, arriendoProducts, setPreSaleValues } = useCartDataContext();
  const [ used, setUsed ] = useState( false );

  useEffect( () => {
    if( cartPayment.payment.pre_sale[ props.indexPresalePosition ] ){
      if( cartPayment.payment.pre_sale[ props.indexPresalePosition ].use ){
        let itemUse = cartPayment.payment.pre_sale[ props.indexPresalePosition ].use;

          const indexLength = cartPayment.payment.pre_sale[ props.indexPresalePosition ].use.length;

          for( let i = 0; i < indexLength; i++ ){
            if( itemUse[i].variation_id === props.indexPresale ){
              if( itemUse[i].item_position.includes( props.indexValue ) ){
                setUsed( true );
              }
              break;
            }
          }
        
      }
    }
  }, [cartPayment] );

  const handleUnUse = () => {
    var preUse = cartPayment.payment.pre_sale[ props.indexPresalePosition ].use;
    const indexLength = preUse.length;

    for( let i = 0; i < indexLength; i++ ){
      if( preUse[i].variation_id === props.indexPresale ){
        let auxItemLength = preUse[i].item_position.length;
        for( let i_item = 0; i_item < auxItemLength; i_item++ ){
          if( preUse[i].item_position[i_item] === props.indexValue ){
            // Encontramos el elemento a quitar
            setUsed( false );
            if( i_item === 0 ){
              // Aplicamos shift
              preUse[i].item_position.shift();
            } else {
              // Aplicamos splice
              preUse[i].item_position.splice( i_item, 1 );
            }
            break; 
          }
        }

        break;
      }
    }

    setPreSaleValues && setPreSaleValues({ use: preUse, itemIndex: props.indexPresalePosition, product_id: props.data.product });
  }

  const handleUse = () => {
    var auxDetected = false;
    var auxDetectedAux = false;
    var cartProductsLength = cart.cart_items.length;
    let itemUse = cartPayment.payment.pre_sale[ props.indexPresalePosition ].use;
    let indexLength = itemUse ? itemUse.length : 0;
    let auxPrice = 0;
    var auxTope = false;

    for(let i = 0; i < cartProductsLength; i++ ){
      //console.log( 'eooo', cart.cart_items[i], props.data );
      if( cart.cart_items[i].product_id === parseInt(props.data.product) ){


        console.log('ppp', props.data, arriendoProducts?.products, cart.cart_items[i]);

        // Revisamos los dias
        if( cart.cart_items[i].days === 0 ){
          alert( 'No tiene dias activos!' );
        } else {
          if( props.data.type === 'Junior' ){
            if( cart.cart_items[i].junior !== 0 ){
              auxDetected = true;
  
              // Verificamos si estamos agregando un producto adicional a los que hay en el carro
              for( let i_use = 0; i_use < indexLength; i_use++ ){
                if( itemUse[i_use].variation_id === props.indexPresale ){
                  if( itemUse[i_use].item_position.length  >=  cart.cart_items[i].junior! ){
                    auxTope = true;
                    auxDetected = false;
                    auxDetectedAux = true;
                    alert( 'No existen más productos en el carro para utilizar pre venta.' ); 
                  }
                  break;
                }
              }
  
              if( !auxTope ){
                // Debo encontrar el tamaño correspondiente
                let auxItemsSizes:number = cart.cart_items[i].sizes.length;
                for(var i_size=0;i_size<auxItemsSizes; i_size++){
                  if( cart.cart_items[i].sizes[i_size].name === "Junior" ){

                    console.log(':::J:', cart.cart_items[i]);
                    console.log( 'productos:', arriendoProducts );
                    // Encontramos el producto en arriendo
                    var auxArriendoCount:number = arriendoProducts?.products.length;
                    for(var i_arriendo_count = 0; i_arriendo_count < auxArriendoCount; i_arriendo_count++ ){
                      if( arriendoProducts?.products[i_arriendo_count].product_id == cart.cart_items[i].product_id ){

                        var auxArriendoPrice = arriendoProducts?.products[i_arriendo_count].detail.Junior.price;
                        auxPrice = parseInt( auxArriendoPrice.replace('.', '') );
                        break;
                      }
                    }

                    // Debo recuperar el valor original, no el almacenado en el carro
                    //auxPrice = cart.cart_items[i].sizes[i_size].price;
                    break;
                  }
                }

              }
            }
          } else if( props.data.type === 'Adulto' ){

            console.log( '----' );
            
            if( cart.cart_items[i].adults !== 0 ){
              auxDetected = true;
              
              for( let i_use = 0; i_use < indexLength; i_use++ ){
                if( itemUse[i_use].variation_id === props.indexPresale ){
                  if( itemUse[i_use].item_position.length  >=  cart.cart_items[i].adults! ){
                    auxTope = true;
                    auxDetected = false;
                    auxDetectedAux = true;
                    alert( 'No existen más productos en el carro para utilizar pre venta.' ); 
                  }
                  break;
                }
              }
              
              if( !auxTope ){
                //auxPrice = arriendoProducts?.products[ props.data.product ].detail.Adulto.price;
                // Debo encontrar el tamaño correspondiente
                let auxItemsSizes:number = cart.cart_items[i].sizes.length;
                for(var i_size=0;i_size<auxItemsSizes; i_size++){
                  if( cart.cart_items[i].sizes[i_size].name === "Adulto" ){

                    console.log(':::A:', cart.cart_items[i]);
                    console.log( 'productos:', arriendoProducts );
                    // Encontramos el producto en arriendo
                    var auxArriendoCount:number = arriendoProducts?.products.length;
                    for(var i_arriendo_count = 0; i_arriendo_count < auxArriendoCount; i_arriendo_count++ ){
                      if( arriendoProducts?.products[i_arriendo_count].product_id == cart.cart_items[i].product_id ){
                        console.log( arriendoProducts?.products[i_arriendo_count] );

                        var auxArriendoPrice = arriendoProducts?.products[i_arriendo_count].detail.Adulto.price;
                        auxPrice = parseInt( auxArriendoPrice.replace('.', '') );
                        break;
                      }
                    }

                    // Debo recuperar el valor original, no el almacenado en el carro
                    //auxPrice = cart.cart_items[i].sizes[i_size].price;
                    break;
                    /*
                    var auxTotalQ:number = 1;
                    if( cart.cart_items[i].adults ){
                      auxTotalQ = cart.cart_items[i].adults!;
                    }
                    auxPrice = cart.cart_items[i].sizes[i_size].price / auxTotalQ;
                    */
                    
                    break;
                  }
                }
              }
            }
          } else {
            console.log( '[Agregar presale]: Tipo de producto no reconocido' );
          }

          console.log('~~', auxPrice);
          console.log( 'presale', props );
  
  
          if( auxDetected ){
            var preUse:Array<useObjectProps>;
            if( cartPayment.payment.pre_sale[ props.indexPresalePosition ].use ){
              preUse = cartPayment.payment.pre_sale[ props.indexPresalePosition ].use;
            } else {
              preUse = [];
            }
    
            // Verificamos si existe
            if( auxPrice !== undefined ){
              let auxUseLength  = preUse.length;
              let auxUseExist = false;
    
              for(let i_use = 0; i_use < auxUseLength ; i_use++ ){
                if( preUse[i_use].variation_id === props.indexPresale ){
                  preUse[i_use].item_position.push( props.indexValue );
                  auxUseExist = true;
                  break;
                }
              }
      
              if( !auxUseExist ){
                preUse.push({
                  product_id: parseInt( props.data.product ),
                  variation_id: props.indexPresale,
                  item_position: [props.indexValue],
                  price: auxPrice,
                });
              }
            } else {
              console.log( 'No pudimos encontrar el precio del descuento' );
            }
    
            setPreSaleValues && setPreSaleValues({ use: preUse, itemIndex: props.indexPresalePosition  });
          }
        }
        break;
      }
    }

    if( !auxDetected && !auxDetectedAux ){
      alert( 'Este producto no está agregado en el carro!' );
    }
  }

  return (
    <li className={"presale-product-item " + (props.used ? 'used' : '' )} style={ {display: 'flex',} }>
      <span className="presale-product-item-name">
        { props.data.name }
      </span>
      <span className="presale-product-item-action" style={ {marginLeft: 'auto',} }>
        { props.used ?
            'Usado'
          :
            used ?
              <BaseButton className="btn-dubra" onClick={handleUnUse}>Quitar</BaseButton>
            :
              <BaseButton className="btn-dubra" onClick={handleUse}>Utilizar</BaseButton>
        }
      </span>
    </li>
  );
}

export default PreSaleItem;