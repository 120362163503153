import React, {useState, useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid, TextField, FormControl, Divider, FormHelperText } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useErrorManager from '../../common/ErrorManager';

import { baseApiURL } from '../../../_services/fetch';
import { errorSectionType, errorType } from '../../../_helpers/enums/all';
import BaseButton from '../../common/BaseButton';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { post } from '../../../_services/api';

import { DateTime } from 'luxon';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

interface reservationsFormProps {
  response    : any,
  error       : any,
}

const ReservationsAssignForm = ( props:reservationsFormProps ) => {
  let history = useHistory();
  const { response, error } = props;
  const { showAlert } = useErrorManager({ id: 'reservations_assign_form', section: errorSectionType.Custom });

  const [ disableButton, setDisableButton ]           = useState<boolean>( false );
  const [ date, setDate ]                             = useState<DateTime | null>( null );
  const [ cashRegister, setCashRegister ]             = useState<string>( '' );
  const [ cashRegisters, setCashRegisters ]           = useState<React.ReactNode[]>([]);
  const [ cashRegisterError, setCashRegisterError ]   = useState<string>( '' );
  const [ paxInStore, setPaxInStore ]                 = useState<number>( 0 );
  const [ paxError, setPaxError ]                     = useState<string>( '' );
  const [ reservationsId, setReservationsId ]         = useState<string>( '' );
  const [ agency, setAgency ]                         = useState<string>( '-' );
  const [ qPax, setQPax ]                             = useState<string>( '-' );
  const [ tour, setTour ]                             = useState<string>( '-' );
  const [ time, setTime ]                             = useState<string>( '-' );
  const [ touristGuide, setTouristGuide ]             = useState<string>( '-' );

  useEffect( () => {
    if( response ){
      setCashRegisters([]);

      //console.log( response.data );
      if( response.data.id ){
        setReservationsId( response.data.id );
      }
      if( response.data.date ){
        setDate( DateTime.fromISO( response.data.date, { locale: "es-CL" } ) );
      }
      if( response.data.cashregisters ){
        let auxCashRegisters:React.ReactNode[] = [ <MenuItem key={0} value=""><em>Selecciona la caja</em></MenuItem> ]; 
        setCashRegister( '' );
        let auxCashRegistersLength = response.data.cashregisters.length;

        for( let i=0; i<auxCashRegistersLength; i++ ){
          //console.log( 'checkkkk', ':', response.data.cashregisters[i] );
          //auxCashRegisters.push( <MenuItem key={i+1} value={ response.data.cashregisters[i].id }>{ response.data.cashregisters[i].name } - <em>{ response.data.cashregisters[i].cashier }</em></MenuItem> );
          auxCashRegisters.push( <MenuItem key={i+1} value={ response.data.cashregisters[i].cash_register }>{ response.data.cashregisters[i].name } - <em>{ response.data.cashregisters[i].cashier_name }</em></MenuItem> );
        }

        //console.log( auxCashRegisters,' ******' );

        setCashRegisters( auxCashRegisters );
      }
      if( response.data.agency ){
        setAgency( response.data.agency );
      }
      if( response.data.tour && response.data.tour !== '' ){
        setTour( response.data.tour );
      }
      if( response.data.q_pax && response.data.q_pax !== '' ){
        setQPax( response.data.q_pax );
      }
      if( response.data.tourism_guide && response.data.tourism_guide !== '' ){
        setTouristGuide( response.data.tourism_guide );
      }
      if( response.data.time && response.data.time !== '' ){
        setTime( response.data.time );
      }
      
      

    }
  }, [response] );

  useEffect( () => {
    if( error ){
      showAlert({
        message: 'Hubo un error al cargar la información, por favor recarga la página ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }
  }, [error] );

  const handleAssignReservation = () => {
    let auxValidForm = true;
    setDisableButton( true );

    if( paxInStore === 0 ){
      auxValidForm = false;
      setPaxError( 'Debes agregar la cantidad de pasajeros que llegaron en el tour.' );
      showAlert({
        message: 'Es obligatorio ingresar la cantidad de pasajeros recibidos.',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }

    if( cashRegister === '' ){
      auxValidForm = false;
      setCashRegisterError( 'Debes seleccionar la caja a la que se asignará esta atención.' );
      showAlert({
        message: 'Es obligatorio seleccionar la caja de atención.',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }

    if( auxValidForm ){
      let createReservations_response = post( baseApiURL+'/post/reservations/assign', {
        uuid: reservationsId,
        cash_register: cashRegister, 
        pax_in_store: paxInStore,
      });

      createReservations_response.then((response:any) => {
        history.push( '/reservations?assigned=true' );
      }).catch( error => {
        setDisableButton( false );
        showAlert({
          message: 'Hubo un error al registrar la reserva, por favor actualiza y vuelve a intentarlo. ['+error+']',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      });

    } else {
      setDisableButton( false );
    }

  }

  return (
    <div>
      {
            !error ?
              <div className="cash-register-box">
                <Paper className="fullwidth">
                  <Grid container className="cash-register-container form-container-common">
                    <Grid item xs={12} sm={6} md={8} className="cash-register-content">
                      <h1 className="form-title">
                        Reserva: {date?.weekdayLong} {date?.day} de {date?.monthLong}, {date?.year}
                      </h1>
                      <div className="form-detail-big">
                        
                        <FormControl variant="outlined" fullWidth className="form-item" error={ cashRegisterError === '' ? false : true } >
                          <InputLabel id="cash-register_value">
                            Caja de atención
                          </InputLabel>
                          <Select
                            labelId="cash-register_value"
                            required={true}
                            value={ cashRegisters.length === 0 ? 'null' : cashRegister }
                            disabled={ cashRegisters.length === 0 ? true : false }
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                              setCashRegisterError( '' );

                              if( typeof event.target.value !== 'string' ){
                                setCashRegister('');
                                console.log(event.target.value);
                              } else {
                                setCashRegister( event.target.value );
                              } 
                            }}
                          >
                            { cashRegisters.length === 0 ?
                                <MenuItem key={0} value={'null'}><em>No hay cajas abiertas</em></MenuItem>
                              :
                                cashRegisters
                            }
                          </Select>
                          <FormHelperText>
                            { cashRegisterError === '' ?
                                "Escoge la caja que atenderá a este grupo"
                              :
                                cashRegisterError
                            }
                          </FormHelperText>
                        </FormControl>

                        <FormControl fullWidth className="form-item">
                          <TextField
                              label="Cantidad de pasajeros"
                              value={paxInStore !== 0 ? paxInStore: ''}
                              helperText={ paxError !== '' ? paxError : "Ingrese la cantidad de pasajeros que llegaron a la tienda" }
                              variant="outlined"
                              error={ paxError !== '' ? true : false }
                              onChange={(event:any) => {
                                const re = /^[0-9\b]+$/;
                                // if value is not blank, then test the regex

                                if (event.target.value === '' || re.test(event.target.value)) {
                                  setPaxError( '' );
                                  if( event.target.value === '' ){
                                    setPaxInStore( 0 );
                                  } else {
                                    setPaxInStore( event.target.value );
                                  }
                                }
                              }}
                              required={true}
                            />
                        </FormControl>

                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className="cash-register-sidebar" >
                      <div className="cash-register-sidebar-info info-data">
                        
                        <div className="cash-register-sidebar-item">
                          <div className="cash-register-sidebar-item-detail">
                            
                            <div className="sidebar-item-box ">
                              <div className="sidebar-item-title item-form">
                                Información de la reserva
                              </div>
                              <div className="sidebar-item-detail item-form">
                                Estos son los detalles de la reserva
                                <br /><br />
                                <Divider />
                                <ul>
                                  <li><strong>Fecha reserva:</strong>       { date?.toFormat("dd'/'LL'/'y") }     </li>
                                  <li><strong>Turno:</strong>               { time }                              </li>
                                  <li><strong>Tour:</strong>                { tour }                              </li>
                                  <li><strong>Cantidad pasajeros:</strong>  { qPax }                              </li>
                                  <li><strong>Agencia:</strong>             { agency }                            </li>
                                  <li><strong>Guía:</strong>                { touristGuide }                      </li>
                                </ul>
                              </div>
                            </div>
                            
                          </div>
                        </div>

                        
                      </div>
                      <div className="cash-register-sidebar-actions">
                        <BaseButton className="btn btn-sidebar-action btn-dubra" onClick={handleAssignReservation} disabled={disableButton} loading={disableButton}>
                          Asignar caja <ChevronRightIcon />
                        </BaseButton>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            :
              null
          }
    </div>
  )
}

export default ReservationsAssignForm;