import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import BaseButton from './BaseButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { errorSectionType, errorType } from '../../_helpers/enums/all';
import useErrorManager from './ErrorManager';
import { baseApiURL } from '../../_services/fetch';
import { post } from '../../_services/api';

interface confirmationBoxProps {
  active            : boolean,
  title             : string|React.ReactNode|React.ReactNode[],
  children          : string|React.ReactNode|React.ReactNode[],
  
  cancelText       ?: string,
  closeAction     (): void,

  sendText         ?: string,
  sendUrl           : string,
  sendProps        ?: object,
  resolvedAction  (): void,
}

const ConfirmationBox = ( props:confirmationBoxProps ) => {
  const { alertBlock, showAlert, resetAlerts } = useErrorManager({ id: 'guarantee_modal', section: errorSectionType.Custom });
  const [ loadingSend, setLoadingSend ]         = useState<boolean>( false );


  const handleCancel = () => {
    resetAlerts();
    props.closeAction();
  }

  const handleSend = () => {
    setLoadingSend( true );
    let guaranteeApproval_response = post( baseApiURL+props.sendUrl, ( props.sendProps ? props.sendProps : {} ) );

    guaranteeApproval_response.then((response:any) => { 
      setLoadingSend( false );
      props.resolvedAction();
    })
    .catch( error => {
      setLoadingSend( false );
      showAlert({
        message: 'Hubo un problema al gurdar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });
  }
  

  return (
    <Dialog
      open={props.active}
      className="guarantee-box"
      onClose={handleCancel}
    >
      <DialogTitle id="alert-dialog-title">
        { props.title }
      </DialogTitle>
      <DialogContent className="guarantee-box-content">
        { alertBlock }
        <DialogContentText id="alert-dialog-description">
          { props.children }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <BaseButton onClick={() => { handleCancel() }} disabled={loadingSend}>
          { props.cancelText ?
              props.cancelText
            :
              'Cancelar'
          }
        </BaseButton>
        <BaseButton onClick={() => { handleSend() }} disabled={loadingSend} >
          { loadingSend ?
              <CircularProgress />
            :
              props.sendText ?
                props.sendText
              :
                'Enviar'
          }
        </BaseButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationBox;