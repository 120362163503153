import React, { createContext, useState, useEffect, useContext } from "react";
import { SaleProps, SaleOrderProps, SalePaymentProps, CartProviderProps, ItemInCartProps, ItemValueInCartProps, MoneyValues, cardItemActionsProps, preSaleProps, sizeElementProps, productNameProps, dubraCreditProps } from '../../_helpers/props/cash_register_props';
import {ProductItem} from './cart/ProductItem';
import { ProductsProps } from "../../_helpers/props";
import { get } from '../../_services/api';
import { baseApiURL } from '../../_services/fetch';
import ProductItemMini from './cart/ProductItem';
import { StepBlock, MoneyTypes } from '../../_helpers/enums/cash_register';
import { productDetailProps } from '../../_helpers/props/cash_register_props';
import ProductBox from './cart/modal/ProductBox';


const order_props_default:SaleOrderProps = {
  order_id: 0,
  cash_register: 0,
  name: '',
  q_adultos: 0,
  q_ninos: 0,
  agencia: 0,
  guia: 0,
}

const payments_props_default:SalePaymentProps = {
  garantia: {
    state: 'normal',
    total: 0,
    credito: [],
  },
  tc: {},
  payment: {
    total: {
      total: 0,
      subtotal: 0,
      vuelto: 0,
      arriendo: 0,
      venta: 0,
    },
    debito: [],
    credito: [],
    pre_sale: [],
    dubra: {
      request_auth: '',
      selected_reason: '',
    },
    discount: {
      amount: 0,
      name: '',
    }
  },
}

const sale_props_default:SaleProps = {
  cart_items: [],
}

const cart_data_default:CartProviderProps = {
  cart: sale_props_default,
  setCart: () => {},

  cartOrder: order_props_default,
  setCartOrder: () => {},
  cartPayment: payments_props_default,
  setCartPayment: () => {},


  loadingCart: false,
  setLoadingCart: () => {},
  cartProducts: [],
  addCartProduct: ()  => {},
  addAdult: () => {},
  minusAdult: () => {},
  addJunior: () => {},
  minusJunior: () => {},
  changeOptions: [],
  getProductName: () => { return 'b' },


  modalTitle: '',
  modalActive: false,
  modalContent: null,
  setModalActive: () => {},

  resetCart: () => {},
}

export const CartContext = createContext( cart_data_default );
export const useCartDataContext = () => useContext(CartContext);

const getSumPrice = ( price:number, q:number, days:number, extra:number ) => {
  var aux_precio_final = price;
	var total_amount = price * q * days;

  if( days === 0 ){
    aux_precio_final = 0;
  } else if( days === 1 ){
    aux_precio_final = total_amount;
  } else if( days === 2 ){
    // 20%
    aux_precio_final = total_amount * .8 ;

  } else if( days === 3 ){
    // 30%
    aux_precio_final = total_amount * .7 ;

  } else if( days === 4 ){
    // 35%
    aux_precio_final = total_amount * .65 ;

  } else if( days === 5 ){
    // 40%
    aux_precio_final = total_amount * .6 ;
    
  } else if( days === 6 ){
    // 45%
    aux_precio_final = total_amount * .55 ;
    
  } else if( days === 7 ){
    // 50%
    aux_precio_final = total_amount * .5 ;
    
  } else {
    // 50%
    var diff_days = days - 7;
    //alert( diff_dias * adicional );
    aux_precio_final = (price * q * 7) * .5 + (diff_days * extra * q) ;

  }

  var aux_round = Math.ceil( aux_precio_final/1000 );
  //alert( aux_round );
  return aux_round*1000;
}

const CartProvider = (props:any) => {
  const [ cart, setCart ] = useState<SaleProps>( sale_props_default );
  const [ cartOrder, setCartOrder ] = useState<SaleOrderProps>( order_props_default );
  const [ cartPayment, setCartPayment ] = useState<SalePaymentProps>( payments_props_default );
  

  const [ loadingCart, setLoadingCart ] = useState<boolean>( cart_data_default.loadingCart );
  const [ cartProducts, setCartProducts ] = useState<React.ReactChild|React.ReactChild[]>();
  const [ itemToCart, addCartProduct ] = useState<ItemInCartProps|undefined>();
  const [ arriendoProducts, setArriendoProducts ] = useState<ProductsProps>({
    status    : 'pending',
    loading   : false,
    items     : '',
  });
  const [ ventaProducts, setVentaProducts ] = useState<ProductsProps>({
    status    : 'pending',
    loading   : false,
    items     : '',
  });
  
  //const [ guaranteeData, setGuaranteeData ] = useState<string>(  );
  const [ changeOptions, setChangeOptions ] = useState<Array<any>>( [] );
  
  const [ modalTitle, setModalTitle ] = useState<string>( cart_data_default.modalTitle );
  const [ modalActive, setModalActive ] = useState<boolean>( cart_data_default.modalActive );
  const [ modalContent, setModalContent ] = useState<React.ReactNode | null >( cart_data_default.modalContent );

  /*
  const getPaid = () => {
    if( cartPayment.payment.pesos || cartPayment.payment.real || cartPayment.payment.dolar || cartPayment.payment.credito || cartPayment.payment.debito ){
      
      let totalPesos = 0;
      if( cartPayment.payment.pesos ){
        totalPesos = Number( cartPayment.payment.pesos );
      }
      
      let totalDolar = 0;
      if( cartPayment.tc.dolar && cartPayment.payment.dolar ){
        totalDolar = cartPayment.tc.dolar * cartPayment.payment.dolar;
      }

      let totalReal = 0;
      if( cartPayment.tc.real && cartPayment.payment.real ){
        totalReal = cartPayment.tc.real * cartPayment.payment.real;
      }

      let totalCredito = 0;
      if( cartPayment.payment.credito ){
        const auxCreditoLength = cartPayment.payment.credito.length;

        if( auxCreditoLength !== 0 ){
          for( let i = 0; i < auxCreditoLength; i++ ){
            if( cartPayment.payment.credito[i] ){
                totalCredito += Number( cartPayment.payment.credito[i].amount );
            }
          }
        }
      }

      let totalDebito = 0;
      if( cartPayment.payment.debito ){
        const auxDebitoLength = cartPayment.payment.debito.length;

        if( auxDebitoLength !== 0 ){
          for( let i = 0; i < auxDebitoLength; i++ ){
            if( cartPayment.payment.debito[i] ){
              totalDebito += Number( cartPayment.payment.debito[i].amount );
            }
          }
        }
      }

      let totalPresale = 0;
      if( cartPayment.payment.pre_sale ){
        const auxPresaleLength = cartPayment.payment.pre_sale.length;

        if( auxPresaleLength !== 0 ){
          for( let i = 0; i < auxPresaleLength; i++ ){
            if( cartPayment.payment.pre_sale[i] ){
              totalPresale += Number( cartPayment.payment.pre_sale[i].amount );
            }
          }
        }
      }

      let auxTotalCalculated = cartPayment.payment.total.total - totalPesos - totalDolar - totalReal - totalCredito - totalDebito - totalPresale;
      console.log( '***', auxTotalCalculated );

    } else {
      console.log( 'Algo falló' );
    }
  }
  */
  
  const getTotal = () => {
    var auxPayments = cartPayment.payment;
    //console.log('******', auxPayments);
    var auxCartItems = cart.cart_items;

    var aux_total = 0;
    var aux_subtotal = 0;
    var aux_total_arriendo = 0;
    var aux_total_venta = 0;
    var aux_total_garantia = 0;

    let aux_cart_items_length = auxCartItems.length;
    for( let i = 0; i < aux_cart_items_length; i++ ){
      let aux_item = auxCartItems[i];
      let sizesLength = auxCartItems[i].sizes.length;
      
      // Reset price
      for( let i_size = 0; i_size < sizesLength; i_size++ ){
        auxCartItems[i].sizes[i_size].price = 0;
      }

      if( aux_item.type !== 'Venta' ){
        let aux_days = auxCartItems[i].days;

        let adult_prod;
        let junior_prod;

        // Debo encontrar el ID del producto correspondiente
        let auxProdsLength:number = arriendoProducts.products.length;
        for(var i_prods=0; i_prods<auxProdsLength; i_prods++){
          if( arriendoProducts.products[ i_prods ] ){
            if( arriendoProducts.products[ i_prods ].product_id === aux_item.product_id ){
              adult_prod = arriendoProducts.products[ i_prods ].detail["Adulto"];
              junior_prod = arriendoProducts.products[ i_prods ].detail["Junior"];
  
              break;
            }
          }
        }

        

        // Adultos
        if( adult_prod && adult_prod.price && aux_item.adults !== undefined && aux_item.adults !== 0 && aux_days !== undefined ){
          let price = parseInt( adult_prod.price.replace(/\D/g,'') );
          let extra = parseInt( adult_prod.field_dia_adicional.replace(/\D/g,'') );
          let garantia = parseInt( adult_prod.field_precio_garantia.replace(/\D/g,'') );

          let sumPrice = getSumPrice(price, aux_item.adults, aux_days, extra); 
          for( let i_size = 0; i_size < sizesLength; i_size++ ){
            if( auxCartItems[i].sizes[i_size].name === 'Adulto' ){
              auxCartItems[i].sizes[i_size].price = sumPrice;
              break;
            }
          }

          aux_total           +=  sumPrice;
          aux_subtotal        +=  sumPrice;
          aux_total_arriendo  +=  sumPrice;
          aux_total_garantia  +=  ( aux_item.adults * garantia * ( aux_days !== 0 ? 1 : 0 ) );
        }

        // Junior
        if( junior_prod && junior_prod.price && aux_item.junior !== undefined && aux_item.junior !== 0 && aux_days !== undefined ){
          let price = parseInt( junior_prod.price.replace(/\D/g,'') );
          let extra = parseInt( junior_prod.field_dia_adicional.replace(/\D/g,'') );
          let garantia = parseInt( junior_prod.field_precio_garantia.replace(/\D/g,'') );

          let sumPrice = getSumPrice(price, aux_item.junior, aux_days, extra);
          for( let i_size = 0; i_size < sizesLength; i_size++ ){
            if( auxCartItems[i].sizes[i_size].name === 'Junior' ){
              auxCartItems[i].sizes[i_size].price = sumPrice;
              break;
            }
          }

          aux_total           += sumPrice;
          aux_subtotal        += sumPrice;
          aux_total_arriendo  += sumPrice;
          aux_total_garantia  += ( aux_item.junior * garantia * ( aux_days !== 0 ? 1 : 0 ) );
        }

      } else {

        let adult_prod;
        let junior_prod;

        // Debo encontrar el ID del producto correspondiente
        let auxProdsLength:number = ventaProducts.products.length;
        for(var i_prods=0; i_prods<auxProdsLength; i_prods++){
          if( ventaProducts.products[ i_prods ] ){
            //console.log( ventaProducts.products[ i_prods ] );
            if( ventaProducts.products[ i_prods ].product_id === aux_item.product_id ){
              adult_prod = ventaProducts.products[ i_prods ].detail["Adulto"];
              junior_prod = ventaProducts.products[ i_prods ].detail["Junior"];
  
              break;
            }
          }
        }
        //console.log( adult_prod, junior_prod );





        if( adult_prod && adult_prod.price && aux_item.adults !== undefined && aux_item.adults !== 0 ){
          const price = parseInt( adult_prod.price.replace(/\D/g,'') );
          
          let sumPrice = price * aux_item.adults;
          for( let i_size = 0; i_size < sizesLength; i_size++ ){
            if( auxCartItems[i].sizes[i_size].name === 'Adulto' ){
              auxCartItems[i].sizes[i_size].price = sumPrice;
              break;
            }
          }

          aux_total       += sumPrice;
          aux_subtotal    += sumPrice;
          aux_total_venta += sumPrice;
        }

        if( junior_prod && junior_prod.price && aux_item.junior !== undefined && aux_item.junior !== 0 ){
          const price = parseInt( junior_prod.price.replace(/\D/g,'') );
          
          let sumPrice = price * aux_item.junior;
          for( let i_size = 0; i_size < sizesLength; i_size++ ){
            if( auxCartItems[i].sizes[i_size].name === 'Junior' ){
              auxCartItems[i].sizes[i_size].price = sumPrice;
              break;
            }
          }

          aux_total     += sumPrice;
          aux_subtotal  += sumPrice;
          aux_total_venta += sumPrice;
        }
        

      }
      
    }


    if( auxPayments.discount.amount && auxPayments.discount.amount !== 0 ){
      console.log('___~~~~', auxPayments.discount.amount);
      aux_total -= auxPayments.discount.amount;
    }
    
    //let auxVuelto = cartPayment.payment.total.total - totalPesos - totalDolar - totalReal - totalCredito - totalDebito - totalPresale;
    //let aux_vuelto = aux_total - Number(auxPayments.pesos ?? 0) - Number(auxPayments.dolar ?? 0) * Number(cartPayment.tc.dolar ?? 0) - Number(auxPayments.real ?? 0) * Number(cartPayment.tc.real ?? 0);
    auxPayments.total = {
      total: aux_total,
      subtotal: aux_subtotal,
      vuelto: 0,
      arriendo: aux_total_arriendo,
      venta: aux_total_venta,
    }

    // OLD
    setCartPayment( cartPayment => ({...cartPayment, payment: auxPayments}) );

    // Agregamos info de la garantía
    let garantia = cartPayment.garantia;
    garantia.total = aux_total_garantia;

    // OLD
    //setCart( cart => ({...cart, garantia: garantia}) );
    setCartPayment( cartPayment => ({...cartPayment, garantia: garantia}) );

  }

  const changeValue = ( values:ItemValueInCartProps ) => {
    
    if( values.product_id ){
      
      let aux_product_length = cart.cart_items.length;
      for( let i = 0; i < aux_product_length; i++ ){
        if( cart.cart_items[i].product_id === values.product_id ){
          let aux_cart_items = cart.cart_items;

          if( values.action === 'add' ){
            if( values.type === 'adults' ){
              
              let aux_total = aux_cart_items[i].adults;
              if( aux_total !== undefined ){
                aux_cart_items[i].adults = aux_total + 1;
              } else {
                console.log( 'no pude cambiar el valor' );
              }
              
            } else if( values.type === 'junior' ){
              
              let aux_total = aux_cart_items[i].junior;
              if( aux_total !== undefined ){
                aux_cart_items[i].junior = aux_total + 1;
              } else {
                console.log( 'no pude cambiar el valor' );
              }
              
            } else if( values.type === 'days' ){
              
              let aux_total = aux_cart_items[i].days;
              if( aux_total !== undefined ){
                aux_cart_items[i].days = aux_total + 1;
              } else {
                console.log( 'no pude cambiar el valor' );
              }
              
            }
          } else if( values.action === 'minus' ){

            if( values.type === 'adults' ){
              
              let aux_total = aux_cart_items[i].adults;
              if( aux_total !== undefined && aux_total !== 0 ){
                aux_cart_items[i].adults = aux_total - 1;
              } else {
                console.log( 'no pude cambiar el valor' );
              }
              
            } else if( values.type === 'junior' ){
              
              let aux_total = aux_cart_items[i].junior;
              if( aux_total !== undefined && aux_total !== 0 ){
                aux_cart_items[i].junior = aux_total - 1;
              } else {
                console.log( 'no pude cambiar el valor' );
              }
              
            } else if( values.type === 'days' ){
              
              let aux_total = aux_cart_items[i].days;
              if( aux_total !== undefined && aux_total !== 0 ){
                aux_cart_items[i].days = aux_total - 1;
              } else {
                console.log( 'no pude cambiar el valor' );
              }
              
            }

          } else {
            alert( 'Hubo un error al realizar la acción' );
          }

          setCart( cart => ({...cart, cart_items: aux_cart_items}) );

        }
      }

      getTotal();

    }
  }

  const getProductVenta = () => {
    if( ventaProducts.status !== 'loaded' ){
      //console.log( 'Cargamos datos de venta' );

      setVentaProducts( ventaProducts => ({ ...ventaProducts, loading: true }) );

      // Revisamos si los datos de acceso son correctos
      let venta_response = get( baseApiURL+'/get/products/venta');

      venta_response.then((response:any) => { 
        //console.log( 'venta', response );
        const aux_products:any = [];

        // Recorremos los productos
        response.data.map((item:any, index:number) => {
          if( !aux_products[item.product_id] ){
            aux_products[item.product_id] = {
              name        : item.title,
              product_id  : parseInt( item.product_id ),
              sort        : index,
              detail      : [],
              type        : item.type,
              image       : item.field_image,
            };
          }

          aux_products[item.product_id]['detail'][ item.size.toString() ] = item;

          return null;
        });

        console.log( 'venta___', aux_products );

        setVentaProducts( ventaProducts => ({ ...ventaProducts, loading: false, status: 'loaded', products: aux_products }) );
        
      }).catch( error => {
        setVentaProducts( ventaProducts => ({ ...ventaProducts, loading: false, status: 'error' }) );
        console.log(error.response);
      });
    }
  }

  const getProductArriendo = () => {
    
    setArriendoProducts( arriendoProducts => ({ ...arriendoProducts, loading: true }) );

    // Revisamos si los datos de acceso son correctos
    let arriendo_response = get( baseApiURL+'/get/products/arriendo');

    arriendo_response.then((response:any) => {
      const aux_products:any = [];

      

      // Recorremos los productos
      response.data.map((item:any, index:number) => {
        //console.log('****', item, index);


        if( !aux_products[item.product_id] ){
          aux_products[item.product_id] = {
            name          : item.title,
            product_id    : parseInt( item.product_id ),
            sort          : index,
            detail        : [],
            type          : item.type,
            image         : item.field_image,
          };
        }

        aux_products[item.product_id]['detail'][ item.size.toString() ] = item;

        return null;
      });

      setArriendoProducts(arriendoProducts => ({ ...arriendoProducts, loading: false, status: 'loaded', products: aux_products }) );
    })
    .catch( error => {
      setArriendoProducts( arriendoProducts => ({ ...arriendoProducts, loading: false, status: 'error' }) );
      console.log(error.response);
    });
  }

  const resetCart = () => {
    setCart( sale_props_default );
    setCartOrder( order_props_default );

    // Debo quitar los parámetros vacíos de peso, dolar y real de garantía y de pago
    var aux_payments_default:SalePaymentProps = payments_props_default;
    // Reset garantía
    delete aux_payments_default.garantia.pesos;
    delete aux_payments_default.garantia.real;
    delete aux_payments_default.garantia.dolar;
    // Reset pago - monedas
    delete aux_payments_default.payment.pesos;
    delete aux_payments_default.payment.real;
    delete aux_payments_default.payment.dolar;
    // Reset pago - credito
    aux_payments_default.payment.credito = [];
    // Reset pago - debito
    aux_payments_default.payment.debito = [];
    // Reset pago - presale
    aux_payments_default.payment.pre_sale = [];
    // Reset pago - credito dubra
    aux_payments_default.payment.dubra = {
      request_auth: "",
      selected_reason: "",
    };
    
    setCartPayment( aux_payments_default );

    //console.log( 'RESET' );
  }

  const handleProductClick = ( props:productDetailProps ) => {
    setModalTitle( 'Agregar producto: ' + props.name );
    setModalContent( <ProductBox detail={props} /> );
    setModalActive( true );
  }

  useEffect( () => {

    if( arriendoProducts && arriendoProducts.products ){

      var auxAvailableProducts:Array<any> = [];
      arriendoProducts?.products.forEach(( element:any ) => {
        if( element.type === 'Arriendo' ){

          if( element.detail.Adulto ){
            auxAvailableProducts.push({
              variation_id  : element.detail.Adulto.variation_id,
              name          : element.detail.Adulto.title+' - '+element.detail.Adulto.size,
            });
          }

          if( element.detail.Junior ){
            auxAvailableProducts.push({
              variation_id  : element.detail.Junior.variation_id,
              name          : element.detail.Junior.title+' - '+element.detail.Junior.size,
            });
          }

        }
      });
      setChangeOptions( auxAvailableProducts );


      var auxType = 'Kit Productos';
      const aux_items = arriendoProducts.products
        .sort(function(a:any, b:any) {   // this anonymous function is the compareFunction
          if (a.sort < b.sort) {    // now the entire values of a & b are compared
              return -1;
          };
          if (a.sort > b.sort) {
              return 1;
          };
          // a must be equal to b, no change to index
          return 0;
        })
        .map((item:any, index:number) => {

        let auxSize:Array<sizeElementProps> = [];
        let sizeLength = Object.keys( item.detail ).length;
        for( let i = 0; i < sizeLength; i++ ){
          auxSize.push({
            variation_id: item.detail[ Object.keys( item.detail )[i] ].variation_id,
            name: Object.keys( item.detail )[i],
            price: 0,
          });
        }

        var auxTypeInfo = false;
        if( item.type !== auxType ){
          auxTypeInfo = true;
          auxType = item.type;
        }

        return (
          <ProductItemMini name={item.name} image={baseApiURL+item.image} separator={auxTypeInfo} onDropHandle={() => { addCartProduct( itemToCart => ({
            product_id: parseInt( item.product_id.toString() ),
            product_name: item.name,
            type: item.type,
            image: item.image,
            sizes: auxSize,
            changes: {adults: [], junior: []},
          }) ); }} onClickHandle={ () => { handleProductClick( item ); } } key={index} />
        )
      });
      setArriendoProducts( arriendoProducts => ({ ...arriendoProducts, items: aux_items }) );
    }
    
  }, [arriendoProducts.products, addCartProduct]);

  useEffect( () => {

    if( ventaProducts && ventaProducts.products ){
      const aux_items = ventaProducts.products
      .sort(function(a:any, b:any) {   // this anonymous function is the compareFunction
        if (a.sort < b.sort) {    // now the entire values of a & b are compared
            return -1;
        };
        if (a.sort > b.sort) {
            return 1;
        };
        // a must be equal to b, no change to index
        return 0;
      })
      .map((item:any, index:number) => {
        let auxSize:Array<sizeElementProps> = [];

        let sizeLength = Object.keys( item.detail ).length;
        for( let i = 0; i < sizeLength; i++ ){
          auxSize.push({
            variation_id: item.detail[ Object.keys( item.detail )[i] ].variation_id,
            name: Object.keys( item.detail )[i],
            price: 0,
          });
        }
        
        return (
          <ProductItemMini name={item.name} image={baseApiURL+item.image} onDropHandle={() => { addCartProduct( itemToCart => ({
            product_id: item.product_id,
            product_name: item.name,
            type: item.type,
            image: item.image,
            sizes: auxSize,
            changes: {adults: [], junior: []},
          }) ); }} onClickHandle={ () => { handleProductClick( item ); } } key={index} />
        )
      });
      setVentaProducts( ventaProducts => ({ ...ventaProducts, items: aux_items }) );
    }
    
  }, [ventaProducts.products, addCartProduct]);

  useEffect( () => {

    if( cart.cart_items.length === 0 ){
      setCartProducts(<div>Arrastre los productos que deseas agregar al carro</div>);
    } else {
      let auxCartData = cart.cart_items.map((item, index) => {
        return (
          <ProductItem {...item} key={index} index_item={index} />
        )
      });
      setCartProducts( auxCartData );
    }

  }, [cart]);

  useEffect( () => {

    if( itemToCart !== undefined ){
    
      // Verificamos si ya existe el producto en el carro
      let cartLength:number = cart.cart_items.length;
      let auxItems:Array<ItemInCartProps> = [];
      let auxAddItem = false;
      
      if( cartLength !== 0 ){
        // Hacemos todo los trucos
        for(let i=0; i<cartLength; i++ ){
          
          if( parseInt( cart.cart_items[i].product_id.toString() ) === parseInt(itemToCart.product_id.toString()) ){
            auxAddItem = true;
            break;
          }
        }
      }

        
      if( auxAddItem ){
        // Verificamos si viene el dato de Adulto o Niño, de ser así, modificamos la cantidad
        if( itemToCart.adults || itemToCart.junior ){
          if( itemToCart.adults ){
            for(var i=0; i<itemToCart.adults; i++){
              changeValue( {
                type: 'adults',
                action: 'add',
                product_id: itemToCart.product_id,
              } );
            }
          }

          if( itemToCart.junior ){
            for(var i=0; i<itemToCart.junior; i++){
              changeValue( {
                type: 'junior',
                action: 'add',
                product_id: itemToCart.product_id,
              } );
            }
          }

        } else {
          console.log( 'ya existe el producto en el carro' );
        }


      } else {
        auxItems.push({
          product_id    : parseInt( itemToCart.product_id.toString() ),
          product_name  : itemToCart.product_name,
          adults        : itemToCart.adults ? itemToCart.adults : 0,
          junior        : itemToCart.junior ? itemToCart.junior : 0,
          days          : 1,
          type          : itemToCart.type,
          image         : itemToCart.image,
          sizes         : itemToCart.sizes,
          changes       : {adults: [], junior: []},
        });
      }
    
      let auxAuxItem = cart.cart_items.concat(auxItems);

      setCart( cart => ({...cart, cart_items: auxAuxItem}) );
      addCartProduct( undefined );
    }
  }, [itemToCart]);

  useEffect(() => {
    if( cart.cart_items ){
      getTotal();
    }
  }, [cart.cart_items] );

  const addAdult = () => {
    const auxAdults = cartOrder.q_adultos;
    // OLD
    // setCart( cart => ({...cart, q_adultos: auxAdults+1}) );
    setCartOrder( cartOrder => ({...cartOrder, q_adultos: auxAdults+1}) );
  }

  const minusAdult = () => {
    const auxAdults = cartOrder.q_adultos;
    if( cartOrder.q_adultos ){
      // OLD
      // setCart( cart => ({...cart, q_adultos: auxAdults-1}) );
      setCartOrder( cartOrder => ({...cartOrder, q_adultos: auxAdults-1}) );
    }
  }

  const addJunior = () => {
    const auxJunior = cartOrder.q_ninos;
    // OLD
    // setCart( cart => ({...cart, q_ninos: auxJunior+1}) );
    setCartOrder( cartOrder => ({...cartOrder, q_ninos: auxJunior+1}) );
  }

  const minusJunior = () => {
    const auxJunior = cartOrder.q_ninos;
    if( cartOrder.q_ninos ){
      // OLD
      // setCart( cart => ({...cart, q_ninos: auxJunior-1}) );

      setCartOrder( cartOrder => ({...cartOrder, q_ninos: auxJunior-1}) );
    }
  }

  const setVoucherValues = (props:MoneyValues) => {
    var auxGuarantee = cartPayment.garantia;
    var auxPayment = cartPayment.payment;

    if( props.step === StepBlock.Garantia ){
      if( props.type === MoneyTypes.Credit ){
        if( props.index !== undefined ){
          auxGuarantee.credito[ props.index ].voucher = props.value;
        }
      } else {
        console.log( 'no reconozco el tipo de pago' );
      }
    } else if( props.step === StepBlock.Pago ){
      if( props.type === MoneyTypes.Credit ){
        if( props.index !== undefined ){
          auxPayment.credito[ props.index ].voucher = props.value;
        }
      } else if( props.type === MoneyTypes.Debit ){
        if( props.index !== undefined ){
          auxPayment.debito[ props.index ].voucher = props.value;
        }
      } else {
        console.log( 'no reconozco el tipo de pago' );
      }
    } else {
      console.log( 'no reconozco la etapa' );
    }

    // OLD
    //setCart( cart => ({...cart, garantia: auxGuarantee, payment: auxPayment}) );
    setCartPayment( cartPayment => ({...cartPayment, garantia: auxGuarantee, payment: auxPayment}) );
  }

  const setMoneyValues = (props:MoneyValues) => {
    var auxGuarantee = cartPayment.garantia;
    var auxPayments = cartPayment.payment;

    if( props.step === StepBlock.Garantia ){
      if( props.type === MoneyTypes.Pesos ){
        auxGuarantee.pesos = props.value;
      } else if( props.type === MoneyTypes.Dolar ){
        auxGuarantee.dolar = props.value;
      } else if( props.type === MoneyTypes.Real ){
        auxGuarantee.real = props.value;
      } else if( props.type === MoneyTypes.Credit ){
        if( props.index !== undefined ){
          auxGuarantee.credito[ props.index ].amount = props.value;
        }
      }
    } else if( props.step === StepBlock.Pago ){

      if( props.type === MoneyTypes.Pesos ){
        auxPayments.pesos = props.value;
      } else if( props.type === MoneyTypes.Dolar ){
        auxPayments.dolar = props.value;
      } else if( props.type === MoneyTypes.Real ){
        auxPayments.real = props.value;
      } else if( props.type === MoneyTypes.Credit ){
        if( props.index !== undefined ){
          auxPayments.credito[ props.index ].amount = props.value;
        } else {
          console.log( 'no viene un index credito' );
        }
      } else if( props.type === MoneyTypes.Debit ){
        if( props.index !== undefined ){
          auxPayments.debito[ props.index ].amount = props.value;
        } else {
          console.log( 'no viene un index debito' );
        }
      } else if( props.type === MoneyTypes.Discount ){

        //auxPayments.discount.amount = auxPayments.total.subtotal * props.value.amount;
        auxPayments.discount.amount = auxPayments.total.arriendo * props.value.amount;
        auxPayments.discount.name = props.value.name;

      } else {
        console.log( 'No se detecta el tipo de tarjeta' );  
      }
    } else {
      console.log( 'No se detecta la etapa' );
    }

    // OLD
    //setCart( cart => ({...cart, garantia: auxGuarantee, payment: auxPayments}) );
    setCartPayment( cartPayment => ({...cartPayment, garantia: auxGuarantee, payment: auxPayments}) );

    getTotal();
    
  }

  const setDubraCredit = ( props:dubraCreditProps ) => {
    var auxPayments = cartPayment.payment;
    auxPayments.dubra = props;
    // OLD
    //setCart( cart => ({...cart, payment: auxPayments}) );
    setCartPayment( cartPayment => ({...cartPayment, payment: auxPayments}) );
  }

  const addCard = (props:cardItemActionsProps) => {
    console.log( props );
    var auxGuarantee = cartPayment.garantia;
    var auxPayment = cartPayment.payment;

    if( props.step === StepBlock.Garantia ){

      if( props.type === MoneyTypes.Credit ){
        if( !auxGuarantee.credito ){
          auxGuarantee.credito = [{ voucher: '', amount: 0 }] 
        } else {
          auxGuarantee.credito.push( { voucher: '', amount: 0 } );
        }
      }
    } else if( props.step === StepBlock.Pago ){

      if( props.type === MoneyTypes.Credit ){
        if( !auxPayment.credito ){
          auxPayment.credito = [{ voucher: '', amount: 0 }] 
        } else {
          auxPayment.credito.push( { voucher: '', amount: 0 } );
        }
      } else if( props.type === MoneyTypes.Debit ){
        if( !auxPayment.debito ){
          auxPayment.debito = [{ voucher: '', amount: 0 }] 
        } else {
          auxPayment.debito.push( { voucher: '', amount: 0 } );
        }
      } else {
        console.log( 'Tipo de pago no reconocido' );  
      }
    } else {
      console.log( 'Step no reconocido' );
    }

    // OLD
    //setCart( cart => ({...cart, garantia: auxGuarantee, payment: auxPayment}) );
    setCartPayment( cartPayment => ({...cartPayment, garantia: auxGuarantee, payment: auxPayment}) );
  }

  const removeCard = (props:cardItemActionsProps) => {
    console.log( props );
    var auxGuarantee = cartPayment.garantia;
    var auxPayment = cartPayment.payment;

    if( props.step === StepBlock.Garantia ){

      if( props.type === MoneyTypes.Credit ){

        if( props.itemIndex === 0 ){
          console.log( 'elimina el primero' );
          auxGuarantee.credito.shift();
        } else {
          console.log( auxGuarantee.credito.length );
          if( props.itemIndex && auxGuarantee.credito && auxGuarantee.credito[ props.itemIndex ] ){
            auxGuarantee.credito.splice( props.itemIndex,1);
          }
        }

      }

    } else if( props.step === StepBlock.Pago ){

      if( props.type === MoneyTypes.Credit ){

        if( props.itemIndex === 0 ){
          console.log( 'elimina el primero' );
          auxPayment.credito.shift();
        } else {
          console.log( auxPayment.credito.length );
          if( props.itemIndex && auxPayment.credito && auxPayment.credito[ props.itemIndex ] ){
            auxPayment.credito.splice( props.itemIndex,1);
          }
        }

      } else if( props.type === MoneyTypes.Debit ){

        if( props.itemIndex === 0 ){
          console.log( 'elimina el primero' );
          auxPayment.debito.shift();
        } else {
          console.log( auxPayment.debito.length );
          if( props.itemIndex && auxPayment.debito && auxPayment.debito[ props.itemIndex ] ){
            auxPayment.debito.splice( props.itemIndex,1);
          }
        }

      } else {
        console.log( 'No se reconoce tipo de pago' );
      }

    } else {
      console.log( 'No se reconoce la etapa' );
    }

    // OLD
    //setCart( cart => ({...cart, garantia: auxGuarantee, payment: auxPayment}) );
    setCartPayment( cartPayment => ({...cartPayment, garantia: auxGuarantee, payment: auxPayment}) );
  }

  const addPreSale = () => {
    var auxPayment = cartPayment.payment;

    if( !auxPayment.pre_sale ){
      auxPayment.pre_sale = [{ code: '', amount: 0, use: [] }] 
    } else {
      const auxLength = auxPayment.pre_sale.length;
      auxPayment.pre_sale.push( { code: '', amount: 0, use: [] } );
    }

    // OLD
    //setCart( cart => ({...cart, payment: auxPayment}) );
    setCart( cartPayment => ({...cartPayment, payment: auxPayment}) );
  }

  const removePreSale = ( itemIndex: number ) => {
    var auxPayment = cartPayment.payment;

    if( itemIndex === 0 ){
      console.log( 'elimina el primero' );
      auxPayment.pre_sale.shift();
    } else {
      if( auxPayment.pre_sale && auxPayment.pre_sale[ itemIndex ] ){
        auxPayment.pre_sale.splice( itemIndex,1);
      }
    }
    
    // OLD
    //setCart( cart => ({...cart, payment: auxPayment}) );
    setCartPayment( cartPayment => ({...cartPayment, payment: auxPayment}) );
  }

  interface localProduct {
    product_id: string,
  }

  const setPreSaleValues = (props:preSaleProps & localProduct) => {
    var auxPayment = cartPayment.payment;

    if( props.itemIndex !== undefined ){


      console.log( 'props', props );
      
      if( props.code !== undefined && auxPayment.pre_sale && auxPayment.pre_sale[ props.itemIndex ] ){
        auxPayment.pre_sale[ props.itemIndex ].code = props.code;
      } else {
        console.log( '[Code]: No encuentra algun elemento relacionado' );
      }
  
      if( props.info !== undefined ){
        if( auxPayment.pre_sale && auxPayment.pre_sale[ props.itemIndex ] ){
          auxPayment.pre_sale[ props.itemIndex ].info = props.info;
        } else {
          console.log( '[Code data]: no encontramos el item' );
        }
      }

      if( props.use !== undefined ){
        var auxTotal:number = 0;
        auxPayment.pre_sale[ props.itemIndex ].use = props.use;

        
        if( auxPayment.pre_sale[ props.itemIndex ].use !== null && auxPayment.pre_sale[ props.itemIndex ].use !== undefined ){
          var auxUseLength:number = auxPayment.pre_sale[ props.itemIndex ].use.length;

          for( let i = 0; i < auxUseLength; i++ ){
            if( auxPayment.pre_sale[ props.itemIndex ].use[ i ] ){
              auxTotal += auxPayment.pre_sale[ props.itemIndex ].use[ i ].item_position.length * auxPayment.pre_sale[ props.itemIndex ].use[ i ].price;
              console.log('**', 'algo', auxPayment.pre_sale[ props.itemIndex ] );
            } else {
              console.log( 'No hay detalle' );
            }
             
          }

        }  

        auxPayment.pre_sale[ props.itemIndex ].amount = auxTotal;

      }
  
      /*
      if( props.amount !== undefined ){
        console.log( '[Code amount]: cambiamos amount' );
        console.log( auxPayment.pre_sale[ props.itemIndex ].use );
      }
      */

    } else {
      console.log( '[Code]: No encuentra el input item' );
    }

    // OLD
    //setCart( cart => ({...cart, payment: auxPayment}) );
    setCartPayment( cartPayment => ({...cartPayment, payment: auxPayment}) );
    
  }

  const removeItem = ( indexItem:number ) => {
    var auxCartItems = cart.cart_items;
    if( indexItem !== 0 ){
      auxCartItems.shift();
    } else {
      auxCartItems.splice(indexItem, 1);
    }

    setCart( cart => ({...cart, cart_items: auxCartItems}) );
    getTotal();
  }

  const changeKit = () => {
    var auxCartItems = cart.cart_items;
    setCart( cart => ({...cart, cart_items: auxCartItems}) );
  }

  const getProductName = ( props:productNameProps ) => {

    if( props.variation_id === 9999 ){
      return 'No lleva';
    } else {
      let auxLength = changeOptions.length;
      if( auxLength !== 0 ){
        for( let i=0; i<auxLength; i++ ){
          if( changeOptions[i].variation_id == props.variation_id ){
            return changeOptions[i].name;
            break;
          }
        }
      }
    }

    return 'Info no disponible';
  }
  

  return <CartContext.Provider value={{
    cart,
    setCart,


    cartOrder, setCartOrder,
    cartPayment, setCartPayment,

    loadingCart,
    setLoadingCart,
    cartProducts,
    itemToCart,
    addCartProduct,
    changeValue,
    arriendoProducts,
    getProductArriendo,
    ventaProducts,
    getProductVenta,
    addAdult,
    minusAdult,
    addJunior,
    minusJunior,
    setVoucherValues,
    setMoneyValues,
    setDubraCredit,
    addCard,
    removeCard,
    addPreSale,
    removePreSale,
    setPreSaleValues,
    removeItem,
    changeKit,
    changeOptions,
    getProductName,
    modalTitle, modalActive, modalContent, setModalActive,

    resetCart,
  }}>{props.children}</CartContext.Provider>
};

export default CartProvider;