import React, { useEffect, useState } from 'react';
import BlockContent from '../../common/BlockContent';

import { useHistory } from 'react-router-dom';
import useFetch from '../../../_services/fetch';
import { errorSectionType, errorType } from '../../../_helpers/enums/all';
import useErrorManager from '../../common/ErrorManager';
import {actionProps} from '../../../_helpers/props/all';

//import React, { PureComponent } from 'react';
import {
  ResponsiveContainer, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

const StockRentalBlock = () => {
  const { response, loading, error } = useFetch({ method: 'GET', path: '/get/dashboard/stock-rental'});
  const { alertBlock, showAlert } = useErrorManager({ id: 'fondofijo_block', section: errorSectionType.Custom });
  
  const [ dataLoaded, setDataLoaded ] = useState<boolean>( false );
  const [ actions ]       = useState<actionProps[]>([]);//const [ actions, setActions ]       = useState<actionProps[]>([]);
  const [ dataRental, setDataRental ] = useState<any[]>([]);

  useEffect( () => {
    if( response ){

      if( response.data ){
        setDataLoaded( true );
        
        var auxProducts:any[] = [];
        let stockLength = response.data.stock.length;
        console.log( '********', response.data );

        for(let i=0; i<stockLength; i++){
          //console.log( response.data.stock[i] );

          auxProducts.push({
            name: response.data.stock[i].name,
            Arrendado: response.data.stock[i].inRental,
            Disponible: response.data.stock[i].available,
          });
        }

        setDataRental( auxProducts );
      }
    }
  }, [response] );

  useEffect( () => {
    if( error ){
      showAlert({
        message: 'Hubo un error al cargar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }
  }, [error] );

  return (
    <BlockContent title="Stock arriendo" loading={loading} actions={ actions }>
      { alertBlock }
      { dataLoaded &&
        <div style={{width: '100%', height: 450}}>
          <ResponsiveContainer >
            <BarChart data={dataRental}
              margin={{top: 20, right: 30, left: 20, bottom: 5}}>
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis dataKey="name" interval={0} angle={-30} dx={-20} dy={25} height={75} />
              <YAxis/>
              <Tooltip/>
              <Legend />
              <Bar dataKey="Arrendado" stackId="rentalbar" fill="#ff677a" />
              <Bar dataKey="Disponible" stackId="rentalbar" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      }
    </BlockContent>
  );
}

export default StockRentalBlock;