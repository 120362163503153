export enum StepBlock {
  Arriendo  = 'arriendo',
  Venta     = 'venta',
  Garantia  = 'garantia',
  Pago      = 'pago',
}

export enum MoneyTypes {
  Pesos   = 'pesos',
  Dolar   = 'dolar',
  Real    = 'real',
  Credit  = 'credito',
  Debit   = 'debito',
  Dubra   = 'dubra_credit',
  Discount   = 'discount',
}

export enum PreSaleResponseCode {
  Fail = 'ps-1001',
  Active = 'ps-1002',
}

export enum typeProduct {
  Rental = 'Arriendo',
  Sale = 'Venta',
}

export enum productSize {
  Adult   = 'Adulto',
  Junior  = 'Junior',
}