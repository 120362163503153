import React, { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import TableContent from '../common/table/TableContent';

import { IColumn } from '../common/table/table-types';
import { errorSectionType, errorType, roleType } from '../../_helpers/enums/all';
import useErrorManager from '../common/ErrorManager';

import EditIcon from '@material-ui/icons/Edit';

import { getParamsFormatFromQs, getQueryParams, addToQuery, createQuery } from '../../_services/request';
import Button from '@material-ui/core/Button';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import SkiroomFilters from './SkiroomFilters';
import { useAuthDataContext } from '../../_auth/auth_provider';
import { DateTime } from 'luxon';

const Skiroom = (props: RouteComponentProps) => {
  let history = useHistory();
  const { alertBlock, showAlert } = useErrorManager({ id: 'skiroom_list', section: errorSectionType.Custom });
  const { userPermission } = useAuthDataContext();
  document.title = 'Skiroom';

  const [ loadingValidation, setLoadingValidation ] = useState<boolean>( false );

  const handleClick = ( props:any ) => {
    history.push( props.url );
  }

  const handleCalibrated = ( props:any ) => {
    if( props.calibrated ){
      if( props.calibrated.total === 0 ){
        return '-'
      } else if( props.calibrated.total === props.calibrated.calibrated ) {
        return 'Completado ( '+props.calibrated.calibrated+' )';
      } else {
        return props.calibrated.calibrated+' / '+props.calibrated.total;
      }
    } else {
      return '-';
    }
  }

  const columns:Array<IColumn> = [
    { id: 'reservation_date',   visible: true,    numeric: false,     button: false,   disablePadding: true,      label: 'Fecha de reserva',     sortable: true     },
    { id: 'uuid',               visible: false,   numeric: false,     button: false,   disablePadding: true,      label: 'ID',                   sortable: false    ,   uKey: true},
    { id: 'agency_name',        visible: true,    numeric: false,     button: false,   disablePadding: true,      label: 'Agencia',              sortable: true     },
    { id: 'status_name',        visible: true,    numeric: false,     button: false,   disablePadding: true,      label: 'Estado',               sortable: false  },
    { id: 'guide_name',         visible: true,    numeric: false,     button: false,   disablePadding: true,      label: 'Guía excursión',       sortable: true     },
    { id: 'list',               visible: true,    numeric: false,     button: true,    disablePadding: true,      label: 'Listado pasajeros',    sortable: false    ,   button_name: <FormatListBulletedIcon />,        button_color: 'primary',       button_action: handleClick,     },
    { id: 'calibrated',         visible: true,    numeric: false,     button: true,    disablePadding: true,      label: 'Calibrado',            sortable: false    , extraData: handleCalibrated},
  ];


  useEffect(() => {
    if (window.location.search !== "") {

      // Berificamos 
      if( userPermission([roleType.SAC]) ){        
        let auxParams = getQueryParams();

        // Agrega from
        auxParams.push({
          param: 'from',
          value: DateTime.local().toFormat('dd-LL-yyyy'),
        });
  
        // Agrega end
        auxParams.push({
          param: 'end',
          value: DateTime.local().toFormat('dd-LL-yyyy'),
        });

        history.push({ search: createQuery( addToQuery(auxParams) ) });
      }
    }

  }, [window.location.search]);

  return (
    loadingValidation ? <LinearProgress color="primary" /> :
    <div className="content_all">
      <div className="content">
        { alertBlock }

        <Paper className="content">
          <div className="paper_content_container">
            <TableContent
              id="skiroom_table"
              title="Skiroom - Listado de reservas"
              columnsValues={columns}
              //urlData="/get/skiroom/all/list"
              urlData="/reservations/skiroom"
              emptyData="No se encontraron reservas para mostrar"
              filters={ <SkiroomFilters /> }
              today
              todayLocked={ userPermission([roleType.SAC]) ? true : false }
              apiType
            />
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default Skiroom;