import React, { useState } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import TableContent from '../common/table/TableContent';

import { IColumn } from '../common/table/table-types';
import { errorSectionType, roleType } from '../../_helpers/enums/all';
import useErrorManager from '../common/ErrorManager';

import IconButton from '@material-ui/core/IconButton/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ProductList from './modal/ProductList';
import ProductReturnList from './modal/ProductReturnList';
import ModalBox from '../common/ModalBox';

import AttentionsFilters from './AttentionsFilters';
import { useAuthDataContext } from '../../_auth/auth_provider';

const Attentions = (props: RouteComponentProps) => {
  document.title = 'Atenciones';

  const { userPermission } = useAuthDataContext();
  //const { response, loading, error } = useFetch({ method: 'GET', path: '/get/presales'});
  const { alertBlock } = useErrorManager({ id: 'attentions_summary', section: errorSectionType.General });
  const [ loadingValidation ] = useState<boolean>( false );

  const [ activeModal, setActiveModal ] = useState<React.ReactNode|null>();

  const handleProducts = (props:any) => {
    //console.log(props);

    return (
      <IconButton aria-label="edit" onClick={() => {setActiveModal(<ProductList agency={props.agency_id} guide={props.guide_id} date={props.created} closeAction={() => { setActiveModal( null ); }} />)}}>
        <VisibilityIcon />
      </IconButton>
    );
  }

  const handleReturn = (props:any) => {
    //console.log( props );

    return (
      <React.Fragment>
        { props.return_status } 
        <IconButton aria-label="edit" onClick={() => {setActiveModal(<ProductReturnList agency={props.agency_id} guide={props.guide_id} date={props.created} closeAction={() => { setActiveModal( null ); }} />)}}>
          <VisibilityIcon />
        </IconButton>
      </React.Fragment>
    )
  }

  const columns:Array<IColumn> = [
    { id: 'created',        visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Fecha',                 sortable: true },
    { id: 'agency_name',    visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Agencia',               sortable: true },
    { id: 'touristGuide',   visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Guía',                  sortable: true },
    { id: 'pax_in_store',   visible: true,    numeric: true,    button: false,   disablePadding: true,  label: 'Pax tienda',            sortable: true },
    { id: 'adults',         visible: true,    numeric: true,    button: false,   disablePadding: true,  label: 'Cantidad adultos',      sortable: true },
    { id: 'junior',         visible: true,    numeric: true,    button: false,   disablePadding: true,  label: 'Cantidad junior',       sortable: true },
    { id: 'return_status',  visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Retorno',               sortable: false, extraData: handleReturn },
    { id: 'total_rental',   visible: true,    numeric: false,   button: false,   disablePadding: true,  label: '$ arriendo',            sortable: true },
    { id: 'total_sale',     visible: true,    numeric: false,   button: false,   disablePadding: true,  label: '$ ventas',              sortable: true },
    { id: 'products',       visible: true,    numeric: false,   button: false,   disablePadding: true,  label: 'Productos',     sortable: false, extraData: handleProducts },
  ];

  return (
    loadingValidation ? <LinearProgress color="primary" /> :
    <div className="content_all">
      <div className="content">
        { alertBlock }

        <Paper className="content">
          <div className="paper_content_container">
            <TableContent
              id="attentions_summary_table"
              title="Resumen de atenciones"
              columnsValues={columns}
              urlData="/get/attentions/full"
              emptyData="No se encontraron atenciones para mostrar"
              filters={<AttentionsFilters />}
              today
              todayLocked={ userPermission([roleType.Return]) ? true : false }
            />
          </div>
        </Paper>
      </div>
      <ModalBox title="Detalle de productos" active={ activeModal ? true : false } >
        { activeModal }
      </ModalBox>
    </div>
  );
}

export default Attentions;

function userPermission(arg0: any[]) {
  throw new Error('Function not implemented.');
}
