import React, {useState, useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid, TextField, FormControl, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useErrorManager from '../../common/ErrorManager';

import { baseApiURL } from '../../../_services/fetch';
import { errorSectionType, errorType, roleType } from '../../../_helpers/enums/all';
import BaseButton from '../../common/BaseButton';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { post, get } from '../../../_services/api';

import LuxonFnsUtils from "@date-io/luxon";
import { DateTime } from 'luxon';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useAuthDataContext } from '../../../_auth/auth_provider';

interface reservationsFormProps {
  response    : any,
  error       : any,
}

const ReservationsForm = ( props:reservationsFormProps ) => {
  let history = useHistory();
  const { userPermission } = useAuthDataContext();
  
  const { response, error } = props;
  const { showAlert } = useErrorManager({ id: 'reservations_form', section: errorSectionType.Custom });

  const [ disableButton, setDisableButton ]     = useState<boolean>( false );
  const [ agencies, setAgencies ]               = useState<Array<React.ReactNode>>([]);
  const [ agency, setAgency ]                   = useState<string>('');
  const [ touristGuides, setTouristGuides ]     = useState<Array<React.ReactNode>>([ <MenuItem key={0} value={'none'}><em>Primero debes escoger una agencia</em></MenuItem> ]);
  const [ touristGuide, setTouristGuide ]       = useState<string>('none');
  const [ tourTypes, setTourTypes ]             = useState<Array<React.ReactNode>>([]);
  const [ tourType, setTourType ]               = useState<string>('');
  const [ times, setTimes ]                     = useState<Array<React.ReactNode>>([ <MenuItem key={0} value={'none'}><em>Escoge un turno</em></MenuItem> ]);
  const [ time, setTime ]                       = useState<string>('none');
  const [ qPax, setQPax ]                       = useState<string>( '' );
  const [ comments, setComments ]               = useState<string>('');
  const [ selectedDate, setSelectedDate ]       = useState<DateTime | null>( DateTime.local() );

  const [ agencyError, setAgencyError ]         = useState<boolean>( false );
  const [ reservationsId, setReservationsId ]   = useState<string>( '' );
  

  const handleDateChange = (date: DateTime | null) => {
    //console.log('eo', date);
    setSelectedDate(date);
  };

  useEffect( () => {
    if( agency ){
      setAgencyError( false );
      // Cargamos información sobre los guias
      

      let touristGuide_response = get( baseApiURL+'/get/tourism-guides?agency='+agency);
      touristGuide_response.then((response:any) => {
        
        //history.push( '/reservations?newpresale=Reserva' );
        let touristGuidesLength:number = response.length;
        let newGuide = true;
        if( touristGuidesLength === 0 ){
          setTouristGuides([ <MenuItem key={0} value="null"><em>Esta agencia no tiene guias disponibles</em></MenuItem> ]);
          setTouristGuide('null');
        } else {
          let auxTouristGuidesOptions:Array<React.ReactNode> = [ <MenuItem key={0} value="none"><em>Escoge un guía</em></MenuItem> ];
          for( let i=0; i<touristGuidesLength; i++ ){
            auxTouristGuidesOptions.push( <MenuItem key={i+1} value={response[i].uuid}>{response[i].field_nombre}</MenuItem> );
            if( response[i].uuid === touristGuide ){
              newGuide = false;
            }
          }
          setTouristGuides( auxTouristGuidesOptions );
          if( newGuide ){
            setTouristGuide('none');
          }
        }
      }).catch( error => {
        showAlert({
          message: 'Hubo un error al cargar los guías de la agencia. ['+error+']',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      });

    }
  }, [agency] );

  useEffect( () => {
    if( response ){

      //console.log( 'presales', response.data.agency );
      
      if( response.data.agency ){
        setAgency( response.data.agency );
      } else {
        let auxAgenciesOptions:Array<React.ReactNode> = [];
        let agenciesLength:number = response.data.agencies?.length ?? 0;
        for( let i=0; i<agenciesLength; i++ ){
          auxAgenciesOptions.push( <MenuItem key={i} value={response.data.agencies[i].uuid}>{response.data.agencies[i].field_nombre}</MenuItem> );
        }
        setAgencies( auxAgenciesOptions );

        /*
        if( agenciesLength == 0 ){
          setAgencies( [ <MenuItem key={0} value={'none'}><em>No existen agencias disponibles para seleccionar</em></MenuItem> ] );
        }
        */
      }

      // Revisamos los tour
      if( response.data.tour ){
        let auxTourOptions:Array<React.ReactNode> = [];
        let toursLength:number = response.data.tour.length;
        for( let i=0; i<toursLength; i++ ){
          auxTourOptions.push( <MenuItem key={i} value={response.data.tour[i].uuid}>{response.data.tour[i].name}</MenuItem> );
        }
        setTourTypes( auxTourOptions );
        setTourType( response.data.tour_default );
        
      } else {
        showAlert({
          message: 'Hubo un error al cargar la información sobre los tour, por favor vuelve a intentarlo.',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      }

      // Revisamos los horarios
      if( response.data.time ){
        let auxTimeOptions:Array<React.ReactNode> = [<MenuItem key={0} value={'none'}><em>Escoge un turno</em></MenuItem>];
        let timeLength:number = response.data.time.length;
        for( let i=0; i<timeLength; i++ ){
          auxTimeOptions.push( <MenuItem key={i} value={response.data.time[i].uuid}>{response.data.time[i].name}</MenuItem> );
        }
        setTimes( auxTimeOptions );
        
      } else {
        showAlert({
          message: 'Hubo un error al cargar la información sobre los horarios, por favor vuelve a intentarlo.',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      }



      // Revisamos los datos que trae la cabecera del formulario
      if( response.data.dataForm ){
        setReservationsId( response.data.dataForm.id );
        // Fecha de reserva
        if( response.data.dataForm.date && response.data.dataForm.date !== '' ){
          setSelectedDate( DateTime.fromISO( response.data.dataForm.date ) );
        }
        // Agency
        if( response.data.dataForm.agency && response.data.dataForm.agency !== '' ){
          setAgency( response.data.dataForm.agency );
        }
        // Guide
        if( response.data.dataForm.tourism_guide && response.data.dataForm.tourism_guide !== '' ){
          setTouristGuide( response.data.dataForm.tourism_guide );
        }
        // Time
        if( response.data.dataForm.time && response.data.dataForm.time !== '' ){
          setTime( response.data.dataForm.time );
        }
        // Q Pax
        if( response.data.dataForm.q_pax && response.data.dataForm.q_pax !== '' ){
          setQPax( response.data.dataForm.q_pax );
        }
        // Q Pax
        if( response.data.dataForm.comments && response.data.dataForm.comments !== '' ){
          setComments( response.data.dataForm.comments );
        }
      }

      

    }
  }, [response] );

  useEffect( () => {
    if( error ){
      showAlert({
        message: 'Hubo un error al cargar la información, por favor recarga la página ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }
  }, [error] );

  const handleCreateReservation = () => {
    let auxValidForm = true;
    setDisableButton( true );

    if( agency === '' ){
      auxValidForm = false;
      setAgencyError( true );
      showAlert({
        message: 'Es obligatorio escoger escoger una agencia.',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }

    if( touristGuide === 'null' ){
      auxValidForm = false;
      showAlert({
        message: 'Debes escoger una agencia que tenga guías disponibles',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }


    if( auxValidForm ){
      let createReservations_response = post( baseApiURL+'/post/reservations/new', {
        uuid: reservationsId,
        date: selectedDate ? selectedDate.toISODate() : '-',
        agency: agency,
        guide: touristGuide,
        tour: tourType,
        time: time,
        pax_quantity: qPax,
        comments: comments,
      });

      createReservations_response.then((response:any) => {
        if( reservationsId === '' ){
          history.push( '/reservations?newreservation=true' );
        } else {
          history.push( '/reservations?editreservation=true' );
        }
        
      }).catch( error => {
        setDisableButton( false );
        showAlert({
          message: 'Hubo un error al registrar la reserva, por favor actualiza y vuelve a intentarlo. ['+error+']',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      });

    } else {
      setDisableButton( false );
    }

  }

  return (
    <div>
      {
            !error ?
              <div className="cash-register-box">
                <Paper className="fullwidth">
                  <Grid container className="cash-register-container form-container-common">
                    <Grid item xs={12} sm={6} md={8} className="cash-register-content">
                      <h1 className="form-title">
                        { reservationsId !== '' ?
                            'Modificar reserva'
                          :  
                            'Crear nueva reserva'
                        }
                      </h1>
                      <div className="form-detail-big">
                        <FormControl fullWidth className="form-item">
                          <MuiPickersUtilsProvider utils={LuxonFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              //variant="inline"
                              format="dd/LL/yyyy"
                              minDate={ DateTime.local() }
                              margin="normal"
                              label="Fecha de reserva"
                              value={selectedDate}
                              onChange={handleDateChange}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              inputVariant="outlined"
                              required={true}
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>

                        { agencies.length !== 0 ?
                            <FormControl variant="outlined" fullWidth className="form-item">
                              <InputLabel id="agency_name">
                                Agencia
                              </InputLabel>
                              <Select
                                labelId="agency_name"
                                value={agency}
                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => { if( typeof event.target.value !== 'string' ){ setAgency('none'); } else { setAgency( event.target.value ); }  }}
                                required={true}
                                error={agencyError}
                              >
                                <MenuItem value="none">
                                  <em>Selecciona una agencia</em>
                                </MenuItem>
                                { agencies }
                              </Select>
                            </FormControl>
                          :
                            ( userPermission([roleType.Admin, roleType.SuperAdmin, roleType.StoreManager]) &&
                                <FormControl variant="outlined" fullWidth className="form-item">
                                  <InputLabel id="agency_name">
                                    Agencia
                                  </InputLabel>
                                  <Select
                                    labelId="agency_name"
                                    value="none"
                                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => { if( typeof event.target.value !== 'string' ){ setAgency('none'); } else { setAgency( event.target.value ); }  }}
                                    required={true}
                                    error={agencyError}
                                  >
                                    <MenuItem value="none">
                                      <em>No existen agencias creadas para asignar</em>
                                    </MenuItem>
                                    { agencies }
                                  </Select>
                                </FormControl>
                            )
                        }

                        <FormControl variant="outlined" fullWidth className="form-item">
                          <InputLabel id="touristGuide_name">
                            Guía
                          </InputLabel>
                          <Select
                            labelId="touristGuide_name"
                            value={touristGuide}
                            disabled={ (agency === '' || touristGuide === 'null' ) ? true : false }
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => { if( typeof event.target.value !== 'string' ){ setTouristGuide('none'); } else { setTouristGuide( event.target.value ); } }}
                          >
                            { touristGuides }
                          </Select>
                        </FormControl>

                        <FormControl variant="outlined" fullWidth className="form-item">
                          <InputLabel id="tour_type">
                            Tour
                          </InputLabel>
                          <Select
                            labelId="tour_type"
                            value={tourType}
                            disabled={ tourTypes.length === 0 ? true : false }
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => { if( typeof event.target.value !== 'string' ){ setTourType('none'); } else { setTourType( event.target.value ); } }}
                          >
                            { tourTypes }
                          </Select>
                        </FormControl>

                        <FormControl variant="outlined" fullWidth className="form-item">
                          <InputLabel id="time_value">
                            Turno horario
                          </InputLabel>
                          <Select
                            labelId="time_value"
                            value={time}
                            disabled={ times.length === 0 ? true : false }
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => { if( typeof event.target.value !== 'string' ){ setTime('none'); } else { setTime( event.target.value ); } }}
                          >
                            { times }
                          </Select>
                        </FormControl>

                        <FormControl fullWidth className="form-item">
                          <TextField
                              label="Cantidad de pasajeros"
                              value={qPax}
                              helperText="Ingrese la cantidad de pasajeros"
                              variant="outlined"
                              onChange={(event:any) => {
                                const re = /^[0-9\b]+$/;

                                // if value is not blank, then test the regex

                                if (event.target.value === '' || re.test(event.target.value)) {
                                  setQPax( event.target.value );
                                }
                                 }}
                            />
                        </FormControl>

                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className="cash-register-sidebar" >
                      <div className="cash-register-sidebar-info info-data">
                        
                        <div className="cash-register-sidebar-item">
                          <div className="cash-register-sidebar-item-detail">
                            
                            <div className="sidebar-item-box ">
                              <div className="sidebar-item-detail item-form">
                                Agrega los comentarios adicionales que sean de importancia para nuestro equipo
                                <br /><br />
                                <Divider />
                                <br />
                                <FormControl fullWidth className="form-item">
                                  <TextField
                                      label="Comentarios adicionales"
                                      value={ comments }
                                      multiline
                                      helperText=""
                                      variant="outlined"
                                      rows={4}
                                      onChange={(event:any) => { setComments( event.target.value );  } }
                                    />
                                </FormControl>
                              </div>
                            </div>
                            
                          </div>
                        </div>

                        
                      </div>
                      <div className="cash-register-sidebar-actions">
                        <BaseButton className="btn btn-sidebar-action btn-dubra" onClick={handleCreateReservation} disabled={disableButton} loading={disableButton}>
                        { reservationsId !== '' ?
                            'Crear reserva'
                          :  
                            'Guardar reserva'
                        } <ChevronRightIcon />
                        </BaseButton>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            :
              null
          }
    </div>
  )
}

export default ReservationsForm;