import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import BaseButton from '../../common/BaseButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { errorSectionType, guaranteeStatusType, errorType } from '../../../_helpers/enums/all';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import useErrorManager from '../../common/ErrorManager';
import useFetch, { baseApiURL } from '../../../_services/fetch';
import { get, post } from '../../../_services/api';
import { autocompleteProps } from '../../../_helpers/props/all';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Grid, Divider } from '@material-ui/core';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';

interface BootAssignProps {
  agency: string,
  guide: string,
  date: string,
  closeAction     : () => void,
}

interface generalReturnProps {
  note: number,
  name: string,
  returned: boolean,
  order_uuid: string,
}

interface productInfoProps {
  name        : string,
  variant_id  : number,
  q           : number,
  returned    : number,
  loading     : boolean,
  hard        : boolean,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }),
);

const ProductReturnList = ( props:BootAssignProps ) => {
  let history = useHistory();

  console.log( 'proooops', props );

  const { alertBlock, showAlert, resetAlerts } = useErrorManager({ id: 'productlist_modal', section: errorSectionType.Custom });
  const [ orderTitle, setOrderTitle ]           = useState<string>('');

  const [ error, setError ]                     = useState<any>();
  const [ loading, setLoading ]                 = useState<boolean>( true );
  const [ loadingSend, setLoadingSend ]         = useState<boolean>( false );
  const [ guaranteeStatus, setGuaranteeStatus ] = useState<number>(0);
  const [ activePending, setActivePending ] = useState<boolean>( false );

  const [ notes, setNotes ] = useState<generalReturnProps[]>([]);

  const [ agencyName, setAgencyName ] = useState<string|React.ReactNode>( <em>Sin información de reserva</em> );
  const [ guideNames, setGuideNames ] = useState<string[]|React.ReactNode[]>([ <em key={0}>Sin información de reserva</em> ]);

  const [ expanded, setExpanded] = React.useState<string | false>(false);
  const [ productsReturn, setProductsReturn ] = useState<productInfoProps[]>( [] );

  const classes = useStyles();

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleLoad = () => {
    setLoading( true );
    let guarantee_response = get( baseApiURL+'/get/attentions/detail_group?agency='+props.agency+'&guide='+props.guide+'&date='+props.date);

    guarantee_response.then((response:any) => { 
      setLoading( false );
      console.log( response );

      if( response.data ){
        var auxGeneralReturn:generalReturnProps[] = [];
        var auxDataLength:number = response.data.length;

        for(var i=0; i<auxDataLength; i++){
          // Info de retorno
          var auxReturned:boolean = false;
          if( response.data[i].returned !== '0' ){
            auxReturned = true;
          }

          /*
          for(let ia=0; ia<response.data[i].return_hard_total.length; ia++){
            console.log( response.data[i].order_id );
            //console.log( 'k', Object.keys(response.data[i].return_hard_total[ia]) );
            console.log( response.data[i].return_hard_total[ia] );
          }
          */

          // Agregamos los valores
          var auxData:generalReturnProps = {note: response.data[i].order_id, returned: auxReturned, name: response.data[i].pax_name, order_uuid: response.data[i].order_uuid};
          auxGeneralReturn.push( auxData ); 
        }

        setNotes( auxGeneralReturn );
      }

      if( response.agency ){
        setAgencyName( response.agency );
      }

      if( response.guide ){
        setGuideNames( response.guide );
      }


      // Agregamos info del retorno
      if( response.prod_total ){
        setProductsReturn( response.prod_total );
      }



    })
    .catch( error => {
      setLoading( false );
      setError( error );
    });
  }

  useEffect( () => {
    if( error ){
      setOrderTitle( 'eo!' );
    }
  }, [error] );

  useEffect( () => {
    handleLoad();
  }, [] );

  const handleCancel = () => {
    resetAlerts();
    props.closeAction();
  }

  const handleNoteDetail = ( note_uuid:string ) => {
    console.log( '****', note_uuid );
    history.push( '/returns/'+note_uuid );
  }

  const setChangeProductDetail = ( props:any ) => {
    // Buscamos el elemento para activar la carga
    var prodLength:number = productsReturn.length;
    var auxProduct:productInfoProps[] = [];
    for( var i=0; i<prodLength; i++ ){
      var auxProductItem:productInfoProps = productsReturn[i];

      if( props.variant_id === auxProductItem.variant_id ){
        
        if( props.action !== null ){
          auxProductItem.loading = props.action;
        }

        if( props.q ){
          auxProductItem.q = props.q;
        }
        if( props.returned ){
          auxProductItem.returned = props.returned;
        }

      }
      auxProduct.push( auxProductItem );
    }
    setProductsReturn( auxProduct );
  }

  const handleReturn = ( variant_id: number ) => {
    setChangeProductDetail( {variant_id: variant_id, action: true} );

    let saveReturn_response = post( baseApiURL+'/post/return/attentions', {
      variant_id  : variant_id,
      agency      : props.agency,
      guide       : props.guide,
      date        : props.date,
    });

    saveReturn_response.then((response:any) => {
      setChangeProductDetail( {variant_id: variant_id, action: false} );
      //console.log( response );

      handleLoad();



    }).catch( (error:any) => {
      setChangeProductDetail( {variant_id: variant_id, action: false} );
      showAlert({
        message: 'Hubo un error al registrar la devolución, por favor actualiza y vuelve a intentarlo. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });


  }

  return (
    <React.Fragment>
      <DialogContent className="guarantee-box-content">
        { alertBlock }
        <DialogContentText id="alert-dialog-description">
          <strong>Agencia:</strong> { agencyName }
          <br /><strong>Guía:</strong> { guideNames }
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          Información sobre la devolución de implementos
        </DialogContentText>
        <div className="guarantee-box-items">
          { !loading ?
              error ?
                <em>No logramos cargar la información de la atención, por favor intentalo más tarde o comunícate con los administradores.</em>
              : 
                <React.Fragment>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>Producto</strong></TableCell>
                        <TableCell align="right"><strong>Pendiente</strong></TableCell>
                        <TableCell align="right"><strong>Devolución</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { productsReturn.length === 0 ?
                          <TableRow>
                            <TableCell colSpan={3} scope="row" align="center">
                              <em>Sin arriendo blando</em>
                            </TableCell>
                          </TableRow>
                        :
                          productsReturn.map((item:productInfoProps, index:number) => {
                            let pending = item.q - item.returned;
                            
                            if( item.hard ){
                              return (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {item.name}
                                  </TableCell>
                                  <TableCell align="right">
                                    { pending }
                                  </TableCell>
                                  <TableCell align="right">
                                    { pending === 0 ?
                                        '-'
                                      :
                                        ( item.loading ?
                                            <CircularProgress />
                                          :
                                            <BaseButton onClick={() => { handleReturn( item.variant_id ) } }>Devolver</BaseButton>
                                        )
                                    }
                                  </TableCell>
                                </TableRow>
                              )
                            } else {
                              return null;
                            }
                            
                          })
                      }                      
                    </TableBody>
                  </Table>
                </React.Fragment>
            :
              <React.Fragment>
                <CircularProgress /><br /><br />
              </React.Fragment>
          }
        </div>
        
        <br />
        <br />
        <DialogContentText id="alert-dialog-description">
          Información sobre la entrega general de productos
        </DialogContentText>
        <div className="guarantee-box-items">
          { !loading ?
              error ?
                <em>No logramos cargar la información de la atención, por favor intentalo más tarde o comunícate con los administradores.</em>
              : 
                <React.Fragment>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>Nº nota</strong></TableCell>
                        <TableCell><strong>Nombre pax</strong></TableCell>
                        <TableCell><strong>Estado retorno</strong></TableCell>
                        <TableCell align="right"><strong>Ver</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { notes.map((item:generalReturnProps, index:number) => {
                          return (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                {item.note}
                              </TableCell>
                              <TableCell>
                                {item.name}
                              </TableCell>
                              <TableCell>
                                { item.returned ?
                                    'Devuelto'
                                  :  
                                    'Pendiente'
                                }
                              </TableCell>
                              <TableCell align="right">
                                <IconButton aria-label="edit" onClick={() => {handleNoteDetail(item.order_uuid);}}>
                                  <VisibilityIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                      
                    </TableBody>
                  </Table>
                </React.Fragment>
            :
              <React.Fragment>
                <CircularProgress /><br /><br />
              </React.Fragment>
          }
        </div>
      </DialogContent>
      { !loading &&
          <DialogActions>
            <div className="btn-container-dashboard">
              <BaseButton className="btn btn-outlined btn-dubra-cancel" onClick={() => { handleCancel() }} disabled={loadingSend}>
                Cerrar
              </BaseButton>
            </div>
          </DialogActions>
      }
    </React.Fragment>
  )
}

export default ProductReturnList;