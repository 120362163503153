import React, {useState, useEffect} from 'react';
import { baseApiURL } from '../../_services/fetch';
import { useHistory } from 'react-router-dom';
import useErrorManager from '../common/ErrorManager';
import { errorSectionType, errorType } from '../../_helpers/enums/all';
import LinearProgress from '@material-ui/core/LinearProgress';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import BaseButton from '../common/BaseButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CancelIcon from '@material-ui/icons/Cancel';

import { post, get } from '../../_services/api';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ConfirmationBox from '../common/ConfirmationBox';

interface roleOptionsProps {
  uuid: string,
  value: string,
}

const UserEdit = () => {
  let history = useHistory();

  const { alertBlock, showAlert, resetAlerts } = useErrorManager({ id: 'user_edit', section: errorSectionType.Custom });
  const [ loading, setLoading ] = useState<boolean>( true );
  const [ sendData, setSendData ] = useState<boolean>( false );
  
  const [ activeUser, setActiveUser ]   = useState<string>( '' );
  const [ activeRoles, setActiveRoles ] = useState<string[]>([]);
  const [ roles, setRoles ] = useState<roleOptionsProps[]>([]);
  
  const [ name, setName ] = useState<string>( '' );
  const [ validName, setValidName ] = useState<string>( '' );
  const [ mail, setMail ] = useState<string>( '' );
  const [ validMail, setValidMail ] = useState<string>( '' );
  const [ activeAccount, setActiveAccount ] = useState<boolean>( true );

  const [ modalDisable, setModalDisable ] = useState<boolean>( false );
  const [ modalDelete, setModalDelete ] = useState<boolean>( false );

  const getData = () =>{
    setLoading( true );

    const responseData = get(baseApiURL+'/get/users/'+activeUser+'/data');

    responseData.then((response:any) => {
      if( response.data ){
        //console.log( response.data );
        setLoading( false );

        if( response.data.name ){
          setName( response.data.name );
        }

        if( response.data.mail ){
          setMail( response.data.mail );
        }

        if( response.data.status ){
          setActiveAccount( true );
        } else {
          setActiveAccount( false );
          showAlert({
            message: 'Esta cuenta de usuario está deshabilitada. Puedes cambiar su información pero el usuario no puede acceder a su cuenta.',
            type_values: errorType.Warning,
            section: errorSectionType.Custom,
          }); 
        }
        
      } else if( response.new ){
        //console.log( response.new );
        setLoading( false );
        
      }

      if( response.options ){
        setRoles( response.options );
      }

      if( response.user_roles ){
        setActiveRoles( response.user_roles );
      }

    }).catch(error => {
      setLoading( false );
      showAlert({
        message: 'Hubo un error al cargar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });
  } 

  useEffect(() => {
    if( activeUser !== '' ){
      getData();
    }
  }, [activeUser]);

  useEffect(() => {
    if( history.location.pathname ){
      setActiveRoles([]);      
      setName( '' );
      setMail( '' );
      setValidMail( '' );

      let auxData = history.location.pathname.split( '/' );
      setActiveUser( auxData[2] );
    }
  }, [history.location.pathname]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let auxRolesActive:string[] = [];

    activeRoles.map((item:string, index: number) => {
      if( event.target.checked ){
        // Agrega
        auxRolesActive.push( item );
      } else {
        // Quita
        if( item !== event.target.name ){
          auxRolesActive.push( item );
        }
      }
    });

    if( event.target.checked ){
      // Agrega
      auxRolesActive.push( event.target.name );
    }

    setActiveRoles( auxRolesActive );
  }

  const handleSend = () => {
    // Validamos envío
    let auxSend = true;

    if( name === '' ){
      auxSend = false;
      setValidName( 'Este campo es obligatorio' );
      showAlert({
        message: 'Debes ingresar un nombre',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }

    if( mail === '' ){
      auxSend = false;
      setValidMail( 'Este campo es obligatorio' );
      showAlert({
        message: 'Debes ingresar al menos un correo',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    } else if( validMail !== '' ){
      auxSend = false;
      showAlert({
        message: 'El mail ingresado no es válido',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }
    
    if( auxSend ){
      setSendData( true );
      resetAlerts();

      const responseData = post(baseApiURL+'/post/users/save', {
        user: activeUser,
        roles: activeRoles,
        name: name,
        mail: mail,
      });

      responseData.then((response:any) => {
        if( response.data ){
          setSendData( false );
          //getData();
          
          if( activeUser === 'new' ){
            history.push('/users?new='+name);
          } else {
            history.push('/users?save='+name);
          }

          showAlert({
            message: 'La información del usuario se ha modificado correctamente.',
            type_values: errorType.Success,
            section: errorSectionType.Custom,
          });
        }
      }).catch(error => {
        setSendData( false );
        showAlert({
          message: 'Hubo un error al cargar la información. ['+error+']',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      });
    }
  }

  const handleMail = ( email:string ) => {
    setMail( email );

    if( email === '' ){
      setValidMail( '' );
    } else {
      // don't remember from where i copied this code, but this works.
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if ( re.test(email) ) {
          // this is a valid email address
          // call setState({email: email}) to update the email
          // or update the data in redux store.
          setValidMail( '' );
      }
      else {
          // invalid email, maybe show an error to the user.
          setValidMail( 'Debes ingresar un correo válido.' );
      }
    }
  }




  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  
  return (
    loading ? <LinearProgress color="primary" /> :
      <div className="content_all">
        <div className="content">
          { alertBlock }
          
            <div className="cash-register-box">
              <Paper className="fullwidth">
                <Grid container className="cash-register-container form-container-common">
                  <Grid item xs={12} sm={6} md={8} className="cash-register-content">
                    <Grid container  spacing={2} justify="space-between">
                      <Grid item>
                        <h1 className="form-title" style={{margin: 0}}>
                          { activeUser === 'new' ?
                              'Nueva cuenta:'
                            :
                              <React.Fragment>
                                Modificar cuenta: <Typography color={ activeAccount ? 'textPrimary' : 'error' }>( { activeAccount ? 'Cuenta activa' : 'Cuenta deshabilitada' } )</Typography>
                              </React.Fragment>
                          }
                        </h1>
                      </Grid>
                      { activeUser !== 'new' &&
                          <Grid>
                            <Typography color="error">
                              <Button aria-controls="simple-menu" aria-haspopup="true" color="secondary" onClick={handleClick}>
                                Configuración cuenta
                              </Button>
                              <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                              >
                                { activeAccount ? 
                                    <MenuItem onClick={() => { handleClose(); setModalDisable(true); }}>
                                      <Typography variant="button" color="textSecondary">
                                        Deshabilitar cuenta
                                      </Typography>
                                    </MenuItem>
                                  :
                                    <MenuItem onClick={() => { handleClose(); setModalDisable(true); }}>
                                      <Typography variant="button" color="textSecondary">
                                        Habilitar cuenta
                                      </Typography>
                                    </MenuItem>
                                }
                                <MenuItem onClick={() => { handleClose(); setModalDelete(true); }}>
                                  <Typography variant="button" color="error">
                                    Eliminar cuenta
                                  </Typography>
                                </MenuItem>
                              </Menu>
                            </Typography>
                          </Grid>   
                      }
                    </Grid>
                    <div className="form-detail-big">
                      <FormControl fullWidth className="form-item">
                        <TextField
                            label="Nombre y apellido"
                            value={name}
                            helperText={ validName === '' ? "Nombre y Apellido de la persona que utilizará esta cuenta." : validName }
                            error={ validName !== '' ? true : false }
                            variant="outlined"
                            onChange={(event:any) => {
                              setName( event.target.value );
                            }}
                          />
                      </FormControl>
                      <FormControl fullWidth className="form-item">
                        <TextField
                            label="Email"
                            value={ mail }
                            helperText={ validMail === '' ? "Ingresa el correo del pasajero para que reciba el comprobante de pago" : validMail }
                            error={ validMail !== '' ? true : false }
                            variant="outlined"
                            onChange={(event:any) => { handleMail( event.target.value );  } }
                          />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className="cash-register-sidebar" >
                    <div className="cash-register-sidebar-info info-data">
                      
                      <div className="cash-register-sidebar-item">
                        <div className="cash-register-sidebar-item-detail">
                          
                          <div className="sidebar-item-box ">
                            <div className="cash-register-sidebar-item-title">
                              PERMISOS
                            </div>
                            { roles.map((item:roleOptionsProps, index:number) => {
                                return (
                                  <div key={index} className="sidebar-item-detail item-form">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={activeRoles.includes(item.uuid)}
                                          onChange={handleChange}
                                          name={item.uuid}
                                          color="primary"
                                        />
                                      }
                                      label={item.value}
                                    />
                                  </div>
                                )
                            })}
                          </div>
                          <br />
                        </div>
                      </div>

                      
                    </div>
                    <div className="cash-register-sidebar-actions">
                      <BaseButton className="btn btn-sidebar-action btn-dubra" onClick={handleSend} disabled={ sendData } loading={sendData}>
                        Guardar cambios <ChevronRightIcon />
                      </BaseButton>
                      <BaseButton className="btn btn-sidebar-action btn-dubra-cancel" onClick={() => { history.goBack() }} disabled={sendData}>
                        Cancelar <CancelIcon />
                      </BaseButton>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </div>

      </div>
      { activeAccount ?
          <ConfirmationBox
            active={modalDisable}
            title="¿Estás seguro de deshabilitar este usuario?"
            
            sendText="Deshabilitar cuenta"
            sendUrl={'/post/users/disable'}
            sendProps={{
              user: activeUser,
            }}
            resolvedAction={()=> {
              setModalDisable( false );
              getData();
            }}
    
            closeAction={() => { setModalDisable(false); }}
            >
            La cuenta quedará suspendida, el usuario no podrá iniciar sesión ni acceder a su información.
          </ConfirmationBox>
        :
          <ConfirmationBox
            active={modalDisable}
            title="¿Estás seguro de habilitar este usuario?"
            
            sendText="Habilitar cuenta"
            sendUrl={'/post/users/enable'}
            sendProps={{
              user: activeUser,
            }}
            resolvedAction={()=> {
              setModalDisable( false );
              getData();
              resetAlerts();
            }}

            closeAction={() => { setModalDisable(false); }}
            >
            La cuenta volverá a quedar activa y el usuario podrá iniciar sesión.
          </ConfirmationBox>
      }

      <ConfirmationBox
            active={modalDelete}
            title={<span>¿Estás seguro de <strong>eliminar</strong> este usuario?</span>}
            
            sendText="Eliminar cuenta"
            sendUrl={'/post/users/delete'}
            sendProps={{
              user: activeUser,
            }}
            resolvedAction={()=> {
              //setModalDelete( false );
              //resetAlerts();
              history.push('/users?deleted='+name);
            }}

            closeAction={() => { setModalDelete(false); }}
            >
            La cuenta será <strong>eliminada</strong> y el usuario no podrá ingresar. Toda la información de esta cuenta será preservada para prevenir errores en conteo general.
            <br />
            <Typography variant="button" color="error">
              Esta acción no podrá ser revertida
            </Typography>
          </ConfirmationBox>

    </div>
  )
}

export default UserEdit;