import React, { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import TableContent from '../common/table/TableContent';

import { IColumn } from '../common/table/table-types';
import { errorSectionType, errorType } from '../../_helpers/enums/all';
import useErrorManager from '../common/ErrorManager';

import AssignmentIcon from '@material-ui/icons/Assignment';
import EditIcon from '@material-ui/icons/Edit';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import SummaryBlock from '../common/SummaryBlock';

import ReservationsFilters from './ReservationsFilters';
import ModalBox from '../common/ModalBox';

import ReservationClose from './modal/ReservationClose';

import TestRest from './custom_test/TestRest';

const Reservations = (props: RouteComponentProps) => {
  let history = useHistory();

  document.title = 'Reservas';
  
  //const { response, loading, error } = useFetch({ method: 'GET', path: '/get/presales'});
  const { alertBlock, showAlert } = useErrorManager({ id: 'reservations_page', section: errorSectionType.Custom });
  const [ loadingValidation ] = useState<boolean>( false );
  const [ activeModal, setActiveModal ] = useState<React.ReactNode|null>( null );

  useEffect( () => {

    if( history.location.search.includes('newreservation') ){      
      showAlert({
        message: 'La reserva ha sido creada con éxito.',
        type_values: errorType.Success,
        section: errorSectionType.Custom,
        id: 'reservations_page',
      });
    }
    if( history.location.search.includes('editreservation') ){      
      showAlert({
        message: 'La reserva ha sido modificada con éxito.',
        type_values: errorType.Success,
        section: errorSectionType.Custom,
        id: 'reservations_page',
      });
    }
    if( history.location.search.includes('assigned') ){      
      showAlert({
        message: 'La reserva se ha asignado con éxito.',
        type_values: errorType.Success,
        section: errorSectionType.Custom,
        id: 'reservations_page',
      });
    }

    

  }, [history.location.search] );


  const handleClose = ( props:any ) => {
    setActiveModal( <ReservationClose activeModalFunc={setActiveModal} uuid={props.all.uuid} /> );
  }

  const handleLink = ( props:any ) => {
   history.push( props.url );
  }
  
  const columns:Array<IColumn> = [
    { id: 'reservation_date',   visible: true,    numeric: false,     button: false,   disablePadding: true,      label: 'Fecha de reserva',     sortable: true     },
    { id: 'uuid',               visible: false,   numeric: false,     button: false,   disablePadding: true,      label: 'ID',                   sortable: false    ,   uKey: true},
    { id: 'time',               visible: true,    numeric: false,     button: false,   disablePadding: true,      label: 'Bloque horario',       sortable: false    },
    { id: 'username',           visible: true,    numeric: false,     button: false,   disablePadding: true,      label: 'Usuario',              sortable: true     },
    { id: 'tour_type',          visible: true,    numeric: false,     button: false,   disablePadding: true,      label: 'Tour',                 sortable: false    },
    { id: 'agency_name',        visible: true,    numeric: false,     button: false,   disablePadding: true,      label: 'Agencia',              sortable: true     },
    { id: 'guide_name',         visible: true,    numeric: false,     button: false,   disablePadding: true,      label: 'Guía excursión',       sortable: true     },
    { id: 'n_pax',              visible: true,    numeric: true,      button: false,   disablePadding: true,      label: 'Nº pax',               sortable: true     },
    { id: 'status_name',        visible: true,    numeric: false,     button: false,   disablePadding: true,      label: 'Estado',               sortable: false    },
    { id: 'comments',           visible: true,    numeric: false,     button: false,   disablePadding: true,      label: 'Comentarios',          sortable: false    },
  
    { id: 'assignation',        visible: true,    numeric: false,     button: true,    disablePadding: true,      label: 'Asignar',              sortable: false    ,   button_name: <AssignmentIcon />,        button_color: 'primary',       button_action: handleLink,     },
    { id: 'edit',               visible: true,    numeric: false,     button: true,    disablePadding: true,      label: 'Modificar',            sortable: false    ,   button_name: <EditIcon />,              button_color: 'secondary',     button_action: handleLink,     },
    { id: 'close',              visible: true,    numeric: false,     button: true,    disablePadding: true,      label: 'Cierre',               sortable: false    ,   button_name: <AlarmOnIcon />,           button_color: 'secondary',     button_action: handleClose,    },
    
  ];

  return (
    loadingValidation ? <LinearProgress color="primary" /> :
    <div className="content_all">
      <div className="content">
        { alertBlock }
    
        
        <SummaryBlock
          title="Resumen de reservas"
          urlData="/get/reservations/summary"
          date={{
            year: true,
            month: true,
            day: true,
          }}
        />

        {/*
        <Paper className="content">
          <TestRest />
        </Paper>
        */}
        
        
        <Paper className="content">
          <div className="paper_content_container">
            <TableContent
              id="reservations_table"
              title="Listado de reservas"
              columnsValues={columns}
              //urlData="/get/reservations"
              urlData="/admin/reservations/all"
              emptyData="No se encontraron reservas para mostrar"
              //filters={ <ReservationsFilters /> }
              today
              apiType
            />
          </div>
        </Paper>
        {/*
        */}
      </div>
      <ModalBox title="¿Deseas cerrar la atención?" active={ activeModal ? true : false } maxWidth="sm" >
        { activeModal }
      </ModalBox>
    </div>
  );
}

export default Reservations;
//<PresaleSummary />