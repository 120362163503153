import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import BlockContent from '../../common/BlockContent';
import { errorSectionType, roleType, errorType } from '../../../_helpers/enums/all';
import { baseApiURL } from '../../../_services/fetch';
import useErrorManager from '../../common/ErrorManager';
import { get, post } from '../../../_services/api';
import { cashRegisterStatusType } from '../../../_helpers/enums/all';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import StoreIcon from '@material-ui/icons/Store';
import Grid from '@material-ui/core/Grid';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { cashRegistersListProps, sendDataProps } from '../../../_helpers/props/all';
import BaseButton from '../../common/BaseButton';
import CashRegisterModal from './CashRegisterModal';

import { useAuthDataContext } from '../../../_auth/auth_provider';

const CashRegisterAdmin = () => {
  let history = useHistory()
  const { showAlert } = useErrorManager({ id: 'cashregisters_admin', section: errorSectionType.Custom });
  const errorModal = useErrorManager({ id: 'cashregisters_admin_modal', section: errorSectionType.Custom });
  const { userPermission } = useAuthDataContext();
  
  const [ loading, setLoading ]   = useState<boolean>( true );
  const [ cashRegisters, setCashRegisters ] = useState<cashRegistersListProps[]>([]);
  const [ activeCashRegister, setActiveCashRegister ] = useState<string>('');

  const [ savingData, setSavingData ] = useState<boolean>( false );

  const getData = () => {
    setLoading( true );
    const responseData = get(baseApiURL+'/get/cash-registers/all/list');

    responseData.then((response:any) => {
      if( response.data ){
        setLoading( false );
        console.log( response.data );
        setCashRegisters( response.data );
      }
    }).catch(error => {
      setLoading( false );
      showAlert({
        message: 'Hubo un error al cargar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });

  }
  
  useEffect(() => {
    getData();
  }, []);

  const handleCancel = () => {
    errorModal.resetAlerts();
    setActiveCashRegister( '' );
  }

  const handleSend = ( props:sendDataProps ) => {
    setSavingData( true );
    errorModal.resetAlerts();
    
    const responseData = post(baseApiURL+'/post/cash-register/assign', {
      cash_register: activeCashRegister,
      cashier: props.cashier,
      sac: props.sac,
    });

    responseData.then((response:any) => {
      if( response.data ){
        setSavingData( false );
        setActiveCashRegister( '' );
        console.log( response.data );
        getData();
      }
    }).catch(error => {
      setSavingData( false );
      errorModal.showAlert({
        message: 'Hubo un error al cargar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });


    

  }

  return (
    <div className="content_all">
      <div className="content">
        <BlockContent title="Administrador de tiendas" loading={loading}>
          <List>
            { cashRegisters.length !== 0 ? 
                cashRegisters.map((item:cashRegistersListProps, index:number) => {
                  let auxClass = '';
                  if( cashRegisterStatusType.Open === item.status_id ){
                    auxClass = 'status_active';
                  } else if( cashRegisterStatusType.Closed === item.status_id || cashRegisterStatusType.Disabled === item.status_id || cashRegisterStatusType.ForcedClosed === item.status_id ){
                    auxClass = 'status_closed';
                  } else {
                    auxClass = 'status_pending';
                  }
  
                  return (
                    <ListItem key={index}>
                      <Grid container>
                        <Grid item xs={12} sm={1}>
                          <ListItemAvatar>
                            <Avatar className={auxClass}>
                              <StoreIcon />
                            </Avatar>
                          </ListItemAvatar>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <ListItemText primary={item.name} secondary={ item.cashier_name === '' ? <i>Sin información</i> : item.cashier_name } />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ListItemText primary="SAC" secondary={item.sac.length === 0 ? <em>Sin información</em> : item.sac.map((sac:any, index: number) => {return ( index !== 0 ? ', ' : '' )+sac}) } />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ListItemSecondaryAction>
                            <div className="btn-container-dashboard">
                              { cashRegisterStatusType.Closed === item.status_id || cashRegisterStatusType.Disabled === item.status_id || cashRegisterStatusType.ForcedClosed === item.status_id ? 
                                  ( userPermission([roleType.Admin, roleType.SuperAdmin, roleType.StoreManager]) ) &&
                                    <BaseButton className="btn btn-simple btn-dubra" onClick={() => { setActiveCashRegister( item.cash_register ) }}>Modificar caja</BaseButton>
                                :
                                  ( userPermission([roleType.Admin, roleType.SuperAdmin, roleType.CashSupervisor]) ) &&
                                    <BaseButton className="btn btn-simple btn-dubra-info" onClick={() => { history.push('/cash-register/'+item.cash_register+'/close') }}>Cerrar caja</BaseButton>
                              }
                            </div>
                          </ListItemSecondaryAction>
                        </Grid>
                      </Grid>
                    </ListItem>
                  )
                }) 
              :
                <div>
                  <i>No existen cajas creadas</i>
                  <br />
                  <BaseButton className="btn btn-simple btn-dubra" onClick={() => { history.push('/admin/cash-registers/new') }}>Crear nueva caja</BaseButton>
                </div>
            }
          </List>
          <CashRegisterModal cashRegisterUUID={activeCashRegister} cancel={handleCancel} error={errorModal} send={handleSend} extraData={cashRegisters} savingData={savingData} />
        </BlockContent>
      </div>
    </div>
  );
}

export default CashRegisterAdmin;