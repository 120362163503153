import Tab from "@material-ui/core/Tab";
import React, { createContext, useState, useEffect, Dispatch, SetStateAction, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useAuthDataContext } from "../../../_auth/auth_provider";
import { roleType } from "../../../_helpers/enums/all";
import { menuLinkProps } from '../props';

interface tabsDataProps {
  tabItems      : React.ReactNode,
  tabsMenu      : menuLinkProps[],
  activeValue   : number;
  setDEFActiveValue: Dispatch<SetStateAction<number>>,
  setActivePath : Dispatch<SetStateAction<string>>,
}

const initialTabsData:tabsDataProps = {
  tabItems      : null,
  tabsMenu      : [],
  activeValue   : 0,
  setDEFActiveValue: () => {},
  setActivePath : () => {},
};

export const TabsContext = createContext( initialTabsData );
export const useTabsDataContext = () => useContext(TabsContext);

const TabsProvider = (props:any) => {
  let history = useHistory();
  const { user, userPermission, setTabsActive, activeCashRegister } = useAuthDataContext();

  const [ activePath, setActivePath ] = useState<string|null>( null );
  const [ tabsMenu, setTabsMenu ] = useState<menuLinkProps[]>( [] );
  const [ tabItems, setTabItems ] = useState<React.ReactNode>( initialTabsData.tabItems );
  //const [ activeValue, setActiveValue ] = useState<number>( 0 );

  const [ auxActiveValue, setActiveValue ] = useState<number>( 0 );
  const [ activeValue, setDEFActiveValue ] = useState<number>( 0 );
  const [ activeCashier, setActiveCashier ] = useState<string>( '' );


  useEffect(() => {
    //console.log('-+-+-+-+-+-+-++-+-+-+-', activeCashRegister, user);
    setActiveCashier( activeCashRegister );
  }, [activeCashRegister]);

  /*
  
        */

  useEffect( () => {
    setActivePath( history.location.pathname );
  }, [] );

  useEffect( () => {

    if( tabsMenu.length !== 0 && user.usertype ){
      //console.log( 'AUXactive:', auxActiveValue );
      //console.log( i, auxCountPemission );

      var auxCountPemission = 0;

      for(var i=0; i<tabsMenu.length; i++){

        //console.log( tabsMenu[i].permission, '***', user.usertype  );
        

        if( tabsMenu[i].permission.some(r => user.usertype.indexOf(r) >= 0 ) ){
          //console.log('_____', auxCountPemission);
          //console.log( 'tabs', tabsMenu );


          if(auxActiveValue === i){
            //console.log( 'eo' );
            setDEFActiveValue( auxCountPemission );
          } else {
            //console.log('b');
          }

          auxCountPemission++;
        } else {
          //console.log('c');
        }
      }
    }

    

  }, [auxActiveValue, tabsMenu, user]);


  useEffect( () => {
    //console.log( 'active:', activeValue );
  }, [activeValue] );

  useEffect( () => {
    var auxMenu = null;
    if( user ){
      if( tabsMenu.length){
        //console.log( 'modifica el tab', tabsMenu );
  
        auxMenu = tabsMenu.map((item: menuLinkProps, index: number) => {
  
          // Verificamos que tenga los permisos
          if( userPermission(item.permission) ){
            if( history.location.pathname === item.link ){
              setActiveValue( index );
            }
  
            return <Tab key={index} label={item.name} />
          } else {
            return null
          }
        });
      }
  
      if( auxMenu == null ){
        setTabsActive( false );
      } else {
        setTabsActive( true );
      }
      setTabItems( auxMenu );
    }    
  }, [tabsMenu, user] );

  useEffect( () => {
    if( activePath ){
      //console.log('****___****', activePath);

      if( activePath === '/' ){
        //console.log('Path: Dashboard');
        setTabsMenu([]);
      } else if( activePath.includes( '/admin/' ) ){
        //console.log('Path: Admin');
      
        // Revisamos tabs para cash registers
        if(  activePath.includes( '/admin/cash-registers' ) ){
          console.log('Path: Cash Registers');
    
          if( activePath === '/admin/cash-registers' ){
            setActiveValue( 0 );
          } else if( activePath === '/admin/cash-registers/new' ){
            setActiveValue( 1 );
          }
    
          setTabsMenu( [{
            name        : 'Listado de cajas',
            link        : '/admin/cash-registers',
            permission  : [ roleType.Admin, roleType.SuperAdmin ],
          }, {
            name        : 'Agregar caja',
            link        : '/admin/cash-registers/new',
            permission  : [ roleType.Admin, roleType.SuperAdmin ],
          }] );
        }

      } else if( activePath.includes( '/presale' ) ){
        //console.log('Path: Presale');
  
        if( activePath === '/presale' ){
          setActiveValue( 0 );
        } else if( activePath === '/presale/' ){
          setActiveValue( 0 );
        }
  
        setTabsMenu( [{
          name        : 'Listado',
          link        : '/presale',
          permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.Agency, roleType.CashSupervisor, roleType.Executive, roleType.Reseller ],
        }, {
          name        : 'Crear preventa',
          link        : '/presale/new',
          permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.Agency, roleType.Executive, roleType.Reseller ],
        }] );
  
      } else if( activePath.includes( '/reservations' ) ){
        //console.log('Path: Reservations');
        
        if( activePath === '/reservations' ){
          setActiveValue( 0 );
        } else if( activePath === '/reservations/' ){
          setActiveValue( 0 );
        }
  
        setTabsMenu( [{
          name        : 'Listado',
          link        : '/reservations',
          permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.Agency, roleType.CashSupervisor, roleType.StoreManager, roleType.Executive, roleType.TouristGuide ],
        }, {
          name        : 'Crear reserva',
          link        : '/reservations/new',
          permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.StoreManager, roleType.Agency, roleType.Executive ],
        }] );
  
      } else if( activePath.includes( '/users' ) ){
        //console.log('Path: Users');
        
        if( activePath === '/users' ){
          setActiveValue( 0 );
        } else if( activePath === '/users/' ){
          setActiveValue( 0 );
        } else if( activePath === '/users/new' ){
          setActiveValue( 1 );
        }
  
        setTabsMenu( [{
          name        : 'Listado',
          link        : '/users',
          permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.Agency ],
        }, {
          name        : 'Crear usuario',
          link        : '/users/new',
          permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.Agency ],
        }] );
  
      } else if( activePath.includes( '/skiroom/' ) ){
        //console.log('Path: Skiroom');

        // Leemos la url
        let auxData = history.location.pathname.split( '/' );
        let auxData2 = auxData[2].split( '?' );
  
        if( activePath === '/skiroom/' ){
          setActiveValue( 0 );
        } else if( activePath === '/skiroom/new' ){
          setActiveValue( 1 );
        }
  
        setTabsMenu( [{
          name        : 'Listado pasajeros',
          link        : '/skiroom/'+auxData2,
          permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.Skiroom, roleType.SAC ],
        }, {
          name        : 'Agregar pasajero',
          link        : '/skiroom/'+auxData2+'/new',
          permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.Skiroom, roleType.SAC ],
        }] );
  
      //} else if( history.location.pathname.includes( '/cash-register' ) ){
      } else if( activePath.includes( '/returns' ) ){
        //console.log('Path: Returns');

        if( activePath === '/returns' ){
          setActiveValue( 0 );
        } else if( activePath === '/returns/attentions/group' ){
          setActiveValue( 2 );
        } else if( activePath === '/returns/attentions' ){
          setActiveValue( 1 );
        } else if( activePath.includes('/returns/') ){
          setActiveValue( 0 );
        }


        setTabsMenu( [{
          name        : 'Búsqueda',
          link        : '/returns',
          permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.CashSupervisor, roleType.StoreManager, roleType.Return ],
        }, {
          name        : 'Ver atenciones',
          link        : '/returns/attentions',
          permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.CashSupervisor, roleType.StoreManager ],
        }, {
          name        : 'Atenciones por grupo',
          link        : '/returns/attentions/group',
          permission  : [ roleType.Admin, roleType.SuperAdmin, roleType.CashSupervisor, roleType.StoreManager, roleType.Return ],
        }] );





        
        

  
        
  
        
        /*
        for(var i=0; i<tabsMenu.length; i++){
          if( tabsMenu[i].permission.some(r => user.usertype.indexOf(r) >= 0 ) ){
            
            if(newValue === auxCountPemission){
              history.push( tabsMenu[i].link );
            }
    
            auxCountPemission++;
          }
          
        }
        */






  
      //} else if( history.location.pathname.includes( '/cash-register' ) ){
      } else if( history.location.pathname.includes( '/cash-register' ) ){
        //console.log('Path: Cash register');

        setTabsMenu( [{
          name        : 'Caja',
          link        : '/cash-register',
          permission  : [ roleType.Cashier ],
        }, {
          name        : 'Mis atenciones',
          link        : '/cash-register/attentions',
          permission  : [ roleType.Cashier ],
        }] ); 
  
        if( history.location.pathname === '/cash-register' ){
          setActiveValue( 0 );
        } else if( history.location.pathname === '/cash-register/attentions' ){
          setActiveValue( 1 );
        } else if( history.location.pathname.includes('/cash-register/sale/') ){
          setActiveValue( 0 );
        }
        
        if( ( history.location.pathname === '/cash-register' || history.location.pathname === '/cash-register/attentions' || ( history.location.pathname.includes('cash-register') && history.location.pathname.includes('close') ) ) ){
          
          console.log('eoooo', activeCashier);
          
          setTabsMenu( [{
            name        : 'Caja',
            link        : '/cash-register',
            permission  : [ roleType.Cashier ],
          }, {
            name        : 'Cerrar caja',
            //link        : '/cash-register/'+activeCashRegister+'/close',
            link        : `/cash-register/{activeCashier}/close`,
            permission  : [ roleType.Cashier ],
          }, {
            name        : 'Mis atenciones',
            link        : '/cash-register/attentions',
            permission  : [ roleType.Cashier ],
          }] ); 
  
          if( history.location.pathname === '/cash-register' ){
            setActiveValue( 0 );
          } else if( history.location.pathname.includes('cash-register') && history.location.pathname.includes('close') ){
            setActiveValue( 1 );
          } else if( history.location.pathname === '/cash-register/attentions' ){
            setActiveValue( 2 );
          } else if( history.location.pathname.includes('/cash-register/sale/') ){
            setActiveValue( 0 );
          }
        }
  
      } else {
        //console.log('Path: No Match');
        setTabsMenu( [] );
      }

    }
  }, [activePath] );

  return <TabsContext.Provider value={{tabItems, tabsMenu, activeValue, setDEFActiveValue, setActivePath}} {...props} />;
};

export default TabsProvider;