import React , { useEffect, useState } from 'react';
import { get, ensureAuth } from './api';
import { FetchingProps } from '../_helpers/props';
import jwt from 'jwt-decode';
import { DateTime } from 'luxon';
import { setAccessToken } from '../_auth/auth';

export const baseApiURL = process.env.REACT_APP_API_URL;
export const baseApiRest = process.env.REACT_APP_APII;
export const apiVersion = process.env.REACT_APP_API_VERSION;


const useFetch = (props: FetchingProps) => {
  const accessToken = ensureAuth();
  const [response, setResponse] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  // Token renewal
  const minuteDiff = 30;
  const [ renewal, setRenewal ] = useState<boolean>( false );


  accessToken.then((token) => {
    if( token ){
      let auxToken:any = jwt(token);
      if( auxToken.exp ){
        let auxTokenDate = DateTime.fromMillis( auxToken.exp*1000 );
        let auxDateActive = DateTime.local();

        //console.log( auxTokenDate );

        // La fecha del token es mayor a la fecha activa: expirado
        if( auxTokenDate < auxDateActive ){
          
        } else {
          // Aún queda tiempo disponible para renovar
          // Verifico si la diferencia es menor a 10 minutos, de ser así, renuevo el token

          //console.log( 'Debo renovar el token', auxTokenDate.minus({ minutes: minuteDiff }), auxDateActive );
          if( auxTokenDate.minus({ minutes: minuteDiff }) < auxDateActive ){

            if( !renewal ){
              //console.log( 'Últimos '+minuteDiff+' minutos, renovamos' );
              setRenewal(true);

              get( baseApiURL+'/jwt/token' ).then( (response:any) => {
                setRenewal( false );
                //console.log( '***', response );
                setAccessToken( response.token );
              })
              .catch( error => {
                setRenewal( false );
              });
            }

          }

        }
      }
    }
  });

  const loadData = () => {
    
    if( props.method === 'GET' ){
      setLoading( true );

      get( baseApiURL+props.path ).then( (response:any) => {
        setLoading( false );
        setResponse( response );
      })
      .catch( error => {
        setLoading( false );
        setError( error );
      });
    } else {
      alert('Método no difinido');
    }
  }

  useEffect(() => {
    loadData();
  }, [props.method, props.path]);
  return { response, loading, error, loadData };
};

export default useFetch;