import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import BaseButton from '../../common/BaseButton';
import MenuItem from '@material-ui/core/MenuItem';
import { useCartDataContext } from '../cart_provider';
import { changeProductProps, KitChangeValues } from '../../../_helpers/props/cash_register_props';
import { baseApiURL } from '../../../_services/fetch';
import { get } from '../../../_services/api';
import Divider from '@material-ui/core/Divider';
import { TextField } from '@material-ui/core';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt, faEquals, faCheck } from '@fortawesome/free-solid-svg-icons'

interface changeProps {
  product_name                : string,
  product_id                  : number,
  open                        : boolean,
  closeDialog()               : void,
  itemIndex                   : number,
  getProductsName : Dispatch<SetStateAction<any>>;
}

interface dataKitChangeProps {
  parent        : number,
  name          : string,
  variation_id  : number,
}

const ChangeDialog = ( props:changeProps ) => {
  const { cart, setCart, changeOptions, getProductName } = useCartDataContext();
  const [ loadingProducts, setLoadingProducts ] = useState<boolean>( true );
  const [ loadedProducts, setLoadedProducts ] = useState<boolean>( false ); 

  const [ kitProductsAdultos, setKitProductsAdultos ] = useState<Array<dataKitChangeProps>>( [] );
  const [ kitProductsJunior, setKitProductsJunior ] = useState<Array<dataKitChangeProps>>( [] );
  const [ changes, setChanges ] = React.useState<KitChangeValues>( cart.cart_items[props.itemIndex] ? cart.cart_items[props.itemIndex].changes : {adults: [], junior: []} );

  const [ expandedData, setExpandedData ] = useState<Array<any>>( [] );

  const loadKitProducts = () => {
    
    // Revisamos los cambios que existen
    let auxChangesAdults:any = [];
    
    if( cart.cart_items[props.itemIndex].adults !== undefined ){
      if( cart.cart_items[props.itemIndex].adults !== 0 ){

        // Viene vacío, debo crear los productos
        for( let i=0; i < cart.cart_items[props.itemIndex].adults!; i++ ){

          let changeProduct:Array<changeProductProps> = [];

          if( changes.adults[ i ] ){
            //console.log( 'Existe, debo recuperar los valores' );

            auxChangesAdults.push({
              name_pax  : changes.adults[ i ].name_pax,
              data      : changes.adults[ i ].data,
            });
          } else {
            //console.log( 'No existe, lo creamos' );

            // Recorremos los productos que están en el kit
            const productsLength = kitProductsAdultos.length;
            for( let i_products=0; i_products<productsLength; i_products++ ){
              
              if( kitProductsAdultos[i_products].variation_id ){
                changeProduct.push({
                  product_original : kitProductsAdultos[i_products].variation_id,
                  product_new      : null,
                });
              }
            }

            auxChangesAdults.push({
              name_pax  : '',
              data      : changeProduct,
            });
          }

        }

      }
    }





















    let auxChangesJunior:any = [];

    
    if( cart.cart_items[props.itemIndex].junior !== undefined ){
      if( cart.cart_items[props.itemIndex].junior !== 0 ){

        // Viene vacío, debo crear los productos
        for( let i=0; i < cart.cart_items[props.itemIndex].junior!; i++ ){

          let changeProduct:Array<changeProductProps> = [];

          if( changes.junior[ i ] ){
            //console.log( 'Existe, debo recuperar los valores' );

            auxChangesJunior.push({
              name_pax  : changes.junior[ i ].name_pax,
              data      : changes.junior[ i ].data,
            });
          } else {
            //console.log( 'No existe, lo creamos' );

            // Recorremos los productos que están en el kit
            const productsLength = kitProductsJunior.length;
            for( let i_products=0; i_products<productsLength; i_products++ ){
              
              if( kitProductsJunior[i_products].variation_id ){
                changeProduct.push({
                  product_original : kitProductsJunior[i_products].variation_id,
                  product_new      : null,
                });
              }
            }

            auxChangesJunior.push({
              name_pax  : '',
              data      : changeProduct,
            });
          }

        }

      }
    }

    setChanges( changes => ({...changes, adults: auxChangesAdults, junior: auxChangesJunior}) );

  }

  useEffect(  () => {
    if( kitProductsAdultos.length !== 0 && kitProductsJunior.length !== 0 ){
      loadKitProducts();
    }
  }, [kitProductsAdultos, kitProductsJunior]);

  const getKitProducts = () => {
    setLoadingProducts( true );
    let expanded_response = get( baseApiURL+'/get/products/kit/'+cart.cart_items[props.itemIndex].product_id);
    var auxInfoExpanded:Array<any> = [];

    expanded_response.then((response:any) => { 
      setLoadingProducts( false );
      setLoadedProducts( true );
      
      if( response.data.Adulto ){
        console.log( 'carga adulto' );
        setKitProductsAdultos( response.data.Adulto );
      }

      if( response.data.Junior ){
        console.log( 'carga junior' );
        setKitProductsJunior( response.data.Junior );
      }

    })
    .catch( error => {
      setLoadingProducts( false );
      console.log(error.response)
      auxInfoExpanded.push(
        <div key={auxInfoExpanded.length}>
          No pudimos cargar la información
          <br /><BaseButton onClick={getKitProducts}>Reintentar</BaseButton>
        </div>
      );

      setExpandedData( auxInfoExpanded );
    });
  }
  
  useEffect( () => {
    if( props.open ){
      if( !loadedProducts ){
        getKitProducts();
      } else {
        loadKitProducts();
      }
    }
  }, [props.open] )

  interface paxNameProps {
    size: string,
    indexItem: number,
    value: any,
  }

  const setPaxName = (props:paxNameProps) => {

    if( props.size === 'adults' ){
      let auxChangesData = changes.adults;
      auxChangesData[ props.indexItem ].name_pax = props.value;
      setChanges( changes => ({...changes, adults: auxChangesData }) );
    } else if( props.size === 'junior' ){
      let auxChangesData = changes.junior;
      auxChangesData[ props.indexItem ].name_pax = props.value;
      setChanges( changes => ({...changes, junior: auxChangesData }) );
    } else {
      return 'No encontrado';
    }

  }


  interface productChangeProps {
    indexParent: number,
    indexItem: number,
    size: string,
    new_variation_id: any,
  }

  const handleProductChange = (props:productChangeProps) => {
    if( props.size === 'adults' ){
      let auxChangesData = changes.adults;
      if( props.new_variation_id === '' ){
        auxChangesData[ props.indexParent ].data[ props.indexItem ].product_new = null;
      } else {
        auxChangesData[ props.indexParent ].data[ props.indexItem ].product_new = props.new_variation_id;
      }
      setChanges( changes => ({...changes, adults: auxChangesData }) );
    } else if( props.size === 'junior' ){
      let auxChangesData = changes.junior;
      if( props.new_variation_id === '' ){
        auxChangesData[ props.indexParent ].data[ props.indexItem ].product_new = null;
      } else {
        auxChangesData[ props.indexParent ].data[ props.indexItem ].product_new = props.new_variation_id;
      }
      setChanges( changes => ({...changes, junior: auxChangesData }) );
    } else {
      return 'No encontrado';
    }
  }

  interface itemToChangeProps {
    indexParent: number,
    indexItem: number,
    size: string,
    item: any,
  }
  

  const ItemToChange = ( props:itemToChangeProps ) => {
    const auxOptions = changeOptions.map( (item:any, index: number) => {
      if( props.item.product_original !== item.variation_id ){
        return <MenuItem key={index} value={ item.variation_id }>{item.name}</MenuItem>
      }
    });

    return (
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            {
              props.item.product_new !== null ?
                <FontAwesomeIcon icon={faCheck} />
              :
                <FontAwesomeIcon icon={faEquals} />
            }                  
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={ getProductName( { size: props.size, variation_id: props.item.product_original } ) }
          secondary={props.item.product_new === null  ? 'Sin cambio' : 'Cambiado por '+getProductName( { size: props.size, variation_id: props.item.product_new } )}
        />
        <ListItemSecondaryAction>
        <FormControl>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Producto
          </InputLabel>
          <Select
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={ props.item.product_new !== null ? props.item.product_new : '' }
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => { handleProductChange({ indexParent: props.indexParent, indexItem: props.indexItem, size: props.size, new_variation_id: event.target.value }) }}
            displayEmpty
          >
            <MenuItem value="">
              <em>Selecciona</em>
            </MenuItem>
            {auxOptions}
            <MenuItem value={9999}>
              <em>No lleva</em>
            </MenuItem>
          </Select>
        </FormControl>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  useEffect( () => {
    if( changes.adults.length !== 0 || changes.junior.length !== 0 ){
      var auxProductInfo:any = [];

      let adultsTotal = changes.adults.length;
      for( let i=0; i<adultsTotal; i++ ){
        var auxProducts = changes.adults[i].data.map((item:any, index:number) => {          
          return (
            <ItemToChange key={index} size="adults" item={item} indexParent={i} indexItem={index}/>
          )
        });

        auxProductInfo.push(
          <div className="change-box-products-item" key={i}>
            <div className="change-box-products-item-data">
              <TextField value={changes.adults[i].name_pax} label={'Nombre adulto '+(i+1)} variant="outlined" onChange={(event:any) => { setPaxName({ size: 'adults', indexItem: i, value: event.target.value })  } } />
              <br />
              <List>
                { auxProducts }
              </List>

            </div>
            <Divider />
          </div>
        );
      }





      let juniorTotal = changes.junior.length;
      for( let i=0; i<juniorTotal; i++ ){

        var auxProducts = changes.junior[i].data.map((item:any, index:number) => {          
          return (
            <ItemToChange key={index} size="junior" item={item} indexParent={i} indexItem={index}/>
          )
        });

        auxProductInfo.push(
          <div className="change-box-products-item" key={i+adultsTotal}>
            <div className="change-box-products-item-data">
              <TextField value={changes.junior[i].name_pax} label={'Nombre junior '+(i+1)} variant="outlined" onChange={(event:any) => { setPaxName({ size: 'junior', indexItem: i, value: event.target.value })  } } />
              <br />
              <List>
                { auxProducts }
              </List>

            </div>
            <Divider />
          </div>
        );
      }
      
      setExpandedData( auxProductInfo );
    }
  }, [changes] )

  const handleClose = ( save:boolean ) => {
    if( save ){
      
      if( props.itemIndex !== 9999 ){
        let auxAuxItem = cart.cart_items;
        auxAuxItem[ props.itemIndex ].changes = changes;
        setCart( cart => ({...cart, cart_items: auxAuxItem}) );
      } else {
        console.log( 'error de index' );
      }

      props.closeDialog();
    } else {
      setChanges( cart.cart_items[props.itemIndex].changes );
      props.closeDialog();
    }
    
  }

  

  return (
    <Dialog open={props.open} className="change-box">
        <DialogTitle id="alert-dialog-title">Modificar Kit: {props.product_name}</DialogTitle>
        <DialogContent className="change-box-content">
          <DialogContentText id="alert-dialog-description">
            A continuación puedes modificar los productos que componen cada uno de los kit que se agregaron al carro de compras
          </DialogContentText>

          <div className="change-box-products">
            { !loadingProducts ?
                expandedData
              :
                <CircularProgress />
            }
          </div>

        </DialogContent>
        <DialogActions>
          <BaseButton onClick={() => { handleClose(false) }}>
            Disagree
          </BaseButton>
          <BaseButton onClick={() => { handleClose(true) }}>
            Agree
          </BaseButton>
        </DialogActions>
      </Dialog>
    
    
  )
}

export default ChangeDialog;