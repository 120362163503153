import React from 'react';
import { presaleItemProps } from '../../_helpers/props/all';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Grid from '@material-ui/core/Grid';
import InputNumber from '../common/InputNumber';
import Typography from '@material-ui/core/Typography';

const PresaleItem = ( props:presaleItemProps ) => {
  
  return (
    <li>
      <h3><ChevronRightIcon /> {props.name}</h3>
      <Grid container justify="center">
        <Grid item xs={12} sm={5}>
          <Typography align="center">Adulto</Typography>
          <InputNumber value={ props.totalAdult } onLess={() => {props.setTotal({ product_name: props.name, variant_id: props.variant_adult, action: 'minus' })}} onPlus={() => {props.setTotal({ product_name: props.name, variant_id: props.variant_adult, action: 'add' })}} />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography align="center">Junior</Typography>
          <InputNumber value={ props.totalJunior } onLess={() => {props.setTotal({ product_name: props.name, variant_id: props.variant_junior, action: 'minus' })}} onPlus={() => {props.setTotal({ product_name: props.name, variant_id: props.variant_junior, action: 'add' })}} />
        </Grid>
      </Grid>
    </li>
  )
}

export default PresaleItem;