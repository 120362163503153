import React, {useState, useEffect} from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { selectDateProps } from '../../../_helpers/props/all';
import { DateTime } from 'luxon';



export const YearSelect = ( props:selectDateProps ) => {
  const [ auxYears ] = useState<any[]>( [
    {value: 'all', name: 'Todos'},
  ]);
  const [ yearOptions, setYearOptions ] = useState<React.ReactNode>();

  useEffect( () => {
    var auxOptions:any[] = auxYears;
    for (var i = 2020; i <= DateTime.local().year; i++) {
      auxOptions.push(
        {value: i, name: i},
      );
    }
    
    setYearOptions( auxOptions.map( ( item:any, index:number ) => {
      return <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
    }) );
  }, [auxYears] );
  
  return (
    <FormControl variant="outlined">
      <InputLabel id="summary-select">Año</InputLabel>
      <Select
        labelId="summary-select"
        value={ props.value ? props.value : '' }
        onChange={ props.onChange }
        disabled={ props.disabled }
        displayEmpty
      >
        { yearOptions }
        
      </Select>
    </FormControl>  
  );
}

export const MonthSelect = ( props:selectDateProps ) => {
  const [ auxMonths ] = useState<any[]>( [
    {value: 'all', name: 'Todos'},
    {value: 1, name: 'Enero'},
    {value: 2, name: 'Febrero'},
    {value: 3, name: 'Marzo'},
    {value: 4, name: 'Abril'},
    {value: 5, name: 'Mayo'},
    {value: 6, name: 'Junio'},
    {value: 7, name: 'Julio'},
    {value: 8, name: 'Agosto'},
    {value: 9, name: 'Septiembre'},
    {value: 10, name: 'Octubre'},
    {value: 11, name: 'Noviembre'},
    {value: 12, name: 'Diciembre'},
  ]);
  const [ monthOptions, setMonthOptions ] = useState<React.ReactNode>();

  useEffect( () => {
    setMonthOptions( auxMonths.map( ( item:any, index:number ) => {
      return <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
    }) );
  }, [auxMonths] );
  
  return (
    <FormControl variant="outlined">
      <InputLabel id="summary-select">Mes</InputLabel>
      <Select
        labelId="summary-select"
        value={ props.value ? props.value : '' }
        onChange={ props.onChange }
        disabled={ props.disabled }
        displayEmpty
      >
        { monthOptions }
        
      </Select>
    </FormControl>  
  );
}

export const DaySelect = ( props:selectDateProps ) => {
  const [ auxDays ] = useState<any[]>( [
    {value: 'all', name: 'Todos'},
  ]);

  for( let i=1; i<=31; i++ ){
    auxDays.push( {value: i, name: ( i < 10 ? '0'+i : i )}, )
  }

  const [ dayOptions, setDayOptions ] = useState<React.ReactNode>();

  useEffect( () => {
    setDayOptions( auxDays.map( ( item:any, index:number ) => {
      return <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
    }) );
  }, [] );
  
  return (
    <FormControl variant="outlined">
      <InputLabel id="summary-select">Día</InputLabel>
      <Select
        labelId="summary-select"
        value={ props.value ? props.value : '' }
        onChange={ props.onChange }
        disabled={ props.disabled }
        displayEmpty
      >
        { dayOptions }
        
      </Select>
    </FormControl>  
  );
}