import React, { useState, useEffect } from 'react';
import { Grid, FormControl, TextField } from '@material-ui/core';

import BaseButton from '../common/BaseButton';
import { addToQuery, createQuery, removeFromQuery, urlParamsProps } from '../../_services/request';
import { useHistory } from 'react-router-dom';

import useDateRange from  '../common/filters/DateRange';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

const AttentionsNotesFilters = () => {
  let history = useHistory();
  const { DateRangeFilter, handleCleanDateRange, handleSearchDateRange } = useDateRange();
  const [ note, setNote ] = useState<string>('');

  const handleSearch = () => {
    let auxParams:urlParamsProps[] = [];
    auxParams = handleSearchDateRange();

    // Custom params
    if( note !== '' ){
      auxParams.push({
        param: 'note',
        value: note,
      });  
    }
    
    history.push({ search: createQuery( addToQuery(auxParams) ) });
  }

  const handleClean = () => {
    let auxParams = removeFromQuery(['from', 'end', 'note']);
    history.push({ search: createQuery( auxParams ) });

    handleCleanDateRange();
    setNote('');
  }

  useEffect(() => {
    console.log('Debo verificar los filtros activos');
  }, []);

  return (
    <React.Fragment>
      <DateRangeFilter />

      <Grid item>
        <FormControl className="form-item">
          <TextField variant="outlined" label="Nº nota"
            value={note}
            onChange={(event:any) => {
              setNote( event.target.value );
            }}
            />
        </FormControl>
      </Grid>

      <Grid item>
        <div className="btn-container-dashboard">
          <BaseButton className="btn btn-simple btn-dubra" onClick={handleSearch}>Buscar</BaseButton>
          <BaseButton className="btn btn-outlined btn-dubra-cancel" onClick={handleClean}><RotateLeftIcon />Limpiar</BaseButton>
        </div>
      </Grid>
    </React.Fragment>
  )
}

export default AttentionsNotesFilters