import React from 'react';
import NumberFormat from 'react-number-format';

function NumberFormatCustom(props:any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      
      thousandSeparator={props.thousandSeparator}
      decimalSeparator={props.decimalSeparator}
      prefix={props.prefix}

    />
  );
}

export function NumberFormatPesos(props:any) {
  return <NumberFormatCustom {...props} thousandSeparator={'.'} decimalSeparator={','} prefix={'$ '} />;
}

export function NumberFormatDolar(props:any) {
  return <NumberFormatCustom {...props} thousandSeparator={'.'} decimalSeparator={','} prefix={'USD '} />;
}

export function NumberFormatReal(props:any) {
  return <NumberFormatCustom {...props} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$ '} />;
}