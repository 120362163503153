import React, { useState, useEffect } from 'react';
import { FormContentProps, FormItemProps, FormActionsProps } from './props';
import { FormItemZone } from './enums';
import { Paper } from '@material-ui/core';

const FormContent = (props: FormContentProps) => {
  const [common, saveCommon] = useState<React.ReactElement[]>( [] );
  const [action, saveAction] = useState<React.ReactElement[]>( [] );

  useEffect(() => {

    let auxCommon:React.ReactElement[] = [];
    let auxAction:React.ReactElement[] = [];

    if( props.children && Array.isArray(props.children) ){

      props.children.map( (obj, key) => { 

        if( obj && obj.props ){
          if( obj.props.position === FormItemZone.Common ){
            auxCommon[ key ] = obj;
          } else if( obj.props.position === FormItemZone.Actions ){
            auxAction[ key ] = obj;
          }
        }

        return null;

      });

    } else {

      if( props.children && props.children.props ){
        if( props.children.props.position === FormItemZone.Common ){
          auxCommon[ 0 ] = props.children;
        } else if( props.children.props.position === FormItemZone.Actions ){
          auxAction[ 0 ] = props.children;
        }
      }

    }

    saveCommon( auxCommon );
    saveAction( auxAction );



  }, [props.children]);

  return (
    
      <form onSubmit={props.onSubmitAction} className={ 'form_container ' + ( props.mini ? 'content_mini ' : '' ) }>
        <Paper elevation={3}>
          <div className="paper_content_container">
            <h1 className="form_title">{props.title}</h1>
            <div className="form_detail">
              {common}
            </div>
          </div>
          <div className="form_actions">
            {action}            
          </div>
        </Paper>
      </form>
      
    
  )
}

export default FormContent;

export const FormItem = (props:FormItemProps) => {
  if( props.visible === false ){
    return null;
  } else {
    let auxClass = "form_item";
    if( props.expanded ){
      auxClass = "form_item expanded";
    }
    return (
      <div className={auxClass}>
        <div className="form_item_title">
          {props.title} {props.require ? <span className="form_item_require">*</span> : ''}
        </div>
        { props.help ?
            <div className="form_item_help">{props.help}</div>
          :
            ''
        }
        <div className="form_item_element">
          {props.children}
        </div>
      </div>
    )
  }
}

export const FormActions = (props:FormActionsProps) => {
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}

FormItem.defaultProps = {
  position: FormItemZone.Common,
}

FormActions.defaultProps = {
  position: FormItemZone.Actions,
}