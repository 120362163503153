import React, {useState, useEffect} from 'react';
import { baseApiURL, baseApiRest } from '../../../_services/fetch';
import { useHistory } from 'react-router-dom';
import useErrorManager from '../../common/ErrorManager';
import { errorSectionType, errorType } from '../../../_helpers/enums/all';
import { autocompleteCashRegisterProps } from '../../../_helpers/props/all';
import LinearProgress from '@material-ui/core/LinearProgress';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import BaseButton from '../../common/BaseButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CancelIcon from '@material-ui/icons/Cancel';

import { post, get } from '../../../_services/api';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

interface roleOptionsProps {
  uuid: string,
  value: string,
}

const CashRegisterNew = () => {
  let history = useHistory();

  const { alertBlock, showAlert, resetAlerts } = useErrorManager({ id: 'user_edit', section: errorSectionType.Custom });
  const [ loading, setLoading ] = useState<boolean>( true );
  const [ errorLoading, setErrorLoading ] = useState<boolean>( false );
  const [ sendData, setSendData ] = useState<boolean>( false );
  
  const [ activeUser, setActiveUser ]   = useState<string>( '' );
  const [ options, setOptions ] = useState<autocompleteCashRegisterProps[]>([]);
  
  const [ cashName, setCashName ] = useState<string>( '' );
  const [ validName, setValidName ] = useState<string>( '' );
  const [ store, setStore ] = useState<string>( '' );
  const [ validStore, setValidStore ] = useState<string>( '' );

  

  const getData = () =>{
    setLoading( true );
    let responseData = get(baseApiRest+`/api/1.0.0/get/json/cash-registers/data/${activeUser}`);
    
    responseData.then((response:any) => {
      if( response.data ){
        //console.log( response.data );
        setLoading( false );

        if( response.data.stores ){
          setOptions( response.data.stores );
        }
        
      }

    }).catch(error => {
      setLoading( false );
      setErrorLoading( true );
      showAlert({
        message: 'Hubo un error al cargar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });
  } 

  useEffect(() => {
    if( activeUser !== '' ){
      getData();
    }
  }, [activeUser]);

  useEffect(() => {
    if( history.location.pathname ){
      setCashName( '' );

      let auxData = history.location.pathname.split( '/' );

      setActiveUser( 'new' );
      if( history.location.pathname.includes('/new') ){
        setActiveUser( 'new' );
      } else {
        setActiveUser( auxData[3] );
      }

    }
  }, [history.location.pathname]);

  const handleSend = () => {
    // Validamos envío
    let auxSend = true;
    
    if( cashName === '' ){
      auxSend = false;
      setValidName( 'Este campo es obligatorio' );
      showAlert({
        message: 'Debes ingresar un nombre',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }

    if( store === '' ){
      auxSend = false;
      setValidStore( 'Este campo es obligatorio' );
      showAlert({
        message: 'Debes seleccionar la tienda donde crearás la caja',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }

    if( auxSend ){
      setSendData( true );
      resetAlerts();

      const responseData = post( baseApiRest+`/api/1.0.0/post/json/cash-registers/data/new`, {
        name: cashName,
        store: store,
      });

      responseData.then((response:any) => {
        if( response.data ){
          setSendData( false );
          //getData();
          
          /*
          if( activeUser === 'new' ){
            history.push('/users?new='+name);
          } else {
            history.push('/users?save='+name);
          }
          */
          history.push( '/admin/cash-registers?new='+cashName );

          showAlert({
            message: 'La información de la caja se ha modificado correctamente.',
            type_values: errorType.Success,
            section: errorSectionType.Custom,
          });
        }
      }).catch(error => {
        setSendData( false );
        showAlert({
          message: 'Hubo un error al cargar la información. ['+error+']',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      });
      
    }
    
  }
  
  return (
    loading ? <LinearProgress color="primary" /> :
      <div className="content_all">
        <div className="content">
          { alertBlock }
          { errorLoading ||
              <div className="cash-register-box">
                <Paper className="fullwidth">
                  <Grid container className="cash-register-container form-container-common">
                    <Grid item xs={12} sm={6} md={8} className="cash-register-content">
                      <Grid container  spacing={2} justify="space-between">
                        <Grid item>
                          <h1 className="form-title" style={{margin: 0}}>
                            { activeUser === 'new' ?
                                'Agregar caja:'
                              :
                                'Modificar caja:'
                            }
                          </h1>
                        </Grid>
                      </Grid>
                      <div className="form-detail-big">
                        <FormControl fullWidth className="form-item">
                          <TextField
                              label="Nombre de la caja"
                              value={cashName}
                              helperText={ validName === '' ? '' : validName }
                              error={ validName !== '' ? true : false }
                              variant="outlined"
                              onChange={(event:any) => {
                                if( event.target.value !== '' ){
                                  setValidName( '' );
                                }
                                setCashName( event.target.value );
                              }}
                            />
                        </FormControl>

                        <Autocomplete
                          autoSelect={true}
                          options={options}
                          getOptionLabel={(option) => option.name}
                          filterOptions={createFilterOptions({
                            matchFrom: 'start',
                            stringify: option => option.name,
                          })}
                          renderInput={(params) => <TextField {...params} label="Tienda" variant="outlined" required={true} helperText={validStore === '' ? '' :validStore } error={validStore !== '' ? true : false } />}
                          onChange={(event: any, newValue: autocompleteCashRegisterProps | null) => {
                            setValidStore('');
                            if( newValue ){
                              console.log('*****', newValue);
                              setStore( newValue.uuid );
                            } else {
                              setStore( 0 );
                            }
                          }}
                        />

                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className="cash-register-sidebar" >
                      <div className="cash-register-sidebar-info info-data">
                        
                        <div className="cash-register-sidebar-item">
                          <div className="cash-register-sidebar-item-detail">
                            
                            {/* <div className="sidebar-item-box ">
                              <div className="cash-register-sidebar-item-title">
                                PERMISOS
                              </div>
                            </div>
                            */}
                            <br />
                          </div>
                        </div>

                        
                      </div>
                      <div className="cash-register-sidebar-actions">
                        <BaseButton className="btn btn-sidebar-action btn-dubra" onClick={handleSend} disabled={ sendData } loading={sendData}>
                          Guardar pasajero <ChevronRightIcon />
                        </BaseButton>
                        <BaseButton className="btn btn-sidebar-action btn-dubra-cancel" onClick={() => { history.goBack() }} disabled={sendData}>
                          Cancelar <CancelIcon />
                        </BaseButton>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
          }

      </div>

    </div>
  )
}

export default CashRegisterNew;