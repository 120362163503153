import React from 'react'
import { DropTargetMonitor } from 'react-dnd'
import { DropTarget, DropTargetConnector } from 'react-dnd'
import { CartContainerProps } from '../../../_helpers/props/cash_register_props';
import Grid from '@material-ui/core/Grid';

const CartContainer: React.FC<CartContainerProps> = ({
  canDrop,
  isOver,
  connectDropTarget,
  empty,
  children,
}) => {
  const isActive = canDrop && isOver

  return (
    <div ref={connectDropTarget} className={'cart-zone '+( isActive ? ' cart-active ' : (canDrop ? ' cart-drop ' : '') )+( empty ? ' cart-empty ': '')}>
      { isActive ? 
          'Suelta aquí para agregar a la canasta' 
        : 
          <React.Fragment>
          {  !canDrop ?
                children !== undefined ?
                  <React.Fragment> 
                    { children !== 'Arrastre los productos que deseas agregar al carro' &&
                        <Grid container className="cart-header">
                          <Grid item xs={12} sm={5}></Grid>
                          <Grid item xs={12} sm={2}>
                            Adultos
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            Junior
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            Días
                          </Grid>
                        </Grid>
                    }
                    {children}
                  </React.Fragment>
                :
                ''
              :
                'Arrastre aquí para agregar el producto'
            
          }
          </React.Fragment>
      }
    </div>
  )
}

export default DropTarget(
  'box',
  {
    drop: () => ({ name: 'CartContainer' }),
  },
  (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }),
)(CartContainer)