import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import BaseButton from '../../common/BaseButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { errorSectionType, guaranteeStatusType, errorType } from '../../../_helpers/enums/all';
import useErrorManager from '../../common/ErrorManager';
import useFetch, { baseApiURL } from '../../../_services/fetch';
import { get, post } from '../../../_services/api';
import { autocompleteProps } from '../../../_helpers/props/all';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Grid, Divider } from '@material-ui/core';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Typography from '@material-ui/core/Typography';

interface BootAssignProps {
  date: string,
  closeAction     : () => void,
}

interface productsProps {
  name: string,
  total: string,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }),
);

const ProductListDate = ( props:BootAssignProps ) => {
  const { alertBlock, showAlert, resetAlerts } = useErrorManager({ id: 'productlist_modal', section: errorSectionType.Custom });
  const [ orderTitle, setOrderTitle ]           = useState<string>('');

  const [ error, setError ]                     = useState<any>();
  const [ loading, setLoading ]                 = useState<boolean>( true );
  const [ loadingSend, setLoadingSend ]         = useState<boolean>( false );
  const [ guaranteeStatus, setGuaranteeStatus ] = useState<number>(0);
  const [ activePending, setActivePending ] = useState<boolean>( false );

  const [ products, setProducts ] = useState<productsProps[]>([]);
  const [ productsHard, setProductsHard ] = useState<productsProps[]>([]);
  const [ saleProducts, setSaleProducts ] = useState<productsProps[]>([]);

  const [expanded, setExpanded] = React.useState<string | false>(false);


  const classes = useStyles();

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleLoad = () => {
    setLoading( true );
    let guarantee_response = get( baseApiURL+'/get/attentions/returns_group?date='+props.date);

    guarantee_response.then((response:any) => { 
      setLoading( false );
      console.log( response );

      if( response.rental.soft ){
        setProducts( response.rental.soft );
      }
      
      if( response.rental.hard ){
        setProductsHard( response.rental.hard );
      }

      if( response.sale ){
        setSaleProducts( response.sale );
      }

    })
    .catch( error => {
      setLoading( false );
      setError( error );
    });
  }

  useEffect( () => {
    if( error ){
      setOrderTitle( 'eo!' );
    }
  }, [error] );

  useEffect( () => {
    handleLoad();
  }, [] );

  const handleCancel = () => {
    resetAlerts();
    props.closeAction();
  }

  return (
    <React.Fragment>
      <DialogContent className="guarantee-box-content">
        { alertBlock }
        <DialogContentText id="alert-dialog-description">
          Estos son los productos que se arrendaron y vendieron el día XXXX
        </DialogContentText>
        <div className="guarantee-box-items">
          { !loading ?
              error ?
                <em>No logramos cargar la información de los productos, por favor intentalo más tarde o comunícate con los administradores.</em>
              : 
                <React.Fragment>

                  <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={classes.heading}>Arriendo blando</Typography>
                      <Typography className={classes.secondaryHeading}><small>Botas, pantalones, chaquetas, etc...</small></Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Divider />
                      { products.length !== 0 ?
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell><strong>Producto</strong></TableCell>
                                <TableCell align="right"><strong>Cantidad</strong></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {products.map((item:productsProps, index: number) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {item.name}
                                  </TableCell>
                                  <TableCell align="right">{item.total}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        :
                            <em>Sin productos</em>
                      }
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className={classes.heading}>Arriendo duro</Typography>
                      <Typography className={classes.secondaryHeading}><small>Ski, snowboard, etc...</small></Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      { productsHard.length !== 0 ?
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell><strong>Producto</strong></TableCell>
                                <TableCell align="right"><strong>Cantidad</strong></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {productsHard.map((item:productsProps, index: number) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {item.name}
                                  </TableCell>
                                  <TableCell align="right">{item.total}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        :
                        <em>Sin productos</em>
                      }
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                    >
                      <Typography className={classes.heading}>Ventas</Typography>
                      <Typography className={classes.secondaryHeading}><small>Lentes, gorros, bloqueador, etc...</small></Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      { saleProducts.length !== 0 ?
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell><strong>Producto</strong></TableCell>
                                <TableCell align="right"><strong>Cantidad</strong></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {saleProducts.map((item:productsProps, index: number) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {item.name}
                                  </TableCell>
                                  <TableCell align="right">{item.total}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        :
                        <em>Sin productos</em>
                      }
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                
                </React.Fragment>
            :
              <React.Fragment>
                <CircularProgress /><br /><br />
              </React.Fragment>
          }
        </div>
      </DialogContent>
      { !loading &&
          <DialogActions>
            <div className="btn-container-dashboard">
              <BaseButton className="btn btn-outlined btn-dubra-cancel" onClick={() => { handleCancel() }} disabled={loadingSend}>
                Cerrar
              </BaseButton>
            </div>
          </DialogActions>
      }
    </React.Fragment>
  )
}

export default ProductListDate;