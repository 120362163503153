import React, { useState, useEffect } from 'react';
import { DragSourceMonitor } from 'react-dnd'
import { ProductItemMiniProps, ItemInCartProps, ItemValueInCartProps, KitChangeValues } from '../../../_helpers/props/cash_register_props';
import { DragSource, DragSourceConnector } from 'react-dnd'
import Grid from '@material-ui/core/Grid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTools } from '@fortawesome/free-solid-svg-icons'

import InputNumber from '../../common/InputNumber';
import { useCartDataContext } from '../cart_provider';
import BaseButton from '../../common/BaseButton';
import ChangeDialog from './ChangeDialog';
import { baseApiURL } from '../../../_services/fetch';
import Divider from '@material-ui/core/Divider';

const ProductItemMini = (props:ProductItemMiniProps) => {
  const opacity = props.isDragging ? 0.4 : 1
  
  return (
    <React.Fragment>
      { props.separator &&
          <Grid item xs={12}>
            <br /><Divider /><br />
          </Grid>
      }
      <Grid item xs={6} sm={4} ref={props.connectDragSource} className="productItemMini" style={{ opacity }}>
        <div className="productDetail" onClick={props.onClickHandle}>
          <img src={props.image} width={60} />
          <br />{props.name}
        </div>
      </Grid>
    </React.Fragment>
  )
}

export default DragSource(
  'box',
  {
    beginDrag: (props: ProductItemMiniProps) => ({ name: props.name }),
    endDrag(props: ProductItemMiniProps, monitor: DragSourceMonitor) {
      //const item = monitor.getItem()
      const dropResult = monitor.getDropResult()

      if (dropResult) {
        //alert(`You dropped ${item.name} into ${dropResult.name}!`)
        // Debo agregar el producto en el listado del carro
        props.onDropHandle();
      }

    },
  },
  (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
)(ProductItemMini)


export const ProductItem = (props:ItemInCartProps) => {
  const { removeItem, getProductName, changeValue } = useCartDataContext();

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [ auxJuniorActive, setAuxJuniorActive] = useState<boolean>( false );
  const [ active, setActive ] = useState<boolean>( false );
  const [ openDialog, setOpenDialog ] = React.useState(false);
  const [ dataProducts, setDataProducts ] = useState();

  const [ auxChangesAdultsList, setAuxChangesAdultsList ] = useState<any>();
  const [ auxChangesJuniorList, setAuxChangesJuniorList ] = useState<any>();

  useEffect( () => {
    // Revisamos si tiene versión Jr
    let auxSizesLength = props.sizes.length;
    for( let i = 0; i < auxSizesLength; i++ ){
      if( props.sizes[i].name === 'Junior' ){
        setAuxJuniorActive( true );
      }
    }
  }, []);
  
  // Type: Arriendo - Kit Productos - Venta
  const handleMinus = ( aux_action:ItemValueInCartProps ) => {
    if( changeValue ){
      changeValue({
        type: aux_action.type,
        action: 'minus',
        product_id: props.product_id,
      });
    }
  }
  const handleAdd = ( aux_action:ItemValueInCartProps ) => {
    if( changeValue ){
      changeValue({
        type: aux_action.type,
        action: 'add',
        product_id: props.product_id,
      });
    }
  }

  const handleRemove = () => {
    setActive( false );
    props.index_item !== undefined && removeItem && removeItem( props.index_item );
  }

  const handleChange = () => {
    var auxInfoExpanded:Array<any> = [];
    setOpenDialog(true);

  }
  
  const handleCloseChange = () => {
    setOpenDialog(false);
    setActive( false );
  }

  useEffect( () => {

    if( props.adults ){
      let auxAdult = props.changes.adults.map( (item:any, index:number) => {
        let auxActive = false;
        let aux_name = 'Adulto '+( index + 1 );
        let auxChanges:any = [];
    
        // Revisamos si viene nombre
        if( item.name_pax !== '' ){
          aux_name = item.name_pax;
        }
    
        // Revisamos los productos
        const changesLength = item.data.length;
        for (let i = 0; i < changesLength; i++) {
          if( ( index + 1 ) <= props.adults! ){
            if( item.data[i].product_new !== null ){
              auxActive = true;
              auxChanges.push( aux_name+' cambia '+getProductName({ size: 'adults', variation_id: item.data[i].product_original })+' por '+getProductName({ size: 'adults', variation_id: item.data[i].product_new }) );
            }
          } else {
            break;
          }
        }
        
        let changesReturn = auxChanges.map((item:any, index:number) => {
          return <div key={index}>{item}</div>;
        });
    
        if( auxActive ){
          return changesReturn;
        } else {
          return null
        }
      } );

      setAuxChangesAdultsList( auxAdult );
    } else {
      setAuxChangesAdultsList( [] );
    }

    if( props.junior ){
      let auxJunior = props.changes.junior.map( (item:any, index:number) => {
        let auxActive = false;
        let aux_name = 'Junior '+( index + 1 );
        let auxChanges:any = [];
    
        // Revisamos si viene nombre
        if( item.name_pax !== '' ){
          aux_name = item.name_pax;
        }
    
        // Revisamos los productos
        const changesLength = item.data.length;
        for (let i = 0; i < changesLength; i++) {
          if( ( index + 1 ) <= props.junior! ){
            if( item.data[i].product_new !== null ){
              auxActive = true;
              auxChanges.push( aux_name+' cambia '+getProductName({ size: 'junior', variation_id: item.data[i].product_original })+' por '+getProductName({ size: 'junior', variation_id: item.data[i].product_new }) );
            }
          } else {
            break;
          }
        }
        
        let changesReturn = auxChanges.map((item:any, index:number) => {
          return <div key={index}>{item}</div>;
        });
    
        if( auxActive ){
          return changesReturn;
        } else {
          return null
        }
      } );

      setAuxChangesJuniorList( auxJunior );
    } else {
      setAuxChangesJuniorList( [] );
    }


  }, [ props.adults, props.junior, props.changes ] );




  
  return (
    <div className="item-cart-box">
      <Grid container className={'option-settings '+( active ? 'active' : '' )}>
        <Grid item xs={12} sm={6} className="option-action">
          { props.type === 'Kit Productos' &&
              (props.junior !== 0 || props.adults !== 0)?
                <BaseButton className="btn btn-dubra-success btn-dubra-big" onClick={handleChange}>Modificar Kit</BaseButton>
              :
                ''
          }
          <BaseButton className="btn btn-dubra-cancel btn-dubra-big" onClick={handleRemove}>Quitar producto</BaseButton>
        </Grid>
        <Grid item xs={12} sm={6} className="option-cancel">
          <BaseButton className="btn btn-dubra btn-dubra-big" onClick={() => { setActive( false ) }}>Cancelar</BaseButton>
        </Grid>
      </Grid>


      
      <Grid container>
        <Grid item xs={12} sm={5} className="item-cart-box-name">
          <div className="item-cart-box-image">
            <img src={ baseApiURL + props.image} style={{maxWidth: '100%',}} />
          </div>
          <div>
            {props.product_name}
            <br />
            <small>
              { auxChangesAdultsList }
              { auxChangesJuniorList }
            </small>
          </div>
        </Grid>
        <Grid item xs={12} sm={2} className="item-cart-box-adults">
          <InputNumber value={ props.adults ? props.adults : 0 } onLess={ () => { handleMinus({type: 'adults'}) } } onPlus={ () => { handleAdd({type: 'adults'}) } } />
        </Grid>
        <Grid item xs={12} sm={2} className="item-cart-box-junior">
          { auxJuniorActive &&
            <InputNumber value={ props.junior ? props.junior : 0 } onLess={ () => { handleMinus({type: 'junior'}) } } onPlus={ () => { handleAdd({type: 'junior'}) } } /> 
          }
        </Grid>
        <Grid item xs={12} sm={2} className="item-cart-box-days">
          { props.type !== 'Venta' &&
            <InputNumber value={ props.days ? props.days : 0 } onLess={ () => { handleMinus({type: 'days'}) } } onPlus={ () => { handleAdd( {type: 'days'} ) } } />
          }
        </Grid>
        <Grid item xs={12} sm={1} className="item-cart-box-action">
          <BaseButton onClick={() => { setActive( true ) }} className="btn-clean"><FontAwesomeIcon icon={ faTools } /></BaseButton>
        </Grid>
      </Grid>
      { props.type === 'Kit Productos' ?
          <ChangeDialog product_name={props.product_name} open={openDialog} closeDialog={handleCloseChange} getProductsName={setDataProducts} product_id={props.product_id} itemIndex={props.index_item !== undefined ? props.index_item : 9999} />
        :
          ''
      }
    </div>
  )
}