import React, { useEffect, useState } from 'react';
import BlockContent from '../../common/BlockContent';

import { useHistory } from 'react-router-dom';
import useFetch from '../../../_services/fetch';
import { errorSectionType, errorType } from '../../../_helpers/enums/all';
import useErrorManager from '../../common/ErrorManager';
import { ResponsiveContainer, Area,  AreaChart, Tooltip, ReferenceLine, XAxis, YAxis, TooltipProps, CartesianGrid } from 'recharts';
import { actionProps } from '../../../_helpers/props/all';
import {DateTime} from 'luxon';


interface summaryProps {
  name: string,
  rental: number,
  sale: number,
}

const LastWeekSaleBlock = () => {
  const { response, loading, error } = useFetch({ method: 'GET', path: '/get/dashboard/last-sales'});
  const { alertBlock, showAlert } = useErrorManager({ id: 'lastsales_block', section: errorSectionType.Custom });
  
  const [ dataLoaded, setDataLoaded ] = useState<boolean>( false );
  const [ actions ]       = useState<actionProps[]>([]);
  const [ data, setData ]             = useState<summaryProps[]>([]);

  /*
  const data = [
    {
      "name": "09/05",
      "rental": 1890,
      "sale": 4800,
    },
  ]
  */

 

  useEffect( () => {
    if( response ){

      if( response.data ){
        setDataLoaded( true );
        const valuesLength = response.data.length;

        let dataDays:summaryProps[] = [];
        let weekAgo = DateTime.local().minus({ days: 7 });
        for(let i=1; i<=7; i++){
          const activeDate = weekAgo.plus({ days: i });
          const dateFormatted = activeDate.toFormat("dd'/'LL'/'yyyy");
          
          // Get data
          let rental_value  = 0;
          let sale_value    = 0;

          // Busco el día
          for(let i_data=0; i_data<valuesLength; i_data++){
            if( response.data[i_data].date === dateFormatted ){ // Esto tenía doble ==
              //console.log( 'Encuentro el dia', response.data[i_data] );
              rental_value  = response.data[i_data].total_rental;
              sale_value    = response.data[i_data].total_sale;
              break;
            }
          }

          dataDays.push({
            name: activeDate.toFormat("dd'/'LL"),
            rental: rental_value,
            sale: sale_value,
          });
        }

        setData( dataDays );
        

      }

    }
  }, [response] );

  useEffect( () => {
    if( error ){
      showAlert({
        message: 'Hubo un error al cargar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }
  }, [error] );

  return (
    <BlockContent title="Resumen de ventas" loading={loading} actions={ actions }>
      { alertBlock }
      { dataLoaded &&
        <ResponsiveContainer width={700} height={310}>
          <AreaChart data={data}
            margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
            <XAxis dataKey="name"/>
            <YAxis tickFormatter={tick => { return( '$'+(new Intl.NumberFormat("es-CL").format( tick )) ); }} />
            <CartesianGrid strokeDasharray="3 3" />
            {/*<Tooltip content={<CustomTooltip />} />*/}<Tooltip />
            <ReferenceLine x="Page C" stroke="green" label="Min PAGE" />
            {/*<ReferenceLine y={4000} label="Mínimo comisión" stroke="red" strokeDasharray="3 3" />*/}
            <Area type="monotone" dataKey="rental" stroke="#ff6666" fill="#ff6600" />
            <Area type="monotone" dataKey="sale" stroke="#8884d8" fill="#8884d8" />
          </AreaChart>
        </ResponsiveContainer>
      }
    </BlockContent>
  );
}

export default LastWeekSaleBlock;