import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import BlockContent from '../../../common/BlockContent';
import { errorSectionType, errorType } from '../../../../_helpers/enums/all';
import { baseApiURL } from '../../../../_services/fetch';
import useErrorManager from '../../../common/ErrorManager';
import { get } from '../../../../_services/api';
import { cashRegisterStatusType } from '../../../../_helpers/enums/all';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import StoreIcon from '@material-ui/icons/Store';
import BaseButton from '../../../common/BaseButton';

interface cashRegistersListProps {
  name          : string,
  status_id     : number,
  cashier_name  : string,
}

const CashRegisterBlock = () => {
  let history = useHistory()
  const { showAlert } = useErrorManager({ id: 'stores_block', section: errorSectionType.Custom });
  
  const [ loading, setLoading ]   = useState<boolean>( true );
  const [ cashRegisters, setCashRegisters ] = useState<cashRegistersListProps[]>([]);

  const getData = () => {
    setLoading( true );
    const responseData = get(baseApiURL+'/get/cash-registers/all/list');

    responseData.then((response:any) => {
      if( response.data ){
        setLoading( false );
        setCashRegisters( response.data );
      }
    }).catch(error => {
      setLoading( false );
      showAlert({
        message: 'Hubo un error al cargar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });

  }
  
  useEffect(() => {
    getData();
  }, []);


  return (
    <BlockContent title="Tiendas" loading={loading}>
      <List>
        { cashRegisters.length !== 0 ?
            cashRegisters.map((item:cashRegistersListProps, index:number) => {
              let auxClass = '';
              if( cashRegisterStatusType.Open === item.status_id ){
                auxClass = 'status_active';
              } else if( cashRegisterStatusType.Closed === item.status_id || cashRegisterStatusType.Disabled === item.status_id || cashRegisterStatusType.ForcedClosed === item.status_id ){
                auxClass = 'status_closed';
              } else {
                auxClass = 'status_pending';
              }
    
              return (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar className={auxClass}>
                      <StoreIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={item.name} secondary={item.cashier_name} />
                </ListItem>
              )
            })
          : 
            <div>
              <i>No existen cajas disponibles</i>
            </div>

        }
      </List>
      <div className="btn-container-dashboard">
        <BaseButton className="btn btn-simple btn-dubra" onClick={() => { history.push('/admin/cash-registers'); }}>Modificar valores</BaseButton>
      </div>
    </BlockContent>
  );
}

export default CashRegisterBlock;