import React, {useState, useEffect} from 'react';
import {HideContentProps, HiddenContentProps, HiddenActiveProps} from './props';

export const HiddenContent = (props:HiddenContentProps) => {
  return (
    <div className={'hidden_content bottom_content '+(props.active ? 'active ' : '')}>
      {props.children}
    </div>
  )
}

export const ActiveContent = (props:HiddenActiveProps) => {
  return (
    <div className={'active_content '+(props.active ? 'disabled ' : '')}>
      {props.children}
    </div>
  )
}

const HideContent = (props:HideContentProps) => {
  const [heightContainer, setheightContainer] = useState( window.innerHeight );  

  useEffect( () => {
    function handleResize() {
      setheightContainer( window.innerHeight );
    }

    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <div className="hidden_content_container" style={ {height: heightContainer} } >
      {props.children}
    </div>
  )
}

export default HideContent;