import React , { useState, useEffect } from 'react';
import Alert, { Color } from '@material-ui/lab/Alert';

import { errorAlertProps, errorManagerProps } from '../../_helpers/props/all';
import { errorType, errorSectionType } from '../../_helpers/enums/all';
import { useErrorDataContext } from './errorCenter';

const ErrorAlert = ( props: errorAlertProps  ) => {
  let auxType:Color = 'warning';

  if( props.type_values === errorType.Error ){
    auxType = 'error';
  } else if( props.type_values === errorType.Warning ){
    auxType = 'warning';
  } else if( props.type_values === errorType.Success ){
    auxType = 'success';
  }

  return (
    <Alert
      severity={auxType}
      onClose={() => {
        if( props.id !== undefined ){
          props.removeItem && props.removeItem( props.id );
        }
      }}
    >
      {props.message}
    </Alert>
  );
}

const getHash = () => {
  return Math.random().toString(36).substring(7);
}

const useErrorManager = ( props:errorManagerProps ) => {
  const { error, addAlertToBlock, destroyAlert, destroyAll } = useErrorDataContext();
  const [ section ] = useState<errorSectionType>( props.section );
  const [ area ] = useState<string>( props.id );
  
  const [ idBlock, setIdBlock ] = useState<string>( props.id );
  const [ newAlert, setNewAlerts] = useState<errorAlertProps>();
  const [ alertBlock, setAlertBlock] = useState<React.ReactNode>();
  
  const resetAlerts = () => {
    destroyAll();
  }

  const showAlert = ( props:errorAlertProps ) => {
    if( idBlock === '' ){
      setIdBlock( getHash() );
    }

    let auxType = 3;
    if( props.type_values !== undefined ){
      auxType = props.type_values;
    }

    const errorItem:errorAlertProps = {
      message     : props.message,
      type_values : auxType,
      section     : props.section,
      id          : getHash(),
    }

    setNewAlerts( errorItem );
  }

  const removeAlert = ( id:string ) => {

    destroyAlert({
      area      : area,
      section   : section,
      alert_id  : id,
    });

  }

  useEffect( () => {
    if( newAlert ){

      addAlertToBlock({
        area    : area,
        section : section,
        alert   : newAlert,
      });

    }
  }, [newAlert] );



  useEffect(() => {

    let auxData:Array<errorAlertProps> = [];
    if( section === errorSectionType.All ){
      if( error.all && error.all.length !== 0 ){
        let auxErrorLength = error.all.length;
        for( let i=0;  i<auxErrorLength; i++ ){
          if( error.all[i].area === area ){
            auxData = error.all[i].data;
            break;
          }
        }
      }
    } else if( section === errorSectionType.General ){
      if( error.general && error.general.length !== 0 ){
        let auxErrorLength = error.general.length;
        for( let i=0;  i<auxErrorLength; i++ ){
          if( error.general[i].area === area ){
            auxData = error.general[i].data;
            break;
          }
        }
      }
    } else if( section === errorSectionType.Custom ){
      if( error.custom && error.custom.length !== 0 ){
        let auxErrorLength = error.custom.length;
        for( let i=0;  i<auxErrorLength; i++ ){
          if( error.custom[i].area === area ){
            auxData = error.custom[i].data;
            break;
          }
        }
      }
    }

    let alertContainer:any = auxData.map( (item:any, index:number) => {
      return <li key={index}><ErrorAlert {...item} removeItem={removeAlert} /></li>
    } );

    setAlertBlock( <ul className="error-box">{alertContainer}</ul> );

  }, [ error ]);

  const removeItem = ( index:number ) => {
    console.log( 'acá vamos a eliminar los mensajes' );
  }

  return { alertBlock, showAlert, removeItem, resetAlerts }
};

export default useErrorManager;

