import React from 'react';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

interface lastSaleProp {
  date?   : string,
  title   : string,
  total?  : number,
}

const LastSaleItem = (props:lastSaleProp) => {
  return (
    <div className="sidebar-item-box">
      <div className="sidebar-item-detail">

        <Grid container>
          <Grid item xs={8} sm={9}>
            {
              props.date ?
                <Chip
                  size="small"
                  label={props.date}
                />
              :
                ''
            }
            &nbsp; &nbsp;
            {props.title}
          </Grid>
          <Grid item xs={4} sm={3}>
          {
              props.total ?
                '$'+new Intl.NumberFormat('es-CL').format(props.total)
              :
                '-'
            }
          </Grid>
        </Grid>

      </div>
    </div>
  )
}

export default LastSaleItem;