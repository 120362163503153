import * as jwt from "jsonwebtoken";

const ACCESS_TOKEN = "access-token";
const REFRESH_TOKEN = "refresh-token";

interface JWTPayload {
  exp: number;
}

const decodeJWT = (token: string) => {
  const decoded = jwt.decode(token);
  if (!decoded) return undefined;
  return decoded as JWTPayload;
};

const getUnixTimestampSeconds = () => {
  return Math.round(new Date().getTime() / 1000);
};

const getSecondsUntilExpired = () => {
  const token = getAccessToken();
  if (!token) return 0;
  const decoded = decodeJWT(token);
  if (!decoded) return 0;
  return decoded.exp - getUnixTimestampSeconds();
};

export const isAuthenticated = () => getSecondsUntilExpired() > 0;

export const reset = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const setAccessToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const setRefreshToken = (token: string) => {
  localStorage.setItem(REFRESH_TOKEN, token);
};