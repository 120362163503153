import React, { useEffect, useState } from 'react';
import BlockContent from '../../../common/BlockContent';
import { stockDataProps, stockProps } from '../../../../_helpers/props/stock';

import Typography from '@material-ui/core/Typography';
import BaseButton from '../../../common/BaseButton';
import { useHistory } from 'react-router-dom';
import useFetch, { baseApiURL } from '../../../../_services/fetch';
import { errorSectionType, errorType, roleType } from '../../../../_helpers/enums/all';
import useErrorManager from '../../../common/ErrorManager';
import Grid from '@material-ui/core/Grid';
import {actionProps} from '../../../../_helpers/props/all';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { get, post } from '../../../../_services/api';
import { FormItem } from '../../../common/FormContent';
import InputNumber from '../../../common/InputNumber';

import StockAssign from './StockAssign';

import { useAuthDataContext } from '../../../../_auth/auth_provider';

const StockVentasBlock = () => {
  let history = useHistory();
  const { userPermission } = useAuthDataContext();

  const { alertBlock, showAlert } = useErrorManager({ id: 'stockSale_block', section: errorSectionType.Custom });
  const alertModalAssign = useErrorManager({ id: 'fondofijo_modal_assign', section: errorSectionType.Custom });
  const alertModalAdd = useErrorManager({ id: 'fondofijo_modal_add', section: errorSectionType.Custom });
  
  const [ dataLoaded, setDataLoaded ]                   = useState<boolean>( false );
  const [ data, setData ]                               = useState<any>();
  const [ actions, setActions ]                         = useState<actionProps[]>([]);
  const [ header, setHeader ]                           = useState<any[]>([]);
  const [ rows, setRows ]                               = useState<any[]>([]);
  const [ dataProducts, setDataProducts ]               = useState<any[]>([]);

  const [ loading, setLoading ]                         = useState<boolean>( true );
  const [ activeDialogAdd, setActiveDialogAdd ]         = useState<boolean>( false );
  const [ activeDialogAssign, setActiveDialogAssign ]   = useState<boolean>( false );
  const [ savingData, setSavingData ]                   = useState<boolean>( false );

  const getData = () => {
    setLoading( true );
    const responseData = get(baseApiURL+'/get/dashboard/stock-ventas');

    responseData.then((response:any) => {
      if( response.data ){
        setLoading( false );
        setDataLoaded( true );
        setData( response.data );
        interface productVentaProps {
          variation_id: number,
          stock: number[],
        }

        // Row
        var auxProducts:any[] = [];
        var auxProductsList:any[] = [];
        var auxProductValues:any[] = [];
        let productsLength;
        if( response.data.productos ){
          productsLength = response.data.productos.length;
        } else {
          productsLength = 0;
        }

        
        for(let i=0; i<productsLength; i++){
          auxProductValues.push( {
            variation_id: response.data.productos[i].variation_id,
            stock: [],
          } );
        }

        // Headers
        var auxHeader:any[] = [
          <TableCell key={0}>Productos</TableCell>,
          <TableCell key={1} align="right">Stock bodega</TableCell>,
        ];
        let headerLength = response.data.tiendas.length;
        for(let i=0; i<headerLength; i++){
          auxHeader.push(<TableCell key={i+2} align="right">{response.data.tiendas[i].name}</TableCell>);

          for(let i_stock=0; i_stock<productsLength; i_stock++){
            let totalStockItem = 0;
            let auxId = auxProductValues[i_stock].variation_id;
            if(response.data.tiendas[i].stock[ auxId ]){
              totalStockItem = response.data.tiendas[i].stock[ auxId ];
            }

            auxProductValues[i_stock].stock.push( totalStockItem );
          }
        }
        setHeader( auxHeader );

        // Finalizamos agregando los totales
        for(let i=0; i<productsLength; i++){

          // Debo encontrar el dato de producto
          let dataStock = [];
          for(let i_stock=0; i_stock<productsLength; i_stock++){
            if( auxProductValues[i_stock].variation_id == response.data.productos[i].variation_id ){
              dataStock = auxProductValues[i_stock].stock;
              break;
            }
          }

          auxProductsList.push({
            name: response.data.productos[i].name,
            variation_id: parseInt(response.data.productos[i].variation_id),
            total: 0,
          });

          auxProducts.push(
            <TableRow key={ auxProducts.length }>
              <TableCell component="th" scope="row">
                { response.data.productos[i].name }
              </TableCell>
              <TableCell align="right">
                { response.data.productos[i].stock }
              </TableCell>
              { dataStock.map((item:any, index:number) => {
                return <TableCell key={index} align="right">{item}</TableCell>;
              }) }
            </TableRow>
          );
        }

        setRows( auxProducts );
        setDataProducts( auxProductsList );


      }
    }).catch(error => {
      setLoading( false );
      showAlert({
        message: 'Hubo un error al cargar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });

  }

  useEffect( () => {
    getData();
  },[]  );

  const handleCancelAssign = () => {
    setActiveDialogAssign( false );
    alertModalAssign.resetAlerts();
  }

  const handleSaveAssign = ( stockAvailable:stockDataProps[], stock:stockProps[] ) => {
    setSavingData( true );
    alertModalAssign.resetAlerts();

    const responseData = post(baseApiURL+'/post/admin/stock-ventas-assign', {
      values: stockAvailable,
      cash_register: stock,
    });

    responseData.then((response:any) => {
      if( response.data && response.data === 'ok' ){
        setSavingData( false );
        getData();
        setActiveDialogAssign( false );
      } else {
        setSavingData( false );
        alertModalAssign.showAlert({
          message: 'Hubo un error al guardar la información. [Error: Problema en la respuesta]',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      }
    }).catch(error => {
      setSavingData( false );
      alertModalAssign.showAlert({
        message: 'Hubo un error al guardar la información. ['+error+']',
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    });
  }

  const resetStockValues = () => {
    let auxProductChange:any[] = [];
    let dataLength:number = dataProducts.length;
    for( let i=0; i<dataLength; i++ ){
      let auxNewData = dataProducts[i];
      auxNewData.total = 0;
      auxProductChange.push( auxNewData );
    }

    setDataProducts( auxProductChange );
  }

  const handleCancelAdd = () => {
    setActiveDialogAdd( false );
    alertModalAdd.resetAlerts();
    resetStockValues();
  }

  const handleSaveAdd = () => {
    setSavingData( true );
    alertModalAdd.resetAlerts();

    // Verificamos que al menos uno no venga en cero
    let saveData:boolean = false;
    let dataLength:number = dataProducts.length;
    for( let i=0; i<dataLength; i++ ){
      let auxNewData = dataProducts[i];
      if( auxNewData.total !== 0 ){
        saveData = true;
        break;
      }
    }

    if( saveData ){
      const responseData = post(baseApiURL+'/post/admin/stock-ventas', {
        values: dataProducts,
      });
  
      responseData.then((response:any) => {
        if( response.data && response.data === 'ok' ){
          setSavingData( false );
          getData();
          setActiveDialogAdd( false );
        } else {
          setSavingData( false );
          alertModalAdd.showAlert({
            message: 'Hubo un error al guardar la información. [Error: Problema en la respuesta]',
            type_values: errorType.Error,
            section: errorSectionType.Custom,
          });
        }
      }).catch(error => {
        setSavingData( false );
        alertModalAdd.showAlert({
          message: 'Hubo un error al guardar la información. ['+error+']',
          type_values: errorType.Error,
          section: errorSectionType.Custom,
        });
      });
    } else {
      setSavingData( false );
      setActiveDialogAdd( false );
      alertModalAdd.resetAlerts();
      resetStockValues();
    }
  }

  const stockPlus = ( variation_id:number ) => {
    let auxProductChange:any[] = [];
    let dataLength:number = dataProducts.length;
    for( let i=0; i<dataLength; i++ ){
      let auxNewData = dataProducts[i];
      if( auxNewData.variation_id === variation_id ){
        auxNewData.total = ( auxNewData.total + 1 );
      }

      auxProductChange.push( auxNewData );
    }

    setDataProducts( auxProductChange );
  }

  const stockMinus = ( variation_id:number ) => {
    let auxProductChange:any[] = [];
    let dataLength:number = dataProducts.length;
    for( let i=0; i<dataLength; i++ ){
      let auxNewData = dataProducts[i];
      if( auxNewData.variation_id === variation_id ){
        if( auxNewData.total ){
          auxNewData.total = ( auxNewData.total - 1 );
        }
      }

      auxProductChange.push( auxNewData );
    }

    setDataProducts( auxProductChange );
  }

  const ProductStock = ( props:any ) => {
    const listProductsStock = props.products.map((item:any, index:number) => {
      if( item ){
        return (
          <Grid container className="form_table_item" key={index} alignItems="center">
            <Grid xs={9} sm={8} item className="form_table_content">
              {item.name}
            </Grid>
            <Grid xs={3} sm={4} item className="form_table_content form_col_center">
              <InputNumber value={item.total} onLess={() => { stockMinus( item.variation_id ); }} onPlus={() => { stockPlus( item.variation_id ); }} />
            </Grid>
          </Grid>
        )
      } else {
        return null
      }
    });
  
    return listProductsStock;
  
  }

  return (
    <BlockContent title="Stock venta" loading={loading} actions={ actions }>
      { alertBlock }
      { dataLoaded &&
        <React.Fragment>
          
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {header.map((row) => (
                    row
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  row
                ))}
              </TableBody>
            </Table>
          
          <br />
          <div className="btn-container-dashboard">
            { userPermission([ roleType.Admin, roleType.SuperAdmin ]) &&
                <BaseButton className="btn btn-simple btn-dubra" onClick={() => { setActiveDialogAdd(true); }}>Agregar stock</BaseButton>
            }
            { userPermission([ roleType.Admin, roleType.SuperAdmin, roleType.CashSupervisor ]) &&
                <BaseButton className="btn btn-simple btn-dubra" onClick={() => { setActiveDialogAssign(true); }}>Asignar stock</BaseButton>
            }
          </div>
        </React.Fragment>
      }
      <Dialog
        open={activeDialogAdd}
        onClose={handleCancelAdd}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Agregar stock ventas
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Utiliza este formulario para agregar nuevos productos al stock total de venta
            <br />
          </DialogContentText>
          { alertModalAdd.alertBlock }

          <div className="form_container">
            <div className="form_detail">
              <FormItem title=" ">
                <div className="form_table">
                  <Grid container className="form_table_item">
                    <Grid xs={9} sm={8} item className="form_table_header">
                      Producto
                    </Grid>
                    <Grid xs={3} sm={4} item className="form_table_header form_col_center">
                      Cantidad
                    </Grid>
                  </Grid>
                  <ProductStock products={dataProducts} />
                </div>
              </FormItem>
            </div>
          </div>
          
        </DialogContent>
        <DialogActions>
          <div className="btn-container-dashboard">
            <BaseButton className="btn btn-outlined btn-dubra-cancel" onClick={handleCancelAdd} disabled={savingData}>
              Cancelar
            </BaseButton>
            <BaseButton className="btn btn-simple btn-dubra" onClick={handleSaveAdd} loading={savingData}>
              Guardar
            </BaseButton>
          </div>
        </DialogActions>
      </Dialog>
      <StockAssign data={data} active={activeDialogAssign} handleCancel={handleCancelAssign} handleSend={handleSaveAssign} savingData={savingData} error={alertModalAssign} />
    </BlockContent>
  );
}

export default StockVentasBlock;