import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

interface ITableSkeletonProps {
    rows: number,
    columns: number,
    hasActions: boolean
}

export const TableSkeleton = (props: ITableSkeletonProps):JSX.Element => {
    return(
        <TableBody>
        {[...Array(props.rows)].map((x, i) => 
            <TableRow key={'ske-'+ i}>
                {[...Array(props.columns)].map((x, j) =>
                    <TableCell key={'skel-'+ j} >{(props.hasActions && j === (props.columns - 1)) ? <Skeleton variant="circle" width={40} height={40}></Skeleton>  : <Skeleton/>}</TableCell>
                )}
            </TableRow>
        )}
        </TableBody>
    
    );
}