import React, {useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import useFetch from '../../_services/fetch';
import useErrorManager from '../common/ErrorManager';
import { errorSectionType, errorType } from '../../_helpers/enums/all';

interface reservationDetailProps {
    reservationUUID: string,
}

const SkiroomReservationDetail = (props:reservationDetailProps) => {
  const { response, loading, error } = useFetch({ method: 'GET', path: '/get/reservation/'+props.reservationUUID});
  const { alertBlock, showAlert } = useErrorManager({ id: 'reservations_summary', section: errorSectionType.Custom });

  const [agency, setAgency] = useState<string>('-');
  const [guide, setGuide] = useState<string>('-');
  const [date, setDate] = useState<string>('-');
  const [status, setStatus] = useState<string>('-');

  useEffect( () => {
    if( response ){
      //console.log( '********', response );

      setAgency( response.data.agency );
      setGuide( response.data.guia );
      setDate( response.data.date );
      setStatus( response.data.status );
    }
  }, [response] )

  useEffect( () => {
    if( error ){
      showAlert({
        message: 'Hubo un error al cargar los datos: '+error,
        type_values: errorType.Error,
        section: errorSectionType.Custom,
      });
    }
  }, [error] );

  return (
      <Paper className="content">
        <div className="paper_content_container">
          <Typography variant="h6" id="tableTitle" component="div">
            Información sobre la reserva - {date}
          </Typography> 
          { //alertBlock 
          }
          { loading ?
              <React.Fragment>
                <br />
                <LinearProgress />
              </React.Fragment>
            :
              <List >
                <ListItem alignItems="flex-start">
                    <ListItemText
                    primary={""}
                    secondary={
                        <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                        >
                            Estado: 
                        </Typography>
                        &nbsp; {status}
                        <br /><Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                        >
                            Agencia: 
                        </Typography>
                        &nbsp; {agency}
                        <br /><Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                        >
                            Guía: 
                        </Typography>
                        &nbsp; {guide}
                        </React.Fragment>
                    }
                    />
                </ListItem>
                
            </List>
          }
        </div>
      </Paper>
  )
}

export default SkiroomReservationDetail;